import { Component, Input, OnInit } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { count, map, Observable, startWith } from 'rxjs';
import { ClinicResponse } from '../../../models/clinic.model';
import { CommonModule } from '@angular/common';
import { FormControl, Validators,ReactiveFormsModule,FormGroup, Form } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClinicService } from '../../../services/clinic.service';

@Component({
  selector: 'app-search-clinic',
  standalone: true,
  imports: [
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    CommonModule
  ],
  templateUrl: './search-clinic.component.html',
  styleUrl: './search-clinic.component.scss'
})
export class SearchClinicComponent implements OnInit {
  @Input() isSaving:boolean=false; // pass in the form saving variable to disable inputs on the form
  @Input() clinicControl:FormControl = new FormControl(); // Pass in the form control to be manipulated by this selector.
  // Output

  clinics!:ClinicResponse[]
  filteredClinics: Observable<ClinicResponse[]>


  constructor(
    private clinicService:ClinicService,
    private toastr:ToastrService
  ) {
    this.filteredClinics = this.clinicControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterClinics(value))
    )
  }

  ngOnInit(): void {
    // Ensure clinicControl is initialized before Angular binds to it
    if (!this.clinicControl) {
      this.clinicControl = new FormControl();
    }
  
    // Fetch clinics before filtering starts
    this.getClinics();
  
    // Ensure the input field is properly enabled/disabled based on `isSaving`
    if (this.isSaving) {
      this.clinicControl.disable();
    } else {
      this.clinicControl.enable();
    }
  }
  

  private _filterClinics(value:string) : ClinicResponse[] {
    const filterValue = value.toLowerCase();
    return this.clinics.filter(clinic => clinic.name.toLowerCase().includes(filterValue));
  }

  getClinics(): void {
    this.clinicService.getClinics().subscribe({
      next: (res) =>{
        this.clinics = res.clinics;
      }
    })
  }

  clinicName(clinic:ClinicResponse):string {
    return clinic && typeof clinic === 'object' && clinic.name ? clinic.name : "";
  }

  onClinicSelected(event:any):void
  {
    //output
  }

}
