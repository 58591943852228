import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedQueryResult } from '../models/pagedQueryResult.interface';
import { CreateRouteRequest, route, RouteResponse, UpdateRouteRequest } from '../models/routes.model';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {
  baseUrl = `${environment.apiUrl}/api/routes`; 
  constructor(private http: HttpClient) { }
  getRoutes(): Observable<PagedQueryResult<RouteResponse>> {
    return this.http.get<PagedQueryResult<RouteResponse>>(`${this.baseUrl}`);
  }
  getRoute(id:string): Observable<RouteResponse> {
    return this.http.get<RouteResponse>(`${this.baseUrl}/${id}`);
  }
  createRoute(data: CreateRouteRequest): Observable<RouteResponse> {
    return this.http.post<RouteResponse>(`${this.baseUrl}`, data);
  }
  updateRoute(id: string, data: UpdateRouteRequest): Observable<RouteResponse> {
    return this.http.patch<RouteResponse>(`${this.baseUrl}/${id}`, data);
  }
  deleteRoute(id:string): Observable<204> {
    return this.http.delete<204>(`${this.baseUrl}/${id}`);
  }
}
