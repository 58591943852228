import { Component, Inject } from '@angular/core';
import { LocationResponse } from '../../../models/location.model';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { SearchLocationComponent } from '../../locations/search-location/search-location.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-route-location-dialog',
  standalone: true,
  imports: [
    SearchLocationComponent,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
  ],
  templateUrl: './route-location-dialog.component.html',
  styleUrl: './route-location-dialog.component.scss'
})
export class RouteLocationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RouteLocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { field: 'origin' | 'destination' }
  ) {}

  onLocationSelected(location: LocationResponse): void {
    this.dialogRef.close(location);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
