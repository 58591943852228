import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DashboardTodaysScheduleComponent } from '../../components/schedules/dashboard-todays-schedule/dashboard-todays-schedule.component';
import { DashboardService } from '../../services/dashboard.service';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    DashboardTodaysScheduleComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  @ViewChild('dashboardContainer', { read: ViewContainerRef, static: true }) container!: ViewContainerRef;
  role: string = '';
  idenitfier: string = ''

  // inputs = {
  //   data: ''
  // };
  componentsToLoad: any[] = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private loginService: AuthService,
    private DashboardService: DashboardService,
    private componentFactoryResolver: ComponentFactoryResolver
  )
  {
    this.loginService.claims$.subscribe((c) => {
      this.role = c['extension_Role'];
      this.idenitfier = c['sub'];
    });
  }  
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      if (result.matches) {
        console.log('matches true',result.matches)
      } else {
        console.log('matches false',result.matches)
      }
      this.loadDashboard()
    });

  }
  loadDashboard()
  {
   const result = this.DashboardService.getUserLayout(this.role,this.idenitfier)
   if(result){
    this.componentsToLoad  = result
   }

  }
}
