import { Injectable } from '@angular/core';
import { CreateLocationResponse, LocationResponse, UpdateLocationResponse } from '../models/location.model';
import { Observable, map } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

interface PagedQueryResult<T> {
  page: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
  locations: T[];
}

interface LocationQueryParams {
  sortBy?: string;
  page?: number;
  pageSize?: number;
}

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  baseUrl = `${environment.apiUrl}/api/locations`;
  constructor(private http: HttpClient) { }

  getLocations(params?: LocationQueryParams): Observable<PagedQueryResult<LocationResponse>> {
    let httpParams = new HttpParams();
    
    if (params?.sortBy) httpParams = httpParams.set('SortBy', params.sortBy);
    if (params?.page != null) httpParams = httpParams.set('Page', (params.page + 1).toString());
    if (params?.pageSize != null) httpParams = httpParams.set('PageSize', params.pageSize.toString());
    
    return this.http.get<PagedQueryResult<LocationResponse>>(`${this.baseUrl}`, { params: httpParams });
  }

  getLocation(id: string): Observable<LocationResponse> {
    return this.http.get<LocationResponse>(`${this.baseUrl}/${id}`);
  }

  createLocation(data: CreateLocationResponse): Observable<LocationResponse> {
    return this.http.post<LocationResponse>(`${this.baseUrl}`, data);
  }

  updateLocation(id: string, data: UpdateLocationResponse): Observable<LocationResponse> {
    return this.http.put<LocationResponse>(`${this.baseUrl}/${id}`, data);
  }

  deleteLocation(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${id}`);
  }
}
