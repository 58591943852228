<div class="m-1"  class="attachment-container">
    @for(attachment of attachments; track $index)
    {
        <mat-card appearance="raised" class="m-1 attachment-content">
            <!--For Other Types File Will need to rework this-->
            <mat-card-header>
                <mat-card-title mat-card-title> {{attachment.requirement.name}}</mat-card-title>
                <mat-card-subtitle>{{attachment.requirement.description}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content *ngIf="attachment.loaded" class="m-1 mt-1" [@expandFade]>
                @if(attachment.requirement.photoRequired)
                {
                   <img mat-card-xl-image class="attachment-image" [src]="attachment.fileUrl">
                }
            </mat-card-content>
            <mat-progress-bar *ngIf="!attachment.loaded" color="primary" mode="indeterminate"></mat-progress-bar>
        </mat-card>
    }
</div>
