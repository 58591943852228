<div class="container">
    <div class="header">
        <h2>Routes</h2>
    </div>
    <div class="flex-row align-items-center">
        <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. toyota" #input>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="openCreateRouteDialog()" class="add-btn" *ngIf="isAdmin">
            Add Route
        </button>
    </div>
    <div *ngIf="isLoading" class="loading-container">
        <mat-spinner
        [diameter]="80">
        </mat-spinner>
    </div>   
    <div class="mat-elevation-z0" [@invisTofadeIn]="isLoading ? 'hidden' : 'visible'">
        <div *ngIf="!isLoading">
            <table mat-table [dataSource]="dataSourceRoutes" matSort multiTemplateDataRows>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Route Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-button color="primary" class="edit-btn" matTooltip="View Route"
                        (click)="viewRoute(row)" 
                        *ngIf="isAdmin">
                        <mat-icon>visibility</mat-icon>
                        </button>   
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsRoutes"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsRoutes"></tr>
                <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>
            </table>
        </div>
        <mat-paginator [ngStyle]="{ 'display': isLoading ? 'none' : 'block' }" [pageSizeOptions]="[25, 50, 100]" aria-label="Select page of users" showFirstLastButtons></mat-paginator>
    </div>
</div>

