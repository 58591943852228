import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { debounceTime, distinctUntilChanged, switchMap, Observable, of } from 'rxjs';
import { FindOrCreateLocationResponse, LocationResponse } from '../../../models/location.model';
import { ToastrService } from 'ngx-toastr';
import { LocationService } from '../../../services/location.service';

@Component({
  selector: 'app-search-location',
  standalone: true,
  imports: [
    MatFormFieldModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    CommonModule,
    MatInputModule,
  ],
  templateUrl: './search-location.component.html',
  styleUrl: './search-location.component.scss'
})
export class SearchLocationComponent implements OnInit {
  @Output() locationSelected = new EventEmitter<LocationResponse>();

  searchControl = new FormControl('');
  suggestions: google.maps.places.AutocompletePrediction[] = [];
  autocompleteService!: google.maps.places.AutocompleteService;
  loading = false;

  constructor(
    private locationService: LocationService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    // Ensure the Google Places library is loaded.
    if (google && google.maps && google.maps.places) {
      this.autocompleteService = new google.maps.places.AutocompleteService();
    } else {
      console.error('Google Maps Places library is not available.');
    }

    // Listen to changes on the search control.
    this.searchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => this.getPredictions(value ?? ""))
    ).subscribe(predictions => {
      this.suggestions = predictions;
    });
  }

  /**
   * Calls the Google Places Autocomplete service and returns an Observable of predictions.
   * @param input The current input string.
   */
  getPredictions(input: string): Observable<google.maps.places.AutocompletePrediction[]> {
    if (!input || !this.autocompleteService) {
      return of([]);
    }

    return new Observable(observer => {
      this.autocompleteService.getPlacePredictions({ input,
        componentRestrictions: { country: 'au'}, // limit searches to Australia
        locationBias: {radius: 15000, center: new google.maps.LatLng(-34.928857245822975, 138.5999041409906)} // centre predictions around adelaide CBD to make life easier
       }, (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
          observer.next(predictions);
        } else {
          observer.next([]);
        }
        observer.complete();
      });
    });
  }

  /**
   * Display function for the autocomplete options.
   * @param prediction A prediction object.
   */
  displayFn(prediction: google.maps.places.AutocompletePrediction): string {
    return prediction ? prediction.description : '';
  }

  /**
   * Called when a user selects a prediction from the list.
   * @param prediction The selected prediction.
   */
  onSelectPrediction(prediction: google.maps.places.AutocompletePrediction): void {
    // Set the control's value and clear suggestions.
    this.searchControl.disable();
    this.searchControl.setValue(prediction.description, { emitEvent: false });
    this.suggestions = [];
    // Fetch full place details and then call backend to find or create the location.
    this.fetchPlaceDetails(prediction);
  }

  /**
   * Uses the Places Details service to fetch full details for the selected prediction,
   * then calls the findOrCreateLocation endpoint.
   * @param prediction The selected autocomplete prediction.
   */
  fetchPlaceDetails(prediction: google.maps.places.AutocompletePrediction): void {
    
    const dummyDiv = document.createElement('div');
    const placesService = new google.maps.places.PlacesService(dummyDiv);
    
    const request: google.maps.places.PlaceDetailsRequest = {
      placeId: prediction.place_id,
      fields: [
        'name',
        'formatted_address',
        'geometry',
        'address_components'
      ]
    };

    placesService.getDetails(request, (place, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK || !place) {
        console.error('Failed to retrieve place details for', prediction.place_id);
        this.toastr.error('Failed to retrieve place details', 'Error');
        return;
      }
      
      const addressComponents = place.address_components || [];
      const getComponent = (type: string): string => {
        const comp = addressComponents.find(ac => ac.types.includes(type));
        return comp ? comp.long_name : '';
      };

      const findOrCreateRequest:FindOrCreateLocationResponse = {
        GooglePlaceId: prediction.place_id,
        Name: place.name || prediction.description,
        Unit: '',
        Street: getComponent('street_number') + ' ' + getComponent('route'),
        City: getComponent('locality'),
        State: getComponent('administrative_area_level_1'),
        PostCode: getComponent('postal_code'),
        Country: getComponent('country'),
        Latitude: place.geometry?.location?.lat() ?? 0.0,
        Longitude: place.geometry?.location?.lng() ?? 0.0
      };

      // Call the backend to find or create the location.
      this.locationService.findOrCreateLocation(findOrCreateRequest)
        .subscribe({
          next: (locationResponse: LocationResponse) => {
            this.toastr.success('Location found or created successfully!', 'Success');
            // Emit the final location response.
            this.locationSelected.emit(locationResponse);
            this.searchControl.enable();
          },
          error: (err: any) => {
            console.error('Error in findOrCreateLocation:', err);
            this.toastr.error('Failed to save location', 'Error');
            this.searchControl.enable();
          }
        });
    });
  }
}