<div class="container">
    <button mat-raised-button routerLink="/orders" type="button" class="back-btn">Back</button>
    <div class="header">
        <h2>Create a new order</h2>
    </div>
    
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Cremation Information</mat-card-title>
              </mat-card-header>
            <mat-card-content>
                <div class="flex-container">
                    <mat-form-field appearance="fill" class="flex-50">
                        <mat-label>Clinic *</mat-label>
                        <input 
                            type="text"
                            placeholder="Pick one"
                            matInput
                            [formControl]="clinicControl"
                            [matAutocomplete]="clinicAuto">
                        <mat-autocomplete #clinicAuto="matAutocomplete" [displayWith]="displayClinicFn" (optionSelected)="onSelectionClinic($event.option.value)">
                            @for (option of clinicFilteredOptions | async; track option) {
                                <mat-option [value]="option">{{option.name}}</mat-option>
                            }
                        </mat-autocomplete>
                    </mat-form-field>
            
                    <mat-form-field appearance="fill" class="flex-50">
                        <mat-label>Cremation Type</mat-label>
                        <mat-select formControlName="cremationType">
                            <mat-option *ngFor="let type of cremationTypes" [value]="type">{{ type }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('cremationType')?.errors?.['required']">
                            Cremation Type is required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="flex-container">
                    <mat-form-field appearance="fill" class="flex-50">
                        <mat-label>Pick Up Location *</mat-label>
                        <input 
                            type="text"
                            placeholder="Pick one"
                            matInput
                            [formControl]="pickUpControl"
                            [matAutocomplete]="pickUpAuto">
                        <mat-autocomplete #pickUpAuto="matAutocomplete" [displayWith]="displayLocationFn" (optionSelected)="onSelectionPickUp($event.option.value)">
                            @for (option of pickUpFilteredOptions | async; track option) {
                                <mat-option [value]="option">{{option.name}}</mat-option>
                            }
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="flex-50">
                        <mat-label>Drop Off Location *</mat-label>
                        <input 
                            type="text"
                            placeholder="Pick one"
                            matInput
                            [formControl]="dropOffControl"
                            [matAutocomplete]="dropOffAuto">
                        <mat-autocomplete #dropOffAuto="matAutocomplete" [displayWith]="displayLocationFn" (optionSelected)="onSelectionDropOff($event.option.value)">
                            @for (option of dropOffFilteredOptions | async; track option) {
                                <mat-option [value]="option">{{option.name}}</mat-option>
                            }
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                
            </mat-card-content>
        </mat-card>
       
        <mat-card>
            <mat-card-header>
                <mat-card-title>Animal</mat-card-title>
              </mat-card-header>
            <mat-card-content>
                <div class="flex-container">
                    <mat-form-field appearance="fill" class="flex-33">
                        <mat-label>Animal Name</mat-label>
                        <input type="text" placeholder="Animal Name" formControlName="animalName" matInput>
                        <mat-error *ngIf="form.get('animalName')?.errors?.['required']">
                            Animal Name is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="flex-33">
                        <mat-label>Weight in Kgs</mat-label>
                        <input class="left-align" type="text" placeholder="Weight in Kgs" formControlName="weight" matInput (blur)="onDeselect()">
                        <mat-hint>Up to 2 decimal places</mat-hint>
                        <mat-error *ngIf="form.get('weight')?.errors?.['pattern']">
                            Invalid format
                        </mat-error >
                        <mat-error *ngIf="form.get('weight')?.errors?.['required']">
                            Weight is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="flex-33">
                        <mat-label>Animal Type</mat-label>
                        <mat-select formControlName="animalTypeId">
                            <mat-option *ngFor="let animal of animalTypes" [value]="animal.id">{{ animal.name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('animalTypeId')?.errors?.['required']">
                            Animal Type is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </mat-card>
       
        <mat-card>
            <mat-card-header>
                <mat-card-title>Contact*</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="contact-header">
                    <div class="contact-btns">
                        <button mat-raised-button color="primary" type="button" (click)="openAddContactDialog()" class="btn">Add New</button>
                        <button mat-raised-button color="primary" type="button" (click)="openSearchContactDialog()" class="btn">Search existing contacts</button>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div *ngIf="form.get('contacts')?.errors">
                    <p class="error" >Contact is required, you can either add a new contact or select from the existing contacts.</p>
                </div>

                <div formArrayName="contacts" *ngFor="let contactCtrl of contactsArray.controls; let i = index">
                    <div [formGroupName]="i">
                        <div class="flex-row align-items-center">
                            <mat-radio-group formControlName="isPrimary">
                                <mat-radio-button [value]="true" (change)="setPrimary(i)">Primary Contact</mat-radio-button>
                            </mat-radio-group>
                            <button mat-icon-button (click)="removeContact(i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                      <div class="flex-container">
                        <p class="flex-25">First Name: {{ contactCtrl.get('contact')?.get('firstName')?.value }}</p>
                        <p class="flex-25">Last Name: {{ contactCtrl.get('contact')?.get('lastName')?.value }}</p>
                        <p class="flex-25">Email: {{ contactCtrl.get('contact')?.get('email')?.value }}</p>
                        <p class="flex-25">Phone: {{ contactCtrl.get('contact')?.get('phone')?.value }}</p>
                      </div>
                      <mat-divider></mat-divider>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <mat-card-title>Other Infomation</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="flex-container">
                    <mat-form-field class="flex-1">
                        <mat-label>Special Instructions</mat-label>
                        <textarea matInput formControlName="specialInstructions"></textarea>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <mat-card-title>Packages</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <button mat-stroked-button color="primary" type="button">
                    <mat-icon>add</mat-icon>
                    Package
                </button>
            </mat-card-content>
        </mat-card>
    
        <mat-card>
            <mat-card-header>
                <mat-card-title>Products</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <button mat-stroked-button color="primary" type="button">
                    <mat-icon>add</mat-icon>
                    Product
                </button>
            </mat-card-content>
        </mat-card>
        <div class="actions flex-end-actions" *ngIf="isSaving">
            <mat-spinner 
            [diameter]="50">
            </mat-spinner>
        </div>
        <div *ngIf="!isSaving" class="actions flex-end-actions">
            <button mat-raised-button color="primary" type="submit">Add</button>
        </div>
    </form>    
</div>