import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-dialog',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
  ],
  templateUrl: './delete-dialog.component.html',
  styles: 
  `
    .mat-mdc-dialog-title {
      font-size: large;
      padding-top: 8px;
      display: flex;
      align-items: center;
    }
    
    mat-form-field {
      width: 100%
    }
  `
})
export class DeleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { type: string, name?: string }
  ) {}

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
