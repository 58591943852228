<mat-card>
    <div class="flex-row align-items-center">
        <mat-card-header>
            <mat-card-title>Clinic Users</mat-card-title>
        </mat-card-header>
        <div>
            <button mat-raised-button color="primary" (click)="openAddUserDialog()" class="add-btn" *ngIf="isAdmin">
                Add User
            </button>
            <mat-form-field class="mx-1 mt-1">
                <mat-label>Select a Role</mat-label>
                <mat-select [(ngModel)]="selectedView" (selectionChange)="filterUsers()">
                  <mat-option [value]="'1'">All</mat-option>
                  @for (role of roles; track $index) {
                    <mat-option [value]="role.value">{{role.name}}</mat-option>
                  }
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="isLoading"  class="loading-container">
        <mat-spinner
        [diameter]="80"></mat-spinner>
    </div>
    <mat-card-content *ngIf="!isLoading">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="accountEnabled">
                <th mat-header-cell *matHeaderCellDef> Account Enabled </th>
                <td mat-cell *matCellDef="let row">
                    <ng-container *ngIf="row.accountEnabled === null; else statusTemplate">
                    </ng-container>
                    <ng-template #statusTemplate>
                      <span *ngIf="row.accountEnabled; else warnTemplate" class="text-primary">
                        <mat-icon aria-hidden="false" aria-label="enabled" fontIcon="check"></mat-icon>
                      </span>
                      <ng-template #warnTemplate>
                        <span class="text-warn">
                            <mat-icon aria-hidden="false" aria-label="disabled" fontIcon="close"></mat-icon>
                        </span>
                      </ng-template>
                    </ng-template>
                  </td>
            </ng-container>
            <ng-container matColumnDef="givenName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Given Name </th>
                <td mat-cell *matCellDef="let row"> {{row.givenName}} </td>
            </ng-container>

            <ng-container matColumnDef="surname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Surname </th>
                <td mat-cell *matCellDef="let row"> {{row.surname}} </td>
            </ng-container>
        
            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef> Role </th>
                <td mat-cell *matCellDef="let row"> {{row.role ? row.role : 'User'}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions </th>
                <td mat-cell *matCellDef="let row"> 
                    <div class="button-container">
                        <button mat-icon-button color="primary" class="edit-btn" matTooltip="Edit Schedule"
                            (click)="openEditUserDialog(row)" *ngIf="row.role !== 'globeadmin'">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
            <!-- Row shown when there is no matching data. -->
             @if(clinicUsers.length <= 0)
             {
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell text-align-center" colspan="5">No User Assigned to this Clinic.</td>
                </tr>
             }
             @else
             {
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="5">No data matching the filter "{{selectedView}}"</td>
                </tr>
             }

        </table>
    </mat-card-content>
</mat-card>
