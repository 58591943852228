import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { RouteResponse, UpdateRouteRequest } from '../../../models/routes.model';
import { RoutesService } from '../../../services/routes.service';
import { MatCardModule } from '@angular/material/card';
import { RouteLocationDialogComponent } from '../route-location-dialog/route-location-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LocationResponse } from '../../../models/location.model';
import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EditRoutesComponent } from '../edit-routes/edit-routes.component';

@Component({
  selector: 'app-route-display',
  standalone: true,
  imports: [
    MatCardModule,
    RouteLocationDialogComponent,
    CommonModule,
    MatButtonModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
  ],
  templateUrl: './route-display.component.html',
  styleUrl: './route-display.component.scss'
})
export class RouteDisplayComponent {
  isEditing: boolean = false;
  editedRouteName: string;
  @Input() route!: RouteResponse;
  constructor(
    private routeService: RoutesService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
  ) { }

  openLocationDialog(field: 'origin' | 'destination'): void {
    const dialogRef = this.dialog.open(RouteLocationDialogComponent, {
      width: '500px',
      data: { field }
    });

    dialogRef.afterClosed().subscribe((result: LocationResponse | undefined) => {
      if (result) {
        if (field === 'origin') {
          this.setOrigin(result);
        } else if (field === 'destination') {
          this.setDestination(result);
        }
      }
    });
  }

  setOrigin(location: LocationResponse): void {
    this.routeService.setOrigin(this.route.id, location.id).subscribe({
      next: () => {
        this.fetchRoute();
        this.toastr.success('Origin set successfully');
      },
      error: (err) => {
        this.toastr.error('Error setting origin');
      }
    });
  }
  setDestination(location: LocationResponse): void {
    this.routeService.setDestination(this.route.id, location.id).subscribe({
      next: () => {
        this.fetchRoute();
        this.toastr.success('Destination set successfully');
      },
      error: (err) => {
        this.toastr.error('Error setting destination');
      }
    });
  }
  toggleEdit() {
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      this.editedRouteName = this.route.name;
    }
  }

  saveRouteName() {
    if (this.isEditing) {
      this.route.name = this.editedRouteName;
      this.isEditing = false;
      let request = new UpdateRouteRequest(
        this.route.name,
        this.route.clinics.map(clinic => clinic.id)
      );
      this.routeService.updateRoute(this.route.id, request).subscribe({
        next: () => {
          this.fetchRoute();
          this.toastr.success('Route name updated successfully');
        },
        error: (err) => {
          this.toastr.error('Error updating route name');
        }
      });
      console.log("TODO");
    }
  }

  fetchRoute(): void {
    this.routeService.getRoute(this.route.id).subscribe({
      next: (route) => {
        this.route = route;
      },
      error: (err) => {
        this.toastr.error('Error fetching route', err);
      }
    });
  }

  openClinicsDialog(): void {
    const dialogRef = this.dialog.open(EditRoutesComponent, {
      width: '600px',
      data: this.route
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.fetchRoute();
      }
    });
  }
}
