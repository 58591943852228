import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ToastrService } from 'ngx-toastr';
import { OrderStatusResponse } from '../../../../models/orders/order.model';
import { AuthService } from '../../../../services/auth.service';
import { OrderService } from '../../../../services/order.service';
import { DeleteDialogComponent } from '../../../delete-dialog/delete-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CreateOrderStatusComponent } from '../create-order-status/create-order-status.component';
import { EditOrderStatusComponent } from '../edit-order-status/edit-order-status.component';
import { FlowChartComponent } from '../../../flow-chart/flow-chart.component';
import { fadeIn, invisTofadeIn } from '../../../../../assets/animations';


@Component({
  selector: 'app-order-status-index',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSort,
    MatTableModule,
    MatDialogModule,
    MatPaginatorModule,
    MatIconModule,
    MatTooltipModule,
    MatDividerModule,
    CommonModule,
    MatProgressSpinnerModule,
    FlowChartComponent
  ],
  templateUrl: './order-status-index.component.html',
  styleUrl: './order-status-index.component.scss',
  animations:[invisTofadeIn]
})
export class OrderStatusIndexComponent {
  displayedColumnsOrderStatuses: string[] = ['id', 'name', 'customerDisplayName', 'description'];
  dataSourceOrderStatuses = new MatTableDataSource<OrderStatusResponse>();
  @ViewChild('sortOrderStatus', {static: true}) sortOrderStatus!: MatSort;
  @ViewChild('paginatorOrderStatus') paginatorOrderStatus!: MatPaginator;
  role: string = '';
  isAdmin: boolean = false;
  isLoading:boolean = false;
  flowchartString!:string;

  constructor(
    public dialog: MatDialog, 
    private orderService: OrderService, 
    private toastr: ToastrService, 
    private breakpointObserver: BreakpointObserver,
    private loginService: AuthService
  ) { 
    this.loginService.claims$.subscribe((c) => {
      this.role = c['extension_Role'];
    });
  }
  
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      if (result.matches) {
        this.displayedColumnsOrderStatuses = ['name', 'customerDisplayName'];
      } else {
        this.displayedColumnsOrderStatuses = ['name', 'customerDisplayName', 'description'];
      }

      if(this.role == 'globeadmin' || this.role == 'awladmin') {
        this.displayedColumnsOrderStatuses.push('actions');
        this.isAdmin = true;
      }
      this.getData();
    });
  }

  ngAfterViewInit(): void {
  }
  
  getData() {
    this.isLoading = true;
    this.orderService.getOrderStatuses().subscribe({
      next: (res) => {
        this.dataSourceOrderStatuses.data = res.items;
        this.dataSourceOrderStatuses.paginator = this.paginatorOrderStatus;
        this.dataSourceOrderStatuses.sort = this.sortOrderStatus;
        this.isLoading = false;
        this.generateFlowChart();
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
        this.isLoading = false;
      }
    })
  }

  generateFlowChart(){
    var statuses = this.dataSourceOrderStatuses.data;
    var jstext="graph TD\n";
    for (let status of statuses) {
      let newLine = `${status.id}[${status.name}]\n`;
      jstext += newLine;
    }
    
    jstext += "\n";
    for (let status of statuses) {
      if (status.validTransitions) {
        for (let targetId of status.validTransitions) {
          let transitionLine = `${status.id} --> ${targetId}\n`;
          jstext += transitionLine;
        }
      }
    }
    this.flowchartString = jstext;
  }

  applyFilterStatus(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceOrderStatuses.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceOrderStatuses.paginator) {
      this.dataSourceOrderStatuses.paginator.firstPage();
    }
  }

  //dialogs
  openCreateOrderStatusDialog() {
    const dialogRef = this.dialog.open(CreateOrderStatusComponent, {
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log(result);
        this.orderService.createOrderStatus(result).subscribe({
          next: (res) => {
            this.toastr.success('Order Status created successfully.');
            this.getData();
          },
          error: (error) => {
            this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
          }
        })
      }
    })
  }

  openFlowChartDialog()
  {
    const chartData = this.flowchartString;
    const dialogRef = this.dialog.open(FlowChartComponent, {
      width: '600px',
      data: chartData
    });
  }

  openEditOrderStatusDialog(element: OrderStatusResponse) {
    const dialogRef = this.dialog.open(EditOrderStatusComponent, {
      width: '600px',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log(result);
        this.orderService.updateOrderStatus(element.id, result).subscribe({
          next: (res) => {
            this.toastr.success('Order Status updated successfully.');
            this.getData();
          },
          error: (error) => {
            this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
          }
        })
      }
    })
  }

  openDeleteOrderStatusDialog(element: OrderStatusResponse) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: { type: "order status", name: element.name }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.orderService.deleteOrderStatus(element.id).subscribe({
          next: (res) => {
            this.toastr.success('Order status deleted successfully.');
            this.getData();
          },
          error: (error) => {
            if(error.status == '409') {
              this.toastr.error('This order status cannot be deleted because it is associated with other entities, such as an order.');
            } else {
              this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
            }
          }
        });
      }
    })
  }
}
