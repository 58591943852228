import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommunicationLogResponse } from '../../../models/orders/communicationLog.model';
import { CommunicationLogsService } from '../../../services/communication-logs.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-order-communication-index',
  standalone: true,
  imports: [
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatButtonModule,
    RouterLink,
    MatCardModule,
    MatIconModule,
    CommonModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './order-communication-index.component.html',
  styleUrl: './order-communication-index.component.scss'
})
export class OrderCommunicationIndexComponent  implements AfterViewInit{
  @Input() orderId:string = ''
  //orders
  refreshing:boolean = false;
  //table
  communicationLogs:CommunicationLogResponse [] = [];
  validRoles = ['globeadmin','awladmin','clinicadmin']
  validPerms:boolean = false

  constructor(public dialog: MatDialog, private communicationService:CommunicationLogsService, private toastr: ToastrService, private loginService: AuthService )
  {
    this.loginService.claims$.subscribe((c) => {
      if(this.validRoles.includes(c['extension_Role']))
        {
          this.validPerms = true;
        }
    })
  }

  ngAfterViewInit() {
    this.getData();
  }
  getData() {
    this.refreshing = true;
    if(this.validPerms)
      {
        this.communicationService.getCommunicationLogsByOrderId(this.orderId).subscribe({
          next: (res) => {
            this.communicationLogs = res.items.sort((a,b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            this.refreshing = false;
          },
          error: (error) => {
            this.refreshing = false;
            this.toastr.error(error.error?.title || 'An error occurred when getting the orders. Please try again.');
          }
        })
      }
      else
      {
        this.refreshing = false;
      }
  }
  formatTimestamp(timestamp: string): string {
    return moment.utc(timestamp).tz(moment.tz.guess()).format('DD-MM-YYYY hh:mm A');
  }
}
