import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup,FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { OrderService } from '../../../../services/order.service';
import { CreateRequirementRequest } from '../../../../models/orders/order.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-requirement',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    CommonModule,
    MatDividerModule,
    MatCheckboxModule
  ],
  templateUrl: './create-requirement.component.html',
  styleUrl: './create-requirement.component.scss'
})
export class CreateRequirementComponent {

  nameControl= new FormControl('',Validators.required);
  descriptionControl= new FormControl('',Validators.required);
  photoRequiredControl= new FormControl(false);
  isSaving:boolean = false;

  createRequirementForm:FormGroup = new FormGroup({
    nameControl: this.nameControl,
    descriptionControl: this.descriptionControl,
    photoRequiredControl: this.photoRequiredControl
  });

  constructor(
    private orderService:OrderService,
    private dialogRef: MatDialogRef<CreateRequirementComponent>,
    private toastr:ToastrService
  ) {}

  onSubmit():void {
    this.isSaving = true;
    const formData = this.createRequirementForm.value;
    const request:CreateRequirementRequest = {
      name:formData.nameControl,
      description:formData.descriptionControl,
      photoRequired:formData.photoRequiredControl
    };
    this.orderService.createRequirement(request).subscribe({
      next: () => {
        this.isSaving = false;
        this.toastr.success("Created requirement","Success");
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.isSaving = false;
        this.toastr.error(error.error,"Error creating requirement");
      }
    });
  }

}
