import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { CreateLocationResponse } from '../../../models/location.model';
import { LocationService } from '../../../services/location.service';
import { OrderService } from '../../../services/order.service';
import { ToastrService } from 'ngx-toastr';
import { fieldsFilledValidator } from '../../../helpers/validator';

export interface Location {
  x: string;
  y: string;
}

export interface Candidate {
  address: string;
  location: Location;
}

export interface APIResponse {
  candidates: Candidate[];
}

@Component({
  selector: 'app-location-new',
  standalone: true,
  imports: [
    MatButtonModule,
    MatCardModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    RouterLink,
    MatAutocompleteModule,
    CommonModule
  ],
  templateUrl: './location-new.component.html',
  styleUrl: './location-new.component.scss',
  providers: [OrderService]
})

export class LocationNewComponent implements OnInit{
  form!: FormGroup;
  addressSearchControl!: FormControl;
  filteredAddresses!: Observable<Candidate[]>;

  constructor(private fb: FormBuilder, private http: HttpClient, private locationService: LocationService, private toastr: ToastrService, private router: Router) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', Validators.required],
      note: [''],
      unit: [''],
      street: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postCode: ['', [Validators.required, Validators.pattern(/^\d{4}$/)]],
      country: ['', Validators.required],
      latitude: ['', Validators.pattern(/^-?\d+(\.\d+)?$/)],
      longitude: ['', Validators.pattern(/^-?\d+(\.\d+)?$/)],
    }, { validators: fieldsFilledValidator(['latitude', 'longitude']) });

    this.addressSearchControl = new FormControl();

    this.filteredAddresses = this.addressSearchControl.valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => this.searchAddress(value)),
        catchError(error => {
          console.error('Error fetching data:', error);
          return of([]); // Handle errors appropriately
        })
      );
  }

  onSubmit() {
    if(this.form.valid) {
      const location: CreateLocationResponse = {
        name: this.form.value.name,
        unit: this.form.value.unit,
        street: this.form.value.street,
        city: this.form.value.city,
        state: this.form.value.state,
        postCode: this.form.value.postCode,
        country: this.form.value.country,
        note: this.form.value.note,
        latitude: this.form.value.latitude,
        longitude: this.form.value.longitude,
      }

      this.locationService.createLocation(location).subscribe({
        next: (response) => {
          this.toastr.success('Location created successfully.');
          this.router.navigate(['/configurations/locations']);
        },
        error: (error) => {
          this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
        }
      })
    }
  }

  searchAddress(query: string): Observable<Candidate[]> {
    query = (typeof query === 'string' ? query : '').trim();

    if (!query) {
      return of([]);
    }

    const queryParams = {
      street: query,
      f: 'json',
      maxLocation: 10
    };

    return this.http.get<{candidates: Candidate[]}>('https://basemap.geohub.sa.gov.au/arcgis/rest/services/Locators/SAGAF_PLUS_Suggest/GeocodeServer/findAddressCandidates', { params: queryParams })
      .pipe(
        map(response => response.candidates)
      );
  }

  extractUnitAndStreet(firstPart: string): { unit: string, street: string } {
    // This pattern matches "UNIT" followed by any number, capturing the unit number and the rest
    const unitPattern = /^UNIT (\d+) (.+)$/i;
    const match = firstPart.match(unitPattern);

    if (match) {
        return { unit: match[1], street: match[2] };
    } else {
        return { unit: '', street: firstPart }; // No unit number found, return the whole as street
    }
  }

  displayFn(candidate: Candidate): string {
    return candidate && candidate.address ? candidate.address : '';
  }
  
  onSelectionChanged(event: { option: { value: Candidate; }; }): void {
    const candidate: Candidate = event.option.value;
    const parts = candidate.address.split(', ');
    const cooridinates = candidate.location;

    // Further breakdown the first part to separate the unit and street if applicable
    const unitStreetSplit = this.extractUnitAndStreet(parts[0]);

    this.form.patchValue({
        unit: unitStreetSplit.unit ? 'Unit ' + unitStreetSplit.unit : unitStreetSplit.unit,
        street: unitStreetSplit.street,
        city: parts[1], 
        state: parts[2], 
        postCode: parts.length > 3 ? parts[3] : '', 
        country: 'Australia', 
        latitude: cooridinates.y,
        longitude: cooridinates.x,
    });
  }
}