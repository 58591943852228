import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLabel } from '@angular/material/form-field';
import { LocationResponse } from '../../../models/location.model';
import { Observable, map, startWith } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { ContactResponse } from '../../../models/contact.model';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ContactNewDialogComponent } from '../../../components/contacts/contact-new-dialog/contact-new-dialog.component';
import { ContactSearchDialogComponent } from '../../../components/contacts/contact-search-dialog/contact-search-dialog.component';
import { CreateClinicRequest } from '../../../models/clinic.model';
import { ClinicService } from '../../../services/clinic.service';
import { Router, RouterLink } from '@angular/router';
import { ContactService } from '../../../services/contact.service';
import { ToastrService } from 'ngx-toastr';
import { LocationService } from '../../../services/location.service';
import { SearchLocationComponent } from '../../../components/locations/search-location/search-location.component';

@Component({
  selector: 'app-clinic-new',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    MatLabel,
    MatButtonModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    RouterLink,
    SearchLocationComponent
  ],
  templateUrl: './clinic-new.component.html',
  styleUrl: './clinic-new.component.scss',
  providers: [ClinicService]
})
export class ClinicNewComponent implements OnInit {
  form!: FormGroup;
  contact: ContactResponse | null = null;
  locations: LocationResponse[] = [];
  locationControl =  new FormControl<null | LocationResponse>(null);
  locationFilteredOptions!: Observable<LocationResponse[]>;
  
  selectedLocation?: LocationResponse;

  constructor(
    private fb: FormBuilder, 
    public dialog: MatDialog, 
    private clinicService: ClinicService, 
    private contactService: ContactService, 
    private locationService: LocationService, 
    private toastr: ToastrService, 
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', Validators.required],
      contactId: ['', Validators.required],
      locationId: ['', Validators.required],
      note: ['']
    });

    this.getData();
  }

  getData() {
    this.locationService.getLocations().subscribe({
      next: (res) => {
        this.locations = res.locations;
        this.setFilteredLocations();
      }, 
      error: (error) => {
          this.toastr.error(error.error?.title || 'An error occurred when getting the locations. Please try again.');
      }    
    })
  }
  
  setFilteredLocations() {
    this.locationFilteredOptions = this.locationControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this.locationFilter(name as string) : this.locations.slice();
      }),
    );
  }

  private locationFilter(name: string): LocationResponse[] {
    const filterValue = name.toLowerCase();
    return this.locations.filter(option => 
      option.name.toLowerCase().includes(filterValue) ||
      option.address.unit.toLowerCase().includes(filterValue) ||
      option.address.street.toLowerCase().includes(filterValue) ||
      option.address.city.toLowerCase().includes(filterValue) ||
      option.address.state.toLowerCase().includes(filterValue) ||
      option.address.postCode.toLowerCase().includes(filterValue)
    );
  }

  displayLocationFn(location: LocationResponse): string {
    return location && location.name ? location.name + ' - ' +
      location.address.unit + ' ' +
      location.address.street + ' ' +
      location.address.city + ', ' +
      location.address.state + ', ' +
      location.address.postCode : '';
  }

  get contactsArray(): FormArray {
    return this.form.get('contacts') as FormArray;
  }

  onSelectionLocation(location: LocationResponse) {
    this.form.get('locationId')?.patchValue(location.id);
  }

  removeContact() {
    this.contact = null;
  }

  onSubmit() {
    if(this.form.valid) {
      const clinic:CreateClinicRequest = {
        name: this.form.value.name,
        note: this.form.value.note,
        locationId: this.form.value.locationId,
        contactId: this.form.value.contactId,
      };
      this.clinicService.createClinic(clinic).subscribe({
        next: (response) => {
          this.toastr.success('Clinic created successfully.');
          this.router.navigate(['/clinics']);
        },
        error: (error) => {
          this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
        }
      })
    }
  }

  openAddContactDialog() {
    const dialogRef = this.dialog.open(ContactNewDialogComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.contactService.createContact(result).subscribe({
          next: (res) => {
            this.toastr.success('Contact created successfully.');
            this.contact = res;
            this.form.get('contactId')?.patchValue(res.id);
          },
          error: (error) => {
            this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
          }
        });
      }
    })
  }

  openSearchContactDialog() {
    const dialogRef = this.dialog.open(ContactSearchDialogComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.contact = result;
        this.form.get('contactId')?.patchValue(this.contact?.id);
      }
    })
  }

  onLocationSelected(location: LocationResponse): void {
    // When the autocomplete returns a full location response,
    // update the form control and store the details for display.
    this.selectedLocation = location;
    this.form.get('locationId')?.setValue(location.id);
    this.toastr.success('Location selected successfully!', 'Success');
  }
}