import { CommonModule } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CameraDialogComponent } from '../../scanner-dialog/camera-dialog/camera-dialog.component';
import { QrScannerComponent } from '../../scanner-dialog/qr-scanner/qr-scanner.component';
import { SchedulePickupComponent } from '../schedule-pickup/schedule-pickup.component';
import { ScheduleOrderResponse, ScheduleResponse } from '../../../models/schedule.model';
import { MatSelectModule } from '@angular/material/select';
import { OrderService } from '../../../services/order.service';
import { OrderStatusResponse } from '../../../models/orders/order.model';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-schedule-checkin',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    CommonModule,
    CameraDialogComponent,
    QrScannerComponent,
    SchedulePickupComponent,
    MatSelectModule,
    FormsModule,
  ],
  templateUrl: './schedule-checkin.component.html',
  styleUrl: './schedule-checkin.component.scss'
})
export class ScheduleCheckinComponent implements OnInit {

  schedule!: ScheduleResponse;
  clinicId!: string;
  visited: boolean = false;
  statuses: OrderStatusResponse[] = [];
  pickupStatus!: string;

  constructor(
    private dialog: MatDialog,
    private orderService: OrderService,
    @Inject(MAT_DIALOG_DATA) public data: { 
      schedule: ScheduleResponse, 
      clinicId: string
    },
  ) 
  { 
    this.schedule = data.schedule;
    this.clinicId = data.clinicId;
  }

  ngOnInit(): void {
    this.retrieveStatuses();
  }

  markArrived(): void {
    this.visited = true;
  }

  retrieveStatuses(): void {
    this.orderService.getOrderStatuses().subscribe(statuses => {
      this.statuses = statuses.items;
    });
  }

  scanPickup(): void {
    const dialogRef = this.dialog.open(QrScannerComponent, {
      width: '90%',
      maxWidth: '100%',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.pickupOrder(result);
    });
  }

  get schedulePickups():ScheduleOrderResponse[] {
    return this.schedule.orders.filter(order => order.order?.clinicId === this.clinicId && order.scheduleType === 'Pickup');
  }

  get scheduleDropoffs():ScheduleOrderResponse[] {
    return this.schedule.orders.filter(order => order.order?.clinicId === this.clinicId && order.scheduleType === 'Dropoff');
  }

  pickupOrder(orderRef: string | number): void {
    if (!orderRef) {  
      return;
    }
  
    const data = { 
      orderReference: orderRef,  
      schedule: this.schedule, 
      clinicId: this.clinicId,
      statusId: this.pickupStatus,
    };
  
    this.dialog.open(SchedulePickupComponent, {
      data,
      width: '90%',
      maxWidth: '100%',
    });
  }
}
