import { Component } from '@angular/core';
import { PlaceIndexComponent } from '../../../components/places/place-index/place-index.component';

@Component({
  selector: 'app-place-index-page',
  standalone: true,
  imports: [
    PlaceIndexComponent
  ],
  templateUrl: './place-index.component.html',
  styleUrl: './place-index.component.scss'
})
export class PlaceIndexComponentPage {

}
