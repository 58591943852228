<!-- <div class="route-action-buttons">
  <button mat-raised-button color="primary">Recalculate route</button>
</div> -->
<div class="route-container w-full">
  <div class="stops-container">
    <div *ngFor="let stop of locations; let i = index" class="stop-wrapper">
      <div class="stop" [ngClass]="{'selected': selectedStopId === stop.id}" (click)="selectStop(stop.id)">
        <div class="stop-circle" [ngClass]="{'visited': stop.visited}" [ngSwitch]="stop.type">
          <mat-icon *ngSwitchCase="'order'">place</mat-icon>
          <mat-icon *ngSwitchCase="'clinic'">home</mat-icon>
          <mat-icon *ngSwitchCase="'origin'">subdirectory_arrow_right</mat-icon>
          <mat-icon *ngSwitchCase="'destination'">stop</mat-icon>
          <mat-icon *ngSwitchDefault>help</mat-icon>
        </div>
      </div>
      <div *ngIf="i < locations.length - 1" class="stop-line"></div>
    </div>
  </div>
</div>
<div *ngIf="selectedStopId && selectedStop">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{selectedStop.title}}</mat-card-title>
      <mat-card-subtitle>{{selectedStop.type}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p>{{selectedStop.id}}</p>
      <div [ngSwitch]="selectedStop.type">
        <button *ngSwitchCase="'clinic'" mat-raised-button color="primary" (click)="checkIn(selectedStopId)">Check-in</button>
        <button *ngSwitchCase="'order'" mat-raised-button color="primary" (click)="openOrderDialog(selectedStopId)">Scan order</button>
      </div>
    </mat-card-content>
  </mat-card>
  <p></p>
</div>