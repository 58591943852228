import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagedQueryResult } from '../models/pagedQueryResult.interface';
import { CreateScheduleRequest, DirectionsRequest, ScheduleOrderResponse, ScheduleResponse, UpdateScheduleRequest } from '../models/schedule.model';
import { ClinicResponse } from '../models/clinic.model';

@Injectable({
  providedIn: 'root'
})
export class SchedulesService {

  baseUrl = `${environment.apiUrl}/api/schedules`; 
  constructor(private http: HttpClient) { }

  getSchedules(): Observable<PagedQueryResult<ScheduleResponse>> {
    return this.http.get<PagedQueryResult<ScheduleResponse>>(`${this.baseUrl}`);
  }
  getSchedule(id:string): Observable<ScheduleResponse> {
    return this.http.get<ScheduleResponse>(`${this.baseUrl}/${id}`);
  }
  getTodaysSchedules(userId:string): Observable<ScheduleResponse[]> {
    return this.http.get<ScheduleResponse[]>(`${this.baseUrl}/${userId}/driver`);
  }
  createSchedule(data: CreateScheduleRequest): Observable<ScheduleResponse> {
    return this.http.post<ScheduleResponse>(`${this.baseUrl}`, data);
  }

  getScheduleOrders(scheduleId: string): Observable<ScheduleOrderResponse[]> {
    return this.http.get<ScheduleOrderResponse[]>(`${this.baseUrl}/${scheduleId}/orders`);
  }

  updateSchedule(id: string, data: UpdateScheduleRequest): Observable<ScheduleResponse> {
    return this.http.patch<ScheduleResponse>(`${this.baseUrl}/${id}`, data);
  }
  deleteSchedule(id:string): Observable<204> {
    return this.http.delete<204>(`${this.baseUrl}/${id}`);
  }
  setScheduleOrigin(scheduleId: string, locationId: string): Observable<ScheduleResponse> {
    const payload = { locationId };
    return this.http.patch<ScheduleResponse>(`${this.baseUrl}/${scheduleId}/origin`, payload);
  }

  setScheduleDestination(scheduleId: string, locationId: string): Observable<ScheduleResponse> {
    const payload = { locationId };
    return this.http.patch<ScheduleResponse>(`${this.baseUrl}/${scheduleId}/destination`, payload);
  }

  addScheduleOrder(scheduleId: string, data: { orderId:string}): Observable<ScheduleResponse> {
    return this.http.post<ScheduleResponse>(`${this.baseUrl}/${scheduleId}/orders/`,data);
  }

  removeScheduleOrder(scheduleId: string, orderId: string): Observable<ScheduleResponse> {
    return this.http.delete<ScheduleResponse>(`${this.baseUrl}/${scheduleId}/orders/${orderId}`);
  }

  getScheduleClinics(scheduleId:string):Observable<ClinicResponse[]>
  {
    return this.http.get<ClinicResponse[]>(`${this.baseUrl}/${scheduleId}/clinics`);
  }

  addScheduleClinic(scheduleId:string, data: {scheduleId: string, clinicId: string}): Observable<ScheduleResponse>{
    return this.http.post<ScheduleResponse>(`${this.baseUrl}/${scheduleId}/clinics/`, data);
  }

  removeScheduleClinic(scheduleId:string, clinicId:string):Observable<ScheduleResponse>
  {
    return this.http.delete<ScheduleResponse>(`${this.baseUrl}/${scheduleId}/clinics/${clinicId}`);
  }

  calculateScheduleDirections(scheduleId: string): Observable<String> {
    return this.http.get<String>(`${this.baseUrl}/${scheduleId}/directions`);
  }

  getScheduleDirections(scheduleId: string): Observable<String> {
    return this.http.get<String>(`${this.baseUrl}/${scheduleId}/directions`);
  }

  setScheduleRoute(scheduleId: string, routeId: string): Observable<ScheduleResponse> {
    const payload = { routeId };
    return this.http.post<ScheduleResponse>(`${this.baseUrl}/${scheduleId}/route`, payload);
  }

  processScheduleOrder(scheduleId: string, orderId: string, statusId: string): Observable<ScheduleOrderResponse> {
    const payload = { statusId };
    return this.http.post<ScheduleOrderResponse>(`${this.baseUrl}/${scheduleId}/orders/${orderId}/process`, payload);
  }
}
