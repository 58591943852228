import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScheduleInteractionService {

  constructor() { }

    // Use a BehaviorSubject to store the currently selected stop id
    private selectedStopSubject = new BehaviorSubject<string | null>(null);
    selectedStop$ = this.selectedStopSubject.asObservable();
  
    // Call this to update the selected stop
    selectStop(stopId: string): void {
      this.selectedStopSubject.next(stopId);
    }
}
