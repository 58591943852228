import { Injectable } from '@angular/core';
import { roles } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RouteConfigService {

  private routes = [
    {
      label: 'Orders',
      icon: 'dashboard',
      panel: 'panelOrders',
      roles: [roles.awladmin, roles.awlstaff, roles.clinicadmin, roles.clinicstaff, roles.globeadmin],
      children: [
        { path: '/orders', label: 'Orders', roles: [roles.awladmin, roles.awlstaff, roles.clinicadmin, roles.clinicstaff, roles.globeadmin] },
        { path: '/orders/track-process/update-bulk', label: 'Update Orders', roles: [roles.awladmin, roles.clinicadmin, roles.globeadmin] },
      ]
    },
    {
      label: 'Clinics',
      icon: 'house',
      panel: 'panelClinics',
      roles: [roles.awlstaff, roles.awladmin, roles.globeadmin],
      children: [
        { path: '/clinics', label: 'Clinics', roles: [roles.awlstaff, roles.awladmin, roles.globeadmin] },
      ]
    },
    {
      label: 'Configurations',
      icon: 'tune',
      panel: 'panelConfigs',
      roles: [roles.clinicadmin, roles.awlstaff, roles.awladmin, roles.globeadmin],
      children: [
        { path: '/configurations/contacts', label: 'Contacts', roles: [roles.awlstaff, roles.awladmin, roles.globeadmin] },
        { path: '/configurations/locations', label: 'Locations', roles: [roles.awlstaff, roles.awladmin, roles.globeadmin] },
        { path: '/configurations/places', label: 'Places', roles: [roles.awlstaff, roles.awladmin, roles.globeadmin] },
        { path: '/configurations/animal-types', label: 'Animal Types', roles: [roles.awlstaff, roles.awladmin, roles.globeadmin] },
        { path: '/configurations/cremation-steps', label: 'Order Status', roles: [roles.awlstaff, roles.awladmin, roles.globeadmin] },
        { path: '/configurations/users', label: 'Users', roles: [roles.clinicadmin, roles.awladmin, roles.globeadmin] },
        { path: '/configurations/vehicles', label: 'Vehicles', roles: [ roles.awladmin, roles.globeadmin] },
      ]
    },
    {
      label: 'Schedules',
      icon: 'schedule',
      panel: 'panelSchedules',
      roles: [ roles.awlstaff, roles.awladmin, roles.globeadmin],
      children: [
        { path: '/schedules/routes', label: 'Routes', roles: [roles.awlstaff, roles.awladmin, roles.globeadmin] },
        { path: '/schedules', label: 'Schedules', roles: [roles.awlstaff, roles.awladmin, roles.globeadmin] },
      ]
    },
  ]

  getRoutesBasedOnUserRole(role: string): any [] {
    return this.routes.map(route => ({
      ...route,
      children: route.children.filter(child => child.roles.includes(role))
    }))
    .filter(route => route.roles.includes(role) && route.children.length > 0);
  }
}
