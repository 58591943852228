<div class="container">
    <div class="header">
        <h2>Schedules</h2>
    </div>
    <div class="flex-row align-items-center">
        <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. toyota" #input>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="openCreateScheduleDialog()" class="add-btn" *ngIf="isAdmin">
            Add Schedule
        </button>
    </div>
    <div *ngIf="isLoading" class="loading-container">
        <mat-spinner
        [diameter]="80"></mat-spinner>
    </div>
    <div [@invisTofadeIn]="isLoading ? 'hidden' : 'visible'">
        <div *ngIf="!isLoading" class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSourceSchedules" matSort>
                <ng-container matColumnDef="route">
                    <th mat-header-cell *matHeaderCellDef> Route </th>
                    <td mat-cell *matCellDef="let row"> {{row.route.name}} </td>
                </ng-container>
                <ng-container matColumnDef="driver">
                    <th mat-header-cell *matHeaderCellDef> Driver </th>
                    <td mat-cell *matCellDef="let row"> 
                        @if(row.driver)
                        {
                            {{row.driver.displayName}}
                        }@else 
                        {
                            Unassigned
                        } 
                    </td>
                </ng-container>
                <ng-container matColumnDef="orders">
                    <th mat-header-cell *matHeaderCellDef> Orders </th>
                    <td mat-cell *matCellDef="let row">
                        @if(row.orders.length > 0)
                        {
                            {{row.orders.length}}
                        } 
                        @else 
                        {
                            Empty
                        }
                    </td>
                </ng-container>
                <ng-container matColumnDef="vehicle">
                    <th mat-header-cell *matHeaderCellDef> Vehicle </th>
                    <td mat-cell *matCellDef="let row">
                        @if(row.vehicle)
                        {
                            {{row.vehicle.name}} ({{row.vehicle.make}} {{row.vehicle.model}})
                        }@else 
                        {
                            Unassigned
                        } 
                        </td>
                </ng-container>
                <ng-container matColumnDef="scheduleDate">
                    <th mat-header-cell *matHeaderCellDef> Schedule Date </th>
                    <td mat-cell *matCellDef="let row"> {{row.scheduleDate | date:'dd-MM-yyyy'}} </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions </th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="button-container">
                            <button mat-icon-button color="warn" matTooltip="Delete Schedule"
                                (click)="openDeleteScheduleDialog(element)" *ngIf="element.role !== 'globeadmin'">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button mat-icon-button color="primary" [routerLink]="['/schedules', element.id] " matTooltip="View Schedule">
                                <mat-icon>fullscreen</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsSchedules"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsSchedules;"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>
            </table> 
        </div>
    <mat-paginator [ngStyle]="{ 'display': isLoading ? 'none' : 'block' }"  [pageSizeOptions]="[25, 50, 100]" aria-label="Select page of schedules" showFirstLastButtons ></mat-paginator>
    </div>  
</div>

