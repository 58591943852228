import { Component } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CreateOrderStatus } from '../../../../models/orders/order.model';

@Component({
  selector: 'app-create-order-status',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule
  ],
  templateUrl: './create-order-status.component.html',
  styleUrl: './create-order-status.component.scss'
})
export class CreateOrderStatusComponent {

  form!:FormGroup;
  constructor(public dialogRef: MatDialogRef<CreateOrderStatusComponent>, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      customerDisplayName: ['', Validators.required],
      name: ['', Validators.required],
      description: ['', Validators.required],
      // isDefault: ['', Validators.required],
    });
  }

  onSubmit() {
    if(this.form.valid) {
      const cremationStep: CreateOrderStatus = {
        name: this.form.value.name,
        description: this.form.value.description,
        customerDisplayName: this.form.value.customerDisplayName,
      }
      this.dialogRef.close(cremationStep)
    }
  }

}
