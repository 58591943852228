<h2 mat-dialog-title>Edit order status</h2>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        <!-- Name and display name-->
        <div class="flex-container">
            <mat-form-field appearance="fill" class="flex-50">
                <mat-label>Status Name</mat-label>
                <input type="text" placeholder="Status Name" formControlName="name" matInput>
            </mat-form-field>
            <mat-form-field appearance="fill" class="flex-50">
                <mat-label>Customer Display Name</mat-label>
                <input type="text" placeholder="Customer Display Name" formControlName="customerDisplayName" matInput>
            </mat-form-field>
        </div>
        <!-- Description -->
        <div class="flex-container">
            <mat-form-field appearance="fill">
                <mat-label>Description</mat-label>
                <input type="text" placeholder="Description" formControlName="description" matInput>
            </mat-form-field>
        </div>
        <!-- Valid Transitions-->
        <p>Orders in this status are allowed to be transitioned to:</p>
        <button (click)="openSearchStatusDialog()" type="button" mat-icon-button color="primary">
            <mat-icon>add</mat-icon>
        </button>
        <mat-spinner [diameter]="30" *ngIf="!validTransitionsArray"></mat-spinner>
        <div *ngIf="validTransitionsArray">
            <div formArrayName="validTransitions" *ngFor="let statusControl of validTransitionsArray.controls; let i = index">
                <div class="column" [formGroupName]="i" *ngIf="statusControl && statusControl.value && statusControl.value.name">
                    <div class="flex-container">
                        <button type="button" (click)="removeValidTransition(i)" color="warn" mat-icon-button>
                            <mat-icon>delete</mat-icon>
                        </button>
                        <div class="flex-container">
                            <p class="flex-25">{{statusControl.value.name}}</p>
                            <p class="flex-75">{{statusControl.value.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Requirements-->
        <p>Before changing orders from this status, requirements:</p>
        <button (click)="openSearchRequirementDialog()" type="button" mat-icon-button color="primary">
            <mat-icon>add</mat-icon>
        </button>
        <div *ngIf="requirementsArray">
            <div formArrayName="requirements" *ngFor="let requirementControl of requirementsArray.controls; let i = index">
                <div [formGroupName]="i" *ngIf="requirementControl.value && requirementControl.value.name">
                    <div class="flex-row align-items-center">
                        <button type="button" color="warn" (click)="removeRequirement(i)" mat-icon-button>
                            <mat-icon>delete</mat-icon>
                        </button>
                        <div class="flex-container">
                            <p class="flex-25">{{requirementControl.value.name}}</p>
                            <p class="flex-75">{{requirementControl.value.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-checkbox color="primary" formControlName="isDefault">Is Default</mat-checkbox>
    </mat-dialog-content>
    <mat-dialog-actions class="flex-end-actions">
        <button mat-raised-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" type="submit">Save</button>
    </mat-dialog-actions>
</form>