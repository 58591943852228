import { Component, OnInit, Input, ViewChild, AfterViewInit, AfterViewChecked, inject, OnDestroy } from '@angular/core';
import { OrderService } from '../../../services/order.service';
import { Attachment, OrderResponse } from '../../../models/orders/order.model';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { MatCard,MatCardHeader,MatCardTitle } from '@angular/material/card';
import { EventResponse } from '../../../models/orders/event.model';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { UserService } from '../../../services/user.service';
import { UserFromAzure, UserInDB } from '../../../models/user.model';
import { PagedQueryResult } from '../../../models/pagedQueryResult.interface';
import { ToastrService } from 'ngx-toastr';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { DataSource } from '@angular/cdk/collections';
import { AuthService } from '../../../services/auth.service';
import { MatIcon } from '@angular/material/icon';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AttachmentDialogComponent } from '../../attachments/attachment-dialog/attachment-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { expand } from '../../../../assets/animations';

@Component({
  selector: 'app-order-events',
  standalone: true,
  imports: [MatProgressSpinnerModule,
    CommonModule,
    MatCard,
    MatButtonModule,
    MatCardHeader,
    MatCardTitle,
    MatTableModule,
    MatSortModule,
    MatSort,
    MatIcon,
    MatDialogModule,
  ],
  templateUrl: './order-events.component.html',
  styleUrl: './order-events.component.scss',
  animations: [expand],
})
export class OrderEventsComponent implements OnInit, AfterViewInit{
  @Input() orderId!:string;
  private _liveAnnouncer = inject(LiveAnnouncer);
  order!:OrderResponse
  orderEvents!:EventResponse[]
  filteredUsers!:UserInDB[];
  displayedColumns: string[] = ['CreatedAt','CreatedBy', 'ToStatus', 'FromStatus', 'ToPlace', 'FromPlace','Attachments',];
  dataSource:MatTableDataSource<EventResponse>;
  @ViewChild(MatSort) sort:MatSort;
  validRoles = ['globeadmin','awladmin','clinicadmin']
  isLoading= false

  userPerms: boolean = false
  constructor(
    private orderService: OrderService,
    private userService: UserService,
    private toastr: ToastrService,
    private loginService: AuthService,
    public dialog: MatDialog, 
  ) {
    this.sort = new MatSort();
    this.dataSource = new MatTableDataSource<EventResponse>();
    this.loginService.claims$.subscribe((c) => {
      if(this.validRoles.includes(c['extension_Role']))
        {
          this.userPerms = true
        }
    });
  }
  async ngAfterViewInit(): Promise<void> {
    await this.getEvents(this.orderId);
    this.dataSource.data = this.orderEvents;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item,property) => { switch (property) {
      case 'createdAt': return new Date(item.createdAt).getTime();
      default: return item['createdAt'];
    }}
  }


  ngOnInit(): void {
    this.isLoading = true
    this.getEvents(this.orderId);
  }
  //causing errors upon loading as not all staff have access
  updateUserIds(){
    if(this.userPerms)
      {
      this.userService.getUsers().subscribe({
        next: (res:PagedQueryResult<UserInDB>) => {
          this.filteredUsers = res.items.filter(user => this.orderEvents.some(e => e.createdBy == user.id));
          if(!this.displayedColumns.includes('CreatedBy'))
          {
            this.displayedColumns.push('CreatedBy');
            
          }
          this.isLoading = false
        },
        error: (error) => {
          this.toastr.error(error.error,error.name);
          this.isLoading = false
        }
      });
    }else
    {
      this.displayedColumns.splice(this.displayedColumns.indexOf('CreatedBy'),1)
      this.isLoading = false
    }
  }

  getUserName(id:string):string
  {
    var user = this.filteredUsers.find(user => user.id == id);
    if (!user){
      return "Unknown";
    }
    return `${user?.givenName} ${user?.surname}`;
  }
  //getting forbidden error for staff
  getEvents(orderId:string){
    this.orderService.getOrderEvents(orderId).subscribe({
      next: (res: EventResponse[]) =>
      {
        this.orderEvents = res;
        this.orderEvents = this.orderEvents.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime() )
        this.dataSource.data = this.orderEvents;
        this.dataSource.sort = this.sort;
        this.updateUserIds();
      }
    });
  }
  openAttachment(attachments:Attachment[])
  {
    console.log('Attachments ',attachments)
    this.dialog.open(AttachmentDialogComponent, {
      width: '*',
      height:'90vh',
      data: attachments
    })
  }
}
