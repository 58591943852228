import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { route, RouteResponse } from '../../../models/routes.model';
import { RoutesService } from '../../../services/routes.service';
import { ToastrService } from 'ngx-toastr';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from '../../../services/auth.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CreateRoutesComponent } from '../../../components/routes/create-routes/create-routes.component';
import { EditRoutesComponent } from '../../../components/routes/edit-routes/edit-routes.component';
import { DeleteDialogComponent } from '../../../components/delete-dialog/delete-dialog.component';
import { invisTofadeIn } from '../../../../assets/animations';

@Component({
  selector: 'app-routes-index',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSortModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './routes-index.component.html',
  styleUrl: './routes-index.component.scss',
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),invisTofadeIn
  ]
})
export class RoutesIndexComponent  implements OnInit , AfterViewInit {
  isAdmin: boolean = false;
  isLoading:boolean = false;
  isExpandLoading:boolean = false;
  role: string = '';
  displayedColumnsRoutes: string[] = ['name'];
  columnsToDisplayWithExpand = [...this.displayedColumnsRoutes, 'expand'];
  dataSourceRoutes = new MatTableDataSource<RouteResponse>();
  expandedElement!: RouteResponse | null;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  constructor(
    private routeService:RoutesService,
    private toastr: ToastrService, 
    private breakpointObserver: BreakpointObserver,
    private loginService: AuthService,
    public dialog: MatDialog, 
  )
  {
    this.loginService.claims$.subscribe((c) => {
      this.role = c['extension_Role'];
    });
  }
  ngOnInit(): void 
  {
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      if (result.matches) {
        this.displayedColumnsRoutes = ['name'];//,'clinics'
      } else {
        this.displayedColumnsRoutes = ['name'];//,'clinics'
      }

      if(this.role == 'globeadmin' || this.role == 'awladmin' || this.role == 'clinicadmin') {
        this.isAdmin = true;
      }
    });
  }
  ngAfterViewInit() {
    setTimeout(() =>{
      this.isLoading = true;
    },0)
    this.getData();
  }
  getData()
  {
    this.routeService.getRoutes().subscribe({
      next: (res) =>{
        this.dataSourceRoutes.data = res.items;
        this.dataSourceRoutes.paginator = this.paginator;
        this.dataSourceRoutes.sort = this.sort;
        this.isLoading = false;
        console.log(res.items)
      },
      error:(error) => {
        this.toastr.error(error.error?.title || 'An error occurred when getting Routes. Please try again.');
        this.isLoading = false;
      }
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceRoutes.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceRoutes.paginator) {
      this.dataSourceRoutes.paginator.firstPage();
    }
  }
  openCreateRouteDialog() {
    const dialogRef = this.dialog.open(CreateRoutesComponent, {
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.routeService.createRoute(result).subscribe({
          next: (res) => {
            this.toastr.success('Route created successfully.');
            this.getData();
          },
          error: (error) => {
            this.toastr.error(error.error,error.name);
          }
        })
      }
    })
  }
  openEditRouteDialog(route: RouteResponse)
  {
    const dialogRef = this.dialog.open(EditRoutesComponent, {
      width: '600px',
      data: route
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.routeService.updateRoute(route.id, result).subscribe({
          next: (res) => {
            this.toastr.success(`${result.name} updated successfully.`);
            this.getData();
          },
          error: (error) => {
            console.log(error.error,error.name)
            this.toastr.error(error.error,error.name);
          }
        })
      }
    })
  }
  openDeleteRouteDialog(route: RouteResponse) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: { type: route.name , name: route.id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.routeService.deleteRoute(route.id).subscribe({
          next: (res) => {
            this.toastr.success('Route deleted successfully.');
            this.getData();
          },
          error: (error) => {
            this.toastr.error(error.error,error.name);
          }
        });
      }
    });
  }
}
