<div class="container">
    <div class="header">
        <h2>Users Configuration</h2>
    </div>
    <div class="flex-row">
        <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="openAddUserDialog()" class="add-btn" *ngIf="isAdmin">
            Add User
        </button>
    </div>
        
    <!--LOADING SPINNER-->

    <div *ngIf="isLoading"  class="loading-container">
        <mat-spinner
        [diameter]="80"></mat-spinner>
    </div>

    <div class="mat-elevation-z0"  [@invisTofadeIn]="isLoading ? 'hidden' : 'visible'">
        <div *ngIf="!isLoading">


        <table mat-table [dataSource]="dataSourceUsersAzure" matSort>
            <ng-container matColumnDef="givenName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Given Name </th>
                <td mat-cell *matCellDef="let row"> {{row.givenName}} </td>
            </ng-container>
        
            <ng-container matColumnDef="surname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Surname </th>
                <td mat-cell *matCellDef="let row"> {{row.surname}} </td>
            </ng-container>
        
            <ng-container matColumnDef="displayName">
                <th mat-header-cell *matHeaderCellDef> Display Name </th>
                <td mat-cell *matCellDef="let row"> {{row.displayName}} </td>
            </ng-container>

            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef> Role </th>
                <td mat-cell *matCellDef="let row"> {{row.role ? row.role : 'User'}} </td>
            </ng-container>
            
            <ng-container matColumnDef="accountEnabled">
                <th mat-header-cell *matHeaderCellDef> Account Enabled </th>
                <td mat-cell *matCellDef="let row">
                    <ng-container *ngIf="row.accountEnabled === null; else statusTemplate">
                    </ng-container>
                    <ng-template #statusTemplate>
                      <span *ngIf="row.accountEnabled; else warnTemplate" class="text-primary">
                        <mat-icon aria-hidden="false" aria-label="enabled" fontIcon="check"></mat-icon>
                      </span>
                      <ng-template #warnTemplate>
                        <span class="text-warn">
                            <mat-icon aria-hidden="false" aria-label="disabled" fontIcon="close"></mat-icon>
                        </span>
                      </ng-template>
                    </ng-template>
                  </td>
            </ng-container>

            <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef> Created At </th>
                <td mat-cell *matCellDef="let row"> {{ formatTimestamp(row.createdAt) }} </td>
            </ng-container>

            <ng-container matColumnDef="invitationStatus">
                <th mat-header-cell *matHeaderCellDef> Invitation Status </th>
                <td mat-cell *matCellDef="let row"> {{row.invitationStatus}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="button-container">
                        <button mat-icon-button color="primary" class="edit-btn" matTooltip="Edit user"
                            (click)="openEditUserDialog(element)" *ngIf="element.role !== 'globeadmin'">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                </td>
                </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsUsersAzure"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsUsersAzure;"></tr>
        
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>
        </div>
        <mat-paginator [ngStyle]="{ 'display': isLoading ? 'none' : 'block' }" [pageSizeOptions]="[25, 50, 100]" aria-label="Select page of users" showFirstLastButtons></mat-paginator>
    </div>

</div>