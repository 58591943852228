 
<h2 mat-dialog-title>
    Update status requirements
</h2>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        <p>
            Check the requirements for updating the status from 
                <!-- {{ this.oldStatus.name }}  -->'old status?'
            to {{ this.newStatus.name }} and tick the box if you have: 
        </p>
        <div *ngFor="let req of newStatus.requirements; let i = index" class="section">
                <mat-checkbox [formControlName]="req.requirement" color="primary">{{ req.requirement }}</mat-checkbox>
            <div *ngIf="req.photoRequired === true">
                <div>
                    <button mat-raised-button (click)="cameraInput.click()" type="button" color="primary" class="btn short-button">
                        <mat-icon>camera_alt</mat-icon> {{ cameraBtn }}
                    </button>
                    <input type="file" accept="image/*" capture="environment" (change)="onFileChanged($event, req.requirement)" hidden #cameraInput>
                    <button mat-raised-button (click)="albumInput.click()" type="button" color="primary" class="btn short-button">
                        <mat-icon>photo_library</mat-icon> {{ photoLibraryBtn }}
                    </button>
                    <input type="file" accept="image/*" (change)="onFileChanged($event, req.requirement)" hidden #albumInput>
                </div>
                <div>
                    <img *ngIf="imageSrc[req.requirement]" [src]="imageSrc[req.requirement]" alt="Image Preview" class="image">
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="flex-end-actions">
        <button mat-raised-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">Add</button>
    </mat-dialog-actions>
</form>
