import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-camera-dialog',
  standalone: true,
  imports: [ 
    MatButtonModule,
    CommonModule,
    MatProgressSpinner,
    MatIconModule,
    MatDialogModule
  ],
  templateUrl: './camera-dialog.component.html',
  styleUrl: './camera-dialog.component.scss'
})
export class CameraDialogComponent implements OnInit, OnDestroy , AfterViewInit {

  constructor(public dialogRef: MatDialogRef<CameraDialogComponent>){}
  @ViewChild('video') video!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas', { static: true }) canvas!: ElementRef<HTMLCanvasElement>;
  isLoading = true

  //photo permission
  hasPermission = false
  private stream: MediaStream | null = null;

  ngOnInit() {
    this.checkPermission()
  }
  async ngAfterViewInit()
  {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ video: true });
      this.video.nativeElement.srcObject = this.stream;
      this.video.nativeElement.play();
      this.isLoading = false

    } catch (error) {
      this.isLoading = false
      console.error('Error accessing camera: ', error);
    }
  }
  ngOnDestroy(): void {
        if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
    }
  }

  capturePhoto() {
    const videoElement = this.video.nativeElement;
    const canvasElement = this.canvas.nativeElement;
    const context = canvasElement.getContext('2d');

    canvasElement.width = videoElement.videoWidth;
    canvasElement.height = videoElement.videoHeight;

    if (context) {
      context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

      const dataUrl = canvasElement.toDataURL('image/png');

      this.submitPhoto(dataUrl);
    }
  }
  close()
  {
    this.dialogRef.close()
  }
  submitPhoto(data: string) {
    this.dialogRef.close(data)
  }
  checkPermission() : boolean
  {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error("Camera access is not supported in this browser.");
      return false;
    }
    return true;
  }
}
