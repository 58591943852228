import { Component, Inject, OnInit, Input, SimpleChanges, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import mermaid from 'mermaid';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-flow-chart',
  standalone: true,
  imports: [
    MatCardModule,
    MatDialogModule
  ],
  templateUrl: './flow-chart.component.html',
  styleUrl: './flow-chart.component.scss'
})
export class FlowChartComponent {
  chartCode!: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string) { }
  ngOnInit() {
    this.chartCode = this.data; // Set the passed chart data to chartCode
  }
  private initialized: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['chartCode'] && this.initialized) {
      this.renderChart();
    }
  }

  ngAfterViewInit() {
    this.initialized = true;
    this.renderChart();
  }

  async renderChart() {
    if (this.chartCode) {
      mermaid.initialize({ startOnLoad: false,
        securityLevel:'sandbox',
        theme: 'dark'
       });

      try {
        const { svg } = await mermaid.render('mermaidChart', this.chartCode, document.getElementById('mermaidChart')!);
        const element = document.getElementById('mermaidChart');
        if (element) {
          element.innerHTML = svg;
        }
      } catch (error) {
        console.error('Error rendering Mermaid chart:', error);
      }
    }
  }
}
