<h2 mat-dialog-title>Edit user</h2>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        <div class="flex-container">
            <mat-form-field appearance="fill" class="flex-50"> 
                <mat-label>Given Name</mat-label>
                <input type="text" placeholder="Given Name" formControlName="givenName" matInput>
                <mat-error *ngIf="form.get('givenName')?.errors?.['required']">
                    Given Name is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="flex-50"> 
                <mat-label>Surname</mat-label>
                <input type="text" placeholder="Surname" formControlName="surname" matInput>
                <mat-error *ngIf="form.get('surname')?.errors?.['required']">
                    Surname is required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex-container">
            <mat-form-field appearance="fill" class="flex-1"> 
                <mat-label>Display Name</mat-label>
                <input type="text" placeholder="Display Name" formControlName="displayName" matInput>
                <mat-error *ngIf="form.get('displayName')?.errors?.['required']">
                    Display Name is required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex-container">
            <mat-form-field appearance="fill" class="flex-1"> 
                <mat-label>Clinic</mat-label>
                <mat-select formControlName="clinicId">
                    <mat-option *ngFor="let clinic of clinics" [value]="clinic.id">
                        {{ clinic.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('clinic')?.errors?.['required']">
                    Clinic is required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex-container">
            <mat-form-field appearance="fill" class="flex-1"> 
                <mat-label>Role</mat-label>
                <mat-select formControlName="role">
                    <mat-option *ngFor="let role of roles" [value]="role.azureRole">
                        {{ role.displayName }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('role')?.errors?.['required']">
                    Role is required
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-slide-toggle color="primary" formControlName="accountEnabled"> 
                Account Enabled
            </mat-slide-toggle>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="flex-end-actions">
        <button mat-raised-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" type="submit">Save</button>
    </mat-dialog-actions>
</form>
