import { Component, Inject, OnInit } from '@angular/core';
import { OrderService } from '../../../../services/order.service';
import { StatusRequirementResponse } from '../../../../models/orders/order.model';
import { ToastrService } from 'ngx-toastr';
import { AsyncPipe, CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'app-search-requirement',
  standalone: true,
  imports: [
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe,
    CommonModule,
    MatProgressSpinnerModule,
    MatButtonModule
  ],
  templateUrl: './search-requirement.component.html',
  styleUrl: './search-requirement.component.scss'
})
export class SearchRequirementComponent implements OnInit {

  requirements!:StatusRequirementResponse[];
  requirementControl = new FormControl<string | StatusRequirementResponse>('');
  filteredRequirements!:Observable<StatusRequirementResponse[]>;

  constructor(
    public dialogRef: MatDialogRef<SearchRequirementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {requirementsToExclude: StatusRequirementResponse[]}, 
    private orderService:OrderService,
    private toastr:ToastrService
  ) {}

  ngOnInit(): void {
    this.getRequirements();
  }

  excludeRequirements(reqs:StatusRequirementResponse[])
  {
    this.requirements = this.requirements.filter(req => !reqs.some(excludeReq => excludeReq.id === req.id));
  }

  private _filter(name: string): StatusRequirementResponse[] {
    const filterValue = name.toLowerCase();

    return this.requirements.filter(req => !req.name.toLowerCase().includes(filterValue));
  }

  displayName(req: StatusRequirementResponse): string {
    return req && req.name ? req.name : '';
  }

  // Get requirements
  getRequirements():void {
    this.orderService.getRequirements().subscribe({
      next: (res) =>{
        this.requirements = res.items;
        if (this.data.requirementsToExclude && this.data.requirementsToExclude.length > 0)
          {
            this.excludeRequirements(this.data.requirementsToExclude);
          }
        this.filteredRequirements = this.requirementControl.valueChanges.pipe(
          startWith(''),
          map(value => {
            const name = typeof value === 'string' ? value : value?.name;
            return name ? this._filter(name as string) : this.requirements.slice();
          }),
        );
      },
      error: (error) => {
        this.toastr.error(error.error,error.name);
      }
    });
  }

  onSubmit():void {
    const data = this.requirementControl.value;
    this.dialogRef.close(data)
  }

}
