<div class="sidebar">
  <div class="content">
    <mat-nav-list>
      <a 
      mat-list-item 
      class="menu-item"
      routerLink="/home" 
      routerLinkActive="selected-menu-item"
      #rla="routerLinkActive"
      [activated]="rla.isActive"
      >
        <mat-icon [fontSet]="rla.isActive ? 'material-icons' : 'material-icons-outlined'" matListItemIcon>home</mat-icon>
        <span matListItemTitle>Home</span>
      </a>
    </mat-nav-list> 
    
    <ng-container *ngFor="let route of routes">
      <mat-expansion-panel
        class="sidebar-panel"
        (click)="handleExpansionPanelClick()"
        [class.active-panel]="activePanel === route.panel"
        [expanded]="activePanel === route.panel"
        [hideToggle]="sideNavCollapsed()"
      >
        <mat-expansion-panel-header [ngStyle]="{'padding-right': sideNavCollapsed() ? '0' : ''}">
          <mat-panel-title>
            <mat-icon [fontSet]="activePanel === route.panel ? 'material-icons' : 'material-icons-outlined'" matListItemIcon>{{ route.icon }}</mat-icon>
            <span matListItemTitle>{{ route.label }}</span> 
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let child of route.children">
          <app-action-list [linkPath]="child.path" [linkLabel]="child.label"></app-action-list>
        </ng-container>
      </mat-expansion-panel>
    </ng-container>
    
  </div>

  <div class="menu-footer">
    <mat-expansion-panel class="sidebar-panel" (click)="handleExpansionPanelClick()" [class.active-panel]="activePanel === 'panelSettings'" [expanded]="activePanel === 'panelSettings'" [hideToggle]="sideNavCollapsed()">
        <mat-expansion-panel-header [ngStyle]="{'padding-right': sideNavCollapsed() ? '0' : ''}">
          <mat-panel-title>
            <mat-icon matListItemIcon [fontSet]="activePanel === 'panelSettings' ? 'material-icons' : 'material-icons-outlined'">settings</mat-icon>
            <span matListItemTitle>Settings</span> 
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-action-list>
          <a mat-list-item>
              <span>Example item</span>
          </a>
        </mat-action-list>
      </mat-expansion-panel>
  </div>
</div>


