import { Component } from '@angular/core';
import { ViewPlaceComponent } from '../../../components/places/view-place/view-place.component';

@Component({
  selector: 'app-place-view',
  standalone: true,
  imports: [
    ViewPlaceComponent
  ],
  templateUrl: './place-view.component.html',
  styleUrl: './place-view.component.scss'
})
export class PlaceViewComponent {

}
