<div class="container">
    <div class="header">
        <h2>Order Status Configuration</h2>
    </div>
</div>
<mat-tab-group dynamicHeight>
    <mat-tab label="Order Statuses">
        <app-order-status-index></app-order-status-index>
    </mat-tab>
    <mat-tab label="Status Requirements"> 
        <app-requirements-index></app-requirements-index>
    </mat-tab>
</mat-tab-group>