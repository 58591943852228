import { Component, model, OnInit } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { OrderResponse } from '../../../models/orders/order.model';
import { OrderService } from '../../../services/order.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { QRCodeModule } from 'angularx-qrcode';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
@Component({
  selector: 'app-search-orders',
  standalone: true,
  imports: [
    MatTableModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatIconModule,
    RouterLink,
    QRCodeModule,
    MatCheckboxModule,
    MatButtonModule,
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './search-orders.component.html',
  styleUrl: './search-orders.component.scss'
})
export class SearchOrdersComponent implements OnInit {
  // Searching and selecting orders in bulk

  // This component is designed to be opened as a dialog, and its contents returned to a parent component.

  orders!: OrderResponse[]
  dataSource: MatTableDataSource<OrderResponse> = new MatTableDataSource<OrderResponse>
  isLoading: boolean = true;
  displayedColumns: string[] = ['select', 'referenceNumber', 'clinic', 'animalName', 'weight', 'cremationType', 'orderStatus'];
  selection = new SelectionModel<OrderResponse>(true, []);
  ordersForm!: FormGroup;

  handset:boolean = false
  selectAllBtn = 'Select All'

  constructor(
    private dialogRef: MatDialogRef<SearchOrdersComponent>,
    private orderService: OrderService,
    private breakpointObserver: BreakpointObserver,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) { }
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      if (result.matches) {
        this.displayedColumns = ['referenceNumber','animalName','orderStatus'];
        this.handset = true
      } else {
        this.displayedColumns = ['select', 'referenceNumber', 'clinic', 'animalName', 'weight', 'cremationType', 'orderStatus'];
        this.handset = false
      }
    })
    this.ordersForm = this.fb.group({
      selectedOrders: [[], [this.requireSelectedOrdersValidator]]
    });
    this.getOrders();
  }

  requireSelectedOrdersValidator = (control:any) => {
    return this.selection.hasValue() ? null : {required: true};
  };

  updateSelectedOrders() {
    this.ordersForm.get('selectedOrders')?.setValue(this.selection.selected);
    this.ordersForm.get('selectedOrders')?.updateValueAndValidity();
  }

  getOrders(): void {
    this.orderService.getOrders().subscribe({
      next: (res) => {
        this.orders = res.orders;
        this.dataSource.data = res.orders;
        this.isLoading = false;
      },
      error: (error) => {
        this.toastr.error("Something went wrong." + (error.error ?? ""), (error.status ?? "") + " Error")
        this.isLoading = false;
      }
    });
  }

  onSubmit(): void {
    if (this.ordersForm.valid)
    {
      const data = this.ordersForm.value;
      this.dialogRef.close(data)
    }
    else
    {
      this.toastr.error("Please select at least one order.","Validation Error");
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const currentPageSize = this.dataSource._pageData(this.dataSource.data).length; //this.dataSource.data.length;
    return numSelected === currentPageSize;
  }
  selectAll() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }
  selectAllOption(event:any) {
    event.preventDefault();
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
    this.updateSelectedOrders()
    
  }

  selectOrder(index:number)
  {
    const model = this.dataSource.data[index];
    if(this.selection.isSelected(model))
      {
        this.selection.deselect(model)
      } 
      else 
      {
        this.selection.select(model)
      }
    this.updateSelectedOrders()
  }
  isOrderSelected(index:number):boolean//order:OrderResponse//(order:tableOrders): boolean
  {
    const model = this.dataSource.data[index]
    if(this.selection.isSelected(model))
      {
        return true;
      } 
      else
      {
        return false;
      }
  }
}