<h2 mat-dialog-title>Update Status on Orders:</h2>
<form [formGroup]="changeStatusForm" (ngSubmit)="onSubmit()">
    <mat-dialog-content *ngIf="orders.length > 0">
        <!-- ORDERS TABLE-->
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="reference">
              <th mat-header-cell *matHeaderCellDef> Ref. </th>
              <td mat-cell *matCellDef="let order"> {{order.reference}} </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let order"> {{order.animalName}} </td>
            </ng-container>
            <ng-container matColumnDef="weight">
              <th mat-header-cell *matHeaderCellDef> Weight </th>
              <td mat-cell *matCellDef="let order"> {{order.weight}} kg </td>
            </ng-container>
            <ng-container matColumnDef="animaltype">
              <th mat-header-cell *matHeaderCellDef> Animal Type </th>
              <td mat-cell *matCellDef="let order"> {{order.animalType.name}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        <!-- Status Picker -->
        <p>The orders will be given the status </p>
        <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select [disabled]="isSaving" [formControl]="statusIdControl" (selectionChange)="updateChosenStatus($event)">
                <mat-option>Clear</mat-option>
                <mat-option *ngFor="let status of validTransitions" value="{{status.id}}">{{status.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <!-- <p *ngIf="validTransitions.length <= 0 || validTransitions == undefined">There is no status that is applicable to all the selected orders. Remove some or all the orders and try again.</p> -->
        <!-- Requirements screen -->
        <div *ngIf="chosenStatus != null">
            <div *ngIf="chosenStatus.requirements && chosenStatus.requirements.length > 0">
                <p>This transition requires the following to be completed:</p>
            @for(order of orders; track $index)
            { 
                <div formGroupName="requirements">
                    <mat-card-title>Order {{order.reference}}</mat-card-title>  
                    <section *ngFor="let requirement of chosenStatus.requirements; let i = index" [formArrayName]="requirement.id" >
                        <mat-card appearance="raised" class="p-1 my-1">
                            <mat-card-subtitle class="pb-1">{{requirement.name}}</mat-card-subtitle>
                            <div  [formArrayName]="$index">
                                <div *ngIf="requirement.photoRequired">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Upload File</mat-label>
                                        <input type="text" [formControlName]="order.id+'_display'" matInput/>
                                        <mat-hint>Allowed formats: .png, .jpg, .webp</mat-hint>
                                    </mat-form-field>
                                    <button mat-raised-button class="me-1" (click)="openFileExplorer($event,requirement.id,order.id,$index)">
                                        <mat-icon color="primary">drive_folder_upload_icon</mat-icon>
                                        Upload
                                    </button>
                                    <button mat-raised-button (click)="openCamera($event,requirement.id,order.id,$index)">
                                        <mat-icon color="primary">photo_camera</mat-icon>
                                        Camera
                                    </button>
                                      <input hidden type="file" (change)="onFileChange($event,requirement.id,order.id,$index)" [attr.id]="'fileInput-' + requirement.id" />
                                </div>
                            </div>
                        </mat-card>
                    </section>
                </div>
            }
            </div>
        </div>
        <mat-divider></mat-divider>
        <!-- Place Picker -->
        <p>The orders will move to</p>
        <mat-form-field>
            <mat-label>Place</mat-label>
            <!--TODO: Autofill based on context-->
            <!-- This could be based in the Order Statuses, or from external components-->
            <mat-select [disabled]="isSaving" [formControl]="toPlaceControl" (optionSelected)="onPlaceControlSelected($event)">
                <mat-option value="Current">Stay in the same location</mat-option>
                <mat-option value="Vehicle">Vehicle</mat-option>
                <mat-option value="Clinic">Clinic</mat-option>
                <mat-option value="Custom">Other</mat-option>
            </mat-select>
        </mat-form-field>
        <!-- SELECT VEHICLE -->
        <app-search-vehicle 
            *ngIf="toPlaceControl.value == 'Vehicle'"
            [vehicleControl]="vehicleControl">
        </app-search-vehicle>
        <!-- SELECT CLINIC -->
        <app-search-clinic 
            *ngIf="toPlaceControl.value == 'Clinic'"
            [clinicControl]="clinicControl">
        </app-search-clinic>
        <mat-form-field *ngIf="toPlaceControl.value == 'Custom'">
            <mat-label>Specify</mat-label>
            <input [formControl]="customPlaceControl" placeholder="e.g. Freezer 1" matInput [disabled]="isSaving"/>
        </mat-form-field>
        <button [disabled]="isSaving || isLoading" (click)="openScanner()" class="form-field-button" mat-stroked-button color="primary" type="button">
            <mat-icon fontIcon="qr_code_scanner" ></mat-icon>
            Scan QR code
        </button>
        <br>
        <mat-divider></mat-divider>
        <br>
        <mat-form-field class="field-full-width">
            <mat-label>Comment</mat-label>
            <!-- TODO: -->
            <textarea matInput [formControl]="commentControl" placeholder="Ex. Moved because of..."></textarea>
        </mat-form-field>
        <br>

    </mat-dialog-content>
    <mat-dialog-content *ngIf="!orders || orders.length <= 0">
        <p>No orders selected.</p>
    </mat-dialog-content>

    <mat-dialog-actions class="flex-end-actions">
        <button mat-raised-button color="info" mat-dialog-close>Close</button>
        <div class="save-spinner">
            <mat-spinner *ngIf="isSaving" [diameter]="30"></mat-spinner>
        </div>
        <!-- Confirm button -->
        <button *ngIf="!isSaving" [disabled]="changeStatusForm.invalid" mat-raised-button color="primary"
            type="submit">Update All</button>
    </mat-dialog-actions>
</form>