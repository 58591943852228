import { CommonModule } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnInit, viewChild, ViewChild, viewChildren } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SchedulesService } from '../../../services/schedules.service';
import { ToastrService } from 'ngx-toastr';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from '../../../services/auth.service';
import { ScheduleResponse } from '../../../models/schedule.model';
import { DeleteDialogComponent } from '../../../components/delete-dialog/delete-dialog.component';
import { RouterLink } from '@angular/router';
import { fadeIn, invisTofadeIn } from '../../../../assets/animations';
import { CreateScheduleComponent } from '../../../components/schedules/create-schedule/create-schedule.component';

@Component({
  selector: 'app-schedules-index',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSortModule,
    MatIconModule,
    MatTooltipModule,
    RouterLink,
    MatProgressSpinnerModule,
  ],
  templateUrl: './schedules-index.component.html',
  styleUrl: './schedules-index.component.scss',
  animations: [fadeIn,invisTofadeIn]
})
export class SchedulesIndexComponent implements OnInit , AfterViewInit{
  isAdmin: boolean = false;
  isLoading:boolean = true;
  role: string = '';
  displayedColumnsSchedules: string[] = ['route','driver','orders','vehicle','scheduleDate','actions'];
  dataSourceSchedules = new MatTableDataSource<ScheduleResponse>();
  @Input() data:string = ''
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  constructor(
    private schduleService:SchedulesService,
    private toastr: ToastrService, 
    private breakpointObserver: BreakpointObserver,
    private loginService: AuthService,
    public dialog: MatDialog, 
  )
  {
    this.loginService.claims$.subscribe((c) => {
      this.role = c['extension_Role'];
    });
  }
  ngOnInit(): void {
    this.isLoading = true;
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      if (result.matches) {
        this.displayedColumnsSchedules = ['route','orders','scheduleDate'];
      } else {
        this.displayedColumnsSchedules = ['route','driver','orders','vehicle','scheduleDate'];
      }

      if(this.role == 'globeadmin' || this.role == 'awladmin' || this.role == 'clinicadmin') {
        this.displayedColumnsSchedules.push('actions');
        this.isAdmin = true;
      }
    });
  }
  ngAfterViewInit() {
    this.getData();
  }
  getData() {
    this.schduleService.getSchedules().subscribe({
      next: (res) =>{
        this.dataSourceSchedules.data = res.items;
        console.log(res);
        this.dataSourceSchedules.paginator = this.paginator;
        this.dataSourceSchedules.sort = this.sort;
        this.isLoading = false;
      },
      error:(error) => {
        this.toastr.error(error.error?.title || 'An error occurred when getting Schedules. Please try again.');
        this.isLoading = false;
      }
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceSchedules.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceSchedules.paginator) {
      this.dataSourceSchedules.paginator.firstPage();
    }
  }
  
  openCreateScheduleDialog() {
    const dialogRef = this.dialog.open(CreateScheduleComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getData();
    })
  }
  openDeleteScheduleDialog(element: ScheduleResponse) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: { type: element.route.name , name: element.scheduleDate }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.schduleService.deleteSchedule(element.id).subscribe({
          next: (res) => {
            this.toastr.success('Schedule deleted successfully.');
            this.getData();
          },
          error: (error) => {
            this.toastr.error(error.error,error.name);
          }
        });
      }
    });
  }
}
