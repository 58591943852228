<h2 mat-dialog-title>Edit Route</h2>
<form class="scrollable-form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>Add/Remove Clinics</h2>
  <div class="mx-1">
    <app-add-clinics (notifyParent)="updateData($event)" [data]="clinicData"></app-add-clinics>
  </div>
  <mat-dialog-actions class="flex-end-actions">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" type="submit">Add</button>
  </mat-dialog-actions>
</form>