<div *ngIf="users">
    <p mat-dialog-title>Choose a {{roleInput}}</p>
    <mat-form-field  class="w-100">
        <mat-label>Choose {{roleInput}}</mat-label>
        <input 
            matInput
            type="text"
            *ngIf="users.length > 0" 
            [formControl]="userControl"
            [matAutocomplete]="autoUser"/>
        @if (value) {
            <button color="primary" matSuffix mat-icon-button aria-label="Clear" (click)="resetValue()">
                <mat-icon>close</mat-icon>
            </button>
            }
        <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="userName" (optionSelected)="onUserSelected($event.option.value)">
            <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
                {{ user.displayName }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
<div class="flex-container">
    <mat-spinner
        *ngIf="!users"
        [diameter]="40">
    </mat-spinner>
</div>