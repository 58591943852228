 
<h2 mat-dialog-title class="flex-row">
      <span>Order Details</span>
      <button [routerLink]="['/orders', data.referenceNumber.toString()]" mat-icon-button aria-label="Open Full Screen" (click)="closeDialog()"> 
          <mat-icon>fullscreen</mat-icon>
      </button>
</h2>
<mat-dialog-content>
  <app-order-edit [orderRefFromTable]="data.referenceNumber.toString()" (orderUpdated)="onOrderUpdated()"></app-order-edit>
</mat-dialog-content>
<mat-dialog-actions class="flex-end-actions">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
