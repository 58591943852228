import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { LocationResponse } from '../../../models/location.model';
import { SearchLocationComponent } from '../../locations/search-location/search-location.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-schedule-location-dialog',
  standalone: true,
  imports: [
    SearchLocationComponent,
    MatButtonModule,
    MatCardModule,
    MatDialogModule
  ],
  templateUrl: './schedule-location-dialog.component.html',
  styleUrl: './schedule-location-dialog.component.scss'
})
export class ScheduleLocationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ScheduleLocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { field: 'origin' | 'destination' }
  ) {}

  onLocationSelected(location: LocationResponse): void {
    // Close the dialog, passing the selected location as result.
    this.dialogRef.close(location);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}