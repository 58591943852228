import { Component } from '@angular/core';
import { OrderEditComponent } from '../../../components/orders/order-edit/order-edit.component';
import { OrderActionsComponent } from '../../../components/orders/order-actions/order-actions.component';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
@Component({
  selector: 'app-orders-edit',
  standalone: true,
  imports: [
    OrderEditComponent,
    OrderActionsComponent,
    MatButtonModule,
    RouterLink
  ],
  templateUrl: './orders-edit.component.html',
  styleUrl: './orders-edit.component.scss'
})
export class OrdersEditComponent{
 
}
