<h2 mat-dialog-title>Create Route</h2>
<form class="scrollable-form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <div class="flex-row align-items-centers">
        <mat-form-field appearance="fill">
            <mat-label>Route Name</mat-label>
            <input type="text" placeholder="Adelaide to Goodwood" class="flex-50" formControlName="name" matInput>
        </mat-form-field>
    </div>
  </mat-dialog-content>
  <h2 mat-dialog-title>Select Clinics</h2>
  <div class="mx-1">
    <app-add-clinics (notifyParent)="updateData($event)" [data]="clinicData"></app-add-clinics>
  </div>

  <mat-dialog-actions class="flex-end-actions">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" type="submit">Add</button>
  </mat-dialog-actions>
</form>
