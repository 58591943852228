<h2 mat-dialog-title>Create a Vehicle</h2>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <div class="flex-container align-items-centers">
        <mat-form-field class="w-50">
            <mat-label>Registration</mat-label>
            <input type="text" placeholder="Reg no." formControlName="registration" matInput>
        </mat-form-field>
        <section class="w-50 center-offset flex-center">
            <mat-slide-toggle class="flex-center" color="primary" formControlName="isAvailable"> 
                Is Available
            </mat-slide-toggle>
        </section>
    </div>

    <div class="flex-container">
        <mat-form-field appearance="fill">
            <mat-label>Make</mat-label>
            <input type="text" placeholder="Toyota" class="flex-33" formControlName="make" matInput>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Model</mat-label>
            <input type="text" placeholder="Hilux" class="flex-33" formControlName="model" matInput>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input type="text" placeholder="Vehicle name" class="flex-33" formControlName="name" matInput>
          </mat-form-field>
    </div>



  </mat-dialog-content>
  <mat-dialog-actions class="flex-end-actions">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" type="submit">Add</button>
  </mat-dialog-actions>
</form>
