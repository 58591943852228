<div *ngIf="schedule">
    <h1 class="title">Schedule Details</h1>
    <div>
        <div class="display-field">
            <mat-icon>
                event
            </mat-icon>
            <mat-label>
                Date:
            </mat-label>
            <div class="display-value">
                {{ schedule.scheduleDate | date }}
            </div>
        </div>
        <div class="display-field">
            <mat-icon>
                airport_shuttle
            </mat-icon>
            <mat-label>
                Vehicle:
            </mat-label>
            <div class="display-value">
                {{ schedule.vehicle.name }}
            </div>
        </div>
        <div class="display-field">
            <mat-icon>
                person
            </mat-icon>
            <mat-label>
                Driver:
            </mat-label>
            <div class="display-value">
                {{ schedule.driver.givenName }} {{ schedule.driver.surname }}
            </div>
        </div>
        <div class="display-field">
            <mat-icon>
                location_on
            </mat-icon>
            <mat-label>
                Route:
            </mat-label>
            <div class="display-value">
                {{ schedule.route.name }}
            </div>
        </div>
      </div>
    <div class="action-button-container">
        <button mat-raised-button color="primary" (click)="addStops()">Add stops</button>
    </div>
    <mat-tab-group dynamicHeight>
        <mat-tab label="Orders View">
            <app-order-table *ngIf="!isLoading" [scheduleOrders]="scheduleOrders" [schedule]=schedule></app-order-table>
        </mat-tab>
        <mat-tab label="Route View">
            <app-schedule-route-view *ngIf="!isLoading" [schedule]=schedule [locations]="locations"></app-schedule-route-view>
            <app-schedule-route-map *ngIf="!isLoading && scheduleDirections" [directions]="scheduleDirections" [locations]="locations" [tabGroup]="tabGroup"></app-schedule-route-map>
        </mat-tab>
        <mat-tab label="Schedule View">
            <app-schedule-edit [schedule]="schedule"></app-schedule-edit>
        </mat-tab>
    </mat-tab-group>
</div>