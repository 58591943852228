<div *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>
  
  <div *ngIf="!isLoading && dataSource.data.length > 0">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
      <ng-container matColumnDef="orderNumber">
        <th mat-header-cell *matHeaderCellDef>Order #</th>
        <td mat-cell *matCellDef="let row">{{ row.order?.referenceNumber }}</td>
      </ng-container>
  
      <ng-container matColumnDef="orderType">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let row">{{ row.scheduleType }}</td>
      </ng-container>

      <ng-container matColumnDef="clinic">
        <th mat-header-cell *matHeaderCellDef>Clinic</th>
        <td mat-cell *matCellDef="let row">{{ getClinicName(row.order?.clinicId) }}</td>
      </ng-container>
  
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row">{{ row.status }}</td>
      </ng-container>
  
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let row">{{ row.scheduleType === 'pickup' ? formatAddress(row.order?.pickUpLocation?.address) : formatAddress(row.order?.dropOffLocation?.address) }}</td>
      </ng-container>
  
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let row">
          <button mat-stroked-button color="primary" type="button" (click)="viewOrder(row.order.referenceNumber)">View Order</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  
  <div *ngIf="!isLoading && dataSource.data.length === 0">
    No orders found!
  </div>