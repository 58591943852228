<div *ngIf="vehicles">
    <p mat-dialog-title>Choose a Vehicle</p>
    <mat-form-field class="w-100">
        <mat-label>Choose Vehicle</mat-label>
        <input 
            matInput
            type="text"
            *ngIf="vehicles.length > 0" 
            [disabled]="isSaving"
            [formControl]="vehicleControl"
            [matAutocomplete]="autoVehicle"/>
        @if (value) {
            <button color="primary" matSuffix mat-icon-button aria-label="Clear" (click)="resetValue()">
                <mat-icon>close</mat-icon>
            </button>
            }
        <mat-autocomplete #autoVehicle="matAutocomplete" [displayWith]="vehicleName" (optionSelected)="onVehicleSelected($event.option.value)">
            <mat-option *ngFor="let vehicle of filteredVehicles | async" [value]="vehicle">
                {{ vehicle.name }} ({{vehicle.registration}})
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
<div class="flex-container">
    <mat-spinner
        *ngIf="!vehicles"
        [diameter]="40">
    </mat-spinner>
</div>