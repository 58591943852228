import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { inject } from '@angular/core';
import { map } from 'rxjs';

export const authGuard: CanActivateFn = (route, state) => {
  const loginService = inject(AuthService);
  const toastr = inject(ToastrService);
  const router = inject(Router);
  
  return loginService.claims$.pipe(
    map((claims: any) => {
      const role = claims['extension_Role'];
      const allowedRoles = route.data['roles'] as string[];
      
      if(allowedRoles.includes(role)) {
        return true;
      } else {
        toastr.error("You do not have the permission to access this page, redirect to home page", "Access Denied")
        // Redirect to the /home route
        router.navigate(['/home']);
        return false;
      }
    })
  )
}