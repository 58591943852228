import { Component, EventEmitter,input,Input ,OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatLabel } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormControl, Validators } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {
  MatDialogModule,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { RoutesService } from '../../../services/routes.service';
import { RouteResponse } from '../../../models/routes.model';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-search-routes',
  standalone: true,
  imports: [
    CommonModule,
    MatLabel,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    MatButtonModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule
  ],
  templateUrl: './search-routes.component.html',
  styleUrl: './search-routes.component.scss'
})
export class SearchRoutesComponent implements OnInit{
  isLoading: boolean = false
  selected!: RouteResponse;
  routeFilteredOptions!: Observable<RouteResponse[]>;
  routes!: RouteResponse[]
  @Input() routeId!:string;
  @Input() routeControl:FormControl =  new FormControl();
  @Output() routeSelected = new EventEmitter<RouteResponse>();

  constructor(public dialogRef: MatDialogRef<SearchRoutesComponent>, private RouteService:RoutesService, private toastr: ToastrService)
  {

  }
  ngOnInit() {
    if(this.routeId)
      {
        this.getRouteData(this.routeId)
      }

    this.getData();
  }
  getData()
  {
    this.RouteService.getRoutes().subscribe({
      next: (res) => {
        this.routes = res.items;
        this.setFilteredRoute();
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
      }
    })
  }
  getRouteData(id:string)
  {
    this.isLoading = true; 
    this.RouteService.getRoute(id).subscribe({
      next: (res) => {
        this.selected = res
        this.routeControl.setValue(res);
        this.isLoading = false;
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
      }
    })

  }
  setFilteredRoute() {
    this.routeFilteredOptions = this.routeControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const searchTerm = typeof value === 'string' ? value : value?.name;
        return searchTerm ? this.routeFilter(searchTerm as string) : this.routes.slice();
      }),
    );
  }
  private routeFilter(searchTerm: string): RouteResponse[] {
    const filterValue = searchTerm.toLowerCase();
    return this.routes.filter(option => 
      (option.name.toLowerCase()).includes(filterValue)
    );
  }
  displayContactFn(route: RouteResponse): string {
    return route && typeof route === 'object' && route.name
    ? `${route.name} `
    : '';
  }
  onSelectionContact(route: RouteResponse) {
    this.selected = route;
    this.routeControl.setValue(route);
    this.routeSelected.emit(route);
  }
}
