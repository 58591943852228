import { Component, Inject, inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatListItem, MatListItemTitle, MatListModule } from '@angular/material/list';
import { SearchOrdersComponent } from '../search-orders/search-orders.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatLine } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { OrderResponse } from '../../../models/orders/order.model';
import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { QrScannerComponent } from '../../scanner-dialog/qr-scanner/qr-scanner.component';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { OrderService } from '../../../services/order.service';

@Component({
  selector: 'app-order-options-bottom-sheet',
  standalone: true,
  imports: [
    CommonModule,
    MatListModule,
    MatListItem,
    MatListItemTitle,
    MatInputModule,
    MatButtonModule,
    MatLine,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule
  ],
  templateUrl: './order-options-bottom-sheet.component.html',
  styleUrl: './order-options-bottom-sheet.component.scss'
})
export class OrderOptionsBottomSheetComponent implements OnInit{
  isLoading = false

  orderReferences:string[] = []//testing
  referenceResult:string = ''//testing

  orders:OrderResponse[] = []
  //manual form
  manualAddingOrder: boolean = false;
  orderReference = new FormControl('', [Validators.required]);

 constructor(
  public dialog: MatDialog,
  private toastr: ToastrService, 
  @Inject(MAT_BOTTOM_SHEET_DATA) public data: OrderResponse[] 
 ){}
 private _bottomSheetRef =
  inject<MatBottomSheetRef<OrderOptionsBottomSheetComponent>>(MatBottomSheetRef)
  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
  ngOnInit(): void {
   this.orders = this.data 
   console.log('orders on bottom sheet ',this.orders)
  }
  openOrdersDialog():void {
    const dialogRef = this.dialog.open(SearchOrdersComponent,{
      width: '950px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.selectedOrders && result.selectedOrders.length > 0)
        {
          result.selectedOrders.forEach((order:OrderResponse) =>{
            this.validateAndProcessOrder(order.reference)
          })
          this.emitOrders(result)
        }
    });
  }
  //QR Order
  openScannerDialog()
  {
    const dialogRef = this.dialog.open(QrScannerComponent,{
      width: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result)
        {
          this.validateAndProcessOrder(result);
          this.referenceResult = result
        }
    });
  }
  qrAddingOrder()
  {
    this.emitOrders(this.referenceResult);
  }
  resetRef()
  {
    this.referenceResult = ''
  }
  //Manual Order
  toggleManualAdd()
  {
    this.manualAddingOrder = !this.manualAddingOrder;
  }
  manualAddOrder() {
    try {
      const orderReference = this.orderReference.value;
      if(orderReference) {
        this.validateAndProcessOrder(orderReference);
        this.emitOrders(orderReference);
        this.orderReference.reset();
        this.manualAddingOrder = false;
      }else {
        throw new Error("Order reference is null or empty");
      }
    } catch (error) {
      if(error instanceof Error) {
        this.toastr.error(error.message);
      } else {
        this.toastr.error('An unexpected error occurred');
      }
    }
  }
  //validate
  validateAndProcessOrder(orderReference: string): void {
    
    const orderReferences = this.orders.map(order => order.reference);
    if (!orderReference || orderReferences.includes(orderReference)) {
      this.toastr.error("Invalid or duplicate order reference")
      throw new Error("Invalid or duplicate order reference");
    }
  }
  //send orders list OR orderRef back
  emitOrders(orders:OrderResponse[]| string)
  {
    this._bottomSheetRef.dismiss({data: orders});
  }
}
