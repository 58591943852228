import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AddClinicsComponent } from '../add-clinics/add-clinics.component';
import { CreateRouteRequest, RouteResponse } from '../../../models/routes.model';
import { EditRoutesComponent } from '../edit-routes/edit-routes.component';
import { ClinicResponse } from '../../../models/clinic.model';

@Component({
  selector: 'app-create-routes',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    AddClinicsComponent
  ],
  templateUrl: './create-routes.component.html',
  styleUrl: './create-routes.component.scss'
})
export class CreateRoutesComponent {
  form!:FormGroup;
  //routes:RouteResponse[] = []
  clinicData: ClinicResponse[] = [];

  
  constructor(
    public dialogRef: MatDialogRef<EditRoutesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RouteResponse, 
    private fb: FormBuilder,
    public dialog: MatDialog, 
  ){}
  ngOnInit(): void {
    this.form = this.fb.group({
      name:['',[Validators.required]],
      clinics:[null]
    });
  }
  updateData(event:ClinicResponse[])
  {
    this.clinicData = event
  } 
  onSubmit()
  {
    if(this.form.valid) {
      const clinicIds =this.clinicData.map(clinic => clinic.id)
      const route: CreateRouteRequest = {
        name: this.form.value.name,
        clinics: clinicIds
      }
      this.dialogRef.close(route)
    }
  }
}
