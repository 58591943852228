<h2 mat-dialog-title >Delete requirement</h2>
<mat-dialog-content>
    <p>Are you sure you want to delete this requirement?</p>
    <p>Details: </p>
    <mat-list>
        <mat-list-item>{{requirement.name}}</mat-list-item>
        <mat-list-item>{{requirement.description}}</mat-list-item>
    </mat-list>
</mat-dialog-content>
<mat-dialog-actions class="flex-end-actions">
    <button mat-raised-button color="into" mat-dialog-close>Cancel</button>
    <button [disabled]="isLoading" mat-raised-button color="warn" (click)="onConfirmClick()">Delete</button>
</mat-dialog-actions>
