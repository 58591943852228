import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../../../components/delete-dialog/delete-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { UserService } from '../../../services/user.service';
import { UpdateUserRoleRequest, UserInDB } from '../../../models/user.model';
import { UserEditDialogComponent } from '../../../components/users/user-edit-dialog/user-edit-dialog.component';
import { AuthService } from '../../../services/auth.service';
import moment from 'moment';
import 'moment-timezone';  
import { UserNewDialogComponent } from '../../../components/users/user-new-dialog/user-new-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { invisTofadeIn } from '../../../../assets/animations';

@Component({
  selector: 'app-users-index',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSortModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './users-index.component.html',
  styleUrl: './users-index.component.scss',
  animations:[invisTofadeIn]
})
export class UsersIndexComponent implements OnInit, AfterViewInit {
  form!: FormGroup;
  role: string = '';
  isAdmin: boolean = false;
  isLoading: boolean = true;

  displayedColumnsUsersAzure: string[] = ['id', 'givenName', 'surname', 'displayName', 'role',  'accountEnabled', 'createdAt','invitationStatus', 'actions'];
  dataSourceUsersAzure = new MatTableDataSource<UserInDB>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  constructor(
    public dialog: MatDialog, 
    private userService: UserService, 
    private toastr: ToastrService, 
    private breakpointObserver: BreakpointObserver,
    private loginService: AuthService
  ) {
    this.loginService.claims$.subscribe((c) => {
      this.role = c['extension_Role'];
    });
   }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      if (result.matches) {
        this.displayedColumnsUsersAzure = ['givenName', 'surname', 'displayName'];
      } else {
        this.displayedColumnsUsersAzure = ['id', 'givenName', 'surname', 'displayName', 'role', 'accountEnabled', 'createdAt', 'invitationStatus'];
      }

      if(this.role == 'globeadmin' || this.role == 'awladmin' || this.role == 'clinicadmin') {
        this.displayedColumnsUsersAzure.push('actions');
        this.isAdmin = true;
      }
    });
  }

  ngAfterViewInit() {
    this.getData();
  }

  getData() {
    this.userService.getUsers().subscribe({
      next: (res) => {
        this.dataSourceUsersAzure.data = res.items;
        this.dataSourceUsersAzure.paginator = this.paginator;
        this.dataSourceUsersAzure.sort = this.sort;
        this.isLoading = false;
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred when getting users from azure. Please try again.');
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceUsersAzure.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceUsersAzure.paginator) {
      this.dataSourceUsersAzure.paginator.firstPage();
    }
  }

  openAddUserDialog() {
    const dialogRef = this.dialog.open(UserNewDialogComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.userService.createUser(result).subscribe({
          next: (res) => {
            this.toastr.success('User created successfully, they should receive an email shortly.');
            this.getData();
          },
          error: (error) => {
            if(error.status == '403') {
              this.toastr.error(error.error?.message || 'User do not have access to perform this action');
            } else {
              this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
            }
          }
        });
      }
    })
  }

  formatTimestamp(timestamp: string): string {
    return moment.utc(timestamp).tz(moment.tz.guess()).format('DD-MM-YYYY hh:mm A');
  }

  openEditUserDialog(element: UserInDB) {
    const dialogRef = this.dialog.open(UserEditDialogComponent, {
      width: '500px',
      // data: element
      data: element.id
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        //update user
        this.userService.updateUser(element.id, result).subscribe({
          next: (res) => {
            this.toastr.success('User updated successfully.');
            this.getData();
          },
          error: (error) => {
            this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
          }
        });
        
        // update role
        const updateRequest: UpdateUserRoleRequest = {
          role: result.role
        };

        if(element.role !== result.role) {
          this.userService.patchUserRole(element.id, updateRequest).subscribe({
            next: (res) => {
              this.toastr.success('User role updated successfully.');
              this.getData();
            },
            error: (error) => {
              this.toastr.error(error.error?.title || error.error.errors[0].message ||'An error occurred. Please try again.');
            }
          });
        }
      }
    })
  }
}
