import { Component } from '@angular/core';
import { RequirementsIndexComponent } from '../../components/orders/requirements/requirements-index/requirements-index.component';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { OrderStatusIndexComponent } from '../../components/orders/order-statuses/order-status-index/order-status-index.component';

@Component({
  selector: 'app-cremation-steps',
  standalone: true,
  imports: [
    CommonModule,
    RequirementsIndexComponent,
    MatTabsModule,
    OrderStatusIndexComponent
  ],
  templateUrl: './cremation-steps.component.html',
  styleUrl: './cremation-steps.component.scss'
})

export class CremationStepsComponent{

}