import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouteResponse,UpdateRouteRequest } from '../../../models/routes.model';
import { AddClinicsComponent } from '../add-clinics/add-clinics.component';
import { ClinicResponse } from '../../../models/clinic.model';
import { RoutesService } from '../../../services/routes.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-routes',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    AddClinicsComponent
  ],
  templateUrl: './edit-routes.component.html',
  styleUrl: './edit-routes.component.scss'
})
export class EditRoutesComponent {
  form!:FormGroup;
  loading:boolean = false;
  clinicData:ClinicResponse[] = []
  constructor(
    public dialogRef: MatDialogRef<EditRoutesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RouteResponse, 
    private fb: FormBuilder,
    public dialog: MatDialog,
    private routesService: RoutesService,
    private toastr: ToastrService,
  ){}
  
  ngOnInit(): void {
    this.clinicData = this.data.clinics
    this.form = this.fb.group({
      name:[this.data.name,[Validators.required]],
      clinics:[]
    });
  }

  updateData(event:ClinicResponse[])
  {
    this.clinicData = event
  } 
  onSubmit()
  {
    if(this.form.valid) {
      const clinicIds = this.clinicData.map(clinic => clinic.id)
      const route: UpdateRouteRequest = {
        name: this.form.value.name,
        clinics: clinicIds
      }
      this.routesService.updateRoute(this.data.id, route).subscribe({
        next: (route) => {
          this.dialogRef.close(true);
        },
        error: (err) => {
          this.toastr.error('Error updating route', err);
        }
      });
    }
  }

}
