import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, RedirectRequest } from '@azure/msal-browser';
import { BehaviorSubject, filter } from 'rxjs';

export const roles = {
  globeadmin: 'globeadmin',
  awladmin: 'awladmin',
  awlstaff: 'awlstaff',
  clinicadmin: 'clinicadmin',
  clinicstaff: 'clinicstaff',
  enduser: 'enduser'
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Use BehaviorSubject to provide an observable for claims availability
  private claimsSubject = new BehaviorSubject<{[key: string]: any }>({});
  claims$ = this.claimsSubject.asObservable();
  
  loginDisplay = false;
  isLoggedIn = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router
  ) {
    // this.getClaims(undefined);
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
    )
    .subscribe((result: EventMessage) => {
      const payload = result.payload as AuthenticationResult;
      this.authService.instance.setActiveAccount(payload.account);
    });

  this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None)
    )
    .subscribe(() => {
      this.setLoginDisplay();
      this.checkAndSetActiveAccount();
      const claims = this.authService.instance.getActiveAccount()?.idTokenClaims;
      this.getClaims(claims);
    });
   }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    this.isLoggedIn = this.loginDisplay;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
        let accounts = this.authService.instance.getAllAccounts();
        this.authService.instance.setActiveAccount(accounts[0]);
    }
}

  getClaims(claims: any) {
    let claimsDict: { [key: string]: any } = {};

    if (claims) {
      // Directly use claims as a dictionary
      Object.keys(claims).forEach(key => {
        claimsDict[key] = claims[key];
      });
  
      // Update your claims subject with the dictionary of claims
      this.claimsSubject.next(claimsDict);
    } else {
      this.claimsSubject.next({});
    }
  }

  login() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    const activeAccount =
      this.authService.instance.getActiveAccount() ||
      this.authService.instance.getAllAccounts()[0];

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        account: activeAccount,
      });
    } else {
      this.authService.logoutRedirect({
        account: activeAccount,
      });
    }
  }
}