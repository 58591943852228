import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PlaceService } from '../../../services/place.service';
import { ToastrService } from 'ngx-toastr';
import { BarcodeFormat } from '@zxing/library';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { PlaceResponse } from '../../../models/orders/place.model';

@Component({
  selector: 'app-scan-place-code',
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    ZXingScannerModule,
    MatSelectModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule
  ],
  templateUrl: './scan-place-code.component.html',
  styleUrl: './scan-place-code.component.scss'
})
export class ScanPlaceCodeComponent {

// This should be a generic QR scanner component for future use.

  referenceResult: string = '';

  isLoading:boolean = true;

  showScanner = true;
  allowedFormats = [BarcodeFormat.QR_CODE];
  availableDevices!: MediaDeviceInfo[];
  deviceCurrent!: MediaDeviceInfo | undefined;
  deviceSelected!: string;

  hasDevices!: boolean;
  hasPermission!: boolean;
  qrResultString!: string;

  place!:PlaceResponse;

  constructor(
    private dialogRef: MatDialogRef<ScanPlaceCodeComponent>,
    private placeService:PlaceService,
    private toastr:ToastrService
  ){}

  confirmPlace() :void {
    console.log(this.place);
    this.dialogRef.close(this.place);
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = devices && devices.length > 0;
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;

    if (this.qrResultString) {
      this.isLoading = true; 
      this.handleQRResult();
      this.closeCamera();  
    }
  }

  onDeviceSelectChange(selected: string) {
    const selectedStr = selected || '';
    if (this.deviceSelected === selectedStr) { return; }
    this.deviceSelected = selectedStr;
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.deviceCurrent = device || undefined;
  }

  onDeviceChange(device: MediaDeviceInfo) {
    const selectedStr = device?.deviceId || '';
    if (this.deviceSelected === selectedStr) { return; }
    this.deviceSelected = selectedStr;
    this.deviceCurrent = device || undefined;
  }

  handleQRResult(): void {
    try {
      const url = new URL(this.qrResultString);
      const envUrl = new URL(window.location.href);
      //domain check
      if(url.hostname !== envUrl.hostname) {
        throw new Error("Invalid domain: " + url.hostname);
      }

      //path check
      if(!url.pathname.startsWith('/configurations/places/')) {
        throw new Error("URL does not belong to correct section.");
      }

      const placeReference = url.pathname.split('/').pop() || ''; // ID is the last segment

      if(placeReference) {
        this.validateAndProcessReference(placeReference);
        this.referenceResult = placeReference;
      } else {
        throw new Error("Order reference is null or empty");
      }
    } catch (error) {
      if(error instanceof Error) {
        this.toastr.error(error.message);
      } else {
        this.toastr.error('An unexpected error occurred');
      }
    } finally {
      this.isLoading = false;
    }
  }

  validateAndProcessReference(placeReference: string): void {
    this.placeService.getPlaceByReference(placeReference).subscribe({
      next:(res) =>
      {
        this.place = res;
        this.isLoading=false;
        this.confirmPlace();
      }
    });
  }

  toggleCamera() {
    this.showScanner = true;
  }

  closeCamera() {
    this.showScanner = false;
    this.qrResultString = '';
  }

  scanErrorHandler(error: Error) {
    this.showScanner = false;

    if (error.name === 'NotAllowedError') {
      this.hasPermission = false;
    } else if (error.name === 'NotFoundError') {
      this.hasDevices = false;
    }
   
    this.toastr.error(error.message || 'An error occurred', 'Scan Error');
  }

}
