import { Component,OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { OrderService } from '../../../../services/order.service';
import { OrderStatusResponse, StatusRequirementResponse } from '../../../../models/orders/order.model';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { map, Observable, startWith } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-search-order-status',
  standalone: true,
  imports: [
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe,
    CommonModule,
    MatProgressSpinnerModule,
    MatButtonModule
  ],
  templateUrl: './search-order-status.component.html',
  styleUrl: './search-order-status.component.scss'
})
export class SearchOrderStatusComponent implements OnInit {
  
  statusControl = new FormControl<string | OrderStatusResponse>('');
  statuses!:OrderStatusResponse[];
  filteredStatuses!:Observable<OrderStatusResponse[]>;

  constructor(
    public dialogRef: MatDialogRef<SearchOrderStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {currentStatuses: OrderStatusResponse[]}, 
    private orderService:OrderService,
    private toastr:ToastrService
  ){}
  ngOnInit(): void {
    this.getOrderStatuses();
  }

  displayName(status: OrderStatusResponse): string {
    return status && status.name ? status.name : '';
  }

  filterOutCurrentStatuses():void {
    if (this.data && this.data.currentStatuses && this.data.currentStatuses.length > 0)
    {
      const currentStatusIds = this.data.currentStatuses.map(status => status.id);
      this.statuses = this.statuses.filter(status => currentStatusIds.includes(status.id));
    }
  }

  private _filter(name: string): OrderStatusResponse[] {
    const filterValue = name.toLowerCase();

    return this.statuses.filter(status => !status.name.toLowerCase().includes(filterValue));
  }

  //Retrieve All Order Statuses
  getOrderStatuses():void {
    this.orderService.getOrderStatuses().subscribe({
      next: (res) =>{
        this.statuses = res.items;
        //this.filterOutCurrentStatuses();
        this.filteredStatuses = this.statusControl.valueChanges.pipe(
          startWith(''),
          map(value => {
            const name = typeof value === 'string' ? value : value?.name;
            return name ? this._filter(name as string) : this.statuses.slice();
          }),
        );
      },
      error: (error) => {
        this.toastr.error(error.error,error.name);
      }
    });
  }

  onSubmit():void {
    const data = this.statusControl.value;
    this.dialogRef.close(data)
  }

}
