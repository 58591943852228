import { Component, OnInit } from '@angular/core';
import { RouteDisplayComponent } from '../../../components/routes/route-display/route-display.component';
import { RoutesService } from '../../../services/routes.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { RouteResponse } from '../../../models/routes.model';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-route-view',
  standalone: true,
  imports: [
    RouteDisplayComponent,
    MatProgressSpinnerModule,
    CommonModule,
    MatButtonModule,
    RouterModule,
  ],
  templateUrl: './route-view.component.html',
  styleUrl: './route-view.component.scss'
})
export class RouteViewComponent implements OnInit {
  
  route!: RouteResponse;

  constructor(
    private routesService: RoutesService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.params['id'];
    this.routesService.getRoute(id).subscribe({
      next: (route) => {
        this.route = route;
      },
      error: (err) => {
        console.error('Error fetching route', err);
      }
    });
  }

}
