import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArray, ReactiveFormsModule, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { OrderStatusResponse, StatusRequirementResponse, UpdateOrderStatus } from '../../../../models/orders/order.model';
import { OrderService } from '../../../../services/order.service';
import { ToastrService } from 'ngx-toastr';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { setThrowInvalidWriteToSignalError } from '@angular/core/primitives/signals';
import { SearchOrderStatusComponent } from '../search-order-status/search-order-status.component';
import { SearchRequirementComponent } from '../../requirements/search-requirement/search-requirement.component';

@Component({
  selector: 'app-edit-order-status',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    MatIconModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
  ],
  templateUrl: './edit-order-status.component.html',
  styleUrl: './edit-order-status.component.scss'
})
export class EditOrderStatusComponent {

  form!:FormGroup;
  constructor(
    public dialogRef: MatDialogRef<EditOrderStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderStatusResponse, 
    public dialog: MatDialog,
    private fb: FormBuilder,
    private orderService:OrderService,
    private toastr:ToastrService
  ) {}
  
  requirements!:StatusRequirementResponse[];
  statuses!:OrderStatusResponse[];
  validStatusesArray!:OrderStatusResponse[];

  get validTransitionsArray() {
    return this.form.get('validTransitions') as FormArray;
  }

  addValidTransition(status:OrderStatusResponse):void {
    this.validTransitionsArray.push(new FormControl(status));
  }

  removeValidTransition(index:number):void {
    this.validTransitionsArray.removeAt(index);
  }

  get requirementsArray() {
    return this.form.get('requirements') as FormArray;
  }

  addRequirement(req:StatusRequirementResponse):void {
    this.requirementsArray.push(new FormControl(req));
  }

  removeRequirement(index:number):void {
    this.requirementsArray.removeAt(index);
  }

  ngOnInit(): void {
    this.getOrderStatuses();
    this.form = this.fb.group({
      name: [this.data.name, Validators.required],
      description: [this.data.description, Validators.required],
      requirements: this.fb.array([this.data.requirements]),
      canBePickedUp: this.fb.control(this.data.canBePickedUp),
      canBeDroppedOff: this.fb.control(this.data.canBeDroppedOff),
      customerDisplayName: [this.data.customerDisplayName, Validators.required],
      isDefault: [this.data.isDefault, Validators.required]
    });
  }

  //Retrieve Other Statuses
  getOrderStatuses():void {
    this.orderService.getOrderStatuses().subscribe({
      next: (res) =>{
        this.statuses = res.items;
        console.log(this.statuses);
        this.validStatusesArray = this.statuses.filter(status => this.data.validTransitions.includes(status.id));
        this.form.addControl('validTransitions', this.fb.array(this.validStatusesArray));
      },
      error: (error) => {
        this.toastr.error(error.error,error.name);
      }
    });
  }


  // dialogs
  openSearchStatusDialog():void {
    const dialogRef = this.dialog.open(SearchOrderStatusComponent, {
      width: '500px',
      data: { currentStatuses: this.validStatusesArray }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        this.addValidTransition(result);
      }
    });
  }

  openSearchRequirementDialog():void {
    const dialogRef = this.dialog.open(SearchRequirementComponent, {
      width: '500px',
      data: { requirementsToExclude: this.requirementsArray.value
        .filter((req:StatusRequirementResponse | null) => req != null) } // This may be heinous nonsense and need redoing
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        this.addRequirement(result);
      }
    });
  }

  onCanBePickedUpChange(event:any):void {
    this.updateCanPickup();
  }

  onCanBeDroppedOffChange(event:any):void {
    this.updateCanDropoff();
  }

  updateCanPickup():void {
    this.orderService.setCanPickup(this.data.id, {orderStatusId: this.data.id, canPickup: this.form.value.canBePickedUp}).subscribe({
      next: (res) => {
        this.toastr.success('Updated order status pickup','Success');
        this.onSubmit();
      },
      error: (error) => {
        this.toastr.error(error.error, error.name);
      }
    });
  }

  updateCanDropoff():void {
    this.orderService.setCanDropOff(this.data.id, {orderStatusId: this.data.id, canDropoff: this.form.value.canBeDroppedOff}).subscribe({
      next: (res) => {
        this.toastr.success('Updated order status dropoff','Success');
        this.onSubmit();
      },
      error: (error) => {
        this.toastr.error(error.error, error.name);
      }
    });
  }

  onSubmit() {
    if(this.form.valid) {
      // Convert selected valid status transitions and requirements into just their IDs
      var transitionStrings:string[] = [];
      var requirementStrings:string[] = [];
      if(this.validTransitionsArray.value.length > 0){
        transitionStrings = this.validTransitionsArray.value
          .filter((status:OrderStatusResponse | null) => status !== null)
          .map((status:OrderStatusResponse) => status.id);
      }
      // The filters are to ignore null values from array initialisation
      if(this.requirementsArray.value.length > 0)
      {
        requirementStrings = this.requirementsArray.value
          .filter((req:StatusRequirementResponse | null) => req !== null)
          .map((req:StatusRequirementResponse) => req.id);
      }
      const updatedOrderStatus: UpdateOrderStatus = {
        name: this.form.value.name,
        description: this.form.value.description,
        validTransitions: transitionStrings,
        requirements: requirementStrings,
        customerDisplayName: this.form.value.customerDisplayName,
        isDefault: this.form.value.isDefault
      }
      this.dialogRef.close(updatedOrderStatus)
    }
  }

}
