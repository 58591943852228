<mat-dialog-content>
    <div [hidden]="!hasDevices">
        <div *ngIf="showScanner">
            <mat-form-field appearance="fill">
                <mat-label>Select Device</mat-label>
                <mat-select [value]="deviceSelected" (valueChange)="onDeviceSelectChange($event)">
                    <mat-option value="">No Device Selected</mat-option>
                    <mat-option *ngFor="let device of availableDevices"
                        [value]="device.deviceId">{{device.label}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div *ngIf="showScanner" class="scanner-shell">
            <div class="scanner-container">
                <div class="scanner-area">
                    <zxing-scanner [formats]="allowedFormats" [device]="deviceCurrent"
                        (deviceChange)="onDeviceChange($event)" (scanSuccess)="onCodeResult($event)"
                        (permissionResponse)="onHasPermission($event)" (camerasFound)="onCamerasFound($event)"
                        (scanError)="scanErrorHandler($event)"></zxing-scanner>
                    <div class="scanning-line" [class.active]="showScanner"></div>
                </div>
                <div class="button-container">
                    <button mat-raised-button (click)="closeCamera()">Close Scanner</button>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="hasPermission === undefined && showScanner">
        <h4>Waiting for permissions.</h4>
        <blockquote>
            The QR Code scanner requires access to your device's media inputs. Please accept the permission prompt if
            you wish to use the QR Code scanner.
        </blockquote>
    </ng-container>

    <ng-container *ngIf="hasPermission === false && showScanner">
        <h4>Application does not have sufficient permission. Request has been denied by user.</h4>
    </ng-container>

    <ng-container *ngIf="hasDevices === undefined && showScanner">
        <h4>Couldn't check for devices.</h4>
        <blockquote>
            Something went wrong. Couldn't scan for media devices.
        </blockquote>
    </ng-container>

    <ng-container *ngIf="hasDevices === false && showScanner">
        <h4>No devices were found.</h4>
        <blockquote>
            No suitable media devices found.
        </blockquote>
    </ng-container>
</mat-dialog-content>