import { ClinicResponse } from "./clinic.model";
import { LocationResponse } from "./location.model";

export class route
{
    name:string
    clinics:string[]
    constructor
    (
        name:string,
        clinics:string[]
    )
    {
        this.name = name;
        this.clinics = clinics
    }
}
export class CreateRouteRequest
{
    name:string
    clinics:string[]
    constructor
    (
        name:string,
        clinics:string[]
    )
    {
        this.name = name;
        this.clinics = clinics
    }
}
export class UpdateRouteRequest
{
    name:string
    clinics:string[]
    constructor
    (
        name:string,
        clinics:string[]
    )
    {
        this.name = name;
        this.clinics = clinics
    }
}
export class RouteResponse
{
    id:string;
    name:string;
    clinics:ClinicResponse[];
    origin:LocationResponse;
    destination:LocationResponse;
    createdAt:string;
    createdBy:string;
    lastUpdatedAt:string;
    lastUpdatedBy:string;
    constructor
    (
        id:string,
        name:string,
        origin:LocationResponse,
        destination:LocationResponse,
        clinics:ClinicResponse[],
        createdAt:string,
        createdBy:string,
        lastUpdatedAt:string,
        lastUpdatedBy:string
    )
    {
        this.id = id;
        this.name = name;
        this.origin = origin;
        this.destination = destination
        this.clinics = clinics
        this.createdAt = createdAt;
        this.createdBy = createdBy;
        this.lastUpdatedAt = lastUpdatedAt;
        this.lastUpdatedBy = lastUpdatedBy;
    }
}