import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ClinicResponse, CreateClinicRequest, UpdateClinicRequest } from '../models/clinic.model';
import { Observable } from 'rxjs';
import { UserInDB } from '../models/user.model';
import { PagedQueryResult } from '../models/pagedQueryResult.interface';

interface PagedClinicsQueryResult<T> {
  page: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
  clinics: T[];
}

interface ClinicQueryParams {
  sortBy?: string;
  page?: number;
  pageSize?: number;
  name?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ClinicService {
  baseUrl = `${environment.apiUrl}/api/clinics`; 
  constructor(private http: HttpClient) { }

  getClinics(params?: ClinicQueryParams): Observable<PagedClinicsQueryResult<ClinicResponse>> {
    let httpParams = new HttpParams();
    
    if (params?.sortBy) httpParams = httpParams.set('SortBy', params.sortBy);
    if (params?.page != null) httpParams = httpParams.set('Page', (params.page + 1).toString());
    if (params?.pageSize != null) httpParams = httpParams.set('PageSize', params.pageSize.toString());
    if (params?.name) httpParams = httpParams.set('Name', params.name);

    return this.http.get<PagedClinicsQueryResult<ClinicResponse>>(`${this.baseUrl}`, { params: httpParams });
  }

  getClinic(id: string): Observable<ClinicResponse> {
    return this.http.get<ClinicResponse>(`${this.baseUrl}/${id}`);
  }

  getClinicUsers(id: string): Observable<PagedQueryResult<UserInDB>>{
    const path = 'users'
    return this.http.get<PagedQueryResult<UserInDB>>(`${this.baseUrl}/${id}/${path}`)
  }

  createClinic(data: CreateClinicRequest): Observable<CreateClinicRequest> {
    return this.http.post<CreateClinicRequest>(`${this.baseUrl}`, data);
  }

  updateClinic(id: string, data: UpdateClinicRequest): Observable<ClinicResponse> {
    return this.http.put<ClinicResponse>(`${this.baseUrl}/${id}`, data);
  }

  deleteClinic(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${id}`);
  }
}
