<div class="camera-container" [class.active]="!isLoading">
    <div class="camera-content">
        <video #video autoplay></video>
    </div>
    <button mat-fab color="primary" (click)="capturePhoto()" class="floating-button">
        <mat-icon class="icon">lens_blur_icon</mat-icon>
    </button>
</div>
<div class="photo-container">
    <canvas #canvas style="display: none;"></canvas>
</div>
