import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CreateUserRequest } from '../../../models/user.model';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { ClinicResponse } from '../../../models/clinic.model';
import { ClinicService } from '../../../services/clinic.service';

@Component({
  selector: 'app-user-new-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    ReactiveFormsModule,
    MatButtonModule,
    MatLabel,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule
  ],
  templateUrl: './user-new-dialog.component.html',
  styleUrl: './user-new-dialog.component.scss'
})
export class UserNewDialogComponent {
  form!: FormGroup;
  clinics: ClinicResponse[] = [];

  //Form preset
  clinic!: ClinicResponse;

  roles = [
    { azureRole: 'awladmin', displayName: 'AWL Admin' },
    { azureRole: 'awlstaff', displayName: 'AWL Staff' },
    { azureRole: 'clinicadmin', displayName: 'Clinic Admin' },
    { azureRole: 'clinicstaff', displayName: 'Clinic Staff' },
    { azureRole: 'enduser', displayName: 'End User' },
  ]

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UserNewDialogComponent>,
    private toastr: ToastrService,
    private clinicService: ClinicService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      givenName: ['', Validators.required],
      surname: ['', Validators.required],
      displayName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]], 
      role: ['', Validators.required],
      clinic: ['', Validators.required],
    });
    if(this.data)
      {
        this.clinic = this.data.clinic
        this.form.patchValue({
          clinic: this.clinic.name
        })
        this.form.get('clinic')?.disable()
      }else
      {
        this.getClinics();
      }
  }

  getClinics() {
    this.clinicService.getClinics().subscribe({
      next: (res) => {
        this.clinics = res.clinics;
      },
      error: (err) => {
        this.toastr.error(err?.error?.title || 'An error has occurred when getting the clinics. Please try it again.');
      }
    })
  }

  onSubmit() {
    if(this.form.valid) {
      const newUser: CreateUserRequest = {
        firstname: this.form.value.givenName,
        lastname: this.form.value.surname,
        displayName: this.form.value.displayName,
        email: this.form.value.email,
        role: this.form.value.role,
        clinic: this.form.value.clinic
      }
      this.dialogRef.close(newUser);
    } else {
      this.toastr.error('Please correct the highlighted errors and ensure all required fields are filled.');
    }
  }
}
