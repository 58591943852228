import { Component } from '@angular/core';

@Component({
  selector: 'app-run-index',
  standalone: true,
  imports: [],
  templateUrl: './run-index.component.html',
  styleUrl: './run-index.component.scss'
})
export class RunIndexComponent {

}
