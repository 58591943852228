import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLabel } from '@angular/material/form-field';
import { LocationResponse } from '../../../models/location.model';
import { Observable, map, startWith } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { ContactResponse, PreferredContactMethod } from '../../../models/contact.model';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ContactNewDialogComponent } from '../../../components/contacts/contact-new-dialog/contact-new-dialog.component';
import { ContactSearchDialogComponent } from '../../../components/contacts/contact-search-dialog/contact-search-dialog.component';
import { ClinicResponse, UpdateClinicRequest } from '../../../models/clinic.model';
import { ClinicService } from '../../../services/clinic.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from '../../../services/contact.service';
import { LocationService } from '../../../services/location.service';
import { ClinicUsersComponent } from '../../../components/clinics/clinic-users/clinic-users.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-clinic-edit',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    MatLabel,
    MatButtonModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    RouterLink,
    MatRadioModule,
    ClinicUsersComponent,
    MatProgressSpinner
  ],
  templateUrl: './clinic-edit.component.html',
  styleUrl: './clinic-edit.component.scss'
})
export class ClinicEditComponent implements OnInit{
  form!: FormGroup;
  clinic!: ClinicResponse;
  locations!: LocationResponse[];

  locationControl =  new FormControl<null | LocationResponse>(null);
  locationFilteredOptions!: Observable<LocationResponse[]>;
  contactControl = new FormControl<ContactResponse | null>(null);
  contactFilteredOptions!: Observable<ContactResponse[]>;
  
  isLoading = false

  constructor(
    private fb: FormBuilder, 
    public dialog: MatDialog, 
    private clinicService: ClinicService, 
    private route: ActivatedRoute, 
    private contactService: ContactService,
    private toastr: ToastrService,
    private locationService: LocationService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', Validators.required],
      locations: this.fb.array([], Validators.required),
      contacts: this.fb.array([], Validators.required),
      note: [''],
      isActive: ['', Validators.required],
      defaultLocationId: ['', Validators.required],
      defaultContactId: ['', Validators.required],
    });

    this.route.paramMap.subscribe(params => {
      const clinicId = params.get('id');
      if(clinicId) {
        this.getClinicData(clinicId);
        this.getLocationData();
      }
    })
  }

  getClinicData(clinicId: string) {
    this.isLoading = true
    this.clinicService.getClinic(clinicId).subscribe({
      next: (res) => {
        this.clinic = res;
        this.isLoading = false
        this.patchClinic(this.clinic);
        console.log('clinic: ', this.clinic);
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred when getting this clinic. Please try again.');
        this.isLoading = false
      }
    })
  }

  getLocationData() {
    this.locationService.getLocations().subscribe({
      next: (res) => {
        this.locations = res.locations;
        this.setFilteredLocations();
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred when getting the locations. Please try again.');
      }
    })
  }

  patchClinic(clinic: ClinicResponse) {
    this.form.patchValue({
      id: clinic.id,
      name: clinic.name,
      note: clinic.note,
      isActive: clinic.isActive,
      defaultLocationId: clinic.defaultLocationId,
      defaultContactId: clinic.defaultContactId
    });

    this.patchContacts(clinic.contacts, clinic.defaultContactId);
    this.patchLocations(clinic.locations, clinic.defaultLocationId);
  }

  //patch contacts
  patchContacts(contacts: ContactResponse[], primaryContactId: string) {
    contacts.forEach(contact => {
      this.contactsArray.push(this.createContactFormGroup(contact, contact.id === primaryContactId));
    });
  }

  createContactFormGroup(contact: ContactResponse, isPrimary: boolean): FormGroup {
    const toAdd = this.fb.group({
      contact: contact,
      isPrimary: [isPrimary]
    });

    return toAdd;
  }

  //patch locations
  patchLocations(locations: LocationResponse[], defaultLocationId: string) {
    locations.forEach(location => {
      this.locationsArray.push(this.createLocationFormGroup(location, location.id === defaultLocationId));
    });
  }

  createLocationFormGroup(location: LocationResponse, isPrimary: boolean): FormGroup {
    const toAdd = this.fb.group({
      location: location,
      isPrimary: [isPrimary]
    });

    return toAdd;
  }

  setFilteredLocations() {
    this.locationFilteredOptions = this.locationControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this.locationFilter(name as string) : this.locations.slice();
      }),
    );
  }

  private locationFilter(name: string): LocationResponse[] {
    const filterValue = name.toLowerCase();
    return this.locations.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  displayLocationFn(location: LocationResponse): string {
    return location && location.name ? location.name : '';
  }

  get locationsArray(): FormArray {
    return this.form.get('locations') as FormArray;
  }

  get contactsArray(): FormArray {
    return this.form.get('contacts') as FormArray;
  }

  onSelectionLocation(location: LocationResponse) {
    const existingLocation = this.locationsArray.controls.find(c => c.value.location.id == location.id);
    const toAdd = {
      location: location,
      isPrimary: [this.locationsArray.length === 0]
    }

    if(!existingLocation)  {
      this.locationsArray.push(this.fb.group(toAdd));
      if (this.locationsArray.length === 1) {
        // Set the first contact as the default primary contact
        this.form.get('defaultContactId')?.setValue(location.id);
      }
    } else {
      this.toastr.error('This location exists');
    }
    this.locationControl.setValue(null);
  }

  addContact(contact: ContactResponse) {
    const existingContact = this.contactsArray.controls.find(c => c.value.contact.id == contact.id);
    const toAdd = {
      contact: contact,
      isPrimary: [this.contactsArray.length === 0]
    }

    if(!existingContact)  {
      this.contactsArray.push(this.fb.group(toAdd));
      if (this.contactsArray.length === 1) {
        // Set the first contact as the default primary contact
        this.form.get('defaultContactId')?.setValue(contact.id);
      }
    } else {
      this.toastr.error('This contact exists');
    }

    this.contactControl.setValue(null);
  }

  setContactPrimary(index: number) {
    this.contactsArray.controls.forEach((control, i) => {
      control.get('isPrimary')?.setValue(i === index); 
      if (i === index) {
        this.form.get('defaultContactId')?.setValue(control.value.contact.id);
      }
    })
  }

  removeContact(index: number) {
    const wasPrimary = this.contactsArray.at(index).get('isPrimary');
    this.contactsArray.removeAt(index);

    if (wasPrimary && this.contactsArray.length > 0) {
      let anotherPrimaryExists = this.contactsArray.controls.some(c => c.value.isPrimary === true);

      if(!anotherPrimaryExists) {
        this.contactsArray.at(0).get('isPrimary')?.setValue(true);
        this.form.get('defaultContactId')?.setValue(this.contactsArray.at(0)?.get('contact')?.value.id);
      }
    }
  }

  setLocationPrimary(index: number) {
    this.locationsArray.controls.forEach((control, i) => {
      control.get('isPrimary')?.setValue(i === index); 
      if (i === index) {
        this.form.get('defaultLocationId')?.setValue(control.value.location.id);
      }
    })
  }

  removeLocation(index: number) {
    const wasPrimary = this.locationsArray.at(index).get('isPrimary');
    this.locationsArray.removeAt(index);

    if (wasPrimary && this.locationsArray.length > 0) {
      let anotherPrimaryExists = this.locationsArray.controls.some(c => c.value.isPrimary === true);

      if(!anotherPrimaryExists) {
        this.locationsArray.at(0).get('isPrimary')?.setValue(true);
        this.form.get('defaultLocationId')?.setValue(this.locationsArray.at(0)?.get('location')?.value.id);
      }
    }
  }

  onSubmit() {
    if(this.form.valid) {
      const locationIds = this.locationsArray.controls.filter(c => c.value !== null && c.value !== undefined).map(c => c.value.location.id);
      const contactIds = this.contactsArray.controls.filter(c => c.value !== null && c.value !== undefined).map(c => c.value.contact.id);

      const clinic: UpdateClinicRequest = {
        name: this.form.value.name,
        note: this.form.value.note,
        locationIds: locationIds,
        contactIds: contactIds,
        isActive: this.form.value.isActive,
        defaultLocationId: this.form.value.defaultLocationId,
        primaryContactId: this.form.value.defaultContactId
      };
      
      this.clinicService.updateClinic(this.clinic.id, clinic).subscribe({
        next: (response) => {
          this.toastr.success('Clinic update successfully.');

          //clear formArray
          const contacts = this.form.get('contacts') as FormArray;
          const locations = this.form.get('locations') as FormArray;
          contacts.clear();
          locations.clear(); 

          this.getClinicData(this.clinic.id);
        },
        error: (error) => {
          this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
        }
      })
    }
  }

  openAddContactDialog() {
    const dialogRef = this.dialog.open(ContactNewDialogComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.contactService.createContact(result).subscribe({
          next: (res) => {
            this.toastr.success('Contact created successfully.');
            this.addContact(res);
          },
          error: (error) => {
            this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
          }
        });
      }
    })
  }

  openSearchContactDialog() {
    const dialogRef = this.dialog.open(ContactSearchDialogComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.addContact(result);
      }
    })
  }
}
