import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-login-failed',
  standalone: true,
  imports: [MatButtonModule, MatCardModule],
  templateUrl: './login-failed.component.html',
  styleUrl: './login-failed.component.scss'
})
export class LoginFailedComponent {
  redirect() {
    window.location.href = environment.apiUrl;
  }
}
