import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { PagedQueryResult } from '../models/pagedQueryResult.interface';
import { GetOrCreatePlaceRequest, PlaceResponse } from '../models/orders/place.model';

@Injectable({
  providedIn: 'root'
})
export class PlaceService {
  baseUrl = `${environment.apiUrl}/api/places`; 
  constructor(private http: HttpClient) { }

  getPlaces(): Observable<PagedQueryResult<PlaceResponse>> {
    return this.http.get<PagedQueryResult<PlaceResponse>>(`${this.baseUrl}`);
  }
  getPlace(id: string): Observable<PlaceResponse> {
    return this.http.get<PlaceResponse>(`${this.baseUrl}/${id}`);
  }
  getOrCreatePlace(data:GetOrCreatePlaceRequest):Observable<PlaceResponse> {
    return this.http.post<PlaceResponse>(`${this.baseUrl}/find-or-create`, data);
  }
  getPlaceByReference(ref:string):Observable<PlaceResponse> {
    return this.http.get<PlaceResponse>(`${this.baseUrl}/ref/${ref}`);
  }
  
}
