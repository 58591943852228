import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { CreateVehicleRequest, UpdateVehicleRequest, vehicle, VehicleResponse } from '../models/vechicle.model';
import { PagedQueryResult } from '../models/pagedQueryResult.interface';

@Injectable({
  providedIn: 'root'
})
export class VechicleService {
  baseUrl = `${environment.apiUrl}/api/vehicles`; 
  constructor(private http: HttpClient) { }

  getVehicle(id:string): Observable<VehicleResponse> {
    return this.http.get<VehicleResponse>(`${this.baseUrl}/${id}`);
  }
  getVehicles(): Observable<PagedQueryResult<vehicle>> {
    return this.http.get<PagedQueryResult<vehicle>>(`${this.baseUrl}`);
  }
  getAllVehicles(): Observable<PagedQueryResult<VehicleResponse>> {
    return this.http.get<PagedQueryResult<VehicleResponse>>(`${this.baseUrl}`);
  }
  createVehicle(data: CreateVehicleRequest): Observable<VehicleResponse> {
    return this.http.post<VehicleResponse>(`${this.baseUrl}`, data);
  }
  updateVehicle(id: string, data: UpdateVehicleRequest): Observable<VehicleResponse> {
    return this.http.patch<VehicleResponse>(`${this.baseUrl}/${id}`, data);
  }
  deleteVehicle(id:string): Observable<204> {
    return this.http.delete<204>(`${this.baseUrl}/${id}`);
  }
}
