export class PlaceResponse {

    id: string;
    reference: string;
    name: string;
    objectId: string; 
    type: string; 
    createdAt: string;
    lastUpdatedAt: string | null;
    lastUpdatedBy: string | null;
    createdBy: string;

    constructor(
        id: string,
        reference: string,
        name: string,
        objectId: string,
        type: string,
        createdAt: string,
        lastUpdatedAt: string | null,
        lastUpdatedBy: string | null,
        createdBy: string
    ){
        this.id = id;
        this.reference = reference;
        this.name = name;
        this.objectId = objectId;
        this.type = type;
        this.createdAt = createdAt;
        this.lastUpdatedAt = lastUpdatedAt;
        this.lastUpdatedBy = lastUpdatedBy;
        this.createdBy = createdBy;    
    }
}

export enum PlaceType {
    Clinic,
    Vehicle,
    Custom
}

export class GetOrCreatePlaceRequest {
    name: string | undefined;
    objectId: string | undefined;
    type: string;

    constructor(
        name: string | undefined,
        objectId: string | undefined,
        type: string
    ){
        this.name = name;
        this.objectId = objectId;
        this.type = type;
    }
}