<div class="flex-row">
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilterStatus($event)" placeholder="Ex. Mia" #input>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="openCreateRequirementDialog()" class="add-btn" *ngIf="isAdmin">Add</button>
</div>

<mat-table [dataSource]="dataSource" class="mat-elevation-z8" [@fadeIn]>  
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
    </ng-container>
  
    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
    </ng-container>
  
    <!-- Photo Required Column -->
    <ng-container matColumnDef="photoRequired">
      <mat-header-cell *matHeaderCellDef> Photo Required </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.photoRequired ? 'Yes' : 'No'}} </mat-cell>
    </ng-container>
  
    <!-- Created At Column -->
    <ng-container matColumnDef="createdAt">
      <mat-header-cell *matHeaderCellDef> Created At </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.createdAt | date:'short'}} </mat-cell>
    </ng-container>
  
    <!-- Last Updated At Column -->
    <ng-container matColumnDef="lastUpdatedAt">
      <mat-header-cell *matHeaderCellDef> Last Updated At </mat-header-cell>
      <mat-cell *matCellDef="let element"> 
        {{element.lastUpdatedAt ? (element.lastUpdatedAt | date:'short') : 'N/A'}} 
      </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Actions </mat-header-cell>
        <mat-cell *matCellDef="let element"> 
            <div class="button-container">
                <button mat-icon-button color="primary" class="edit-btn" matTooltip="Edit Requirement"
                    (click)="openEditRequirementDialog(element)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button color="warn" matTooltip="Delete Requirement"
                    (click)="openDeleteRequirementDialog(element)">
                <mat-icon>delete</mat-icon>
            </button>
            </div>
        </mat-cell>
    </ng-container>

    <!-- Header Row -->
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  
    <!-- Data Row -->
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  
  </mat-table>