import { Component, Inject } from '@angular/core';
import { SearchRoutesComponent } from '../search-routes/search-routes.component';
import { RouteResponse } from '../../../models/routes.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-route-select-dialog',
  standalone: true,
  imports: [SearchRoutesComponent],
  templateUrl: './route-select-dialog.component.html',
  styleUrl: './route-select-dialog.component.scss'
})
export class RouteSelectDialogComponent {
  selectedRoute: RouteResponse | null = null;

  constructor(
    public dialogRef: MatDialogRef<RouteSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onRouteSelected(route: RouteResponse): void {
    this.selectedRoute = route;
    this.dialogRef.close(route);
  }
}