import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatLabel } from '@angular/material/form-field';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { CreateContactRequest, PreferredContactMethod } from '../../../models/contact.model';
import { ContactService } from '../../../services/contact.service';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-contact-new-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    ReactiveFormsModule,
    MatButtonModule,
    MatLabel,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule
  ],
  templateUrl: './contact-new-dialog.component.html',
  styleUrl: './contact-new-dialog.component.scss',
  providers:[ContactService]
})
export class ContactNewDialogComponent implements OnInit{
  contactForm!: FormGroup;
  contactMethods = Object.values(PreferredContactMethod);

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<ContactNewDialogComponent>, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]], 
      phone: ['', [Validators.required, Validators.pattern('^[4]{1}[0-9]{8}$')]],
      preferredContactMethod: ['', Validators.required],
      note: ['']
    })
  }

  onSubmit() {
    if(this.contactForm.valid) {
      const contact:CreateContactRequest = {
        firstName: this.contactForm.value.firstName,
        lastName: this.contactForm.value.lastName,
        email: this.contactForm.value.email,
        phone: '61' + this.contactForm.value.phone,
        note: this.contactForm.value.note,
        preferredContactMethod: this.contactForm.value.preferredContactMethod
      }
      this.dialogRef.close(contact);
    } else {
      this.toastr.error('Please correct the highlighted errors and ensure all required fields are filled.');
    }
  }
}
