import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatGridListModule } from '@angular/material/grid-list'; 
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable, map, of, startWith } from 'rxjs';
import { ContactResponse, PreferredContactMethod } from '../../../models/contact.model';
import { MatButtonModule } from '@angular/material/button';
import { Validators } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { Router, RouterLink } from '@angular/router';
import { ContactNewDialogComponent } from '../../../components/contacts/contact-new-dialog/contact-new-dialog.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ContactSearchDialogComponent } from '../../../components/contacts/contact-search-dialog/contact-search-dialog.component';
import { FormsModule } from '@angular/forms';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio'; 
import { MatIconModule } from '@angular/material/icon';
import { AnimalTypeResponse } from '../../../models/animalType.model';
import { CreateOrderRequest, CremationType } from '../../../models/orders/order.model';
import { ClinicResponse } from '../../../models/clinic.model';
import { LocationResponse } from '../../../models/location.model';
import { OrderService } from '../../../services/order.service';
import { ContactService } from '../../../services/contact.service';
import { ToastrService } from 'ngx-toastr';
import { ClinicService } from '../../../services/clinic.service';
import { LocationService } from '../../../services/location.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-orders-new',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDividerModule,
    RouterLink,
    MatDialogModule,
    FormsModule,
    MatRadioModule,
    MatIconModule
  ],
  templateUrl: './orders-new.component.html',
  styleUrl: './orders-new.component.scss',
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  },
    OrderService,DecimalPipe
  ]
})
export class OrdersNewComponent implements OnInit{
  form!: FormGroup;
  clinics: ClinicResponse[] = [];
  locations: LocationResponse[] = [];
  contactsAll: ContactResponse[] = [];
  animalTypes: AnimalTypeResponse[] = [];

  cremationTypes = Object.values(CremationType);
  contactMethods = Object.values(PreferredContactMethod);

  clinicControl = new FormControl<string | ClinicResponse>('');
  pickUpControl = new FormControl<string | LocationResponse>('');
  dropOffControl = new FormControl<string | LocationResponse>('');
  contactControl = new FormControl<ContactResponse | null>(null);

  clinicFilteredOptions!: Observable<ClinicResponse[]>;
  pickUpFilteredOptions!: Observable<LocationResponse[]>;
  dropOffFilteredOptions!: Observable<LocationResponse[]>;
  contactFilteredOptions!: Observable<ContactResponse[]>;
  
  isSaving = false

  constructor(
    private fb: FormBuilder, 
    public dialog: MatDialog, 
    private orderService: OrderService, 
    private router: Router, 
    private contactService: ContactService, 
    private toastr: ToastrService,
    private clinicService: ClinicService,
    private locationService: LocationService,
    private decimalPipe: DecimalPipe
  ) {
  }
  
  ngOnInit() {
    this.getData();
      
    this.form = this.fb.group({
      clinicId: ['', Validators.required],
      weight: ['', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(0.01)]],
      cremationType: ['', Validators.required],
      pickUpLocationId: ['', Validators.required],
      dropOffLocationId: ['', Validators.required],
      primaryContactId: [''],
      animalName: ['',  Validators.required],
      animalTypeId: ['',  Validators.required],
      specialInstructions: ['',],
      contacts: this.fb.array([], Validators.required),
      packageIds: [[]],
      productIds: [[]],
    });
  }

  getData() {
    this.clinicService.getClinics().subscribe({
      next: (result) => {
        this.clinics = result.clinics;
        this.setFilteredClinics();
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred when getting the clinics. Please try again.');
      }
    });

    this.locationService.getLocations().subscribe({
      next: (result) => {
        this.locations = result.locations;
        this.setFilteredLocations();
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred when getting the locations. Please try again.');
      }
    });

    this.orderService.getAnimalTypes().subscribe({
      next: (result) => {
        this.animalTypes = result.items;
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred when getting the animalTypes. Please try again.');
      }
    });
  }

  setFilteredClinics() {
    this.clinicFilteredOptions = this.clinicControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this.clinicFilter(name as string) : this.clinics.slice();
      }),
    );

    this.clinicControl.valueChanges.subscribe(value => {
      if (typeof value === 'string' && !value) {
        this.form.patchValue({
          clinicId: null  // Clear the Id if the input is empty
        });
      }
    });
  }
  
  setFilteredLocations() {
    this.pickUpFilteredOptions = this.pickUpControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this.locationFilter(name as string) : this.locations.slice();
      }),
    );

    this.dropOffFilteredOptions = this.dropOffControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this.locationFilter(name as string) : this.locations.slice();
      }),
    );

    this.pickUpControl.valueChanges.subscribe(value => {
      if (typeof value === 'string' && !value) {
        this.form.patchValue({
          pickUpLocationId: null  // Clear the Id if the input is empty
        });
      }
    });

    this.dropOffControl.valueChanges.subscribe(value => {
      if (typeof value === 'string' && !value) {
        this.form.patchValue({
          dropOffLocationId: null  // Clear the Id if the input is empty
        });
      }
    });
  }

  private clinicFilter(name: string): ClinicResponse[] {
    const filterValue = name.toLowerCase();
    return this.clinics.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private locationFilter(name: string): LocationResponse[] {
    const filterValue = name.toLowerCase();
    return this.locations.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  displayClinicFn(clinic: ClinicResponse): string {
    return clinic && clinic.name ? clinic.name : '';
  }

  displayLocationFn(location: LocationResponse): string {
    return location && location.name ? location.name : '';
  }

  onSelectionClinic(clinic: ClinicResponse) {
    this.form.patchValue({
      clinicId: clinic.id,
      pickUpLocationId: clinic.defaultLocationId,
      dropOffLocationId: clinic.defaultLocationId,
    });

    const defaultLocation = this.locations.find(location => location.id == clinic.defaultLocationId);
    if(defaultLocation) {
      this.pickUpControl.setValue(defaultLocation);
      this.dropOffControl.setValue(defaultLocation);
    }
  }

  onSelectionPickUp(location: LocationResponse) {
    this.form.patchValue({
      pickUpLocationId: location.id,
    });
  }

  onSelectionDropOff(location: LocationResponse) {
    this.form.patchValue({
      dropOffLocationId: location.id,
    });
  }
  
  get contactsArray(): FormArray {
    return this.form.get('contacts') as FormArray;
  }

  onSubmit() {
    this.isSaving = true
    const contactIds = this.contactsArray.controls.map(c => c.value.contact.id);
    const primaryId = this.contactsArray.controls.find(c => c.value.isPrimary === true)?.value.contact.id;

    if (this.form.valid && primaryId) {
      this.form.get('primaryContactId')?.patchValue(primaryId);
      const order:CreateOrderRequest = {
        clinicId: this.form.value.clinicId,
        cremationType: this.form.value.cremationType,
        weight: this.form.value.weight,
        pickUpLocationId: this.form.value.pickUpLocationId,
        dropOffLocationId: this.form.value.dropOffLocationId,
        primaryContactId: this.form.value.primaryContactId,
        contactIds: contactIds,
        animalName: this.form.value.animalName,
        animalTypeId: this.form.value.animalTypeId,
        specialInstructions: this.form.value.specialInstructions,
        packageIds: this.form.value.packageIds,
        productIds: this.form.value.productIds,
      }
      this.orderService.addOrder(order).subscribe({
        next: (res) => {
          this.toastr.success('Order created successfully.');
          this.router.navigate(['/orders']);
          this.isSaving = false
        },
        error: (error) => {
          this.isSaving = false
          this.toastr.error(error.error?.title || 'An error has occurred. Please try again later.');
        }
      });
    } else {
      this.isSaving = false
      this.toastr.error('Please correct the highlighted errors and ensure all required fields are filled.');
    }
  }
 
  addContact(contact: ContactResponse) {
    const contactFormGroup = this.fb.group({
      contact: this.fb.group({
        id: [contact.id],
        firstName: [contact.firstName, Validators.required],
        lastName: [contact.lastName, Validators.required],
        email: [contact.email, Validators.email],
        phone: [contact.phone],
        PreferredContactMethod: [contact.preferredContactMethod, Validators.required],
        note: [contact.note]
      }),
      isPrimary: [this.contactsArray.length === 0] // Automatically set the first contact as primary
    });
    this.contactsArray.push(contactFormGroup);
  }

  removeContact(index: number) {
    const wasPrimary = this.contactsArray.at(index).get('isPrimary');
    this.contactsArray.removeAt(index);

    if (wasPrimary && this.contactsArray.length > 0) {
      let anotherPrimaryExists = this.contactsArray.controls.some(c => c.value.isPrimary === true);

      if(!anotherPrimaryExists)
        this.contactsArray.at(0).get('isPrimary')?.setValue(true);
    }
  }

  setPrimary(index: number) {
    this.contactsArray.controls.forEach((control, i) => {
      control.get('isPrimary')?.setValue(i === index); 
    })
  }

  //Dialogs
  openAddContactDialog() {
    const dialogRef = this.dialog.open(ContactNewDialogComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.contactService.createContact(result).subscribe({
          next: (res) => {
            this.toastr.success('Contact created successfully.');
            this.addContact(res);
          },
          error: (error) => {
            this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
          }
        });
      }
    })
  }

  openSearchContactDialog() {
    const dialogRef = this.dialog.open(ContactSearchDialogComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        if(!this.contactsArray.controls.some(c => c.get('contact')?.get('id')?.value === result.id)) {
          this.addContact(result);
        } else {
          this.toastr.error('This contact exists, cannot add it twice');
        }
      }
    })
  }
  onDeselect()
  {
    const value = this.form.value.weight
    const formatted = this.decimalPipe.transform(value, '1.2-2')
    this.form.patchValue({weight:formatted})
  }
}
