
<mat-card>
    <mat-card-content class="flex-container">
        <h2>
            Todays Schedules
        </h2>
        <div *ngIf="isLoading" class="loading-container mb-1">
            <mat-spinner
            [diameter]="80">
            </mat-spinner>
        </div> 
        <div *ngIf="!isLoading" class="information-container">
            <mat-tab-group *ngIf="schedules.length > 0; else emptySchedules" style="width: 100%;" dynamicHeight #tabGroup>
                    <mat-tab *ngFor="let schedule of schedules; let index = index" [label]="schedule.route.name">
                        <ng-template matTabContent>
                            <app-overview-schedule [data]="schedule" (touchstart)="onTouchStart($event)" (touchend)="onTouchEnd($event)"></app-overview-schedule>
                        </ng-template>
                    </mat-tab>
            </mat-tab-group>
            <ng-template #emptySchedules>
                @if(error)
                {
                    {{error}}
                }
            </ng-template>
        </div>
    </mat-card-content>         
</mat-card>