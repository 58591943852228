export enum PreferredContactMethod {
    phone = "Phone",
    email = "Email"
}

//TODO: client want to have address for the contact
export class CreateContactRequest {
    firstName: string;
    lastName: string;
    email?: string;
    phone: string;
    note?: string;
    preferredContactMethod: PreferredContactMethod;

    constructor(firstName: string, lastName: string, email: string, phone: string, note: string, preferredContactMethod: PreferredContactMethod) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.phone = phone;
        this.note = note;
        this.preferredContactMethod = preferredContactMethod;
    }
}

export class ContactResponse {
    id: string;
    firstName: string;
    lastName: string;
    email?: string;
    phone: string;
    note?: string;
    preferredContactMethod: PreferredContactMethod;

    constructor(id: string, firstName: string, lastName: string, email: string, phone: string, note: string, preferredContactMethod: PreferredContactMethod) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.phone = phone;
        this.note = note;
        this.preferredContactMethod = preferredContactMethod;
    }
}