import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, ReactiveFormsModule, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AttachmentFileRequest, ChangeOrderStatusRequest, OrderResponse, OrderStatusResponse } from '../../../models/orders/order.model';
import { PagedQueryResult } from '../../../models/pagedQueryResult.interface';
import { ClinicService } from '../../../services/clinic.service';
import { OrderService } from '../../../services/order.service';
import { PlaceService } from '../../../services/place.service';
import { VechicleService } from '../../../services/vehicle.service';
import { ChangeSingleOrderStatusComponent } from '../change-single-order-status/change-single-order-status.component';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { SearchClinicComponent } from '../../clinics/search-clinic/search-clinic.component';
import { SearchVehicleComponent } from '../../vehicle/search-vehicle/search-vehicle.component';
import { MatListModule } from '@angular/material/list';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { GetOrCreatePlaceRequest } from '../../../models/orders/place.model';
import { catchError, forkJoin, lastValueFrom, Observable, of } from 'rxjs';
import { ScanPlaceCodeComponent } from '../../places/scan-place-code/scan-place-code.component';
import { CameraDialogComponent } from '../../scanner-dialog/camera-dialog/camera-dialog.component';


@Component({
  selector: 'app-change-bulk-order-status',
  standalone: true,
  imports: [
    MatDialogModule,
    MatCardModule,
    MatButtonModule,
    MatSelectModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatDividerModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    SearchVehicleComponent,
    SearchClinicComponent,
    MatListModule,
    MatTableModule,
  ],
  templateUrl: './change-bulk-order-status.component.html',
  styleUrl: './change-bulk-order-status.component.scss'
})
export class ChangeBulkOrderStatusComponent implements OnInit {

  orders!: OrderResponse[];

  statuses!: PagedQueryResult<OrderStatusResponse>;
  validTransitions!: OrderStatusResponse[];
  chosenStatus!: OrderStatusResponse | null | undefined;
  
  //file form varibles
  orderId!: string;
  index!: number;

  dataSource: MatTableDataSource<OrderResponse> = new MatTableDataSource<OrderResponse>;
  displayedColumns: string[] = ['reference', 'name', 'weight', 'animaltype'];
  isSaving: boolean = false;
  isLoading: boolean = false;

  statusIdControl = new FormControl('', Validators.required);
  toPlaceControl = new FormControl('', Validators.required);
  commentControl = new FormControl('');
  vehicleControl = new FormControl();
  clinicControl = new FormControl();
  customPlaceControl = new FormControl();

  changeStatusForm: FormGroup = new FormGroup({
    statusIdControl: this.statusIdControl,
    toPlaceControl: this.toPlaceControl,
    commentControl: this.commentControl,
    vehicleControl: this.vehicleControl,
    clinicControl: this.clinicControl,
    customPlaceControl: this.customPlaceControl,
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { orders: OrderResponse[] },
    private dialogRef: MatDialogRef<ChangeSingleOrderStatusComponent>,
    private dialog: MatDialog,
    private orderService: OrderService,
    private clinicService: ClinicService,
    private vehicleService: VechicleService,
    private placeService: PlaceService,
    private toastr: ToastrService,
  ) { }
  ngOnInit(): void {
    this.orders = this.data.orders;
    this.dataSource.data = this.data.orders;
    this.getStatuses();
  }

  async openScanner(): Promise<void> {
    try {
      this.isLoading = true
      const dialogRef = this.dialog.open(ScanPlaceCodeComponent, {
        width: "600px"
      });
      const result = await lastValueFrom(dialogRef.afterClosed());
      if (result) {
        this.toPlaceControl.setValue(result.type);
        switch (result.type) {
          case "Vehicle":
            var vehicle = await this.getVehicle(result.objectId);
            this.vehicleControl.setValue(vehicle)
            break;
          case "Clinic":
            var clinic = await this.getClinic(result.objectId);
            this.clinicControl.setValue(clinic)
            break;
          case "Custom":
            this.customPlaceControl.setValue(result.name);
            break;
        }
      }
    }
    catch (error) {
      this.toastr.error("Something went wrong during QR Code scan", "QR Scanner Error")
    }
    finally {
      this.isLoading = false;
    }
  }

  async getVehicle(id: string) {
    return await lastValueFrom(this.vehicleService.getVehicle(id));
  }

  async getClinic(id: string) {
    return await lastValueFrom(this.clinicService.getClinic(id));
  }


  getStatuses(): void {
    this.orderService.getOrderStatuses().subscribe({
      next: (res) => {
        this.statuses = res;
        // TODO: calculate ALL valid transitions - remove any that can't be selected on ALL orders.
        this.getValidTransitions();
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred');
      }
    })
  }

  getValidTransitions(): void {
    var statusArray = this.statuses.items;
    this.validTransitions = statusArray;
    for(const order of this.orders)
    {
      this.validTransitions = this.validTransitions.filter(item => order.status.validTransitions.includes(item.id));
    }
    if(this.validTransitions.length <= 0)
    {
      this.toastr.warning("The selected orders have no common statuses they can move to.","No Available Statuses");
    }
  }

  updateChosenStatus(event: MatSelectChange): void {
    var chosen = this.validTransitions.find(item => event.value == item.id);
    this.chosenStatus = chosen;
    this.generateRequirementFields()
  }

  onPlaceControlSelected(event: any): void {

  }

  async generateRequirementFields()
  {
    this.changeStatusForm.addControl("requirements",new FormGroup({}))
    const requirements =  new Set(this.chosenStatus?.requirements)
    const orders = new Set(this.orders)
    const reqFG = this.changeStatusForm.get('requirements') as FormGroup//ACTUAL NAME: requirementFormGroup
    await requirements.forEach(async req => {
      const reqId = req.id
      let requirementsForms: FormGroup[] = []
      if(req.photoRequired)
        { 
          await orders.forEach( async order => {
            const photoForm = await this.generatePhotoForms(order.id)
            requirementsForms.push(photoForm)
          })
          
        }
      reqFG.addControl(reqId, new FormArray(requirementsForms))
      console.log('full form ',reqFG) 
    })
  }

  async generatePhotoForms(id :string) :Promise<FormGroup>
  {
    const photoForm = new FormGroup({})
    photoForm.addControl(id,new FormControl())
    photoForm.addControl(id+"_display",new FormControl('',[Validators.required]));
    return photoForm
  }

  openCamera(event: Event, reqId:string, orderId:string, index:number) {
    event.preventDefault();
    const dialogRef = this.dialog.open(CameraDialogComponent, {
      width:"*"
    });

    dialogRef.afterClosed().subscribe((data:string) => {
      if (data) { 
        this.addPhoto(reqId,orderId,index,data)
      }
    })
  }

  async onSubmit(): Promise<void> {
    if (this.changeStatusForm.valid) {
      console.log("beginning form save");
      this.isSaving = true;
      const form = this.changeStatusForm.value;
      const requests: Observable<any>[] = [];
      var toPlace = "";
      for(const [key ,order] of Object.entries(this.orders))
        {
          try {
            switch (form.toPlaceControl) {
              case "Current":
                toPlace = order.currentPlace.id;
                break;
              case "Vehicle":
                console.log("sending request for new vehicle placetype");
                console.log(form);
                var request = new GetOrCreatePlaceRequest(`${form.vehicleControl.name} (${form.vehicleControl.registration})`, form.vehicleControl.id, "Vehicle");
                var res = this.placeService.getOrCreatePlace(request);
                toPlace = (await lastValueFrom(res)).id;
                break;
              case "Clinic":
                var request = new GetOrCreatePlaceRequest(`${form.clinicControl.name}`, form.clinicControl.id, "Clinic");
                var res = this.placeService.getOrCreatePlace(request);
                toPlace = (await lastValueFrom(res)).id;
                break;
              case "Custom":
                var request = new GetOrCreatePlaceRequest(form.customPlaceControl, undefined, "Custom");
                var res = this.placeService.getOrCreatePlace(request);
                console.log(res);
                toPlace = (await lastValueFrom(res)).id;
                break;
              default:
                toPlace = order.currentPlace.id;
                break;
            }
          const attachedFiles: AttachmentFileRequest[] = []
          this.chosenStatus?.requirements.forEach(req =>
            {
              const reqArray = this.changeStatusForm.get(`requirements.${req.id}`) as FormArray
              const requirementForm = reqArray.at(Number(key)) as FormGroup
              const photo = requirementForm.get(`${order.id}`)?.value
              const attachment = new AttachmentFileRequest(req.id,photo)
              attachedFiles.push(attachment)
            })
          const updateRequest: ChangeOrderStatusRequest = {
            statusId: form.statusIdControl,
            toPlaceId: toPlace,
            comment: form.commentControl,
            UploadAttachments: attachedFiles
          }
          requests.push(
            this.orderService.changeOrderStatus(order.id, updateRequest).pipe(
              catchError(error => {
                this.toastr.error(error.error, "Error updating order status");
                return of(null);  // Continue processing even if one request fails
              })
            ));
          } catch (error: any) {
            this.toastr.error(error?.error, error?.name);
            this.isSaving = false;
            return;
          }
        }
      forkJoin(requests).subscribe({
        next: () => {
          this.isSaving = false;
          this.toastr.success("Successfully updated all orders", "Success");
          this.dialogRef.close(true);
        },
        error: (error) => {
          this.isSaving = false;
          this.toastr.error("Failed to update some orders", "Error");
        }
      });
    } else {
      this.toastr.error("Something went wrong - check you have filled out all the required fields.");
    }
  }
  //File Handling
  addPhoto(reqId: string, orderId:string, index: number,base64 :string )
  {
    // console.log('photo Change', index ,' OrderId ', orderId ,' reqId ' ,reqId)
    const controlArray = this.changeStatusForm.get(`requirements.${reqId}`) as FormArray
    const controls = controlArray.controls.at(index)

    const control = controls?.get(`${orderId}`)
    const displayControl = controls?.get(`${orderId}_display`)

    displayControl?.patchValue('photo.png')
    control?.patchValue(base64);
  }
  async onFileChange(event: any, reqId: string, orderId:string, index: number )
  {
    //console.log('File Change', index ,' OrderId ', orderId ,' reqId ' ,reqId)
    const controlArray = this.changeStatusForm.get(`requirements.${reqId}`) as FormArray
    const controls = controlArray.controls.at(this.index)
    //dont know why orderId has stopped working
    const control = controls?.get(`${this.orderId}`)
    const displayControl = controls?.get(`${this.orderId}_display`)
    //setting file 
    const file: File = event.target.files[0]
    const base64 = await this.convertToBase64(file)
    control?.patchValue(base64);
    //outputing file name for UI
    displayControl?.patchValue(file.name)
  }
  openFileExplorer(event: Event,reqId: string,orderId:string,index:number) {
    event.preventDefault();
    this.orderId = orderId//orderId Fix
    this.index = index
    const fileInput = document.getElementById(`fileInput-${reqId}`) as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }
  convertToBase64(file:File):Promise<string|ArrayBuffer>
  {
    return new Promise((resolve, reject) =>{
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error)
    })
  }

}
