import { Component, OnInit } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { PlaceResponse } from '../../../models/orders/place.model';
import { PlaceService } from '../../../services/place.service';
import { ToastrService } from 'ngx-toastr';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialog } from '@angular/material/dialog';
import { ViewPlaceComponent } from '../view-place/view-place.component';
import { fadeIn } from '../../../../assets/animations';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-place-index',
  standalone: true,
  imports: [
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatLabel,
    MatFormFieldModule,
    MatInputModule,
    CommonModule
  ],
  templateUrl: './place-index.component.html',
  styleUrl: './place-index.component.scss',
  animations: [fadeIn]
})
export class PlaceIndexComponent implements OnInit {
  dataSource:MatTableDataSource<PlaceResponse> = new MatTableDataSource<PlaceResponse>;
  places!:PlaceResponse[];
  displayedColumns:string[] = [
    'reference',
    'name',
    'type',
    'details',
    'actions'
  ];

  constructor(
    private placeService:PlaceService,
    private toastr:ToastrService,
    private dialog:MatDialog
  )
  {}
  ngOnInit(): void {
    this.getPlaces();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openViewPlaceDialog(place:PlaceResponse){
    const dialogRef = this.dialog.open(ViewPlaceComponent, {
      width: '500px',
      data: place
    });
  }

  getPlaces():void {
    this.placeService.getPlaces().subscribe({
      next: (res) => {
        this.places = res.items;
        console.log(this.places);
        this.dataSource.data = this.places;
      },
      error: (error) => {
        this.toastr.error("Something went wrong retrieving list of Places. Details: "+(error?.error ?? "unknown."),(error.status ?? "")+" Error "+(error.title ?? ""));
      }
    });
  }
}
