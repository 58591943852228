import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { OrdersIndexComponent } from './pages/orders/orders-index/orders-index.component';
import { OrdersNewComponent } from './pages/orders/orders-new/orders-new.component';
import { RunIndexComponent } from './pages/run/run-index/run-index.component';
import { OrdersEditComponent } from './pages/orders/orders-edit/orders-edit.component';
import { LocationIndexComponent } from './pages/locations/location-index/location-index.component';
import { LocationNewComponent } from './pages/locations/location-new/location-new.component';
import { ContactIndexComponent } from './pages/contacts/contact-index/contact-index.component';
import { AnimalTypeIndexComponent } from './pages/animal-types/animal-type-index/animal-type-index.component';
import { ClinicIndexComponent } from './pages/clinics/clinic-index/clinic-index.component';
import { ClinicNewComponent } from './pages/clinics/clinic-new/clinic-new.component';
import { LocationEditComponent } from './pages/locations/location-edit/location-edit.component';
import { ClinicEditComponent } from './pages/clinics/clinic-edit/clinic-edit.component';
import { CremationStepsComponent } from './pages/cremation-steps/cremation-steps.component';
import { MsalGuard } from '@azure/msal-angular';
import { AuthedLayoutComponent } from './authed-layout/authed-layout.component';
import { LoginFailedComponent } from './pages/auth/login-failed/login-failed.component';
import { StatusUpdateBulkComponent } from './pages/orders/status-update-bulk/status-update-bulk.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { authGuard } from './guards/auth.guard';
import { UsersIndexComponent } from './pages/users/users-index/users-index.component';
import { VehiclesIndexComponent } from './pages/vehicles/vehicles-index/vehicles-index.component';
import { SchedulesIndexComponent } from './pages/schedules/schedules-index/schedules-index.component';
import { RoutesIndexComponent } from './pages/routes/routes-index/routes-index.component';
import { PlaceIndexComponentPage } from './pages/places/place-index/place-index.component';
import { PlaceViewComponent } from './pages/places/place-view/place-view.component';
import { OverviewScheduleComponent } from './components/schedules/overview-schedule/overview-schedule.component';
import { roles } from './services/auth.service';

export const routes: Routes = [
    { path: 'login-failed', component: LoginFailedComponent, title: 'Login Failed'},
    {
        path: '',
        component: AuthedLayoutComponent,
        canActivate: [MsalGuard],
        children : [
            { 
                path: '', 
                redirectTo: '/home', pathMatch: 'full' 
            },
            { 
                path: 'home', 
                component: HomeComponent, 
                title: 'AWL FIVE - Home'
            },
            { 
                path: 'orders', component: OrdersIndexComponent, 
                pathMatch: 'full', title: 'AWL FIVE - Orders', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awlstaff, roles.awladmin, roles.clinicadmin, roles.clinicstaff, roles.globeadmin]}
            },
            { 
                path: 'orders/create', 
                component: OrdersNewComponent, 
                title: 'AWL FIVE - Order New', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awlstaff, roles.awladmin, roles.clinicadmin, roles.clinicstaff, roles.globeadmin]}
            },
            { 
                path: 'orders/:ref', 
                component: OrdersEditComponent, 
                title: 'AWL FIVE - Order Edit', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awlstaff, roles.awladmin, roles.clinicadmin, roles.clinicstaff, roles.globeadmin]}
            },
            { 
                path: 'configurations/contacts', 
                component: ContactIndexComponent, 
                pathMatch: 'full', title: 'AWL FIVE - Contacts', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awlstaff, roles.awladmin, roles.globeadmin]}
            },
            { 
                path: 'configurations/animal-types', 
                component: AnimalTypeIndexComponent, 
                pathMatch: 'full', title: 'AWL FIVE - Animal Types', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awlstaff, roles.awladmin, roles.globeadmin]}
            },
            { 
                path: 'configurations/cremation-steps', 
                component: CremationStepsComponent, 
                pathMatch: 'full', 
                title: 'AWL FIVE - Cremation Steps', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awlstaff, roles.awladmin, roles.globeadmin]}
            },
            {
                path: 'configurations/places', 
                component: PlaceIndexComponentPage, 
                pathMatch: 'full', title: 'AWL FIVE - Places', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awlstaff, roles.awladmin, roles.globeadmin]}
            },
            {
                path: 'configurations/places/:ref', 
                component: PlaceViewComponent, 
                pathMatch: 'full', title: 'AWL FIVE - Places', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awlstaff, roles.awladmin, roles.globeadmin]}
            },
            { 
                path: 'configurations/locations', 
                component: LocationIndexComponent, 
                pathMatch: 'full', title: 'AWL FIVE - Locations', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awlstaff, roles.awladmin, roles.globeadmin]}
            },
            { 
                path: 'configurations/locations/create', 
                component: LocationNewComponent, 
                title: 'AWL FIVE - Locations New', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awlstaff, roles.awladmin, roles.globeadmin]}
            },
            { 
                path: 'configurations/locations/:id', 
                component: LocationEditComponent, 
                title: 'AWL FIVE - Locations Edit', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awlstaff, roles.awladmin, roles.globeadmin]}
            },
            { 
                path: 'configurations/users', 
                component: UsersIndexComponent, 
                pathMatch: 'full', title: 'AWL FIVE - Users', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awladmin, roles.clinicadmin, roles.globeadmin]}
            },
            { 
                path: 'configurations/vehicles', 
                component: VehiclesIndexComponent, 
                pathMatch: 'full', title: 'AWL FIVE - Vehicles', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awladmin, roles.globeadmin]}
            },
            { 
                path: 'schedules/routes', 
                component: RoutesIndexComponent, 
                pathMatch: 'full', title: 'AWL FIVE - Routes', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awladmin, roles.globeadmin]}
            },
            { 
                path: 'schedules/:ref', 
                component: OverviewScheduleComponent, 
                pathMatch: 'full', title: 'AWL FIVE - Schedule Overview', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awladmin, roles.globeadmin]}
            },
            { 
                path: 'schedules', 
                component: SchedulesIndexComponent, 
                pathMatch: 'full', title: 'AWL FIVE - Schedules', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awladmin, roles.globeadmin]}
            },
            { 
                path: 'clinics', 
                component: ClinicIndexComponent, 
                pathMatch: 'full', title: 'AWL FIVE - Clinics', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awlstaff, roles.awladmin, roles.globeadmin]}
            },
            { 
                path: 'clinics/create', 
                component: ClinicNewComponent, 
                pathMatch: 'full', title: 'AWL FIVE - Clinics New', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awlstaff, roles.awladmin, roles.globeadmin]}
            },
            { 
                path: 'clinics/:id', 
                component: ClinicEditComponent, 
                pathMatch: 'full', title: 'AWL FIVE - Clinics Edit', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awlstaff, roles.awladmin, roles.globeadmin]}
            },
            { 
                path: 'run', 
                component: RunIndexComponent, 
                pathMatch: 'full', title: 'AWL FIVE - Run List', 
                canActivate: [authGuard], 
                data: {'roles': [roles.awlstaff, roles.awladmin, roles.globeadmin]}
            },
            
            //update-bulk
            { 
                path: 'orders/track-process/update-bulk', 
                component: StatusUpdateBulkComponent, 
                pathMatch: 'full', 
                title: 'Update Order Statuses',
                data: {'roles': [ roles.awladmin, roles.clinicadmin, roles.globeadmin]}
            },
            
            { 
                path: '**', 
                component: NotFoundComponent, 
                pathMatch: 'full', 
                title: 'AWL FIVE - Not Found'
            },
        ]
    },
];