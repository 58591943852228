<div *ngIf="isLoading" class="loading-container">
    <mat-spinner
    [diameter]="80">
    </mat-spinner>
</div>
<div *ngIf="!isLoading">
<div *ngIf="!selected">
    <h2 mat-dialog-title>Search Routes</h2>
</div>
<div *ngIf="selected" >
    <h2 mat-dialog-title>
        Selected Route: {{selected.name}} 
    </h2>
</div>
    <mat-form-field  class="w-100">
        <mat-label>Route</mat-label>
        <input 
                type="text"
                placeholder="Search for a route"
                matInput
                [formControl]="routeControl"
                [matAutocomplete]="contactAuto">
        <mat-autocomplete #contactAuto="matAutocomplete" [displayWith]="displayContactFn" (optionSelected)="onSelectionContact($event.option.value)">
            @for (option of routeFilteredOptions | async; track option) {
                <mat-option [value]="option">{{ option.name }}</mat-option>
            }
        </mat-autocomplete>
    </mat-form-field>        
    <div *ngIf="selected">
        <div *ngIf="isLoading" class="loading-container">
            <mat-spinner
            [diameter]="80">
            </mat-spinner>
        </div>

        @if(selected.clinics.length != 0)
        {
            <h4 mat-dialog-title>
            Clinics on Route:
            </h4>
            <div mat-dialog-content>
                    @for(clinic of selected.clinics;track $index)
                    {
                        <span>
                            {{clinic.name}}
                        </span><br>
                    }   
            </div>
        } 
        @else
        {
            <span>
                Currently no clinics on Selected Route.
            </span>
        }

    </div>
</div>


