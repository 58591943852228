import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { OrderEditComponent } from '../order-edit/order-edit.component';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialog,
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
} from '@angular/material/dialog';
import { OrderResponse } from '../../../models/orders/order.model';

@Component({
  selector: 'app-order-details-dialog',
  standalone: true,
  imports: [ CommonModule, RouterLink, MatButtonModule, MatDialogModule, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatIconModule, OrderEditComponent],
  templateUrl: './order-details-dialog.component.html',
  styleUrl: './order-details-dialog.component.scss'
})
export class OrderDetailsDialogComponent {
  @Output() orderUpdated = new EventEmitter<void>();
  
  constructor(public dialogRef: MatDialogRef<OrderDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: OrderResponse) {}

  closeDialog() {
    this.dialogRef.close();
  }

  onOrderUpdated() {
    this.orderUpdated.emit();
    this.closeDialog();
  }
}
