import { Component, OnInit } from '@angular/core';
import { CreateScheduleRequest, ScheduleResponse, UpdateScheduleRequest } from '../../../models/schedule.model';
import {  MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { CommonModule, DatePipe } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { ToastrService } from 'ngx-toastr';
import {MatStepperModule} from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { SearchRoutesComponent } from '../../routes/search-routes/search-routes.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, provideNativeDateAdapter} from '@angular/material/core';
import { SearchVehicleComponent } from '../../vehicle/search-vehicle/search-vehicle.component';
import { UserSearchComponent } from '../../users/user-search/user-search.component';
import { SchedulesService } from '../../../services/schedules.service';
import { dateLessThanTodayValidator } from '../../../helpers/validator';
import { OrderResponse } from '../../../models/orders/order.model';
import { SearchOrdersComponent } from '../../orders/search-orders/search-orders.component';

@Component({
  selector: 'app-create-schedules',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDialogContent,
    MatDialogTitle,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatIconModule,
    MatDialogClose,
    MatDialogActions,
    MatTooltipModule,
    MatTabsModule,
    MatStepperModule,
    SearchRoutesComponent,
    MatDatepickerModule,
    SearchVehicleComponent,
    UserSearchComponent,
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    },
    provideNativeDateAdapter(),
    DatePipe
  ],
  templateUrl: './create-schedules.component.html',
  styleUrl: './create-schedules.component.scss',
  
})
export class CreateSchedulesComponent {
  isSaving:boolean = false

  routeControl = new FormControl()
  routeFormGroup = this.fb.group({
    route: this.routeControl,
  });

  vehicleControl = new FormControl()
  driverControl = new FormControl()

  vehicleFormGroup = this.fb.group({
    vehicle: this.vehicleControl,
    driver: this.driverControl
  });

  orderControl = new FormControl()
  orderFormGroup = this.fb.group({
    orders: this.orderControl
  });

  dateControl = new FormControl()
  dateFormGroup = this.fb.group({
    date: this.dateControl
  });

  //Order Table
  orders: OrderResponse[] = []
  displayedColumns: string[] = ['reference', 'name', 'weight', 'animaltype'];
  dataSource: MatTableDataSource<OrderResponse> = new MatTableDataSource<OrderResponse>;
  isLoading = false
  
  constructor(
    private ScheduleService:SchedulesService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateSchedulesComponent>,
    private  datePipe: DatePipe
  )
  {
    //this.driverControl.addValidators(Validators.required)
    this.routeControl.addValidators(Validators.required)
    //this.vehicleControl.addValidators(Validators.required)
    this.dateControl.addValidators([Validators.required,dateLessThanTodayValidator()])

  }
  
  onSubmit()
  {
    this.isSaving = true
    const vehicleFormdata = this.vehicleFormGroup.value;
    const routeFormdata = this.routeFormGroup.value;
    //const orderFormdata = this.orderFormGroup.value;
    const orderData = this.orders.map(order=> order.id)
    const dateFormdata = this.dateFormGroup.value;
    const date = this.datePipe.transform(dateFormdata.date, 'yyyy-MM-dd')
    if(date)
      {
        const schedule: CreateScheduleRequest = {
          route: routeFormdata.route?.id ?? null,
          vehicle: vehicleFormdata.vehicle?.id ?? null,
          driver: vehicleFormdata.driver?.id ?? null,
          orders: orderData,
          scheduleDate: date
        }
        console.log('schedule',schedule)
        this.ScheduleService.createSchedule(schedule).subscribe({
          next: (res) => {
            this.toastr.success('Schedule created successfully.');
            this.dialogRef.close()
            this.isSaving = false
          },
          error: (error) => {
            this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
            this.isSaving = false
          }
        })
    }else
    {
      this.toastr.error('Date is wrong. Please try again.');
    }
  }
  // patchOrders(res:ScheduleResponse)
  // {
  //   if(res)
  //     {
  //       const schedule:UpdateScheduleRequest = {
  //         route:res.route.id,
  //         driver:res.driver.id,
  //         vehicle:res.vehicle.id,
  //         orders:[],
  //         scheduleDate:res.scheduleDate
  //         //orders: orderIds
  //       }
  //       this.ScheduleService.updateSchedule(res.id,schedule).subscribe({
  //         next: (res) => {
  //           this.toastr.success('Schedule created successfully.');
  //           this.isSaving = false
  //         },error: (error) =>
  //           {
  //             this.toastr.error(error.error?.title || 'An error occurred. Please try again.')
  //             this.isSaving = false
  //           }

  //       })
  //     }
  // }
  orderSearch()
  {
    const dialogRef = this.dialog.open(SearchOrdersComponent,{
      width: '950px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('result ',result.selectedOrders
      )
      if(result.selectedOrders)
        {
          result.selectedOrders.forEach((order:OrderResponse) =>{
            this.orders.push(order)
          })
          this.dataSource.data = this.orders
        }
    });
  }
  //error handling
  getRouteErrorMessage():string
  {

    const control = this.routeFormGroup.get('route');
    if (control?.hasError('required')) {
      return 'Route is required';
    }
    return 'Route Unknown Error';
  }
  getVehicleErrorMessage():string //unused
  {
    const driverControl = this.vehicleFormGroup.get('driver');
    if (driverControl?.hasError('required')) {
      return 'Driver is required';
    }
    const vehicleControl = this.vehicleFormGroup.get('vehicle');
    if (vehicleControl?.hasError('required')) {
      return 'Vehicle is required';
    }
    return 'Unknown Error'
  }
  getOrdersErrorMessage():string
  {
    return 'Order Unknown Error'
  }
  getDateErrorMessage():string
  {
    const control = this.dateFormGroup.get('date');
    if (control?.hasError('required')) {
      return 'Date is required';
    }
    if (control?.hasError('dateLessThanToday')) {
      return 'Date is less than current date';
    }
    return 'Date Unknown Error'
  }
}
