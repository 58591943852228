import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  @ViewChild('dashboardContainer', { read: ViewContainerRef, static: true }) container!: ViewContainerRef;
  role: string = '';
  idenitfier: string = ''

  // inputs = {
  //   data: ''
  // };
  componentsToLoad: any[] = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private loginService: AuthService,
  )
  {
    this.loginService.claims$.subscribe((c) => {
      this.role = c['extension_Role'];
      this.idenitfier = c['sub'];
    });
  }  
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      if (result.matches) {
        console.log('matches true',result.matches)
      } else {
        console.log('matches false',result.matches)
      }
    });

  }
}
