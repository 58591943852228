import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { 
  ChangeOrderStatusRequest, 
  CreateOrderRequest, 
  CreateOrderStatus, 
  CreateRequirementRequest, 
  EditRequirementRequest, 
  OrderResponse, 
  OrderStatusResponse, 
  StatusRequirementResponse, 
  UpdateOrderRequest, 
  UpdateOrderStatus 
} from '../models/orders/order.model';
import { AnimalTypeResponse, CreateAnimalTypeResponse, UpdateAnimalTypeRequest } from '../models/animalType.model';
import { CommunicationLogResponse, CreateCommunicationLogRequest } from '../models/orders/communicationLog.model';
import { PagedQueryResult } from '../models/pagedQueryResult.interface';
import { EventResponse } from '../models/orders/event.model';
import { EditRequirementComponent } from '../components/orders/requirements/edit-requirement/edit-requirement.component';
import { ScheduleOrderResponse } from '../models/schedule.model';

interface OrderQueryParams {
  sortBy?: string;
  page?: number;
  pageSize?: number;
}

interface AnimalQueryParams {
  sortBy?: string;
  page?: number;
  pageSize?: number;
  name?: string;
}

interface PagedQueryResultOrder<T> {
  page: number; 
  pageSize: number;
  totalItems: number;
  totalPages: number;
  orders: T[];
}

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  baseUrl = `${environment.apiUrl}`;
  orderBaseUrl = 'api/orders'; 
  animalTypeBaseUrl = 'api/orders/animaltypes'; 
  orderStatusBaseUrl = 'api/orders/orderstatuses'; 
  requirementsBaseUrl = 'api/orders/requirements';

  constructor(private http: HttpClient) { }

  getOrders(params?: OrderQueryParams): Observable<PagedQueryResultOrder<OrderResponse>> {
    let httpParams = new HttpParams();
    
    if (params?.sortBy) httpParams = httpParams.set('SortBy', params.sortBy);
    if (params?.page != null) httpParams = httpParams.set('Page', (params.page + 1).toString());
    if (params?.pageSize != null) httpParams = httpParams.set('PageSize', params.pageSize.toString());

    return this.http.get<PagedQueryResultOrder<OrderResponse>>(`${this.baseUrl}/${this.orderBaseUrl}`, { params: httpParams });
  }

  getOrder(id: string): Observable<OrderResponse> {
    return this.http.get<OrderResponse>(`${this.baseUrl}/${this.orderBaseUrl}/${id}`);
  }

  getOrderByReference(ref: string): Observable<OrderResponse> {
    return this.http.get<OrderResponse>(`${this.baseUrl}/${this.orderBaseUrl}/ref/${ref}`);
  }

  getOrderEvents(id:string): Observable<EventResponse[]> {
    return this.http.get<EventResponse[]>(`${this.baseUrl}/${this.orderBaseUrl}/${id}/events`);
  }

  addOrder(data: CreateOrderRequest): Observable<OrderResponse> {
    return this.http.post<OrderResponse>(`${this.baseUrl}/${this.orderBaseUrl}`, data);
  }

  updateOrder(id: string, data: UpdateOrderRequest): Observable<OrderResponse> {
    return this.http.put<OrderResponse>(`${this.baseUrl}/${this.orderBaseUrl}/${id}`, data);
  }

  changeOrderStatus(id: string, data: ChangeOrderStatusRequest): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/${this.orderBaseUrl}/${id}/status`,data);
  }

  addOrderContacts(orderId: string, data: string[]): Observable<OrderResponse> {
    return this.http.post<OrderResponse>(`${this.orderBaseUrl}/${orderId}/contacts`, data);
  }

  deleteOrderContacts(orderId: string, data: string[]): Observable<any>{
    const options = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      }),
      body: data
    };
    return this.http.delete<any>(`${this.orderBaseUrl}/${orderId}/contacts`, options);
  }

  //animal types
  getAnimalTypes(params?: AnimalQueryParams): Observable<PagedQueryResult<AnimalTypeResponse>> {
    let httpParams = new HttpParams();
    
    if (params?.sortBy) httpParams = httpParams.set('SortBy', params.sortBy);
    if (params?.page != null) httpParams = httpParams.set('Page', params.page.toString());
    // if (params?.page != null) httpParams = httpParams.set('Page', (params.page + 1).toString());
    if (params?.pageSize != null) httpParams = httpParams.set('PageSize', params.pageSize.toString());
    if (params?.name) httpParams = httpParams.set('Name', params.name);

    return this.http.get<PagedQueryResult<AnimalTypeResponse>>(`${this.baseUrl}/${this.animalTypeBaseUrl}`, { params: httpParams });
  }

  addAnimalType(data: CreateAnimalTypeResponse): Observable<AnimalTypeResponse> {
    return this.http.post<AnimalTypeResponse>(`${this.baseUrl}/${this.animalTypeBaseUrl}`, data);
  }

  updateAnimalType(id: string, data: UpdateAnimalTypeRequest): Observable<AnimalTypeResponse> {
    return this.http.put<AnimalTypeResponse>(`${this.baseUrl}/${this.animalTypeBaseUrl}/${id}`, data);
  }

  deleteAnimalType(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${this.animalTypeBaseUrl}/${id}`);
  }

  //order statuses
  getOrderStatuses(): Observable<PagedQueryResult<OrderStatusResponse>> {
    return this.http.get<PagedQueryResult<OrderStatusResponse>>(`${this.baseUrl}/${this.orderStatusBaseUrl}`);
  }

  getOrderStatus(id: string): Observable<OrderStatusResponse> {
    return this.http.get<OrderStatusResponse>(`${this.baseUrl}/${this.orderStatusBaseUrl}/${id}`);
  }

  createOrderStatus(data: CreateOrderStatus): Observable<OrderStatusResponse> {
    return this.http.post<OrderStatusResponse>(`${this.baseUrl}/${this.orderStatusBaseUrl}`, data);
  }

  updateOrderStatus(id: string, data: UpdateOrderStatus): Observable<OrderStatusResponse> {
    return this.http.put<OrderStatusResponse>(`${this.baseUrl}/${this.orderStatusBaseUrl}/${id}`, data);
  }

  deleteOrderStatus(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${this.orderStatusBaseUrl}/${id}`);
  }

  setCanPickup(id: string, data: { orderStatusId: string, canPickup: boolean }): Observable<OrderStatusResponse> {
    return this.http.post<any>(`${this.baseUrl}/${this.orderStatusBaseUrl}/${id}/setPickup`, data);
  }

  setCanDropOff(id: string, data: { orderStatusId: string, canDropoff: boolean }): Observable<OrderStatusResponse> {
    return this.http.post<any>(`${this.baseUrl}/${this.orderStatusBaseUrl}/${id}/setDropoff`, data);
  }

  //order status requirements
  getRequirements(): Observable<PagedQueryResult<StatusRequirementResponse>> {
    return this.http.get<PagedQueryResult<StatusRequirementResponse>>(`${this.baseUrl}/${this.requirementsBaseUrl}`);
  }

  createRequirement(data:CreateRequirementRequest): Observable<StatusRequirementResponse> {
    return this.http.post<StatusRequirementResponse>(`${this.baseUrl}/${this.requirementsBaseUrl}`,data);
  }

  editRequirement(id:string, data:EditRequirementRequest): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/${this.requirementsBaseUrl}/${id}`,data);
  }

  deleteRequirement(id:string): Observable<StatusRequirementResponse> {
    return this.http.delete<StatusRequirementResponse>(`${this.baseUrl}/${this.requirementsBaseUrl}/${id}`);
  }

  //* Order Schedule Methods
  getScheduleOrders(orderId: string): Observable<ScheduleOrderResponse[]> {
    return this.http.get<ScheduleOrderResponse[]>(`${this.baseUrl}/${this.orderBaseUrl}/${orderId}/schedules`);
  }


}
