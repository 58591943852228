<div class="container">
    <div class="header">
        <h2>Vehicle Configuration</h2>
    </div>
    <div class="flex-row align-items-center">
        <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. toyota" #input>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="openCreateVehicleDialog()" class="add-btn" *ngIf="isAdmin">
            Add Vehicle
        </button>
    </div>
    <div *ngIf="isLoading" class="loading-container">
        <mat-spinner
        [diameter]="80"></mat-spinner>
    </div>   
    <div [@invisTofadeIn]="isLoading ? 'hidden' : 'visible'"  class="mat-elevation-z8">
        <div *ngIf="!isLoading">
            <table mat-table [dataSource]="dataSourceVehicles" matSort>
                <ng-container matColumnDef="registration">
                    <th mat-header-cell *matHeaderCellDef> Registration </th>
                    <td mat-cell *matCellDef="let row"> {{row.registration}} </td>
                </ng-container>
                <ng-container matColumnDef="isAvailable">
                    <th mat-header-cell *matHeaderCellDef> Is Available </th>
                    <td mat-cell *matCellDef="let row"> {{row.isAvailable}} </td>
                </ng-container>
                <ng-container matColumnDef="make">
                    <th mat-header-cell *matHeaderCellDef> Make </th>
                    <td mat-cell *matCellDef="let row"> {{row.make}} </td>
                </ng-container>
                <ng-container matColumnDef="model">
                    <th mat-header-cell *matHeaderCellDef> Model </th>
                    <td mat-cell *matCellDef="let row"> {{row.model}} </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions </th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="button-container">
                            <button mat-icon-button color="primary" class="edit-btn" matTooltip="Edit Vehicle"
                                (click)="openEditVehicleDialog(element)" *ngIf="element.role !== 'globeadmin'">
                                <mat-icon>edit</mat-icon>
                            </button>   
            
                            <button mat-icon-button color="warn" matTooltip="Delete Vehicle"
                                (click)="openDeleteVehicleDialog(element)" *ngIf="element.role !== 'globeadmin'">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsVehicles"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsVehicles;"></tr>


                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>
            </table>
        </div>
        <mat-paginator  [ngStyle]="{ 'display': isLoading ? 'none' : 'block' }" [pageSizeOptions]="[25, 50, 100]" aria-label="Select page of users" showFirstLastButtons></mat-paginator>
    </div>
</div>
