import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { dateLessThanTodayValidator } from '../../../helpers/validator';
import { SchedulesService } from '../../../services/schedules.service';
import { CreateSchedulesComponent } from '../create-schedules/create-schedules.component';
import { SearchRoutesComponent } from '../../routes/search-routes/search-routes.component';
import { SearchVehicleComponent } from '../../vehicle/search-vehicle/search-vehicle.component';
import { UserSearchComponent } from '../../users/user-search/user-search.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CreateScheduleRequest } from '../../../models/schedule.model';

@Component({
  selector: 'app-create-schedule',
  standalone: true,
  imports: [
    SearchRoutesComponent,
    SearchVehicleComponent,
    ReactiveFormsModule,
    UserSearchComponent,
    MatFormFieldModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatDialogModule,
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    },
    provideNativeDateAdapter(),
    DatePipe,
  ],
  templateUrl: './create-schedule.component.html',
  styleUrl: './create-schedule.component.scss'
})
export class CreateScheduleComponent {

  isSaving: boolean = false;

  // Set controls so they can be explicitly accessed in the HTML template
  routeControl = new FormControl(null, Validators.required);
  vehicleControl = new FormControl(null);
  driverControl = new FormControl(null);
  dateControl = new FormControl(null, [Validators.required,dateLessThanTodayValidator()]);

  scheduleForm: FormGroup;

  constructor(
    private scheduleService: SchedulesService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateSchedulesComponent>,
    private datePipe: DatePipe
  ) {
    // Define a single form group for all inputs
    this.scheduleForm = this.fb.group({
      route: this.routeControl,
      vehicle: this.vehicleControl,
      driver: this.driverControl,
      date: this.dateControl,
    });
  }

  onSubmit() {
    if (this.scheduleForm.invalid) {
      this.toastr.error('Please fill in all required fields.');
      return;
    }

    this.isSaving = true;

    const formData = this.scheduleForm.value;
    const formattedDate = this.datePipe.transform(formData.date, 'yyyy-MM-dd');

    if (!formattedDate) {
      this.toastr.error('Invalid date format.');
      this.isSaving = false;
      return;
    }

    const schedule: CreateScheduleRequest = {
      route: formData.route?.id ?? null,
      vehicle: formData.vehicle?.id ?? null,
      driver: formData.driver?.id ?? null,
      orders: [],
      scheduleDate: formattedDate
    };

    this.scheduleService.createSchedule(schedule).subscribe({
      next: () => {
        this.toastr.success('Schedule created successfully.');
        this.dialogRef.close();
        this.isSaving = false;
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
        this.isSaving = false;
      }
    });
  }
}
