import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CreateVehicleRequest } from '../../../models/vechicle.model';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-create-vehicle',
  standalone: true,
  imports: [    
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    MatSlideToggle
  ],
  templateUrl: './create-vehicle.component.html',
  styleUrl: './create-vehicle.component.scss'
})
export class CreateVehicleComponent {
  form!:FormGroup;
  constructor(public dialogRef: MatDialogRef<CreateVehicleComponent>, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      registration: ['',[Validators.required]],
      isAvailable: [false],
      make:[''],
      model:[''],
      name: [''],


    });
  }
  onSubmit() {
    if(this.form.valid) {
      const vehicle: CreateVehicleRequest = {
        registration: this.form.value.registration,
        isAvailable: this.form.value.isAvailable,
        make: this.form.value.make,
        model: this.form.value.model,
        name: this.form.value.name,
      }
      this.dialogRef.close(vehicle)
    }
  }
}
