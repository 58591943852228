<div class="container">
    <button mat-raised-button routerLink="/clinics" type="button" class="back-btn">Back</button>
    <div class="header">
        <h2>Edit Clinic</h2>
    </div>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Name</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="flex-container">
                    <mat-form-field appearance="fill" class="flex-1">
                        <mat-label>Name</mat-label>
                        <input type="text" placeholder="Name" formControlName="name" matInput>
                        <mat-error *ngIf="form.get('name')?.errors?.['required']">
                            Name is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <mat-card-title>Locations</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-divider *ngIf="locationsArray.controls"></mat-divider>
                <p *ngIf="locationsArray.controls">Clinic Locations:</p>
                <div formArrayName="locations" *ngFor="let locationCtrl of locationsArray.controls; let i = index">
                    <div [formGroupName]="i">
                        <div class="flex-row align-items-center">
                            <mat-radio-group formControlName="isPrimary">
                               <mat-radio-button color="primary" [value]="true" (change)="setLocationPrimary(i)">Primary Location</mat-radio-button>
                           </mat-radio-group>
                           <button mat-icon-button (click)="removeLocation(i)">
                               <mat-icon>delete</mat-icon>
                           </button>
                       </div>
                       <div class="align-items-center">
                            <p>
                                <span *ngIf="locationCtrl.get('location')?.value.address.unit">
                                    {{ locationCtrl.get('location')?.value.address.unit }}&nbsp;
                                </span>
                                {{ locationCtrl.get('location')?.value.address.street }},&nbsp;
                                {{ locationCtrl.get('location')?.value.address.city }},&nbsp;
                                {{ locationCtrl.get('location')?.value.address.state }}&nbsp;
                                {{ locationCtrl.get('location')?.value.address.postCode }},&nbsp;
                                {{ locationCtrl.get('location')?.value.address.country }}
                            </p>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                </div>
                <p>Add a location</p>
                <app-search-location (locationSelected)="onLocationSelected($event)"></app-search-location>
            </mat-card-content>
        </mat-card>
        <div *ngIf="isLoading"  class="loading-container">
            <mat-spinner
            [diameter]="80"></mat-spinner>
        </div>
        <app-clinic-users *ngIf="!isLoading" [clinic]="clinic"></app-clinic-users>
        <mat-card>
            <mat-card-header>
                <mat-card-title>Contact Details</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-divider></mat-divider>
                <p>Clinic Phone Number **placeholder**</p>
                <p>Clinic Email Address **placeholder**</p>
                <mat-divider></mat-divider>
                <p *ngIf="contactsArray.controls">Clinic Contacts:</p>                
                <div formArrayName="contacts" *ngFor="let contactCtrl of contactsArray.controls; let i = index">
                    <div [formGroupName]="i">
                        <div class="flex-row align-items-center">
                             <mat-radio-group formControlName="isPrimary" class="flex-50">
                                <mat-radio-button color="primary" [value]="true" (change)="setContactPrimary(i)">Primary Contact</mat-radio-button>
                            </mat-radio-group>
                            <button mat-icon-button (click)="removeContact(i)" class="flex-50">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                        <div class="flex-container">
                            <p class="flex-20">First Name: {{ contactCtrl.get('contact')?.value.firstName }}</p>
                            <p class="flex-20">Last Name: {{ contactCtrl.get('contact')?.value.lastName }}</p>
                            <p class="flex-20">Email: {{ contactCtrl.get('contact')?.value.email }}</p>
                            <p class="flex-20">Phone: {{ contactCtrl.get('contact')?.value.phone }}</p>
                            <p class="flex-20">Contact method: {{ contactCtrl.get('contact')?.value.preferredContactMethod }}</p>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                </div>
                <div class="contact-header">
                    <div class="contact-btns">
                        <button mat-raised-button color="primary" type="button" (click)="openAddContactDialog()" class="btn">Add New</button>
                        <button mat-raised-button color="primary" type="button" (click)="openSearchContactDialog()" class="btn">Search existing contacts</button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <mat-card-title>Note</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="flex-container">
                    <mat-form-field appearance="fill" class="flex-1">
                        <mat-label>Note</mat-label>
                        <textarea matInput formControlName="note"></textarea>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </mat-card>
        <div class="actions flex-end-actions">
            <button mat-raised-button color="primary" type="submit">Save</button>
        </div>
    </form>    
</div>