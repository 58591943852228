<div class="container">
    <div *ngIf="!data">
        <button mat-raised-button routerLink="/schedules" type="button" class="back-btn">Back</button>
        <div class="header">
            <h2>View Schedule</h2>
        </div>
    </div>
    <div *ngIf="isLoading" class="loading-container">
        <mat-spinner
        [diameter]="80">
        </mat-spinner>
    </div>   
    <div *ngIf="!isLoading" [@fadeIn]>
        <div class="header flex" >
            <h2>{{schedule.route.name}}</h2>
            <button mat-icon-button matTreeNodeToggle class="ms-1" (click)="toggleCard()" color="primary" style="cursor: pointer;">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{isExpanded ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
        </div>
        <mat-card [@expandCollapse]="isExpanded ? 'expanded' : 'collapsed'" class="flex-container">
            <mat-card-content >
                <div class="information-container">
                    <span class="ref">Driver</span>
                    <span *ngIf="schedule.driver;else driverEmpty">{{schedule.driver.displayName}}</span>
                    <ng-template #driverEmpty>
                        <span>Driver is Currently Empty</span>
                    </ng-template>
                    <br>
                    <span class="ref">Vehicle</span>
                    <span *ngIf="schedule.vehicle;else vehicleEmpty">{{schedule.vehicle.make}} {{schedule.vehicle.model}} </span><br>
                    <ng-template #vehicleEmpty>
                        <span>Vehicle is Currently Empty</span>
                    </ng-template>
                    <span class="ref">Scheduled Date</span>
                    <span  *ngIf="schedule.scheduleDate"> {{schedule.scheduleDate | date:'medium'}}</span><br>
                    <span class="ref">Scheduled Route</span>
                    <span *ngIf="schedule.route"> {{schedule.route.name}}</span><br>
                </div>
            </mat-card-content>         
        </mat-card>
        <mat-card>
            <mat-card-content class="flex-container">
                <div *ngIf="routeData.clinics.length > 0; else emptyClinics" >
                    <div class="flex-row w-100 center-items">
                        <div class="header">
                            <h2>Orders</h2>
                        </div>
                        <div>
                            <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <ng-container *ngFor="let clinic of clinics;let i = index">
                                    <button mat-menu-item (click)="onClinicMenuClick(i)"
                                    >
                                        <span 
                                        class="ms-1"                                    
                                        [matBadge]="groupedOrders[clinic.id].length"
                                        [matBadgeHidden]="groupedOrders[clinic.id].length <= 0"
                                        matBadgePosition="before"
                                        matBadgeOverlap="false"
                                        matBadgeSize="small">
                                        </span>
                                        <span>
                                        {{clinic.name}}
                                        </span>
                                    </button>
                                </ng-container>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="center-items row-flex">
                        <div>
                            <mat-form-field class="comp-btn">
                                <mat-label>Filter by Order Status</mat-label>
                                <mat-select [(ngModel)]="selectedView" (selectionChange)="filterOrders()">
                                  <mat-option [value]="'1'">All</mat-option>
                                  @for (status of statuses; track status.id) {
                                    <mat-option [value]="status.id">{{status.name}}</mat-option>
                                  }
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <button mat-raised-button color="primary" matTooltip="Add Order" class="comp-btn" (click)="openBottomSheet()">
                                <mat-icon>add</mat-icon>
                                    Add Order
                            </button>
                        </div>
                    </div>
                    <div *ngIf="isRefreshing" class="loading-container mb-1">
                        <mat-spinner
                        [diameter]="80">
                        </mat-spinner>
                    </div>
                    <!--FOR Swipe Pagination-->
                    <!--(touchstart)="onTouchStart($event)" (touchend)="onTouchEnd($event)"-->
                    @defer (when !isRefreshing)
                    {

                    }
                    <div *ngIf="!isRefreshing" [@expand] class="information-container mt-1" >
                        <!--animationDuration="0ms" for simple fix (selectedTabChange)="onTabChange($event)" making manual tab layout-->
                        <mat-tab-group style="width: 100%;" [mat-stretch-tabs]="true" (selectedTabChange)="onTabChange($event)" dynamicHeight #tabGroup >
                            @if(isMobile)
                            {
                                @for(clinic of clinics; track $index){
                                    <mat-tab  *ngIf="selectedTabIndex === $index">
                                        <ng-template  mat-tab-label>
                                            <span 
                                            [matBadge]="groupedOrders[clinic.id].length"
                                            [matBadgeHidden]="groupedOrders[clinic.id].length <= 0" 
                                            matBadgeSize="small" 
                                            matBadgeOverlap="false"
                                            >
                                                {{clinic.name}}
                                            </span>
                                        </ng-template>
                                        <ng-template matTabContent>
                                            <mat-selection-list  *ngIf="groupedOrders[clinic.id].length > 0; else emptyOrders" [(ngModel)]="selectedOrders">
                                                @for(order of groupedOrders[clinic.id];track $index)
                                                {
                                                    <mat-list-option color="primary" [value]="order">
                                                        {{order.reference}} - {{order.animalName}} 
                                                    </mat-list-option>
                                                }
                                            </mat-selection-list>
                                            <ng-template #emptyOrders>
                                                <div class="ordersMsg">
                                                    No Orders left at Clinic
                                                </div>
                                            </ng-template>
                                            <div *ngIf="data && schedule.vehicle" class="flex-end-actions">
                                                <button mat-raised-button color="primary" matTooltip="Picks Up All Orders From Clinic" class="mt-1 comp-btn" (click)="pickUp(clinic)">
                                                    <mat-icon>local_shipping</mat-icon>
                                                    Pick Up All
                                                </button>
                                            </div>
                                        </ng-template>
                                    </mat-tab>
                                }
                            }@else{   
                               @for(clinic of clinics; track $index){
                                <mat-tab>
                                    <ng-template mat-tab-label>
                                        <span 
                                        [matBadge]="groupedOrders[clinic.id].length"
                                        [matBadgeColor]="selectedTab === $index? 'primary' : 'warn'" 
                                        [matBadgeHidden]="groupedOrders[clinic.id].length <= 0" 
                                        matBadgeSize="small" 
                                        matBadgeOverlap="false"
                                        >
                                            <!-- <mat-icon class="tab-icon" *ngIf="data">{{groupedOrders[clinic.id].length <= 0 ? "check_circle_rounded" : "cancel_rounded"}}</mat-icon> -->
                                            {{clinic.name}}
                                        </span>
                                    </ng-template>
                                    <ng-template matTabContent>
                                        <mat-selection-list  *ngIf="groupedOrders[clinic.id].length > 0; else emptyOrders" [(ngModel)]="selectedOrders">
                                            @for(order of groupedOrders[clinic.id];track $index)
                                            {
                                                <mat-list-option color="primary" [value]="order">
                                                    {{order.reference}} - {{order.animalName}}
                                                </mat-list-option>
                                            }
                                        </mat-selection-list>
                                        <ng-template #emptyOrders>
                                            <div class="ordersMsg">
                                                No Orders left at Clinic
                                            </div>
                                        </ng-template>
                                        <div class="row-flex m-offset">
                                            <div>
                                                <button *ngIf="selectedOrders.length > 0" [ngClass]="selectedOrders.length > 0 ? 'visible' : 'hidden'" mat-stroked-button color="warn" matTooltip="Remove Orders" class="comp-btn" (click)="removeOrders()">
                                                    <mat-icon>remove</mat-icon>
                                                        Remove Orders
                                                </button>
                                            </div>
                                            <div *ngIf="data && schedule.vehicle" >
                                                <button mat-stroked-button color="primary" matTooltip="Picks Up All Orders From Clinic" class="comp-btn" (click)="pickUp(clinic)">
                                                    <mat-icon>local_shipping</mat-icon>
                                                    Pick Up All
                                                </button>
                                            </div>
                                            <!--*ngIf="selectedOrders.length > 0" class="flex-end-actions"-->
                                        </div>
                                    </ng-template>
                                </mat-tab>
                                }   
                            } 
                        </mat-tab-group>
                    </div>
                </div>
                <ng-template #emptyClinics>
                    <div class="information-container">
                        Currently no Clinics on Route
                    </div>
                </ng-template>
            </mat-card-content>         
        </mat-card>
    </div>
    <!--vehicleOrders-->
    <div *ngIf="data && vehicleOrders.length > 0 && !isRefreshing" [@expand] class="header">
        <h2>Orders In Vehicle</h2>
        <mat-selection-list  [(ngModel)]="selectedVehicleOrders" >
            @for(vehicleOrder of vehicleOrders;track $index)
            {
                <mat-list-option color="primary" [value]="vehicleOrder">
                    {{vehicleOrder.reference}} - {{vehicleOrder.animalName}}
                </mat-list-option>

            }
        </mat-selection-list>
        <div *ngIf="data && schedule.vehicle" class="flex-end-actions">
            <button mat-raised-button color="primary" matTooltip="Complete Delivery" class="mt-1 comp-btn" (click)="completeSchedule()">
                <mat-icon>inventory</mat-icon>
                Complete Schedule
            </button>
        </div>
    </div>
    <div *ngIf="!data">
        <button mat-raised-button *ngIf="!isLoading" routerLink="/schedules" type="button" class="back-btn mt-1">Go Back</button>
    </div>

</div>
