import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource , MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LocationResponse } from '../../../models/location.model';
import { MatDialog } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatLabel } from '@angular/material/form-field';
import { DeleteDialogComponent } from '../../../components/delete-dialog/delete-dialog.component';
import { LocationService } from '../../../services/location.service';
import { ToastrService } from 'ngx-toastr';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from '../../../services/auth.service';
import { CommonModule } from '@angular/common';
import { invisTofadeIn } from '../../../../assets/animations';

@Component({
  selector: 'app-location-index',
  standalone: true,
  imports: [
    MatCardModule, 
    MatButtonModule, 
    RouterLink, 
    MatProgressSpinnerModule, 
    MatTableModule, 
    MatSortModule, 
    MatPaginatorModule, 
    MatInputModule, 
    MatFormFieldModule, 
    MatLabel,
    MatIconModule,
    MatTooltipModule,
    CommonModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './location-index.component.html',
  styleUrl: './location-index.component.scss',
  animations: [invisTofadeIn]
})
export class LocationIndexComponent implements OnInit, AfterViewInit{
  displayedColumns: string[] = ['id', 'name', 'address', 'note'];
  dataSourceLocations = new MatTableDataSource<LocationResponse>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  role: string = '';
  isAdmin: boolean = false;
  isLoading:boolean = true;

  constructor(
    public dialog: MatDialog, 
    private locationService: LocationService, 
    private toastr: ToastrService, 
    private breakpointObserver: BreakpointObserver, 
    private loginService: AuthService
  ) { 
    this.loginService.claims$.subscribe((c) => {
      this.role = c['extension_Role'];
    });
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      if (result.matches) {
        this.displayedColumns = ['name', 'address', 'note']; // MOBILE DISPLAY
      } else {
        this.displayedColumns = ['name', 'address', 'note']; // DESKTOP DISPLAY
      }

      if(this.role == 'globeadmin' || this.role == 'awladmin') {
        this.displayedColumns.push('actions');
        this.isAdmin = true;
      }
    });
  }

  ngAfterViewInit() {
    this.getData();
  }

  getData() {
    this.locationService.getLocations().subscribe({
      next: (res) => {
        this.dataSourceLocations.data = res.locations;
        this.dataSourceLocations.paginator = this.paginator;
        this.dataSourceLocations.sort = this.sort;
        this.isLoading=false;
      },
      error: (error) => {
        this.isLoading= false;
        this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
      }
    }) 
  }

  getFormattedUnit(unit: string): string {
    if (!unit.startsWith('Unit ')) {
      return 'Unit ' + unit;
    }
    return unit;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceLocations.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceLocations.paginator) {
      this.dataSourceLocations.paginator.firstPage();
    }
  }

  openDeleteDialog(element: LocationResponse) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: { type: "location", name: element.name }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.locationService.deleteLocation(element.id).subscribe({
          next: (res) => {
            this.toastr.success('Location deleted successfully.');
            this.getData();
          },
          error: (error) => {
            if(error.status == '409') {
              this.toastr.error('This location cannot be deleted because it is associated with other entities, such as a clinic.');
            } else {
              this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
            }
          }
        });
      }
    })
  }
}