<h2 mat-dialog-title>Add contact</h2>
<form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        <div class="flex-container">
            <mat-form-field appearance="fill" class="flex-50">
                <mat-label>First Name</mat-label>
                <input type="text" placeholder="First Name" formControlName="firstName" matInput>
                <mat-error *ngIf="contactForm.get('firstName')?.errors?.['required']">
                    First Name is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="flex-50">
                <mat-label>Last Name</mat-label>
                <input type="text" placeholder="Last Name" formControlName="lastName" matInput>
                <mat-error *ngIf="contactForm.get('lastName')?.errors?.['required']">
                    Last Name is required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex-container">
            <mat-form-field appearance="fill" class="flex-50">
                <mat-label>Email</mat-label>
                <input type="email" placeholder="Email" formControlName="email" matInput>
                <mat-error *ngIf="contactForm.get('email')?.errors?.['pattern'] && contactForm.get('email')?.touched">
                    Invalid email address
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="flex-50">
                <mat-label>Phone</mat-label>
                <input type="tel" placeholder="Phone" formControlName="phone" maxlength="9" matInput id="phone">
                <span matPrefix> +61&nbsp;</span>
                <mat-icon matSuffix class="info-icon" matTooltip="Phone must start with '4' followed by 8 more digits.">info_outline</mat-icon>
                <mat-error  *ngIf="contactForm.get('phone')?.errors?.['required']">
                    Phone is required
                </mat-error >
                <mat-error *ngIf="contactForm.get('phone')?.errors?.['pattern'] && contactForm.get('phone')?.touched">
                  Invalid format
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex-container">
            <mat-form-field appearance="fill" class="flex-1">
                <mat-label>Preferred Contact Method</mat-label>
                <mat-select formControlName="preferredContactMethod">
                    <mat-option *ngFor="let method of contactMethods" [value]="method">
                    {{ method }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="contactForm.get('preferredContactMethod')?.invalid">
                    Contact method is required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex-container">
            <mat-form-field appearance="fill" class="flex-1">
                <mat-label>Note</mat-label>
                <textarea matInput formControlName="note"></textarea>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="flex-end-actions">
        <button mat-raised-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" type="submit">Add</button>
    </mat-dialog-actions>
</form>
