import { AfterViewInit, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogModule} from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { ScheduleResponse, UpdateScheduleRequest } from '../../../models/schedule.model';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { SchedulesService } from '../../../services/schedules.service';
import{ MatBottomSheet,MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { OrderOptionsBottomSheetComponent } from '../../orders/order-options-bottom-sheet/order-options-bottom-sheet.component';
import { ChangeOrderStatusRequest, OrderResponse, OrderStatusResponse, ScheduleOrderResponse } from '../../../models/orders/order.model';
import { ToastrService } from 'ngx-toastr';
import { ClinicResponse } from '../../../models/clinic.model';
import { ClinicService } from '../../../services/clinic.service';
import { MatListModule } from '@angular/material/list';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { RoutesService } from '../../../services/routes.service';
import { RouteResponse } from '../../../models/routes.model';
import { OrderService } from '../../../services/order.service';
import { PlaceService } from '../../../services/place.service';
import { GetOrCreatePlaceRequest,PlaceResponse} from '../../../models/orders/place.model';
import { MatBadgeModule} from '@angular/material/badge'
import { MatMenuModule } from '@angular/material/menu';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { expand, expandCollapse, expandPage, fadeIn } from '../../../../assets/animations';
import { catchError, forkJoin, Observable, of } from 'rxjs';
@Component({
  selector: 'app-overview-schedule',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatIconModule,
    MatDialogClose,
    MatDialogActions,
    MatTooltipModule,
    MatSelectModule,
    MatTabsModule,
    MatBottomSheetModule,
    RouterLink,
    MatListModule,
    MatTableModule,
    MatTableModule,
    FormsModule,
    MatDialogModule,
    MatMenuModule,
    MatOptionModule,
    MatBadgeModule
  ],
  templateUrl: './overview-schedule.component.html',
  styleUrl: './overview-schedule.component.scss',
  animations: [
    ,expand
    ,expandCollapse
    ,fadeIn
  ]
    //custom slide animations
    // trigger('slideInRight', [
    //   state('hidden', style({
    //     transform: 'translateX(100%)',
    //     opacity: 0,
    //   })),
    //   state('visible', style({
    //     transform: 'translateX(0)',
    //     opacity: 1,
    //   })),
    //   transition('hidden => visible', [
    //     animate('300ms ease-out')
    //   ]),
    //   transition('visible => hidden', [
    //     animate('300ms ease-in')
    //   ])
    // ]),
    // trigger('slideInLeft', [
    //   state('hidden', style({
    //     transform: 'translateX(-100%)',
    //     opacity: 0,
    //   })),
    //   state('visible', style({
    //     transform: 'translateX(0)',
    //     opacity: 1,
    //   })),
    //   transition('hidden => visible', [
    //     animate('300ms ease-out')
    //   ]),
    //   transition('visible => hidden', [
    //     animate('300ms ease-in')
    //   ]),
    // ])

})
export class OverviewScheduleComponent implements OnInit{
  isLoading:boolean = false
  isRefreshing:boolean = false
  isExpanded:boolean = false;
  isMobile: boolean = false;

  clinics: ClinicResponse[] =[]
  selectedOrders: OrderResponse[] = []
  routeData!:RouteResponse

  clinic!:ClinicResponse
  vehiclesPlace!:PlaceResponse

  //tabs
  selectedTabIndex = 0
  selectedTab = 0

  //status Filtering
  selectedView: string = '1';
  filteredOrders:ScheduleOrderResponse[] = []

  //vehicle orders
  vehicleOrders:ScheduleOrderResponse[] = []
  selectedVehicleOrders:OrderResponse[] = []//unused

  //Statuses
  statusIds:string[] = []
  statuses:OrderStatusResponse[] = []
  filteredStatuses:OrderStatusResponse[] = []
  unwantedStatusIds: string[] = 
  [
    'c54b2de9-e88b-4a41-984a-1f11d4bb643b',
    'f4feecff-dedc-405e-af38-92d1dea0ccb1',
    'fc324917-7bf4-4a61-8f0a-9ae6eaaeb4b4',
    '481956b1-e9a6-4c93-ab9f-992a32df824f',
    '18a01df0-d52b-44fc-8087-d28b76f8a6ac',
   //"a04bc5f5-a4f7-40a2-a0a1-7137925685e6"-new
  ]//replace with config setting

  @Input() data! : ScheduleResponse;
  schedule!: ScheduleResponse;
  groupedOrders:{[key: string]: ScheduleOrderResponse[] } = {};

  private _bottomSheet = inject(MatBottomSheet);

  //touch pageination settings
  private touchStartX = 0;
  private touchEndX = 0;
  private readonly swipeThreshold = 30;

  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;

  constructor(
    private route: ActivatedRoute,
    private scheduleService:SchedulesService,
    private toastr: ToastrService,
    private routeService: RoutesService,
    private orderService:OrderService,
    private placeService:PlaceService,
    private clinicService:ClinicService,
    public dialog: MatDialog, 
    private breakpointObserver: BreakpointObserver
  )
  {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.isMobile = result.matches;
    });
  }
  ngOnInit(): void 
  {
    this.isLoading = true;
    this.getStatusData()
    if(this.data){
      this.schedule = this.data
      this.filteredOrders = this.schedule.orders
      this.getRouteData(this.data.route.id)
    }
    else
    {
      this.route.paramMap.subscribe(params => {
        const scheduleReference = params.get('ref');
        if(scheduleReference)
          {
            this.getData(scheduleReference)
            this.isExpanded = true
          }
      })
    }
  }
  getData(id:string)
  {
    this.isRefreshing = true
    this.scheduleService.getSchedule(id).subscribe({
      next: (res) => {
        this.schedule = res
        this.filteredOrders = this.schedule.orders//Potentially needs to be reset
        this.getRouteData(res.route.id)
      },
      error: (error) =>
      {
        this.toastr.error('Failed to get Schedule.', error.title)
      }
    })
  }
  getRouteData(id:string)
  {
    this.isRefreshing = true
    this.routeService.getRoute(id).subscribe({
      next: (res) => {
        this.routeData = res
        this.getVehiclesPlace()
      },
      error: (error) =>
      {
        this.toastr.error('Failed to get Routes on Schedule.', error.title)
      }
    })
  }
  getStatusData()
  {
    this.orderService.getOrderStatuses().subscribe({
      next: (res) => {
        this.statuses = res.items
      },
      error: (error) =>
      {
        this.toastr.error('Failed to get Statuses.', error.title)
      }
    })
  }
  getVehiclesPlace()
  {
    if(this.schedule.vehicle)
      {
        const data: GetOrCreatePlaceRequest = 
        {
          name:'',
          objectId:this.schedule.vehicle.id,
          type:'Vehicle'
        }
        this.placeService.getOrCreatePlace(data).subscribe({          
        next: (res) => {
          this.vehiclesPlace = res
          this.getClinicsHeaders()
        }, 
        error: (err) => 
        {
          this.toastr.error('Failed to Create place.',err.message)
        }})
      }
      else
      {
        this.getClinicsHeaders()
      }
    
  }
  async getClinicsHeaders()
  {
    this.clinics = []
    this.groupedOrders = {}
    const requests: Observable<any>[] = [];
    this.clinics = this.routeData.clinics
    const clinicIds = new Set(this.schedule.orders.map(order => order.clinicId))
    this.clinics.forEach(clinic=>{
      clinicIds.add(clinic.id)
    })
    clinicIds.forEach(id =>{
      if(!this.groupedOrders[id])
        {
          this.groupedOrders[id] = []
        }
      if(!this.clinics.map(clinic => clinic.id).includes(id))
        {
          requests.push(this.clinicService.getClinic(id).pipe(
            catchError(error =>{
              this.toastr.error('Failed to get clinic ID: ' + id, error.error)
              return of(null)
            })))
        } 
      }
    )
    if(requests.length > 0)
      {
        forkJoin(requests).subscribe({
          next: (res) => {
            this.clinics.push(...res);
            this.isRefreshing = false;
          },
          error: (error) => {
            this.isRefreshing = false;
            this.toastr.error(`${error}`, "Error");
          }
        });
      }else
      {
        this.isRefreshing = false
      } 

    this.sortOrders()
  }
  resetOrders()
  {
    this.clinics.forEach(clinic=>{
      this.groupedOrders[clinic.id] = []
    })
  }
  sortOrders()
  {
    this.vehicleOrders = []

    this.filteredOrders = this.filteredOrders.filter(order => !this.unwantedStatusIds.includes(order.statusId))
    this.filteredOrders.forEach(order =>
    {
      if(this.vehiclesPlace)
        {
        if(order.currentPlaceId != this.vehiclesPlace.id)
          {
            this.groupedOrders[order.clinicId].push(order)
          } 
          else 
          {
            this.vehicleOrders.push(order)
          }
        }
        else
        {
          this.groupedOrders[order.clinicId].push(order)
        }
    })
    this.isLoading = false
  }
  filterOrders()
  {
    if (this.selectedView === '1') {
      this.filteredOrders = this.schedule.orders;
    } else {
      this.filteredOrders = this.schedule.orders.filter(order =>
        order.statusId === this.selectedView
      );
    }
    this.resetOrders()
    this.sortOrders()
  }
  saveOrders(orders:OrderResponse[])
  {
    let orderIds: string[] = []
    if(this.schedule.orders.length > 0)
      {
        orderIds = [...this.schedule.orders,...orders ].map(orders => orders.id)
      } else 
      {
        orderIds = orders.map(order => order.id)
      }
    const schedule: UpdateScheduleRequest = {
      route: this.schedule.route?.id ?? null,
      vehicle: this.schedule.vehicle?.id ?? null,
      driver: this.schedule.driver?.id ?? null,
      orders: orderIds,
      scheduleDate: this.schedule.scheduleDate
    }
    this.scheduleService.updateSchedule(this.schedule.id,schedule).subscribe({
      next: (res) => {
        this.toastr.success('Schedule updated successfully.');
        this.isRefreshing = true
        this.getData(this.schedule.id)
      }, error: (error) =>
      {
        this.toastr.error('Schedule failed to update.', error.title);
      }
    })

  }
  removeOrders()
  {
    const orderIds = this.selectedOrders.map(order => order.id)
    const newIds = this.schedule.orders.filter(order => !orderIds.includes(order.id)).map(order=> order.id)
    const newSchedule: UpdateScheduleRequest = {
      route: this.schedule.route?.id ?? null,
      vehicle: this.schedule.vehicle?.id ?? null,
      driver: this.schedule.driver?.id ?? null,
      orders: newIds,
      scheduleDate: this.schedule.scheduleDate
    }
    this.scheduleService.updateSchedule(this.schedule.id,newSchedule).subscribe({
      next: (res) => 
      {
        this.toastr.success('Orders Removed Successfully')
        this.isRefreshing = true
        this.getData(this.schedule.id)
      },
      error: (error) => 
      {
        this.toastr.error('Failed to Remove Orders due to:', error.title)
      }
    })
    this.selectedOrders = []
  }
  openBottomSheet(): void {
    const bottomSheetRef = this._bottomSheet.open(OrderOptionsBottomSheetComponent,{
      data: this.schedule.orders ?? this.data.orders
    });
    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result) {
        if(typeof result.data === 'string')
          { 
            this.saveByReference(result.data)
          } else 
          {
            const selectedOrders = result.data.selectedOrders as OrderResponse[]
            const orders = selectedOrders.filter(order => { 
              if(order.status.id != '0e1f1bd8-6a21-497e-9cb3-69490ef18752')
              {
                this.toastr.error(order.reference,"Must Be Awaiting Pickup:")
                return false
              }
            return true
          })
            if(orders.length > 0)
              {
                this.saveOrders(orders)
              } 
          }
      }
    });
  }
  saveByReference(ref:string)
  {
    let orders: OrderResponse[] = []
    this.orderService.getOrderByReference(ref).subscribe({
      next: (res) => 
        {
          if(res.status.id == '0e1f1bd8-6a21-497e-9cb3-69490ef18752')
            {
              orders.push(res)
              this.saveOrders(orders)
            }
            else
            { 
              this.toastr.error("Invalid Status Type")
            }
        },
        error: (error) =>
        {
          this.toastr.error("Couldn't find Order by Reference.")
        } 
    })
  }
  //ORDER OPERATIONS
  //Starts Process of picking up all orders from the selected clinic
  pickUp(clinic:any)
  {
    const changeId = 'e8ca869c-5af6-4b2f-99d9-b933eedac491'//recommended to have value configurable within settings
    this.clinic = clinic
    this.getPlaceData(this.schedule.vehicle.id,this.schedule.vehicle.name,'Vehicle',changeId,true)
  }
  completeSchedule()
  {
    //TODO requires dialog for where to put the orders
    const changeId = 'fc324917-7bf4-4a61-8f0a-9ae6eaaeb4b4'//needs a status selection for in freezer or
    const emptyOrders = this.clinics.every(clinic => this.groupedOrders[clinic.id].length === 0)
    if(emptyOrders)
      {
        const res = this.vehicleOrders.every(order => order.dropOffLocationId === this.vehicleOrders[0].dropOffLocationId)
        if(res)
          {
            this.getPlaceData(this.vehicleOrders[0].dropOffLocationId,'End Point','Custom',changeId,false)
          } 
          else 
          {
            this.toastr.error(`Schedule Drop off Location is not all the same`)
          }
      }
      else
      {
        this.toastr.error('Not all Clinic Orders are in the Vehicle.')
      }
  }
  getPlaceData(objId:string,name:string,type:string,statusId:string,operation:boolean)
  {
    const data: GetOrCreatePlaceRequest = 
    {
      name:name,
      objectId:objId,
      type:type
    }
  this.placeService.getOrCreatePlace(data).subscribe({          
    next: (res) => {
      this.transferOrders(res,statusId,operation)
    }, 
    error: (err) => 
    {
      this.toastr.error('Failed to Create place.',err.message)
    }

  })
  }
  transferOrders(place:PlaceResponse,statusId:string,pickUp:boolean)
  {
      let orders:ScheduleOrderResponse[] = []
      const requests: Observable<any>[] = [];
      if(pickUp)
        {
          orders = this.groupedOrders[this.clinic.id]
        }
        else
        {
          orders = this.vehicleOrders
        }
      orders.forEach(order => 
        {
          const newOrderStatus: ChangeOrderStatusRequest = 
          {
            statusId: statusId,
            toPlaceId:place.id,
            comment: "Order Transfered"
          }
          requests.push(this.orderService.changeOrderStatus(order.id,newOrderStatus).pipe(catchError(error => {
            this.toastr.error(order.reference,'Pick Up Failed.')
            return of(null)
          })))
      })
      if(requests.length > 0)
        {
          forkJoin(requests).subscribe({
            next: (res) => 
              {
                this.isLoading = true
                this.getData(this.schedule.id)
              },
            error: (error) => 
              {
                this.toastr.error(`${error}`, "Error");
              }
          })
        }
  }
  toggleCard() {
    this.isExpanded = !this.isExpanded;
  }
  onTabChange(event: any) 
  {

    this.selectedTab = event.index
  }
  onClinicMenuClick(index:number)
  {
    this.selectedTabIndex = index
    this.tabGroup.selectedIndex = index
  }
  //TAB PAGINATION
  //Clinic Pageination
  //testing
  // onTabChange(event: any) {
  //   // console.log('hit tab change',event.index)
  //   // const currentIndex = event.index;

  //   // if (currentIndex > this.previousIndex) {
  //   //   this.tabAnimationState = 'exit'; // Slide out to the right
  //   // } else {
  //   //   this.tabAnimationState = 'enter'; // Slide in from the left
  //   // }

  //   // this.previousIndex = currentIndex;
  //   // setTimeout(() => {
  //   //   this.tabAnimationState = 'enter'; // Reset state for next animation
  //   // }, 300); // Match the duration of your animation
  // }
  //C
  // selectedIndex = 0;
  // //tabAnimationState = 'enter';
  // toggleAnimation() {
  //   // Toggle between hidden and visible states
  //   //this.tabAnimationState  = this.tabAnimationState  === 'hidden' ? 'visible' : 'hidden';
  // }
  // selectTab(index: number) {
  //   this.selectedIndex = index;
  // }
  // manualTabChange(index: number) {
  //   this.selectedIndex = index;
  // }

  //

  //CURRENTLY not working because of manual layout

  //Touch Pageination
  // onTouchStart(event: TouchEvent) {
  //   event.stopPropagation();
  //   this.touchStartX = event.changedTouches[0].screenX;
  // }

  // // Capture the ending X position of the touch
  // onTouchEnd(event: TouchEvent) {
  //   event.stopPropagation();
  //   this.touchEndX = event.changedTouches[0].screenX;
  //   this.handleSwipeGesture();
  // }
  // handleSwipeGesture() {
  //   const swipeDistance = this.touchEndX - this.touchStartX;

  //   if (swipeDistance > this.swipeThreshold) {
  //     // Swiped right (go to the previous tab)
  //     this.swipeRight();
  //   } else if (swipeDistance < -this.swipeThreshold) {
  //     // Swiped left (go to the next tab)
  //     this.swipeLeft();
  //   }
  // }
  // //if doesnt work correctly on mobile need to inverse methods
  // swipeLeft() {
  //   if(this.tabGroup.selectedIndex)
  //     {
  //       if (this.tabGroup.selectedIndex > 0) {
  //         this.tabGroup.selectedIndex -= 1;
  //       }
  //     }

  // }
  // swipeRight() {
  //   if(this.tabGroup.selectedIndex != null)
  //   {
  //     if (this.tabGroup.selectedIndex < this.tabGroup._tabs.length - 1) {
  //       this.tabGroup.selectedIndex += 1;
  //     }
  //     if(this.tabGroup.selectedIndex == 0)
  //       {
  //         this.tabGroup.selectedIndex += 1
  //       }
  //   }
  // }
}
