import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, Renderer2, ViewChildren, signal } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { ActionListComponent } from '../action-list/action-list.component';
import { AuthService } from '../../../services/auth.service';
import { RouteConfigService } from '../../../services/route-config.service';
import { environment } from '../../../../environments/environment';

export type MenuItem = {
  icon: string;
  label: string;
  route?: string;
}

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, MatListModule, MatIconModule, RouterLink, RouterLinkActive, MatMenuModule, MatExpansionModule, RouterModule, ActionListComponent],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit, AfterViewInit{
  sideNavCollapsed = signal(false);
  activePanel: string = '';
  routes: any[] = [];

  @Output() expandSideBar = new EventEmitter<void>();
  @ViewChildren(MatExpansionPanel) panels!: QueryList<MatExpansionPanel>;
  @Input() set collapsed(val: boolean) {
    this.sideNavCollapsed.set(val);
    this.updatePanelStates();
  }
  @Input() isHandset: boolean = false;
  @Input() isTablet: boolean = false;
  
  constructor(private router: Router,
    private authSerivce: AuthService, 
    private routeService: RouteConfigService, 
    private renderer: Renderer2, 
    private el: ElementRef
  ){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setActivePanel(event.urlAfterRedirects);
        this.updatePanelStates();
      }
    });

    this.authSerivce.claims$.subscribe(claims => {
      const role = claims['extension_Role'];
      if(role) {
        this.routes = this.routeService.getRoutesBasedOnUserRole(role);
      }
    })
  }

  private setActivePanel(url: string) {
    if (url.startsWith('/orders')) {
      this.activePanel = 'panelOrders';
    } else if (url.startsWith('/run')) {
      this.activePanel = 'panelRun';
    } else if (url.startsWith('/clinics')) {
      this.activePanel = 'panelClinics';
    } else if (url.startsWith('/configurations')) {
      this.activePanel = 'panelConfigs';
    } else if (url.startsWith('/schedules')) {
      this.activePanel = 'panelSchedules';
    } else if (url.startsWith('/settings')) {
      this.activePanel = 'panelSettings';
    } else {
      this.activePanel = '';
    }
  }

  ngOnInit(): void {
    //apply css when it is not in production
    const sidebarElement = this.el.nativeElement.querySelector('.sidebar');
    if (sidebarElement) {
      if (!environment.production) {
        this.renderer.addClass(sidebarElement, 'non-production-env');
      }
    }
  }

  ngAfterViewInit() {
    Promise.resolve().then(() => this.updatePanelStates());
  }

  updatePanelStates() {
    if(this.sideNavCollapsed() && !this.isHandset) {
      this.panels.forEach(panel => panel.expanded = false);
    }
  }

  handleExpansionPanelClick() {
    if(this.sideNavCollapsed()) {
      this.expandSideBar.emit();
    }
  }
}
