import { CommonModule, DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SearchRoutesComponent } from '../../routes/search-routes/search-routes.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SearchVehicleComponent } from '../../vehicle/search-vehicle/search-vehicle.component';
import { UserSearchComponent } from '../../users/user-search/user-search.component';
import { SchedulesService } from '../../../services/schedules.service';
import { ToastrService } from 'ngx-toastr';
import { CreateSchedulesComponent } from '../create-schedules/create-schedules.component';
import { dateLessThanTodayValidator } from '../../../helpers/validator';
import { ScheduleResponse, UpdateScheduleRequest } from '../../../models/schedule.model';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { OrderResponse } from '../../../models/orders/order.model';
import { SearchOrdersComponent } from '../../orders/search-orders/search-orders.component';
import { OrderService } from '../../../services/order.service';

@Component({
  selector: 'app-edit-schedules',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDialogContent,
    MatDialogTitle,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatIconModule,
    MatDialogClose,
    MatDialogActions,
    MatTooltipModule,
    MatTabsModule,
    MatStepperModule,
    SearchRoutesComponent,
    MatDatepickerModule,
    SearchVehicleComponent,
    UserSearchComponent,
    SearchOrdersComponent
  ],
  templateUrl: './edit-schedules.component.html',
  styleUrl: './edit-schedules.component.scss',
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB',
      
    },
    provideNativeDateAdapter(),
    DatePipe
  ],
})
export class EditSchedulesComponent implements OnInit{
  isSaving:boolean = false
  constructor(    
    private ScheduleService:SchedulesService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private OrderService: OrderService,
    public dialogRef: MatDialogRef<EditSchedulesComponent>,
    private  datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data:ScheduleResponse
  )
  {
    //this.driverControl.addValidators(Validators.required)//not required
    this.routeControl.addValidators(Validators.required)
    //this.vehicleControl.addValidators(Validators.required)//not required
    this.dateControl.addValidators([Validators.required,dateLessThanTodayValidator()])
  }
  routeControl = new FormControl<any>(this.data.route)
  routeFormGroup = this.fb.group({
    route: this.routeControl,
  });
  vehicleControl = new FormControl<any>(this.data.vehicle ?? null)
  driverControl = new FormControl<any>(this.data.driver ?? null)
  
  vehicleFormGroup = this.fb.group({
    vehicle: this.vehicleControl,
    driver: this.driverControl
  });
  //this.data.orders
  orderControl = new FormControl()
  orderFormGroup = this.fb.group({
    orders: this.orderControl
  });

  dateControl = new FormControl(this.data.scheduleDate)
  dateFormGroup = this.fb.group({
    date: this.dateControl
  });

  //Order Table
  orders: OrderResponse[] = []
  displayedColumns: string[] = ['reference', 'name', 'weight', 'animaltype'];
  dataSource: MatTableDataSource<OrderResponse> = new MatTableDataSource<OrderResponse>;
  isLoading = false
  ngOnInit(): void {
    this.isLoading=true
    this.getOrderData()
  }

  getOrderData()
  {
    console.log(this.data)
    const numOrders = this.data.orders.length
    let count = 0
    if(numOrders >= 0)
      {
        this.isLoading = false
      }
    this.data.orders.forEach(order =>{
      this.OrderService.getOrder(order.id).subscribe(
        {next: (res) => 
        { 
        this.orders.push(res)
        count ++     
        if(count === numOrders)
          {
            this.dataSource.data = this.orders
            this.isLoading = false
          }
        }
        ,error: (error) => {
          console.error('failed to get order.')
          this.isLoading = false
        }})
    })

    console.log('end')
  }
  orderSearch()
  {
    const dialogRef = this.dialog.open(SearchOrdersComponent,{
      width: '950px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('result ',result.selectedOrders
      )
      if(result.selectedOrders)
        {
          result.selectedOrders.forEach((order:OrderResponse) =>{
            this.orders.push(order)
          })
          this.dataSource.data = this.orders
        }
    });
  }

  onSubmit()
  {
    this.isSaving = true
    const vehicleFormdata = this.vehicleFormGroup.value;
    const routeFormdata = this.routeFormGroup.value;
    //const orderFormdata = this.orderFormGroup.value;
    const orderData = this.orders.map(order=> order.id)
    const dateFormdata = this.dateFormGroup.value;
    console.log('formValues ',this.vehicleFormGroup.value)
    const date = this.datePipe.transform(dateFormdata.date, 'yyyy-MM-dd')
    if(date)
      {
        const schedule: UpdateScheduleRequest = {
          route: routeFormdata.route?.id ?? null,
          vehicle: vehicleFormdata.vehicle?.id ?? null,
          driver: vehicleFormdata.driver?.id ?? null,
          orders: orderData,
          scheduleDate: date
        }
        console.log('updating schedule', schedule)
        this.ScheduleService.updateSchedule(this.data.id,schedule).subscribe({
          next: (res) => {
            this.toastr.success('Schedule updated successfully.');
            this.isSaving = false
            this.dialogRef.close()
          },
          error: (error) => {
            this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
            this.isSaving = false
          }
        })
    }else
    {
      this.toastr.error('Date is wrong. Please try again.');
    }
  }
  //error handling
  getRouteErrorMessage():string
  {

    const control = this.routeFormGroup.get('route');
    if (control?.hasError('required')) {
      return 'Route is required';
    }
    return 'Route Unknown Error';
  }
  getVehicleErrorMessage():string
  {
    const driverControl = this.vehicleFormGroup.get('driver');
    if (driverControl?.hasError('required')) {
      return 'Driver is required';
    }
    const vehicleControl = this.vehicleFormGroup.get('vehicle');
    if (vehicleControl?.hasError('required')) {
      return 'Vehicle is required';
    }
    return 'Unknown Error'
  }
  getOrdersErrorMessage():string
  {
    return 'Order Unknown Error'
  }
  getDateErrorMessage():string
  {
    const control = this.dateFormGroup.get('date');
    if (control?.hasError('required')) {
      return 'Date is required';
    }
    if (control?.hasError('dateLessThanToday')) {
      return 'Date is less than current date';
    }
    return 'Date Unknown Error'
  }
}
