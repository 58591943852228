import { CommonModule } from '@angular/common';
import { Component,OnInit,Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ToastrService } from 'ngx-toastr';
import { EditRequirementRequest, StatusRequirementResponse } from '../../../../models/orders/order.model';
import { OrderService } from '../../../../services/order.service';
import { CreateRequirementComponent } from '../create-requirement/create-requirement.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-requirement',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    CommonModule,
    MatDividerModule,
    MatCheckboxModule
  ],
  templateUrl: './edit-requirement.component.html',
  styleUrl: './edit-requirement.component.scss'
})
export class EditRequirementComponent implements OnInit {
  nameControl= new FormControl(this.requirement.name);
  descriptionControl= new FormControl(this.requirement.description);
  photoRequiredControl= new FormControl(this.requirement.photoRequired);
  isSaving:boolean = false;
  editRequirementForm:FormGroup = new FormGroup({
    nameControl: this.nameControl,
    descriptionControl: this.descriptionControl,
    photoRequiredControl: this.photoRequiredControl
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public requirement: StatusRequirementResponse,
    private orderService:OrderService,
    private dialogRef: MatDialogRef<CreateRequirementComponent>,
    private toastr:ToastrService
  ) {}
  ngOnInit(): void {
  }

  onSubmit():void {
    this.isSaving = true;
    const formData = this.editRequirementForm.value;
    const request = new EditRequirementRequest(
      formData.nameControl,
      formData.descriptionControl,
      formData.photoRequiredControl
    );
    this.orderService.editRequirement(this.requirement.id,request).subscribe({
      next: () => {
        this.isSaving = false;
        this.toastr.success("Saved requirement","Success");
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.isSaving = false;
        this.toastr.error(error.error,"Error editing requirement");
      }
    });
  }
}
