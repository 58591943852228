import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ContactResponse, PreferredContactMethod } from '../../../models/contact.model';
import { ContactNewDialogComponent } from '../../../components/contacts/contact-new-dialog/contact-new-dialog.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ContactEditDialogComponent } from '../../../components/contacts/contact-edit-dialog/contact-edit-dialog.component';
import { DeleteDialogComponent } from '../../../components/delete-dialog/delete-dialog.component';
import { ContactService } from '../../../services/contact.service';
import { ToastrService } from 'ngx-toastr';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from '../../../services/auth.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { fadeIn, invisTofadeIn } from '../../../../assets/animations';

@Component({
  selector: 'app-contact-index',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSortModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './contact-index.component.html',
  styleUrl: './contact-index.component.scss',
  animations: [invisTofadeIn]
})

export class ContactIndexComponent implements OnInit, AfterViewInit {
  form!: FormGroup;
  role: string = '';
  isAdmin: boolean = false;
  isLoading: boolean = true;
  displayedColumnsContacts: string[] = ['id', 'firstName', 'lastName', 'email', 'phone'];
  dataSourceContacts = new MatTableDataSource<ContactResponse>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  constructor(
    public dialog: MatDialog, 
    private contactService: ContactService, 
    private toastr: ToastrService, 
    private breakpointObserver: BreakpointObserver,
    private loginService: AuthService
  ) { 
    this.loginService.claims$.subscribe((c) => {
      this.role = c['extension_Role'];
    });
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      if (result.matches) {
        this.displayedColumnsContacts = ['firstName', 'lastName', 'email', 'phone']; // MOBILE DISPLAYS
      } else {
        this.displayedColumnsContacts = ['firstName', 'lastName', 'email', 'phone']; // DESKTOP DISPLAYS
      }

      if(this.role == 'globeadmin' || this.role == 'awladmin') {
        this.displayedColumnsContacts.push('actions');
        this.isAdmin = true;
      }
    });
  }

  ngAfterViewInit() {
    this.getData();
  }

  getData() {
    this.contactService.getContacts().subscribe({
      next: (res) => {
        this.dataSourceContacts.data = res.contacts;
        this.dataSourceContacts.paginator = this.paginator;
        this.dataSourceContacts.sort = this.sort;
        this.isLoading = false;
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
      }
    })  
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceContacts.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceContacts.paginator) {
      this.dataSourceContacts.paginator.firstPage();
    }
  }

  //dialogs
  openAddContactDialog() {
    const dialogRef = this.dialog.open(ContactNewDialogComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.contactService.createContact(result).subscribe({
          next: (res) => {
            this.toastr.success('Contact created successfully.');
            this.getData();
          },
          error: (error) => {
            this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
          }
        });
      }
    })
  }

  openEditContactDialog(element: ContactResponse) {
    const dialogRef = this.dialog.open(ContactEditDialogComponent, {
      width: '500px',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      this.contactService.updateContact(element.id, result).subscribe({
        next: (res) => {
          this.toastr.success('Contact updated successfully.');
          this.getData();
        },
        error: (error) => {
          this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
        }
      });
      }
    })
  }

  openDeleteDialog(element: ContactResponse) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: { type: "contact", name: element.email }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.contactService.deleteContact(element.id).subscribe({
          next: (res) => {
            this.toastr.success('Contact deleted successfully.');
            this.getData();
          },
          error: (error) => {
            if(error.status == '409') {
              this.toastr.error('This contact cannot be deleted because it is associated with other entities, such as a clinic.');
            } else {
              this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
            }
          }
        });
      }
    })
  }
}
