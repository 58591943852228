import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { UpdateVehicleRequest, VehicleResponse } from '../../../models/vechicle.model';

@Component({
  selector: 'app-edit-vehicle',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    MatSlideToggle
  ],
  templateUrl: './edit-vehicle.component.html',
  styleUrl: './edit-vehicle.component.scss'
})
export class EditVehicleComponent {
  form!:FormGroup;

  constructor(
    public dialogRef: MatDialogRef<EditVehicleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VehicleResponse, 
    private fb: FormBuilder
  ){}

  ngOnInit(): void {
    this.form = this.fb.group({
      registration: [this.data.registration,[Validators.required]],
      isAvailable: [this.data.isAvailable],
      make:[this.data.make],
      model:[this.data.model],
      name: [this.data.name],
    });
  }
  onSubmit()
  {
    if(this.form.valid) {
      const vehicle: UpdateVehicleRequest = {
        registration: this.form.value.registration,
        isAvailable: this.form.value.isAvailable,
        make: this.form.value.make,
        model: this.form.value.model,
        name: this.form.value.name,
      }
      this.dialogRef.close(vehicle)
    }
  }

}
