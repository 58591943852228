import { Component, Inject, OnInit, Renderer2, ViewChild, computed, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenav, MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { BreakpointObserver, Breakpoints, LayoutModule } from '@angular/cdk/layout';
import { MatExpansionModule } from '@angular/material/expansion';
import { SidebarComponent } from '../components/sidebar/sidebar/sidebar.component';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { Subject } from 'rxjs';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-authed-layout',
  standalone: true,
  imports: [CommonModule, RouterOutlet, MatToolbarModule, MatSidenavModule, MatIconModule, MatButtonModule, MatListModule, LayoutModule, 
    MatExpansionModule, SidebarComponent, MatMenuModule, RouterLink, HttpClientModule, MsalModule],
  templateUrl: './authed-layout.component.html',
  styleUrl: './authed-layout.component.scss'
})
export class AuthedLayoutComponent implements OnInit{
  title = 'Five';
  color: string = '';
  @ViewChild('sidenav') sidenav!: MatSidenav;
  isHandset = signal(false);
  isTablet = signal(false);
  isCollapsed = signal(false);

  //Auth
  env: string = '';
  isLoggedIn: boolean = false;
  claims: { [key: string]: any } = {};
  role: string = '';
  private readonly _destroying$ = new Subject<void>();

  constructor(private breakpointObserver: BreakpointObserver, private router: Router,
    //Auth
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, 
    private authService: MsalService, 
    private loginService: AuthService,
    private renderer: Renderer2
  ) {
    this.isLoggedIn = this.loginService.isLoggedIn;
    this.loginService.claims$.subscribe((c) => {
      this.claims = c;
      this.role = this.claims['extension_Role'];
    });
  }

  sidenavWidth = computed(() => {
    if (this.isHandset()) {
      return this.isCollapsed() ? '0px' : '100%';
    } else {
      return this.isCollapsed() ? '65px' : '250px';
    }
  });

  ngOnInit(): void {
    this.breakpointObserver
      .observe([
        Breakpoints.HandsetPortrait,
        Breakpoints.TabletPortrait,
      ])
      .subscribe((state) => {
        this.isHandset.set(state.breakpoints[Breakpoints.HandsetPortrait]);
        this.isTablet.set(state.breakpoints[Breakpoints.TabletPortrait]);
        this.isCollapsed.set(this.isHandset() || this.isTablet());
      });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.isHandset() && !this.isCollapsed()) {
          this.closeSidenav();
        } 
      }
    });

    this.env = environment.env;
    const envColorMap: { [key: string]: string } = {
      'Production': 'primary',
      'Development': 'warn',
      'UAT': 'accent'
    };
    
    this.color = envColorMap[this.env];

    //hide banner if production
    const sidenavContainer = document.querySelector('mat-sidenav-container');
    if (sidenavContainer) {
      if (!environment.production) {
        this.renderer.addClass(sidenavContainer, 'non-production-env');
      }
    }
  }

  expandSidebar() {
    this.isCollapsed.set(false);
  }

  toggleSideBar(): void {
    this.isCollapsed.set(!this.isCollapsed());
  }

  contentClick() {
  if(this.isTablet() && !this.isCollapsed())
      this.toggleSideBar();
  }

  closeSidenav() {
    this.sidenav.close().then(() => {
      this.isCollapsed.set(true);
      this.sidenav.opened = true; // Keep the opened property true to maintain sidenav open status logically
    });
  }

  //Msal auth
  logout() { 
    this.authService.logoutRedirect({
      postLogoutRedirectUri: '/'
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}