import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
  standalone: true
})
export class HighlightPipe implements PipeTransform {
  transform(text: string, search: string): string {
    if (!search) {
      return text;
    }
    // Escape special regex characters in the search term
    const pattern = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const regex = new RegExp(pattern, 'gi');
    return text.replace(regex, match => `<span class="highlight">${match}</span>`);
  }
}
