<!--MULTIPLE ORDERS STYLE-->
<!-- <div>
    <mat-selection-list #orders>
        @for(ref of orderReferences; track ref)
        {
            <mat-list-option>
                {{ref}}
            </mat-list-option>
        }
    </mat-selection-list>
</div> -->
<!--SINGLE-->
<mat-nav-list *ngIf="!manualAddingOrder && referenceResult">
    <mat-list-item>
        <mat-icon matListItemIcon class="icon">qr_code_2</mat-icon>
        <span matListItemTitle>{{referenceResult}}</span>
    </mat-list-item>
    <a mat-list-item (click)="qrAddingOrder()">
        <mat-icon matListItemIcon class="icon">add_circle_outlined</mat-icon>
        <span matLine>Add to Orders</span>
    </a>
    <a mat-list-item (click)="resetRef()">
        <mat-icon matListItemIcon color="warn" class="icon">redo</mat-icon>
        <span matLine>Remove and Try again</span>
    </a>
</mat-nav-list>
<mat-nav-list *ngIf="manualAddingOrder">
    <mat-form-field appearance="fill" class="w-100">
        <mat-label>Order Reference</mat-label>
        <input matInput
        [formControl]="orderReference"
        required>
    </mat-form-field>
    <a mat-list-item (click)="manualAddOrder()" [disabled]="orderReference.invalid">
        <mat-icon matListItemIcon class="icon">add</mat-icon>
        <span matListItemTitle>Add to the list</span>
    </a>
    <a mat-list-item (click)="toggleManualAdd()">
        <mat-icon matListItemIcon color="warn" class="icon">redo</mat-icon>
        <span matLine>Go Back</span>
    </a>
</mat-nav-list>
<mat-nav-list *ngIf="!referenceResult && !manualAddingOrder">
    <a mat-list-item (click)="openOrdersDialog()">
        <mat-icon matListItemIcon class="icon">search</mat-icon>
        <div matListItemTitle>Add by Order Search</div>
    </a>
    <a mat-list-item (click)="openScannerDialog()">
        <mat-icon matListItemIcon class="icon">qr_code_2</mat-icon>
        <span matListItemTitle>Add by QR Scan</span>
    </a>
    <a mat-list-item (click)="toggleManualAdd()">
        <mat-icon matListItemIcon class="icon">badge</mat-icon>
        <span matListItemTitle>Add by Reference Id</span>
    </a>
</mat-nav-list>
