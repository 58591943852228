<mat-card *ngIf="route">
    <mat-card-header class="route-card-header">
        <mat-card-title class="route-title">
          <h2 mat-title *ngIf="!isEditing">{{ route.name }}</h2>
          <mat-form-field *ngIf="isEditing" appearance="outline" >
            <input matInput [(ngModel)]="editedRouteName" (blur)="saveRouteName()" (keyup.enter)="saveRouteName()">
          </mat-form-field>
        </mat-card-title>
        <button mat-stroked-button (click)="toggleEdit()">{{ isEditing ? 'Save' : 'Edit' }}</button>
      </mat-card-header>
    <mat-card-content>
        <h3 mat-title>Origin</h3>
        <div class="field-div">
            <p *ngIf="route.origin">{{route.origin.address.street}}, {{route.origin.address.city}}</p>
            <!-- <p *ngIf="!route.origin">Not set</p> -->
            <button mat-stroked-button color="primary" (click)="openLocationDialog('origin')" type="button">Set
                Origin</button>
        </div>
        <h3 mat-title>Destination</h3>
        <div class="field-div">
            <p *ngIf="route.destination">{{route.origin.address.street}}, {{route.origin.address.city}}</p>
            <!-- <p *ngIf="!route.destination">Not set</p> -->
            <button mat-stroked-button color="primary" (click)="openLocationDialog('destination')" type="button">Set
                Destination</button>
        </div>
        <div class="route-card-header w-full">
            <mat-card-title class="route-title">
                <h3 mat-title>Clinics</h3>
            </mat-card-title>
            <button mat-stroked-button (click)="openClinicsDialog()">Edit</button>
        </div>
        <div class="w-full">
            <div *ngFor="let clinic of route.clinics" class="field-div">
                <p>{{ clinic.name }}</p>
            </div>
        </div>
    </mat-card-content>
</mat-card>