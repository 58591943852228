import { CommonModule } from '@angular/common';
import { Component, AfterViewInit, ViewChild, ElementRef, Input, Optional, Host } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { ScheduleInteractionService } from '../../../services/schedule-interaction.service';
import { Location } from '../../../models/schedule.model';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-schedule-route-map',
  standalone: true,
  imports: [
    MatIconModule,
    MatCardModule,
    CommonModule,
  ],
  templateUrl: './schedule-route-map.component.html',
  styleUrls: ['./schedule-route-map.component.scss']
})
export class ScheduleRouteMapComponent implements AfterViewInit {

  constructor(
    private scheduleInteractionService: ScheduleInteractionService
  ) { }

  // Get a reference to the map container using ViewChild
  @ViewChild('mapRef', { static: false }) mapRef!: ElementRef<HTMLDivElement>;
  map: google.maps.Map | undefined;

  @Input() tabGroup: MatTabGroup;

  @Input() locations: Location[] = [];
  @Input() directions: google.maps.DirectionsResult | undefined;

  selectedStopId: string | null = null;

  //directionsService = new google.maps.DirectionsService();
  directionsRenderer: google.maps.DirectionsRenderer | undefined;
  markers: { [locationId: string]: google.maps.marker.AdvancedMarkerElement } = {};

  async ngAfterViewInit(): Promise<void> {
    await this.loadMap();

    this.scheduleInteractionService.selectedStop$.subscribe((stopId) => {
      this.selectStop(stopId ?? "");
    });
    if (this.tabGroup) {
      this.tabGroup.selectedTabChange.subscribe(() => {
        this.reRenderMapAndMarkers();
      });
    }
  }

  async loadMap(): Promise<void> {
    if (!this.locations.length) return;

    // Use the ViewChild reference to get the map element
    const mapElement = this.mapRef.nativeElement;
    if (!mapElement) {
      console.error('Map element not found');
      return;
    }

    // Initialize the map
    this.map = new google.maps.Map(mapElement, {
      center: { lat: this.locations[0].lat, lng: this.locations[0].lng },
      zoom: 12,
      mapId: "a3018be7cfd38215",
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      cameraControl: false,
    });

    this.directionsRenderer = new google.maps.DirectionsRenderer({
      map: this.map,
      polylineOptions: { strokeColor: '#8ac220', strokeWeight: 5 },
      suppressMarkers: true,
    });

    if (this.directions) {
      console.log(this.directions);
      try{
        this.directionsRenderer.setDirections(this.directions);
      }
      catch(e){
        console.error('Error setting directions', e);
      }
    }

    this.addMarkers();
  }

  markLocationVisited(locationId: string): void {
    const location = this.locations.find(l => l.id === locationId);
    if (!location) return;

    location.visited = true;

    // Update the marker icon
    const marker = this.markers[locationId];
    if (marker) {
      marker.title = `Visited: ${marker.title}`;
      marker.content = this.createIcon('home');
    }
  }


  selectStop(stopId: string): void {
    this.selectedStopId = stopId;
    const marker = this.markers[stopId];
    if (marker && this.map) {
      // Pan the map to the selected marker
      this.map.panTo(marker.position as google.maps.LatLng);
    }
  }

  createIcon(type: string): Node {
    const icon = document.createElement('div');
    icon.style.width = '32px';
    icon.style.height = '32px';
    icon.style.backgroundImage = 'url(assets/svg/google_' + type + '_icon.svg)';
    icon.style.backgroundSize = 'contain';
    icon.style.backgroundColor = 'rgba(48, 68, 10, 1)';
    icon.style.borderRadius = '50%';
    icon.style.backgroundSize = '80%';
    icon.style.backgroundPosition = 'center';
    return icon;
  }

  async reRenderMapAndMarkers(): Promise<void> {
    if (this.map) {
      google.maps.event.trigger(this.map, 'resize');
      this.clearMarkers();
      await this.addMarkers(); // Re-add markers
      if (this.directionsRenderer && this.directions) {
        this.directionsRenderer.setDirections(this.directions);
      }
    }
  }

  async addMarkers() {
    // Import the marker library to access AdvancedMarkerElement
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

    // Add a marker for each location using AdvancedMarkerElement
    this.locations.forEach(location => {
      let icon_name = location.type === 'clinic'
        ? 'home'
        : location.type === 'order'
          ? 'location_pin'
          : 'location_pin';
      const marker = new AdvancedMarkerElement({
        map: this.map,
        position: { lat: location.lat, lng: location.lng },
        title: location.type === 'clinic' ? 'Clinic Visit' : location.type === 'order' ? 'Order Location' : 'Home - OpSys',
        content: this.createIcon(icon_name),
      });

      // Store the marker reference
      this.markers[location.id] = marker;
      marker.addListener('gmp-click', () => {
        this.scheduleInteractionService.selectStop(location.id);
      });
    });
  }

  clearMarkers() {
    Object.values(this.markers).forEach(marker => {
      marker.map = null;
    });
    this.markers = {};
  }

}
