import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ContactResponse, CreateContactRequest } from '../models/contact.model';
import { Observable } from 'rxjs';

interface PagedQueryResult<T> {
  page: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
  contacts: T[];
}

interface ContactQueryParams {
  sortBy?: string;
  page?: number;
  pageSize?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  baseUrl = `${environment.apiUrl}/api/contacts`;
  constructor(private http: HttpClient) { }

  getContacts(params?: ContactQueryParams): Observable<PagedQueryResult<ContactResponse>> {
    let httpParams = new HttpParams();
    
    if (params?.sortBy) httpParams = httpParams.set('SortBy', params.sortBy);
    if (params?.page != null) httpParams = httpParams.set('Page', (params.page + 1).toString());
    if (params?.pageSize != null) httpParams = httpParams.set('PageSize', params.pageSize.toString());

    return this.http.get<PagedQueryResult<ContactResponse>>(`${this.baseUrl}`, { params: httpParams });
  }

  getContact(id: string): Observable<ContactResponse> {
    return this.http.get<ContactResponse>(`${this.baseUrl}/${id}`);
  }

  createContact(contactData: CreateContactRequest): Observable<ContactResponse> {
    return this.http.post<ContactResponse>(`${this.baseUrl}`, contactData);
  }

  updateContact(id: string, data: ContactResponse): Observable<ContactResponse> {
    return this.http.put<ContactResponse>(`${this.baseUrl}/${id}`, data);
  }

  deleteContact(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${id}`);
  }
}
