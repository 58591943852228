import { AfterViewInit, Component, OnInit, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { HighlightPipe  } from '../../../pipes/highlight.pipe';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table'; 
import { MatSort, MatSortModule}  from '@angular/material/sort';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card'; 
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { OrderDetailsDialogComponent } from '../../../components/orders/order-details-dialog/order-details-dialog.component';
import {
  MatDialog,
} from '@angular/material/dialog';
import { OrderService } from '../../../services/order.service';
import { OrderResponse, OrderStatusResponse } from '../../../models/orders/order.model';
import { CommonModule } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import { ToastrService } from 'ngx-toastr';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import moment from 'moment';
import 'moment-timezone';  
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {invisTofadeIn } from '../../../../assets/animations';

@Component({
  selector: 'app-orders-index',
  standalone: true,
  imports: [
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatButtonModule,
    RouterLink,
    MatSortModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    CommonModule,
    QRCodeModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    HighlightPipe
  ],
  templateUrl: './orders-index.component.html',
  styleUrl: './orders-index.component.scss',
  providers: [],
  animations: [invisTofadeIn]
})

export class OrdersIndexComponent implements AfterViewInit {
  orders!: OrderResponse[];
  selectedView: string = '1';
  statuses: OrderStatusResponse[] = [];
  refreshing:boolean = true;
  searchError:boolean = false;

  displayedColumns: string[] = ['select', 'referenceNumber', 'clinic', 'animalName', 'weight', 'cremationType', 'orderStatus', 'createdAt', 'qrCode', 'action'];
  dataSource = new MatTableDataSource<OrderResponse>();
  selection = new SelectionModel<OrderResponse>(true, []);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  //users Clinic sorting
  usersClinic:string = ''
  constructor(
    public dialog: MatDialog,
    private orderService: OrderService,
    private toastr: ToastrService,
    private breakpointObserver: BreakpointObserver,
    )
  {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe((result: { matches: any; }) => {
      if (result.matches) {
        this.displayedColumns = ['referenceNumber', 'clinic', 'animalName','orderStatus', 'action'];
      } else {
        this.displayedColumns = ['select', 'referenceNumber', 'clinic', 'animalName', 'weight', 'cremationType', 'orderStatus', 'createdAt', 'qrCode', 'action'];
      }
    });
  }

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.refreshing = false;
    this.dataSource.sortingDataAccessor = (item, property) => {
      let value;
      switch (property) {
        case 'referenceNumber':
          value = item.referenceNumber;
          break;
        case 'clinic':
          value = item.clinic?.name || '';
          break;
        case 'animalName':
          value = item.animalName;
          break;
        case 'weight':
          value = item.weight;
          break;
        case 'cremationType':
          value = item.cremationType;
          break;
        case 'orderStatus':
          value = item.status?.name || '';
          break;
        case 'createdAt':
          value = new Date(item.createdAt);
          break;
        default:
          value = (item as any)[property];
      }
      return value;
    };

    this.dataSource.filterPredicate = (data: OrderResponse, filter: string): boolean => {
      const dataStr = (
        data.referenceNumber +
        ' ' +
        (data.clinic?.name || '') +
        ' ' +
        data.animalName +
        ' ' +
        data.weight +
        ' ' +
        data.cremationType +
        ' ' +
        (data.status?.name || '')
      ).toLowerCase();
      return dataStr.indexOf(filter.trim().toLowerCase()) !== -1;
    };
    
    this.getData();
    if(this.sort && this.sort.sortChange)
    {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    }
    this.dataSource.sort = this.sort;
  }
  


  getData() {
    this.refreshing = true;
    this.searchError = false;
    this.orderService.getOrders().subscribe({
      next: (res: { orders: any[]; }) => {
        if(this.usersClinic)
          {
            var filteredOrders = res.orders.filter((order: { clinic: { id: any; }; }) => order.clinic.id === this.usersClinic);
            this.dataSource.data = filteredOrders;
            this.orders = filteredOrders;
          } else
          {
            this.dataSource.data = res.orders;
            this.orders = res.orders;
          }
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.refreshing = false;
      },
      error: (error: { error: { title: any; }; }) => {
        this.refreshing = false;
        this.toastr.error(error.error?.title || 'An error occurred when getting the orders. Please try again.');
        this.searchError = true;
      }
    });

    this.orderService.getOrderStatuses().subscribe({
      next: (result: { items: any; }) => {
        this.statuses = result.items;
      },
      error: (error: { error: { title: any; }; }) => {
        this.toastr.error(error.error?.title || 'An error occurred when getting the order statuses. Please try again.');
      }
    })
  }

  //sort
  filterOrders(): void {
    if (this.selectedView === '1') {
      this.dataSource.data = this.orders;; // 'All' selected
    } else {
      const filteredData = this.orders.filter((order: { status: { id: any; }; }) =>
        order.status.id === this.selectedView
      );
      this.dataSource.data = filteredData;
    }
    
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  handleSortChange(event: any)
  {
    this.paginator.pageIndex = 0;
  }

  //select TODO: need to review how data is returned with _pageData when there is pagination query
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const currentPageSize = this.dataSource._pageData(this.dataSource.data).length; //this.dataSource.data.length;
    return numSelected === currentPageSize;
  }

  selectAll() {
    this.isAllSelected() ?
      this.selection.clear() :
      // this.dataSource.data.forEach(row => this.selection.select(row)); //for all data
      this.dataSource._pageData(this.dataSource.data).forEach((row: any) => this.selection.select(row));
  }

  formatTimestamp(timestamp: string): string {
    return moment.utc(timestamp).tz(moment.tz.guess()).format('DD-MM-YYYY hh:mm A');
  }
  
  openDialog(element: OrderResponse) {
    const dialog = this.dialog.open(OrderDetailsDialogComponent, {
      data: element
    });

    dialog.componentInstance.orderUpdated.subscribe(() => {
      this.getData();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  generateQrLink(reference: string) {
    return window.location.origin + `/orders/${reference}`;
  }
}

export interface View {
  value: string;
  viewValue: string;
}