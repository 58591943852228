<div class="container">
    <div class="header">
        <h2>Locations Configuration</h2>
    </div>
    <div class="flex-row">
        <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
        </mat-form-field>
        <button mat-raised-button color="primary" routerLink="/configurations/locations/create" class="add-btn" *ngIf="isAdmin">Add</button>
    </div>

    <!--LOADING SPINNER-->
    <div *ngIf="isLoading"  class="loading-container">
        <mat-spinner
        [diameter]="80"></mat-spinner>
    </div>
    <div [@invisTofadeIn]="isLoading ? 'hidden' : 'visible'">
        <div *ngIf="!isLoading" >
            <table mat-table [dataSource]="dataSourceLocations" matSort>
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> ID </th>
                    <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                </ng-container>
            
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                </ng-container>
            
                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef> Address </th>
                    <td mat-cell *matCellDef="let row">
                        {{ getFormattedUnit(row.address.unit) }} {{row.address.street}},  {{row.address.city}},  {{row.address.state}}  {{row.address.postCode}} 
                    </td>
                </ng-container>

                <ng-container matColumnDef="note">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Note </th>
                    <td mat-cell *matCellDef="let row"> {{row.note}} </td>
                </ng-container>
            
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions </th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="button-container">
                            <button mat-icon-button color="primary" class="edit-btn" matTooltip="Edit location"
                                [routerLink]="['/configurations/locations', element.id]">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button color="warn" matTooltip="Delete location"
                                (click)="openDeleteDialog(element)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </td>
                    </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>
            </table>
        </div>
    <mat-paginator [ngStyle]="{ 'display': isLoading ? 'none' : 'block' }" [pageSizeOptions]="[25, 50, 100]" aria-label="Select page of locations" showFirstLastButtons></mat-paginator>
    </div>
</div>