import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateUserRequest, UpdateUserRequest, UpdateUserRoleRequest, UserFromAzure, UserInDB } from '../models/user.model';
import { PagedQueryResult } from '../models/pagedQueryResult.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl = `${environment.apiUrl}/api/users`; 
  baseAzureUrl = `${environment.apiUrl}/api/users/azure`; 
  constructor(private http: HttpClient) { }
  
  getUsers(): Observable<PagedQueryResult<UserInDB>> {
    return this.http.get<PagedQueryResult<UserInDB>>(`${this.baseUrl}`);
  }

  getUser(id: string): Observable<UserInDB> {
    return this.http.get<UserInDB>(`${this.baseUrl}/${id}`);
  }
  
  getAzureUsers(): Observable<PagedQueryResult<UserFromAzure>> {
    return this.http.get<PagedQueryResult<UserFromAzure>>(`${this.baseAzureUrl}`);
  }

  getAzureUser(id: string): Observable<UserFromAzure> {
    return this.http.get<UserFromAzure>(`${this.baseAzureUrl}/${id}`);
  }

  createUser(data: CreateUserRequest): Observable<UserInDB> {
    return this.http.post<UserInDB>(`${this.baseUrl}`, data);
  }

  patchUserRole(id: string, data: UpdateUserRoleRequest): Observable<any> {
    return this.http.patch(`${this.baseUrl}/${id}/role`, data);
  }

  updateUser(id: string, data: UpdateUserRequest): Observable<UserInDB> {
    return this.http.patch<UserInDB>(`${this.baseUrl}/${id}`, data);
  }
}
