<div class="wrapper">
    <mat-card class="login-card">
        <mat-card-header>
            <mat-card-title>Login Failed</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p class="error-message">Please try again later.</p>
            <button mat-raised-button color="primary" (click)="redirect()" class="btn-full">Login</button>
        </mat-card-content>
    </mat-card>
</div>