import { Component, Input, OnInit } from '@angular/core';
import { ScheduleResponse, Location } from '../../../models/schedule.model';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { ScheduleInteractionService } from '../../../services/schedule-interaction.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ScheduleCheckinComponent } from '../schedule-checkin/schedule-checkin.component';
import { MatDialog } from '@angular/material/dialog';
import { ClinicService } from '../../../services/clinic.service';
import { SchedulePickupComponent } from '../schedule-pickup/schedule-pickup.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-schedule-route-view',
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    CommonModule,
    MatButtonModule,
    ScheduleCheckinComponent,
  ],
  templateUrl: './schedule-route-view.component.html',
  styleUrl: './schedule-route-view.component.scss'
})
export class ScheduleRouteViewComponent implements OnInit {
  @Input() schedule!: ScheduleResponse;
  @Input() locations: Location[] = [];

  selectedStopId: string | null = null;
  selectedStop: Location | undefined;

  constructor(
    private scheduleInteractionService: ScheduleInteractionService,
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) {}


  ngOnInit(): void {
    this.scheduleInteractionService.selectedStop$.subscribe((stopId) => {
      if(stopId)
      {
        this.selectStop(stopId,false);
        return;
      }
    });
    if (this.schedule) {
    } else {
      console.log('NO SCHEDULE FOUND');
    }
  }

  selectStop(stopId: string, propagate:boolean = true): void {
    if(propagate)
    {
      this.scheduleInteractionService.selectStop(stopId);
    }
    this.selectedStopId = stopId;
    this.selectedStop = this.locations.find((loc) => loc.id === stopId);
  }

  checkIn(clinic: string): void {
    this.dialog.open(ScheduleCheckinComponent, {
      data: { 
        schedule: this.schedule, 
        clinicId: clinic },
      width: '90%',
      maxWidth: '100%',
    });
  }

  openOrderDialog(order: string): void {
    if(this.selectedStop === undefined || this.selectedStop.objectId === undefined) {
      this.toastr.warning('Please select a stop to process', 'Warning');
      return;
    }
    this.dialog.open(SchedulePickupComponent, {
      data: { 
        orderReference: order, 
        scheduleOrderId: this.selectedStop?.objectId,
        schedule: this.schedule,
      },
      width: '90%',
      maxWidth: '100%',
    })
  }

}