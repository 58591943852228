import { AfterContentInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SchedulesService } from '../../../services/schedules.service';
import { CommonModule } from '@angular/common';
import { MatDialogTitle } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { ScheduleResponse } from '../../../models/schedule.model';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { OverviewScheduleComponent } from '../overview-schedule/overview-schedule.component';

@Component({
  selector: 'app-dashboard-todays-schedule',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogTitle,
    MatCardModule,
    MatProgressSpinner,
    MatTabsModule,
    OverviewScheduleComponent
  ],
  templateUrl: './dashboard-todays-schedule.component.html',
  styleUrl: './dashboard-todays-schedule.component.scss'
})
export class DashboardTodaysScheduleComponent implements OnInit {
  isLoading:boolean  = false
  @Input() data!:{userId:string}
  schedules: ScheduleResponse[] = []
  error: string = ''

  //touch pageination settings
  private touchStartX = 0;
  private touchEndX = 0;
  private readonly swipeThreshold = 30;

  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;

  constructor(private schduleService:SchedulesService){}


  ngOnInit() : void
  {
    this.getData()
  }
  getData()
  { 
    this.isLoading = true
    this.schduleService.getTodaysSchedules(this.data.userId).subscribe({
      next: (res) =>{
        this.schedules = res
        this.isLoading = false
        if(this.schedules.length <= 0)
          {
            this.error = 'Todays Schedules are Currently Empty'
          }  
      }, 
      error: (error) =>
      {
        this.isLoading = false
        this.error = error.message
      }
    })
  }
  //Touch Pageination
  onTouchStart(event: TouchEvent) {
    this.touchStartX = event.changedTouches[0].screenX;
  }
  // Capture the ending X position of the touch
  onTouchEnd(event: TouchEvent) {
    this.touchEndX = event.changedTouches[0].screenX;
    this.handleSwipeGesture();
  }
  handleSwipeGesture() {
    const swipeDistance = this.touchEndX - this.touchStartX;

    if (swipeDistance > this.swipeThreshold) {
      // Swiped right (go to the previous tab)
      this.swipeRight();
    } else if (swipeDistance < -this.swipeThreshold) {
      // Swiped left (go to the next tab)
      this.swipeLeft();
    }
  }
  //if doesnt work on mobile need to inverse methods
  swipeLeft() {
    if(this.tabGroup.selectedIndex)
      {
        if (this.tabGroup.selectedIndex > 0) {
          this.tabGroup.selectedIndex -= 1;
        }
      }
  }
  swipeRight() {
    if(this.tabGroup.selectedIndex != null)
    {
      if (this.tabGroup.selectedIndex < this.tabGroup._tabs.length - 1) {
        this.tabGroup.selectedIndex += 1;
      }
      if(this.tabGroup.selectedIndex == 0)
        {
          this.tabGroup.selectedIndex += 1
        }
    }
  }
}
