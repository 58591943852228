<h2 mat-dialog-title >Edit New Requirement</h2>
<form [formGroup]="editRequirementForm" (ngSubmit)="onSubmit()">
<mat-dialog-content>
        <!-- Name Field -->
        <div class="flex-container">
            <mat-form-field appearance="fill" class="field-full-width">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Requirement Name" [formControl]="nameControl">
            </mat-form-field>
        </div>
        <!-- Description Field -->
        <div class="flex-container">
            <mat-form-field appearance="fill" class="field-full-width">
                <mat-label>Description</mat-label>
                <textarea matInput placeholder="Description" [formControl]="descriptionControl"></textarea>
            </mat-form-field>
        </div>
        <div class="flex-container">
            <!-- Photo Required Field -->
             <mat-label>Photo Required</mat-label>
            <mat-checkbox color="primary" [formControl]="photoRequiredControl"></mat-checkbox>
        </div>
        <!-- Buttons -->
        <mat-dialog-actions class="flex-end-actions">
            <button mat-raised-button color="info" mat-dialog-close>Close</button>
            <button mat-raised-button color="primary" type="submit"
                [disabled]="editRequirementForm.invalid || isSaving">Save</button>
        </mat-dialog-actions>
    </mat-dialog-content>
</form>