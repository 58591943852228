<h1 mat-dialog-title>Clinic check-in</h1>
<div mat-dialog-content>
    <p>Clinic: <span>Big Jon's Big Animals</span></p>
    <button *ngIf="!visited" class="check-in-button" color="primary" (click)="markArrived()" mat-raised-button>I have
        arrived</button>
    <div *ngIf="visited" class="checked-in-container">
        <p>Checked in</p>
        <mat-icon>check</mat-icon>
    </div>
    <div class="orders-card-actions" *ngIf="visited">
        <button mat-raised-button (click)="scanPickup()" color="primary">Scan orders</button>
        <button mat-raised-button color="primary">Enter reference</button>
    </div>
    <div class="card-wrapper">
        <mat-card class="orders-card" *ngIf="schedulePickups.length > 0">
            <mat-card-header class="orders-card-header">
                <mat-card-title>Orders to pick up</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="order-container">
                    <div *ngFor="let scheduleOrder of schedulePickups" class="order actioned">
                        <div class="order-circle">
                            <mat-icon>place</mat-icon>
                        </div>
                        <div class="order-information" *ngIf="scheduleOrder.order">
                            <p>{{scheduleOrder.order.animalName}} - {{scheduleOrder.order.weight}} kg</p>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card class="orders-card" *ngIf="scheduleDropoffs.length > 0">
            <mat-card-header class="orders-card-header">
                <mat-card-title>Orders to drop off</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="order-container">
                    <div *ngFor="let scheduleOrder of scheduleDropoffs" class="order">
                        <div class="order-circle">
                            <mat-icon>place</mat-icon>
                        </div>
                        <div class="order-information" *ngIf="scheduleOrder.order">
                            <p>{{scheduleOrder.order.animalName}} - {{scheduleOrder.order.weight}} kg</p>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
</div>