import { Component, Inject } from '@angular/core';
import { UserSearchComponent } from '../user-search/user-search.component';
import { UserInDB } from '../../../models/user.model';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-select-dialog',
  standalone: true,
  imports: [
    UserSearchComponent,
    MatDialogModule,

  ],
  templateUrl: './user-select-dialog.component.html',
  styleUrl: './user-select-dialog.component.scss'
})
export class UserSelectDialogComponent {
  roleInput: string;
  selectedUser: UserInDB | null = null;

  constructor(
    public dialogRef: MatDialogRef<UserSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.roleInput = data.role; // Get the role from the data passed into the dialog
  }

  onUserSelected(user: UserInDB): void {
    this.selectedUser = user;
    this.dialogRef.close(user); // Close the dialog with the selected user data
  }
}