<div *ngIf="clinics">
    <p>Choose Clinic</p>
    <mat-form-field>
        <mat-label>Choose Clinic</mat-label>
        <input 
            matInput
            type="text"
            *ngIf="clinics.length > 0" 
            [disabled]="isSaving"
            [formControl]="clinicControl"
            [matAutocomplete]="autoClinic"/>
        <mat-autocomplete #autoClinic="matAutocomplete" [displayWith]="clinicName" (optionSelected)="onClinicSelected($event.option.value)">
            <mat-option *ngFor="let clinic of filteredClinics | async" [value]="clinic">
                {{ clinic.name }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
<div class="flex-container">
    <mat-spinner
        *ngIf="!clinics"
        [diameter]="40">
    </mat-spinner>
</div>