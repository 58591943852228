import { CommonModule } from '@angular/common';
import { AfterViewInit, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BarcodeFormat } from '@zxing/library';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-qr-scanner',
  standalone: true,
  imports: [
    CommonModule,
    ZXingScannerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
  ],
  templateUrl: './qr-scanner.component.html',
  styleUrl: './qr-scanner.component.scss'
})
export class QrScannerComponent implements AfterViewInit {
  isLoading = false
  referenceResult: string = '';

  showScanner = false;
  allowedFormats = [BarcodeFormat.QR_CODE];
  availableDevices!: MediaDeviceInfo[];
  deviceCurrent!: MediaDeviceInfo | undefined;
  deviceSelected!: string;

  hasDevices!: boolean;
  hasPermission!: boolean;
  qrResultString!: string;

  constructor(
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<QrScannerComponent>,  
  )
  {}
  ngAfterViewInit(): void {
    this.toggleCamera()
  }
  toggleCamera() {
    this.showScanner = true;
  }
  closeCamera() {
    this.showScanner = false;
    this.qrResultString = '';
    this.dialogRef.close(this.referenceResult)
  }
  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = devices && devices.length > 0;
  }
  onCodeResult(resultString: string) {
    this.qrResultString = resultString;

    if (this.qrResultString) {
      this.isLoading = true; 
      this.handleQRResult();
      this.closeCamera();  
    }
  }
  onDeviceSelectChange(selected: string) {
    const selectedStr = selected || '';
    if (this.deviceSelected === selectedStr) { return; }
    this.deviceSelected = selectedStr;
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.deviceCurrent = device || undefined;
  }
  onDeviceChange(device: MediaDeviceInfo) {
    const selectedStr = device?.deviceId || '';
    if (this.deviceSelected === selectedStr) { return; }
    this.deviceSelected = selectedStr;
    this.deviceCurrent = device || undefined;
  }
  scanErrorHandler(error: Error) {
    this.showScanner = false;

    if (error.name === 'NotAllowedError') {
      this.hasPermission = false;
    } else if (error.name === 'NotFoundError') {
      this.hasDevices = false;
    }
   
    this.toastr.error(error.message || 'An error occurred', 'Scan Error');
  }
  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }
  handleQRResult(): void {
    try {
      const url = new URL(this.qrResultString);
      const envUrl = new URL(window.location.href);

      //domain check
      if(url.hostname !== envUrl.hostname) {
        throw new Error("Invalid domain: " + url.hostname);
      }

      //path check
      if(!url.pathname.startsWith('/orders/')) {
        throw new Error("URL does not belong to correct section.");
      }

      const orderReference = url.pathname.split('/').pop() || ''; // ID is the last segment
      console.log('hit order Ref', orderReference)
      if(orderReference) {
        this.referenceResult = orderReference;
      } else {
        throw new Error("Order reference is null or empty");
      }
    } catch (error) {
      if(error instanceof Error) {
        this.toastr.error(error.message);
      } else {
        this.toastr.error('An unexpected error occurred');
      }
    } finally {
      this.isLoading = false;
    }
  }
}
