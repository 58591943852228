import { Component, Input, OnInit } from '@angular/core';
import { ScheduleResponse, UpdateScheduleRequest } from '../../../models/schedule.model';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { SchedulesService } from '../../../services/schedules.service';
import { ToastrService } from 'ngx-toastr';
import { SearchLocationComponent } from '../../locations/search-location/search-location.component';
import { LocationResponse } from '../../../models/location.model';
import { MatRadioButton } from '@angular/material/radio';
import { ScheduleLocationDialogComponent } from '../schedule-location-dialog/schedule-location-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LocationService } from '../../../services/location.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter, provideNativeDateAdapter } from '@angular/material/core';
import { SearchVehicleComponent } from '../../vehicle/search-vehicle/search-vehicle.component';
import { VehicleResponse } from '../../../models/vechicle.model';
import { UserSearchComponent } from '../../users/user-search/user-search.component';
import { UserFromAzure, UserInDB } from '../../../models/user.model';
import { UserSelectDialogComponent } from '../../users/user-select-dialog/user-select-dialog.component';
import { VehicleSelectDialogComponent } from '../../vehicle/vehicle-select-dialog/vehicle-select-dialog.component';
import { RouteSelectDialogComponent } from '../../routes/route-select-dialog/route-select-dialog.component';
import { RouteResponse } from '../../../models/routes.model';


@Component({
  selector: 'app-schedule-edit',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatRadioButton,
    SearchLocationComponent,
    ScheduleLocationDialogComponent,
    CommonModule,
    MatIconModule,
    MatDatepickerModule,
  ],
  templateUrl: './schedule-edit.component.html',
  styleUrl: './schedule-edit.component.scss',
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-AU' }, // Set the locale
    ...provideNativeDateAdapter()
  ]
})
export class ScheduleEditComponent implements OnInit {
  @Input() schedule!: ScheduleResponse;

  editDate: boolean = false;
  editedDate: Date | null = null;

  // Location IDs come from the schedule or from the modal selection.
  originId!: string;
  destinationId!: string;

  // Full location details retrieved from the DB.
  originLocation?: LocationResponse;
  destinationLocation?: LocationResponse;

  constructor(
    private schedulesService: SchedulesService,
    private locationService: LocationService,
    private toastr: ToastrService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    // Initialize from the schedule.
    this.originId = this.schedule.originId || '';
    this.destinationId = this.schedule.destinationId || '';

    // If there's already an origin/destination, load the full details.
    if (this.originId) {
      this.locationService.getLocation(this.originId).subscribe({
        next: (loc) => (this.originLocation = loc),
        error: (err) => console.error('Error fetching origin details', err)
      });
    }
    if (this.destinationId) {
      this.locationService.getLocation(this.destinationId).subscribe({
        next: (loc) => (this.destinationLocation = loc),
        error: (err) => console.error('Error fetching destination details', err)
      });
    }
  }

  openLocationDialog(field: 'origin' | 'destination'): void {
    const dialogRef = this.dialog.open(ScheduleLocationDialogComponent, {
      width: '500px',
      data: { field }
    });

    dialogRef.afterClosed().subscribe((result: LocationResponse | undefined) => {
      if (result) {
        if (field === 'origin') {
          this.originId = result.id;
          this.originLocation = result; // Use the returned full location
          this.updateOrigin();
        } else if (field === 'destination') {
          this.destinationId = result.id;
          this.destinationLocation = result;
          this.updateDestination();
        }
      }
    });
  }

  beginEditDate(): void {
    this.editedDate = new Date(this.schedule.scheduleDate);
    this.editDate = true;
  }

  formatDate(date: Date | null): string {
    if (date === null) {
      return '';
    }
    let dateString = date.getFullYear().toString();
    dateString += '-';
    if (date.getMonth() < 9) {
      dateString += '0';
    }
    dateString += (date.getMonth() + 1).toString();
    dateString += '-';
    if (date.getDate() < 10) {
      dateString += '0';
    }
    dateString += date.getDate().toString();
    return dateString;
  }

  updateDate(): void {
    let scheduleRequest: UpdateScheduleRequest = {
      driver: this.schedule.driver?.id ?? null,
      scheduleDate: this.formatDate(this.editedDate),
      vehicle: this.schedule.vehicle?.id ?? null,
    };
    this.schedulesService.updateSchedule(this.schedule.id, scheduleRequest).subscribe({
      next: (response) => {
        this.toastr.success('Schedule updated successfully!', 'Success');
        this.schedule.scheduleDate = this.formatDate(this.editedDate);
        this.editDate = false;
      },
      error: (error) => {
        console.error('Error updating schedule:', error);
        this.toastr.error('Failed to update schedule.', 'Error');
        this.editDate = false;
      }
    });
  }

  openVehicleDialog(): void {
    const dialogRef = this.dialog.open(VehicleSelectDialogComponent, {
      width: '500px',
      data: { type: 'vehicle' }
    });

    dialogRef.afterClosed().subscribe((result: VehicleResponse | undefined) => {
      if (result) {
        this.updateVehicle(result);
      }
    });
  }

  openDriverDialog(): void {
    const dialogRef = this.dialog.open(UserSelectDialogComponent, {
      width: '500px',
      data: { type: 'driver' }
    });

    dialogRef.afterClosed().subscribe((result: UserInDB | undefined) => {
      if (result) {
        this.updateDriver(result);
      }
    });
  }

  openRouteDialog(): void {
    const dialogRef = this.dialog.open(RouteSelectDialogComponent, {
      width: '500px',
      data: { type: 'route' }
    });
    dialogRef.afterClosed().subscribe((result: RouteResponse | undefined) => {
      if (result) {
        this.updateRoute(result);
      }
    });
  }

  updateVehicle(vehicle: VehicleResponse): void {
    let scheduleRequest: UpdateScheduleRequest = {
      driver: this.schedule.driver?.id ?? null,
      scheduleDate: this.schedule.scheduleDate,
      vehicle: vehicle.id,
    };
    this.schedulesService.updateSchedule(this.schedule.id, scheduleRequest).subscribe({
      next: (response) => {
        this.toastr.success('Vehicle updated successfully!', 'Success');
        this.schedule.vehicle = vehicle;
      },
      error: (error) => {
        console.error('Error updating vehicle:', error);
        this.toastr.error('Failed to update vehicle.', 'Error');
      }
    });
  }

  updateDriver(user: UserInDB): void {
    let scheduleRequest: UpdateScheduleRequest = {
      driver: user.id,
      scheduleDate: this.schedule.scheduleDate,
      vehicle: this.schedule.vehicle?.id ?? null,
    };
    this.schedulesService.updateSchedule(this.schedule.id, scheduleRequest).subscribe({
      next: (response) => {
        this.toastr.success('Driver updated successfully!', 'Success');
        this.schedule.driver = user;
      },
      error: (error) => {
        console.error('Error updating driver:', error);
        this.toastr.error('Failed to update driver.', 'Error');
      }
    });
  }

  updateRoute(route: RouteResponse): void {
    this.schedulesService.setScheduleRoute(this.schedule.id, route.id).subscribe({
      next: (response) => {
        this.toastr.success('Route updated successfully!', 'Success');
        window.location.reload();
      },
      error: (error) => {
        console.error('Error updating route:', error);
        this.toastr.error('Failed to update route.', 'Error');
      }
    });
  }

  updateOrigin(): void {
    if (!this.originId || !this.schedule.id) {
      return;
    }
    this.schedulesService.setScheduleOrigin(this.schedule.id, this.originId).subscribe({
      next: (response) => {
        this.toastr.success('Origin updated successfully!', 'Success');
        // If we didn't already have the full details (from modal), fetch them.
        if (!this.originLocation) {
          this.locationService.getLocation(this.originId).subscribe({
            next: (loc) => (this.originLocation = loc),
            error: (err) => console.error('Error fetching origin details', err)
          });
        }
      },
      error: (error) => {
        console.error('Error updating origin:', error);
        this.toastr.error('Failed to update origin.', 'Error');
      }
    });
  }

  updateDestination(): void {
    if (!this.destinationId || !this.schedule.id) {
      return;
    }
    this.schedulesService.setScheduleDestination(this.schedule.id, this.destinationId).subscribe({
      next: (response) => {
        this.toastr.success('Destination updated successfully!', 'Success');
        if (!this.destinationLocation) {
          this.locationService.getLocation(this.destinationId).subscribe({
            next: (loc) => (this.destinationLocation = loc),
            error: (err) => console.error('Error fetching destination details', err)
          });
        }
      },
      error: (error) => {
        console.error('Error updating destination:', error);
        this.toastr.error('Failed to update destination.', 'Error');
      }
    });
  }
}