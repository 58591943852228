import { Component, Inject } from '@angular/core';
import { SearchVehicleComponent } from '../search-vehicle/search-vehicle.component';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VehicleResponse } from '../../../models/vechicle.model';

@Component({
  selector: 'app-vehicle-select-dialog',
  standalone: true,
  imports: [
    SearchVehicleComponent,
    MatDialogModule
  ],
  templateUrl: './vehicle-select-dialog.component.html',
  styleUrl: './vehicle-select-dialog.component.scss'
})
export class VehicleSelectDialogComponent {
  selectedVehicle: VehicleResponse | null = null;

  constructor(
    public dialogRef: MatDialogRef<VehicleSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onVehicleSelected(vehicle: VehicleResponse): void {
    this.selectedVehicle = vehicle;
    this.dialogRef.close(vehicle);
  }
}