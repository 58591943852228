import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Attachment, AttachmentWithFile } from '../../../models/orders/order.model';
import { CommonModule } from '@angular/common';
import { MatCardHeader, MatCardModule, MatCardTitle } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { FileService } from '../../../services/file.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ToastrService } from 'ngx-toastr';
import { expandFade } from '../../../../assets/animations';

@Component({
  selector: 'app-attachment-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatCardHeader,
    MatCardTitle,
    MatButtonModule,
    MatProgressBarModule,
  ],
  templateUrl: './attachment-dialog.component.html',
  styleUrl: './attachment-dialog.component.scss',
  animations: [expandFade],
})
export class AttachmentDialogComponent implements OnInit {
 constructor(
  @Inject(MAT_DIALOG_DATA) public data: Attachment[],
  private FileService: FileService,
  private toastrService:ToastrService
 )
 {

 }
 isLoading = false
 attachments: AttachmentWithFile[] = []

 ngOnInit(): void {
    this.getData()
 }
 async getData()
 {
  this.isLoading = true
  const mappedAttachments: AttachmentWithFile[] = this.data.map(att =>(
    {
      ...att, fileUrl: null, loaded: false
    }))
  this.attachments = mappedAttachments
  this.attachments.forEach(async (attachment,index) => {
     await this.FileService.getImage(attachment.filePath).subscribe(
      {
        next:(res)=>
          {
            const url = window.URL.createObjectURL(res)
            this.attachments[index] = {...attachment,fileUrl: url,loaded: true}
          },
        error:(error)=>
          {
            this.toastrService.error(error,"Fail to get Image")
            this.isLoading=false
          }
      }
    )
    this.isLoading=false
  });
 }
}
