import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {MatTabsModule} from '@angular/material/tabs';
import { OrderEditComponent } from '../order-edit/order-edit.component';
import { OrderEventsComponent } from '../order-events/order-events.component';
import { OrderService } from '../../../services/order.service';
import { OrderResponse } from '../../../models/orders/order.model';
import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-order-actions',
  standalone: true,
  imports: [MatTabsModule, OrderEditComponent, OrderEventsComponent,CommonModule],
  templateUrl: './order-actions.component.html',
  styleUrl: './order-actions.component.scss'
})
export class OrderActionsComponent implements OnInit {
  @Input() orderRefFromTable!:string;

  @ViewChild('orderEvents') orderEvents: any;

  order!:OrderResponse;
  refreshTrigger:boolean = false;

  constructor(
    private route: ActivatedRoute, 
    private orderService: OrderService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    if(this.orderRefFromTable) {
      this.getOrder(this.orderRefFromTable);
    } else {
      this.route.paramMap.subscribe(params => {
        const orderReference = params.get('ref');
        if(orderReference) {
          this.getOrder(orderReference);
        }
      });
  }
}

  getOrder(ref: string) {
    this.orderService.getOrderByReference(ref).subscribe({
      next: (res) => {
        this.order = res;
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred');
      } 
    })
  }

  handleOrderUpdated():void {
    this.orderEvents.getEvents(this.order.id);
    console.log("Refreshing events table.");
  }

}
