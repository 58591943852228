import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { ClinicResponse } from '../../../models/clinic.model';
import { MatInputModule } from '@angular/material/input';
import { DeleteDialogComponent } from '../../../components/delete-dialog/delete-dialog.component';
import { ClinicService } from '../../../services/clinic.service';
import { ToastrService } from 'ngx-toastr';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from '../../../services/auth.service';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatCardModule } from '@angular/material/card';
import { UserService } from '../../../services/user.service';
import { UserInDB } from '../../../models/user.model';
import { invisTofadeIn } from '../../../../assets/animations';

@Component({
  selector: 'app-clinic-index',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatCardModule,
    RouterLink,
    MatFormFieldModule,
    MatPaginator,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './clinic-index.component.html',
  styleUrl: './clinic-index.component.scss',
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),invisTofadeIn
  ]
})
export class ClinicIndexComponent implements OnInit, AfterViewInit{
  displayedColumns: string[] = ['name'];
  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  dataSource = new MatTableDataSource<ClinicResponse>();
  isLoading:boolean = true;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  role: string = '';
  isAdmin: boolean = false;

  //users
  clinicUsers:UserInDB[] = []
  userDisplayedColumns = ['givenName', 'surname', 'displayName', 'role', 'accountEnabled'];
  userDataSource = new MatTableDataSource<UserInDB>();

  isExpandLoading:boolean = false;
  expandedElement!: ClinicResponse | null;

  constructor(
    public dialog: MatDialog, 
    private clinicService: ClinicService, 
    private toastr: ToastrService, 
    private breakpointObserver: BreakpointObserver,
    private loginService: AuthService,
    private userService:UserService
  
  ) { 
    this.loginService.claims$.subscribe((c) => {
      this.role = c['extension_Role'];
    });
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      if (result.matches) {
        this.displayedColumns = ['name'];
      } else {
        this.displayedColumns = ['name' ];
      }

      if(this.role == 'globeadmin' || this.role == 'awladmin') {
        this.displayedColumns.push('actions');
        this.isAdmin = true;
      }
    });
  }

  ngAfterViewInit() {
    this.getData();
  }

  getData() {
    this.clinicService.getClinics().subscribe({
      next: (res) => {
        this.dataSource.data = res.clinics;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
      }
    })  
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  } 
  
  openDeleteDialog(element: ClinicResponse) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: { type: "clinic", name: element.name }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.clinicService.deleteClinic(element.id).subscribe({
          next: (res) => {
            this.toastr.success('Clinic deleted successfully.');
            this.getData();
          },
          error: (error) => {
            if(error.status == '409') {
              this.toastr.error('This clinic cannot be deleted because it is associated with other entities, such as an order.');
            } else {
              this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
            }
          }
        });
      }
    })
  }

  expandClinic(clinic :ClinicResponse)
  {

    if(this.expandedElement === clinic )
      {
        this.expandedElement = null
      }
      else
      {
        this.expandedElement = clinic
        this.isExpandLoading = true
        this.userService.getUsers().subscribe(
          {
            next: (res) => 
            {
              this.clinicUsers = res.items.filter(user => user.clinicId === clinic.id)
              this.userDataSource.data = this.clinicUsers
              this.isExpandLoading= false
            },
            error:(error) => 
            {
              this.toastr.error('Failed to get Clinics Users.')
            }
          })
      }
  }
}
