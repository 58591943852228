import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { OrderStatusWithRequirementResponse, TransitionRequirement } from '../../../models/orders/order.model';
import { MatCheckboxModule } from '@angular/material/checkbox'; 
import { MatIconModule } from '@angular/material/icon';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-status-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    ReactiveFormsModule,
    MatButtonModule,
    MatLabel,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule
  ],
  templateUrl: './status-dialog.component.html',
  styleUrl: './status-dialog.component.scss'
})
export class StatusDialogComponent {
  form!: FormGroup;
  newStatus!: OrderStatusWithRequirementResponse;
  orderIdFromEdit!:string
  // oldStatus!: OrderStatusWithRequirementResponse;
  cameraBtn: string = "Take Photo";
  photoLibraryBtn: string = "Album";

  imageSrc: {[key: string]: string | null } = {};

  constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<StatusDialogComponent>, private toastr: ToastrService) {
    this.newStatus = this.data.newStatus;
    // this.oldStatus = this.data.oldStatus;
    this.createForm(this.newStatus.requirements);
  }

  createForm(requirements: TransitionRequirement[]) {
    this.form = this.fb.group({});

    requirements.forEach(req => {
      const validators = req.photoRequired ? [Validators.required, this.checkedValidator()] : this.checkedValidator();
      this.form.addControl(req.requirement, this.fb.control('', validators));

      if(req.photoRequired) {
        this.imageSrc[req.requirement] = null;
        this.form.addControl(`${req.requirement}Image`, this.fb.control(null, Validators.required));
      }
    });
  }

  checkedValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      return control.value === true ? null : {'required': {value: control.value}}
    }
  }

  onSubmit() {
    if(this.form.valid) {
      const submissionData = {
        requirements: this.form.value,
        images: this.imageSrc
      };
      this.dialogRef.close(this.form.value);
    }
  }

  onFileChanged(event: any, requirement: string) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result) {
          this.imageSrc[requirement] = e.target.result as string;
          this.form.get(`${requirement}Image`)?.setValue(true); 
        }
      };
      reader.onerror = (err) => {
        this.toastr.error('Error loading the image. Please try again');
      };
      reader.readAsDataURL(file);
    } else {
      // This case can happen if the file dialog is cancelled or if permissions are denied
      this.toastr.error('No file selected or permissions denied. Please try again');
      this.imageSrc[requirement] = null;
      this.form.get(`${requirement}Image`)?.setValue(null); 
    }
  }
}
