import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { CremationType, OrderResponse, OrderStatusResponse, OrderStatusWithRequirementResponse } from '../../../models/orders/order.model';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { PreferredContactMethod } from '../../../models/contact.model';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { BarcodeFormat } from '@zxing/library';
import { MatCardModule } from '@angular/material/card';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialog } from '@angular/material/dialog';
import { StatusDialogComponent } from '../../../components/orders/status-dialog/status-dialog.component';  
import { OrderService } from '../../../services/order.service';
import { ChangeBulkOrderStatusComponent } from '../../../components/orders/change-bulk-order-status/change-bulk-order-status.component';
import { SearchOrdersComponent } from '../../../components/orders/search-orders/search-orders.component';

@Component({
  selector: 'app-status-update-bulk',
  standalone: true,
  imports: [
    ZXingScannerModule, 
    CommonModule,
    MatButtonModule,
    MatListModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    MatIconModule,
    MatDividerModule,
    MatCardModule,
    MatProgressSpinnerModule,
    ChangeBulkOrderStatusComponent,
    SearchOrdersComponent
  ],
  templateUrl: './status-update-bulk.component.html',
  styleUrl: './status-update-bulk.component.scss'
})
export class StatusUpdateBulkComponent implements OnInit{
  form!: FormGroup;
  orders: OrderResponse[] = [];
  referenceResult: string = '';

  showScanner = false;
  allowedFormats = [BarcodeFormat.QR_CODE];
  availableDevices!: MediaDeviceInfo[];
  deviceCurrent!: MediaDeviceInfo | undefined;
  deviceSelected!: string;

  hasDevices!: boolean;
  hasPermission!: boolean;
  qrResultString!: string;

  statusSelected!: OrderStatusWithRequirementResponse;
  isLoading: boolean = false;

  manualAddingOrder: boolean = false;
  orderReference = new FormControl('', [Validators.required]);

  constructor(
    private fb: FormBuilder, 
    private toastr: ToastrService, 
    public dialog: MatDialog, 
    private orderService: OrderService) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      newStatus: ['', Validators.required],
    });
  }

  validateAndProcessOrder(orderReference: string): void {
    const orderReferences = this.orders.map(order => order.reference);
  
    if (!orderReference || orderReferences.includes(orderReference)) {
      throw new Error("Invalid or duplicate order reference");
    }
  }

  handleQRResult(): void {
    try {
      const url = new URL(this.qrResultString);
      const envUrl = new URL(window.location.href);

      //domain check
      if(url.hostname !== envUrl.hostname) {
        throw new Error("Invalid domain: " + url.hostname);
      }

      //path check
      if(!url.pathname.startsWith('/orders/')) {
        throw new Error("URL does not belong to correct section.");
      }

      const orderReference = url.pathname.split('/').pop() || ''; // ID is the last segment

      if(orderReference) {
        this.validateAndProcessOrder(orderReference);
        this.referenceResult = orderReference;
      } else {
        throw new Error("Order reference is null or empty");
      }
    } catch (error) {
      if(error instanceof Error) {
        this.toastr.error(error.message);
      } else {
        this.toastr.error('An unexpected error occurred');
      }
    } finally {
      this.isLoading = false;
    }
  }

  manualAddOrder() {
    try {
      const orderReference = this.orderReference.value;

      if(orderReference) {
        this.validateAndProcessOrder(orderReference);
        this.addOrder(orderReference);
        this.orderReference.reset();
        this.manualAddingOrder = false;
      }else {
        throw new Error("Order reference is null or empty");
      }
    } catch (error) {
      if(error instanceof Error) {
        this.toastr.error(error.message);
      } else {
        this.toastr.error('An unexpected error occurred');
      }
    }
  }


  openBulkChangeStatusDialog():void {
    console.log(this.orders);
    const dialogRef = this.dialog.open(ChangeBulkOrderStatusComponent, {
      width: '600px',
      data: {orders: this.orders}
    });

    dialogRef.afterClosed().subscribe({
      next:(res) =>
      {
        this.orders = [];
      }
    })

  }

  toggleManualAdd() {
    this.manualAddingOrder = !this.manualAddingOrder;
  }

  addOrder(reference: string) {
    this.orderService.getOrderByReference(reference).subscribe({
      next: (res) => {
        const order = res;
        this.orders.push(order);
        this.qrResultString = '';
        this.referenceResult = '';
        this.toastr.success('Order scanned and added successfully'); 
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error has occurred when getting this order, make sure you have the right order reference. Please try again.');
      }
    })
  }

  toggleCamera() {
    this.showScanner = true;
  }

  closeCamera() {
    this.showScanner = false;
    this.qrResultString = '';
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = devices && devices.length > 0;
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;

    if (this.qrResultString) {
      this.isLoading = true; 
      this.handleQRResult();
      this.closeCamera();  
    }
  }

  onDeviceSelectChange(selected: string) {
    const selectedStr = selected || '';
    if (this.deviceSelected === selectedStr) { return; }
    this.deviceSelected = selectedStr;
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.deviceCurrent = device || undefined;
  }

  onDeviceChange(device: MediaDeviceInfo) {
    const selectedStr = device?.deviceId || '';
    if (this.deviceSelected === selectedStr) { return; }
    this.deviceSelected = selectedStr;
    this.deviceCurrent = device || undefined;
  }

  scanErrorHandler(error: Error) {
    this.showScanner = false;

    if (error.name === 'NotAllowedError') {
      this.hasPermission = false;
    } else if (error.name === 'NotFoundError') {
      this.hasDevices = false;
    }
   
    this.toastr.error(error.message || 'An error occurred', 'Scan Error');
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  removeOrderReference(reference: string) {
    this.orders = this.orders.filter(o => o.reference !== reference);
  }


  openStatusDialog(newStatus: OrderStatusWithRequirementResponse, ) {
    const dialogRef = this.dialog.open(StatusDialogComponent, {
      data: { newStatus }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog was closed', result);
      // TODO: further process with the result, like add it to the order status
    });
  }

  openOrdersDialog():void {
    const dialogRef = this.dialog.open(SearchOrdersComponent,{
      width: '950px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.selectedOrders && result.selectedOrders.length > 0)
      {
        for (const o of result.selectedOrders)
        {
          this.orders.push(o);
        }
      }
    });
  }

  onSubmit() {
    if(this.form.valid) {
      console.log(this.form.value, this.orders);

      //TODO: check if all orders have valid status and then trigger the event to open the update status dialog
      this.openStatusDialog(this.form.value.newStatus);
    }
  }
}
