<div class="container">
  <div class="header">
    <h2>Orders</h2>
    <button mat-raised-button routerLink="/orders/create" color="primary">New </button>
  </div>
  <div class="wrapper">
    <div class="selection">
      <mat-form-field>
        <mat-label>Select a view</mat-label>
        <mat-select [(ngModel)]="selectedView" (selectionChange)="filterOrders()">
          <mat-option [value]="'1'">All</mat-option>
          @for (status of statuses; track status.id) {
            <mat-option [value]="status.id">{{status.name}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="orders-table">
      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Search.." #input>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="refreshing" class="loading-container">
    <mat-spinner
    [diameter]="80"></mat-spinner>
  </div>
  <div [@invisTofadeIn]="refreshing ? 'hidden' : 'visible'">

    <div [hidden]="refreshing" class="table">
    <table class="orders-table" mat-table [dataSource]="dataSource" matSort (matSortChange)="handleSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
  
          <mat-checkbox color="primary" (change)="$event ? selectAll() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox color="primary" (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(element) : null"
          [checked]="selection.isSelected(element)">
          </mat-checkbox>
        </td> 
      </ng-container>

      <ng-container matColumnDef="referenceNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference</th>
        <td mat-cell [innerHTML]="element.referenceNumber.toString() | highlight:dataSource.filter" *matCellDef="let element">{{ element.referenceNumber }}</td>
      </ng-container>
      
      <ng-container matColumnDef="clinic">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Clinic</th>
        <td mat-cell [innerHTML]="element.clinic.name | highlight:dataSource.filter" *matCellDef="let element"></td>
      </ng-container>
  
      <ng-container matColumnDef="animalName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Animal Name</th>
        <td mat-cell *matCellDef="let element">
          <span [innerHTML]="element.animalName | highlight:dataSource.filter"></span>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="weight">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Weight</th>
        <!-- <td mat-cell *matCellDef="let element">{{ element.weight }} {{ element.weight === 1 ? 'kg' : 'kgs'}} </td> -->
        <td mat-cell *matCellDef="let element">
          <span [innerHTML]="element.weight.toString() | highlight:dataSource.filter"></span>
          <span> kg</span>
        </td>
      </ng-container>
  
  <!-- Cremation Type Column -->
  <ng-container matColumnDef="cremationType">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Cremation Type</th>
    <td mat-cell *matCellDef="let element">
      <span [innerHTML]="element.cremationType | highlight:dataSource.filter"></span>
    </td>
  </ng-container>
      
  <!-- Order Status Column -->
  <ng-container matColumnDef="orderStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Status</th>
    <td mat-cell *matCellDef="let element">
      <span [innerHTML]="(element.status ? element.status.name : 'null') | highlight:dataSource.filter"></span>
    </td>
  </ng-container>
  
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At </th>
        <td mat-cell *matCellDef="let element">{{ formatTimestamp(element.createdAt) }}</td>
      </ng-container>
  
      <ng-container matColumnDef="qrCode">
        <th mat-header-cell *matHeaderCellDef>QR Code</th>
        <td mat-cell *matCellDef="let element">
          <qrcode [qrdata]="generateQrLink(element.referenceNumber.toString())"
            [elementType]="'img'"
            [errorCorrectionLevel]="'M'"
            [margin]="4"
            [scale]="1"
            [width]="75"
          ></qrcode>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action </th>
        <td mat-cell *matCellDef="let element"> 
          <div class="button-container">
            <button mat-raised-button color="primary" class="left-button" (click)="openDialog(element)" matTooltip="Open">
              Open
            </button>
            <button mat-raised-button color="accent" class="right-button" [routerLink]="['/orders', element.referenceNumber.toString()]" matTooltip="Fullscreen">
              <mat-icon>fullscreen</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4" *ngIf="!searchError">No data matching the filter</td>
        <td class="mat-cell" colspan="4" *ngIf="searchError">There was an error retrieving orders. Please try refreshing the page.</td>
      </tr>
    </table>
  </div>
    <mat-paginator 
    [ngStyle]="{ 'display': refreshing ? 'none' : 'block' }"
    [pageSizeOptions]="[25, 50, 100]"
    showFirstLastButtons
    aria-label="Select page of orders">
  </mat-paginator>
  </div>
</div>

