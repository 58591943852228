<div class="schedule-edit">
  <h2>Edit Schedule</h2>
  <div class="display-field">
    <mat-icon>event</mat-icon>
    <mat-label>Date:</mat-label>
    <div class="display-value">
      <span *ngIf="!editDate">
        {{ schedule.scheduleDate | date }}
      </span>
      <mat-form-field *ngIf="editDate">
        <input matInput [matDatepicker]="picker" [(ngModel)]="editedDate">
        <mat-hint>dd/mm/yyyy</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <button mat-icon-button color="primary" (click)="editDate ? updateDate() : beginEditDate()">
      <mat-icon>{{ editDate ? 'save' : 'edit' }}</mat-icon>
    </button>
  </div>
  <div class="display-field">
    <mat-icon>
      airport_shuttle
    </mat-icon>
    <mat-label>
      Vehicle:
    </mat-label>
    <div class="display-value">
      {{ schedule.vehicle.name }}
    </div>
    <button mat-icon-button color="primary" (click)="openVehicleDialog()">
      <mat-icon>
        edit
      </mat-icon>
    </button>
  </div>
  <div class="display-field">
    <mat-icon>
      person
    </mat-icon>
    <mat-label>
      Driver:
    </mat-label>
    <div class="display-value">
      {{ schedule.driver.givenName }} {{ schedule.driver.surname }}
    </div>
    <button mat-icon-button color="primary" (click)="openDriverDialog()">
      <mat-icon>
        edit
      </mat-icon>
    </button>
  </div>
  <div class="display-field">
    <mat-icon>
      location_on
    </mat-icon>
    <mat-label>
      Route:
    </mat-label>
    <div class="display-value">
      {{ schedule.route.name }}
    </div>
    <button mat-icon-button color="primary" (click)="openRouteDialog()">
      <mat-icon>
        edit
      </mat-icon>
    </button>
  </div>
  <div class="display-field">
    <mat-icon>
      location_on
    </mat-icon>
    <mat-label>
      Origin:
    </mat-label>
    <div class="display-value">
      <span *ngIf="(originLocation && originLocation.address)">
        {{ originLocation.address.street }}, {{ originLocation.address.city }}, {{ originLocation.address.state }}
      </span>
    </div>
    <button mat-icon-button color="primary" (click)="openLocationDialog('origin')">
      <mat-icon>
        edit
      </mat-icon>
    </button>
  </div>
  <div class="display-field">
    <mat-icon>
      location_on
    </mat-icon>
    <mat-label>
      Destination:
    </mat-label>
    <div class="display-value">
      <span *ngIf="(destinationLocation && destinationLocation.address)">
        {{ destinationLocation.address.street }}, {{ destinationLocation.address.city }}, {{
        destinationLocation.address.state }}
      </span>
    </div>
    <button mat-icon-button color="primary" (click)="openLocationDialog('destination')">
      <mat-icon>
        edit
      </mat-icon>
    </button>
  </div>
</div>