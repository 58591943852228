import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogTitle } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserInDB } from '../../../models/user.model';
import { map, Observable, startWith } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { PagedQueryResult } from '../../../models/pagedQueryResult.interface';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-user-search',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './user-search.component.html',
  styleUrl: './user-search.component.scss'
})
export class UserSearchComponent implements OnInit{
  @Input() isSaving:boolean=false; // pass in the form saving variable to disable inputs on the form
  @Input() user!:UserInDB;
  @Input() userControl:FormControl = new FormControl(); // Pass in the form control to be manipulated by this selector.
  @Input() roleInput:string= '';//testing role filtering
  value:string =''
  // Output
  
    users!:UserInDB[]
    filteredUsers: Observable<UserInDB[]>;
  
  
    constructor(
      private userService:UserService,
      private toastr:ToastrService
    )
    {
      this.filteredUsers = this.userControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterVehicles(value))
      );
    
    }
    ngOnInit(): void {
      if(this.user)
        {
          this.userControl.setValue(this.user)
          this.value = this.userControl.value
        }
      this.getUsers()
    }
    
    private _filterVehicles(value: string): UserInDB[] {
      const filterValue = value.toLowerCase();
      return this.users.filter(user => user.displayName.toLowerCase().includes(filterValue));
    }
    
    getUsers(): void {
      this.userService.getUsers().subscribe({
        next: (res:PagedQueryResult<UserInDB>) => {
          //REQUIRES ROLES TO BE USED
          //this.users = res.items.filter(user => user.role === this.roleInput);
          this.users = res.items;
        }
      })
    }
  
    userName(user:UserInDB): string {
      return user && typeof user === 'object' && user.displayName ? user.displayName : "";
    }
    resetValue()
    {
      this.userControl.reset()
      this.value = ''
    }

    onUserSelected(event:any):void
    {
      // Output
      this.value = this.userControl.value
      console.log(this.userControl.value);

    }
  
}
