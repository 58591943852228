import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AttachmentFileRequest, ChangeOrderStatusRequest, OrderResponse, OrderStatusResponse } from '../../../models/orders/order.model';
import { OrderService } from '../../../services/order.service';
import { PagedQueryResult } from '../../../models/pagedQueryResult.interface';
import { ToastrService } from 'ngx-toastr';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, Validators, ReactiveFormsModule, FormGroup, FormArray, AbstractControl } from '@angular/forms';
import { count, generate, lastValueFrom, map, Observable, startWith } from 'rxjs';
import { vehicle, VehicleResponse } from '../../../models/vechicle.model';
import { ClinicResponse } from '../../../models/clinic.model';
import { ClinicService } from '../../../services/clinic.service';
import { VechicleService } from '../../../services/vehicle.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SearchVehicleComponent } from '../../vehicle/search-vehicle/search-vehicle.component';
import { SearchClinicComponent } from '../../clinics/search-clinic/search-clinic.component';
import { GetOrCreatePlaceRequest, PlaceType } from '../../../models/orders/place.model';
import { PlaceService } from '../../../services/place.service';
import { ScanPlaceCodeComponent } from '../../places/scan-place-code/scan-place-code.component';
import { CameraDialogComponent } from '../../scanner-dialog/camera-dialog/camera-dialog.component';

@Component({
  selector: 'app-change-single-order-status',
  standalone: true,
  imports: [
    MatDialogModule,
    MatCardModule,
    MatButtonModule,
    MatSelectModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatDividerModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    SearchVehicleComponent,
    SearchClinicComponent,
    ScanPlaceCodeComponent,
    CameraDialogComponent
  ],
  templateUrl: './change-single-order-status.component.html',
  styleUrl: './change-single-order-status.component.scss'
})
export class ChangeSingleOrderStatusComponent implements OnInit {
  order!: OrderResponse;

  statuses!: PagedQueryResult<OrderStatusResponse>;
  validTransitions!: OrderStatusResponse[];
  chosenStatus!: OrderStatusResponse | null | undefined;
  isLoading: boolean = false;
  isSaving: boolean = false;

  //Form Handlers
  formIndex!: number;
  //base64String: string | ArrayBuffer = '';

  statusIdControl = new FormControl('', Validators.required);
  fromPlaceControl = new FormControl('');
  toPlaceControl = new FormControl('', Validators.required);
  commentControl = new FormControl('');
  vehicleControl = new FormControl();
  clinicControl = new FormControl();
  customPlaceControl = new FormControl();

  changeStatusForm: FormGroup = new FormGroup({
    statusIdControl: this.statusIdControl,
    fromPlaceControl: this.fromPlaceControl,
    toPlaceControl: this.toPlaceControl,
    commentControl: this.commentControl,
    vehicleControl: this.vehicleControl,
    clinicControl: this.clinicControl,
    customPlaceControl: this.customPlaceControl,
    photoControls: new FormArray([]),
    photoDisplayControls: new FormArray([]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { order: OrderResponse },
    private dialogRef: MatDialogRef<ChangeSingleOrderStatusComponent>,
    private dialog: MatDialog,
    private orderService: OrderService,
    private clinicService: ClinicService,
    private vehicleService: VechicleService,
    private placeService: PlaceService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    if (this.data.order) {
      this.order = this.data.order;
    }
    this.getStatuses();
  }

  onPlaceControlSelected(event: any): void {

  }

  getStatuses(): void {
    this.orderService.getOrderStatuses().subscribe({
      next: (res) => {
        this.statuses = res;
        this.getValidTransitions();
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred');
      }
    })
  }

  updateChosenStatus(event: MatSelectChange): void {
    var chosen = this.validTransitions.find(item => event.value == item.id);
    this.chosenStatus = chosen;
    this.generateRequirementFields()
  }

  getValidTransitions(): void {
    var statusArray = this.statuses.items;
    this.validTransitions = statusArray.filter(item => this.order.status.validTransitions.includes(item.id));
  }

  generateRequirementFields()
  {
    const controlArray = this.changeStatusForm.get('photoControls') as FormArray;
    const controlDisplayArray = this.changeStatusForm.get('photoDisplayControls') as FormArray;
    controlArray.clear();
    controlDisplayArray.clear();
    this.chosenStatus?.requirements.forEach(req =>{
      //might cause errors for a requirement not having a photo
      controlArray.push(new FormControl());
      controlDisplayArray.push(new FormControl('',[Validators.required]));
    })
  }
  openCamera(event: Event, reqId:string, index:number) {
    event.preventDefault();
    const dialogRef = this.dialog.open(CameraDialogComponent, {
      width:"*"
    });

    dialogRef.afterClosed().subscribe((data:string) => {
      if (data) { 
        this.addPhoto(reqId,index,data)
      }
    })
  }

  async openScanner(): Promise<void> {
    try {
      this.isLoading = true
      const dialogRef = this.dialog.open(ScanPlaceCodeComponent, {
        width: "600px"
      });
      const result = await lastValueFrom(dialogRef.afterClosed());
      if (result) {
        this.toPlaceControl.setValue(result.type);
        switch (result.type) {
          case "Vehicle":
            var vehicle = await this.getVehicle(result.objectId);
            this.vehicleControl.setValue(vehicle)
            break;
          case "Clinic":
            var clinic = await this.getClinic(result.objectId);
            this.clinicControl.setValue(clinic)
            break;
          case "Custom":
            this.customPlaceControl.setValue(result.name);
            break;
        }
      }
    }
    catch (error) {
      this.toastr.error("Something went wrong during QR Code scan", "QR Scanner Error")
    }
    finally {
      this.isLoading = false;
    }
  }

  async getVehicle(id: string) {
    return await lastValueFrom(this.vehicleService.getVehicle(id));
  }

  async getClinic(id: string) {
    return await lastValueFrom(this.clinicService.getClinic(id));
  }

  async onSubmit(): Promise<void> {
    if (this.changeStatusForm.valid) {
      console.log("beginning form save");
      this.isSaving = true;
      const form = this.changeStatusForm.value;
      var toPlace = "";
      try {
        switch (form.toPlaceControl) {
          case "Current":
            toPlace = this.order.currentPlace.id;
            break;
          case "Vehicle":
            var request = new GetOrCreatePlaceRequest(`${form.vehicleControl.name} (${form.vehicleControl.registration})`, form.vehicleControl.id, "Vehicle");
            var res = this.placeService.getOrCreatePlace(request);
            toPlace = (await lastValueFrom(res)).id;
            break;
          case "Clinic":
            var request = new GetOrCreatePlaceRequest(`${form.clinicControl.name}`, form.clinicControl.id, "Clinic");
            var res = this.placeService.getOrCreatePlace(request);
            toPlace = (await lastValueFrom(res)).id;
            break;
          case "Custom":
            var request = new GetOrCreatePlaceRequest(form.customPlaceControl, undefined, "Custom");
            var res = this.placeService.getOrCreatePlace(request);
            toPlace = (await lastValueFrom(res)).id;
            break;
          default:
            toPlace = this.order.currentPlace.id;
            break;
        }
      } catch (error: any) {
        this.toastr.error(error?.error, error?.name);
        this.isSaving = false;
        return;
      }
      const hasFileReq = this.chosenStatus?.requirements;//for getting requirements
      let attachments: AttachmentFileRequest[] = []
      const reader = new FileReader();
      this.chosenStatus?.requirements.forEach((req,index )=>{
        const controlArray = form.photoControls as []
        const fileControl = controlArray[index]
        const attachment = new AttachmentFileRequest(req.id,fileControl)
        attachments.push(attachment)
      })
      const updateRequest: ChangeOrderStatusRequest = {
        statusId: form.statusIdControl,
        toPlaceId: toPlace,
        comment: form.commentControl,
        UploadAttachments: attachments
      }
      this.orderService.changeOrderStatus(this.order.id, updateRequest).subscribe({
        next: () => {
          this.isSaving = false;
          this.toastr.success("Successfully updated ", "Success")
          this.dialogRef.close(true);
        },
        error: (error) => {
          this.isSaving = false;
          this.toastr.error(error.error, "Error updating order status");
        }
      });
    }
    else {
      this.toastr.error("Something went wrong - check you have filled out all the required fields.");
    }
  }
  //File Handling
  addPhoto(reqId: string, index: number,base64 :string )
  {
    console.log('photo Change', index ,' reqId ' ,reqId)
    const control = this.changeStatusForm.get('photoControls') as FormArray;
    const displayControl =  this.changeStatusForm.get('photoDisplayControls') as FormArray;

    const selectedControl = control.get(`${index}`)
    selectedControl?.patchValue(base64);
    const selectedDisplayControl = displayControl.get(`${index}`)
    selectedDisplayControl?.patchValue('photo.png')

  }
  async onFileChange(event: any)
  {
    const control = this.changeStatusForm.get('photoControls') as FormArray;
    const displayControl =  this.changeStatusForm.get('photoDisplayControls') as FormArray;
    //setting file 
    const file: File = event.target.files[0]
    const base64 = await this.convertToBase64(file)
    const selectedControl = control.get(`${this.formIndex}`)
    selectedControl?.patchValue(base64);
    //outputing file name for UI
    const selectedDisplayControl = displayControl.get(`${this.formIndex}`)
    selectedDisplayControl?.patchValue(file.name)
  }
  openFileExplorer(event: Event,index: number) {
    event.preventDefault();
    const fileInput = document.getElementById(`fileInput-${index}`) as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
    this.formIndex = index;
  }
  convertToBase64(file:File):Promise<string|ArrayBuffer>
  {
    return new Promise((resolve, reject) =>{
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error)
    })
  }
}
