<div class="container">
    <div class="header">
        <h2>Routes</h2>
    </div>
    <div class="flex-row align-items-center">
        <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. toyota" #input>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="openCreateRouteDialog()" class="add-btn" *ngIf="isAdmin">
            Add Route
        </button>
    </div>
    <div *ngIf="isLoading" class="loading-container">
        <mat-spinner
        [diameter]="80">
        </mat-spinner>
    </div>   
    <div class="mat-elevation-z8" [@invisTofadeIn]="isLoading ? 'hidden' : 'visible'">
        <div *ngIf="!isLoading">
            <table mat-table [dataSource]="dataSourceRoutes" matSort multiTemplateDataRows>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Route Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                </ng-container>
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsRoutes.length">
                        <div class="details"
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div *ngIf="isExpandLoading" class="loading-container">
                                <mat-spinner
                                [diameter]="80"></mat-spinner>
                            </div>  
                            <div *ngIf="!isExpandLoading" class="mat-elevation-z8 mb-1" style="overflow-y: hidden;">
                                <mat-card>
                                    <mat-card-content class="flex-row text-align-center">
                                        <div>
                                            <h2>
                                                {{element.name}}
                                            </h2>
                                        </div>
                                        <button mat-stroked-button color="primary" class="edit-btn" matTooltip="Edit Route"
                                        (click)="openEditRouteDialog(element)" *ngIf="element.role !== 'globeadmin'">
                                        <mat-icon>edit</mat-icon>
                                        Edit Route
                                        </button>   
                    
                                        <button mat-stroked-button color="warn" matTooltip="Delete Route"
                                            (click)="openDeleteRouteDialog(element)" *ngIf="element.role !== 'globeadmin'">
                                            <mat-icon>delete</mat-icon>
                                            Delete Route
                                        </button> 
                                    </mat-card-content>
                                </mat-card>
                                <h3>
                                    Clinics on Route
                                </h3>
                                @for(clinic of element.clinics; track $index)
                                {
                                <mat-card>
                                    <mat-card-header>
                                        <h3>
                                            {{clinic.name}}
                                        </h3>
                                    </mat-card-header>
                                </mat-card>

                                }
                            </div>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsRoutes"></tr>
                <tr 
                mat-row *matRowDef="let element; columns: displayedColumnsRoutes;"
                class="table-row"
                [class.expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row active-row"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>
            </table>
        </div>
        <mat-paginator [ngStyle]="{ 'display': isLoading ? 'none' : 'block' }" [pageSizeOptions]="[25, 50, 100]" aria-label="Select page of users" showFirstLastButtons></mat-paginator>
    </div>
</div>

