import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ClinicService } from '../../../services/clinic.service';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ClinicResponse } from '../../../models/clinic.model';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatPaginator,MatPaginatorModule } from '@angular/material/paginator';
import { MatSort,MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToastrService } from 'ngx-toastr';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
export interface tableClinics extends ClinicResponse 
{
  isSelected:boolean;
}
@Component({
  selector: 'app-add-clinics',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatIconModule,
    MatDialogClose,
    MatDialogActions,
    MatTooltipModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './add-clinics.component.html',
  styleUrl: './add-clinics.component.scss'
})
export class AddClinicsComponent implements AfterViewInit {
  form!:FormGroup;
  isLoading:boolean = false;
  displayedColumnsClinics: string[] = ['name','note','isSelected'];
  dataSourceClinics = new MatTableDataSource<tableClinics>();
  selected:boolean[] = []
  
  @Input() data!: ClinicResponse[]
  @Output() notifyParent: EventEmitter<ClinicResponse[]> = new EventEmitter<ClinicResponse[]>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  constructor(
    private ClinicService:ClinicService,
    private toastr: ToastrService,
  )
  {}
  ngAfterViewInit() {
    setTimeout(() =>{
      this.isLoading = true;
    },0)
    this.getData();
  }
  getData()
  {
    this.ClinicService.getClinics().subscribe({
      next: (res) =>{
        const tableClinics = res.clinics.map(clinic => ({
          ...clinic , isSelected: false
        }))
        if(this.data)
          {
            const idSet = new Set(this.data.map(clinic => clinic.id));
            tableClinics.forEach(clinic=>{
              if(idSet.has(clinic.id))
                {
                  clinic.isSelected = true
                }
              })
          }
        this.dataSourceClinics.data = tableClinics;
        this.dataSourceClinics.paginator = this.paginator;
        this.dataSourceClinics.sort = this.sort;
        this.isLoading = false;
      },
      error:(error) => {
        this.toastr.error(error.error?.title || 'An error occurred when getting Routes. Please try again.');
        this.isLoading = false;
      }
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceClinics.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceClinics.paginator) {
      this.dataSourceClinics.paginator.firstPage();
    }
  }
  selectClinic(index:number)
  {
    this.dataSourceClinics.data[index].isSelected = !this.dataSourceClinics.data[index].isSelected
    const selectedClinics:ClinicResponse[] = []
    this.dataSourceClinics.data.forEach(clinic =>{
      if(clinic.isSelected )
        {
          selectedClinics.push(clinic)
        }
    })
    this.notifyParent.emit(selectedClinics)
  }
  isClinicSelected(clinic:tableClinics): boolean
  {
    return clinic.isSelected;
  }
}
