import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DirectionsRequest, Location, OrderInfoResponse, ScheduleOrderResponse, ScheduleResponse } from '../../../models/schedule.model';
import { ActivatedRoute } from '@angular/router';
import { SchedulesService } from '../../../services/schedules.service';
import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { OrderTableComponent } from '../../../components/schedules/order-table/order-table.component';
import { ScheduleRouteViewComponent } from '../../../components/schedules/schedule-route-view/schedule-route-view.component';
import { ScheduleRouteMapComponent } from '../../../components/schedules/schedule-route-map/schedule-route-map.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { ScheduleEditComponent } from '../../../components/schedules/schedule-edit/schedule-edit.component';
import { MatButtonModule } from '@angular/material/button';
import { ScheduleAddStopsComponent } from '../../../components/schedules/schedule-add-stops/schedule-add-stops.component';
import { MatDialog } from '@angular/material/dialog';
import { ClinicResponse } from '../../../models/clinic.model';
import { LocationService } from '../../../services/location.service';
import { lastValueFrom } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-schedule-view',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    OrderTableComponent,
    ScheduleRouteViewComponent,
    ScheduleRouteMapComponent,
    MatGridListModule,
    ScheduleEditComponent,
    MatButtonModule,
    ScheduleAddStopsComponent,
    MatFormFieldModule,
    MatIconModule,
  ],
  templateUrl: './schedule-view.component.html',
  styleUrl: './schedule-view.component.scss'
})
export class ScheduleViewComponent implements OnInit {
  @Input() data!: ScheduleResponse;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  isLoading: boolean = true;
  schedule!: ScheduleResponse;
  scheduleClinics!: ClinicResponse[];
  scheduleOrders!: ScheduleOrderResponse[];
  locations: Location[] = [];
  directionsService: any;
  scheduleDirections!: google.maps.DirectionsResult;

  constructor(
    private route: ActivatedRoute,
    private scheduleService: SchedulesService,
    private locationService: LocationService,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.schedule = this.data
    }
    else {
      this.route.paramMap.subscribe(params => {
        const scheduleReference = params.get('ref');
        if (scheduleReference) {
          this.getData(scheduleReference)
        }
      })
    }
    this.directionsService = new google.maps.DirectionsService();
  }

  private getData(scheduleReference: string): void {
    this.locations = [];
    this.scheduleService.getSchedule(scheduleReference).subscribe({
      next: (response) => {
        this.schedule = response;
        Promise.all([
          this.initScheduleOrders(),
          this.initScheduleClinics(),
          this.initScheduleLocations(),
        ]).then(() => {
          this.calculateDirectionsAndReorder();
          this.isLoading = false;
        });
      },
      error: (error) => {
        this.toastr.error('An error occurred while fetching schedule data');
        this.isLoading = false;
      }
    });
  }

  initScheduleOrders(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.scheduleService.getScheduleOrders(this.schedule.id).subscribe({
        next: (response) => {
          this.scheduleOrders = response;
          this.loadOrderLocations();
          resolve();
        },
        error: (error) => {
          this.toastr.error('An error occurred while fetching schedule orders');
          reject(error);
        }
      });
    });
  }

  initScheduleClinics(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.scheduleService.getScheduleClinics(this.schedule.id).subscribe({
        next: (response) => {
          this.scheduleClinics = response;
          this.loadClinicLocations();
          resolve();
        },
        error: (error) => {
          this.toastr.error('An error occurred while fetching schedule clinics');
          reject();
        }
      });
    });
  }

  loadClinicLocations(): void {
    this.scheduleClinics.forEach((clinic) => {
      var clinicLocation = clinic.locations.find(l => l.id = clinic.defaultLocationId);
      var newLocs = this.locations;
      newLocs.push({
        id: clinic.id,
        type: 'clinic',
        title: clinic.name,
        objectId: clinic.id,
        location: clinicLocation,
        clinic: clinic,
        order: null,
        lat: clinicLocation?.coordinates.latitude ?? 0.0,
        lng: clinicLocation?.coordinates.longitude ?? 0.0,
        googleId: clinicLocation?.googleId ?? '',
        visited: false,
      });
      this.locations = newLocs;
    });
  }

  loadOrderLocations(): void {
    this.scheduleOrders.forEach((scheduleOrder) => {
      var newLocs = this.locations;
      var order = scheduleOrder.order;
      if(!order) return;
      // Don't add orders specifically attached to clinics, they will be managed from within the clinic check-in interface.
      if(scheduleOrder.scheduleType === 'Pickup' && order.pickupFromClinic) {
        return;
      }
      if(scheduleOrder.scheduleType === 'Dropoff' && order.dropOffToClinic) {
        return;
      }
      newLocs.push({
        id: order.id,
        type: 'order',
        objectId: scheduleOrder.id,
        location: order.pickupLocation,
        clinic: null,
        order: order,
        title: order.animalName ?? 'Order',
        lat: order.pickupLocation?.coordinates.latitude ?? 0.0,
        lng: order.pickupLocation?.coordinates.longitude ?? 0.0,
        googleId: order.pickupLocation?.googleId,
        visited: false,
      });
      this.locations = newLocs;
    });
  }

  initScheduleLocations(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (!this.schedule.originId || !this.schedule.destinationId) {
        resolve();
        return;
      }

      try {
        const originPromise = lastValueFrom(this.locationService.getLocation(this.schedule.originId));
        const destinationPromise = lastValueFrom(this.locationService.getLocation(this.schedule.destinationId));

        const [originResponse, destinationResponse] = await Promise.all([originPromise, destinationPromise]);

        let newLocs = [...this.locations]; // Create a new array

        newLocs.push({
          id: originResponse.id,
          type: 'origin',
          title: 'Start',
          objectId: this.schedule.originId,
          order: null,
          clinic: null,
          location: originResponse,
          lat: originResponse.coordinates.latitude,
          lng: originResponse.coordinates.longitude,
          googleId: originResponse.googleId,
          visited: false,
        });

        newLocs.push({
          id: destinationResponse.id,
          type: 'destination',
          title: 'End',
          order: null,
          clinic: null,
          location: destinationResponse,
          objectId: this.schedule.destinationId,
          lat: destinationResponse.coordinates.latitude,
          lng: destinationResponse.coordinates.longitude,
          googleId: destinationResponse.googleId,
          visited: false,
        });

        this.locations = newLocs; // Assign the new array

        resolve();
      } catch (error) {
        this.toastr.error("Error loading origin/destination locations");
        reject(error);
      }
    });
  }

  reorderLocations(waypointOrder?: number[]): Location[] {
    let origin: Location | undefined;
    let destination: Location | undefined;
    const otherLocations: Location[] = [];

    for (const location of this.locations) {
      if (location.type === 'origin') {
        origin = location;
      } else if (location.type === 'destination') {
        destination = location;
      } else {
        otherLocations.push(location);
      }
    }

    const newLocations: Location[] = [];
    if (origin) {
      newLocations.push(origin);
    }

    if (waypointOrder && waypointOrder.length === otherLocations.length) {
      // Reorder based on waypointOrder
      const orderedWaypoints = waypointOrder.map(index => otherLocations[index]);
      newLocations.push(...orderedWaypoints);
    } else {
      // Fallback: If no waypointOrder or length mismatch, use original order
      newLocations.push(...otherLocations);
    }

    if (destination) {
      newLocations.push(destination);
    }
    return newLocations;
  }

  calculateDirectionsAndReorder(): void {
    var origin = this.locations.find(l => l.type === 'origin');
    var destination = this.locations.find(l => l.type === 'destination');

    if (!origin || !destination) {
      this.locations = this.reorderLocations();
      this.isLoading = false;
      return;
    }

    var waypoints = this.locations.filter(l => l.type === 'clinic' || l.type === 'order');

    const requestBody: DirectionsRequest = {
      originLat: origin.lat,
      originLng: origin.lng,
      destinationLat: destination.lat,
      destinationLng: destination.lng,
      waypoints: waypoints.map((l) => ({ lat: l.lat, lng: l.lng })),
      travelMode: "DRIVING", // Use google maps travel mode.
      optimizeWaypoints: true,
    };


    this.directionsService.route({
      origin: { lat: origin.lat, lng: origin.lng },
      destination: { lat: destination.lat, lng: destination.lng },
      waypoints: [
        ...waypoints.map(l => ({ location: { lat: l.lat, lng: l.lng } }))
      ],
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING
    }, (result: any , status: string) => {
      if (status === google.maps.DirectionsStatus.OK && result) {
        this.locations = this.reorderLocations(result.routes[0].waypoint_order);
        this.scheduleDirections = result;
        this.isLoading = false;
      } else {
        console.error('Could not display directions due to: ' + status);
        this.locations = this.reorderLocations();
        this.isLoading = false;
      }
    });

  }

  addStops(): void {
    const ref = this.dialog.open(ScheduleAddStopsComponent, {
      data: {
        locations: this.locations,
        schedule: this.schedule,
      },
      width: '90%',
      maxWidth: '90%',
    });

    ref.afterClosed().subscribe(() => {
      this.getData(this.schedule.id);
    });
  }

}