<h2 mat-dialog-title>Search existing contacts</h2>
<mat-dialog-content class="flex">
    <mat-form-field appearance="fill" class="flex-1">
        <mat-label>Contact</mat-label>
        <input 
                type="text"
                placeholder="Search for a contact"
                matInput
                [formControl]="contactControl"
                [matAutocomplete]="contactAuto">
        <mat-autocomplete #contactAuto="matAutocomplete" [displayWith]="displayContactFn" (optionSelected)="onSelectionContact($event.option.value)">
            @for (option of contactFilteredOptions | async; track option) {
                <mat-option [value]="option">{{ option.firstName }} {{ option.lastName }} {{ option.email }}</mat-option>
            }
        </mat-autocomplete>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions class="flex-end-actions">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="onSave()">Add</button>
</mat-dialog-actions>
