import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  baseUrl = `${environment.apiUrl}/api/images`;
  constructor(private http: HttpClient) { }

  getImage(path:string): Observable<Blob> {
    
    const result =this.http.get(`${this.baseUrl}/${path}`,{responseType: 'blob'});
    return result
  }
}
