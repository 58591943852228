<h2 mat-dialog-title>Add order status</h2>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <div class="flex-container">
      <mat-form-field appearance="fill" class="flex-50">
        <mat-label>Status Name</mat-label>
        <input type="text" placeholder="Status Name" formControlName="name" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" class="flex-50">
        <mat-label>Customer Display Name</mat-label>
        <input type="text" placeholder="Customer Display Name" formControlName="customerDisplayName" matInput>
      </mat-form-field>
    </div>
    <div class="flex-container">
      <mat-form-field appearance="fill">
          <mat-label>Description</mat-label>
          <input type="text" placeholder="Description" formControlName="description" matInput>
      </mat-form-field>
    </div>
    <!-- <mat-checkbox color="primary" formControlName="isDefault">Is Default</mat-checkbox> -->
  </mat-dialog-content>
  <mat-dialog-actions class="flex-end-actions">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" type="submit">Add</button>
  </mat-dialog-actions>
</form>