import { Component, Inject, OnInit, Optional } from '@angular/core';
import { QRCodeModule } from 'angularx-qrcode';
import { PlaceResponse } from '../../../models/orders/place.model';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatLabel } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { VehicleResponse } from '../../../models/vechicle.model';
import { ClinicResponse } from '../../../models/clinic.model';
import { ClinicService } from '../../../services/clinic.service';
import { VechicleService } from '../../../services/vehicle.service';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PlaceService } from '../../../services/place.service';
import { ActivatedRoute } from '@angular/router';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import pdfMake from 'pdfmake/build/pdfmake';

@Component({
  selector: 'app-view-place',
  standalone: true,
  imports: [
    QRCodeModule,
    MatCardModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatLabel,
    MatListModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './view-place.component.html',
  styleUrl: './view-place.component.scss'
})
export class ViewPlaceComponent implements OnInit {

  vehicle!:VehicleResponse;
  clinic!:ClinicResponse;
  place!:PlaceResponse;

  constructor(
    private clinicService:ClinicService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: PlaceResponse, 
    private vehicleService:VechicleService,
    private toastr:ToastrService,
    private placeService:PlaceService,
    private route: ActivatedRoute,
  ){}
  ngOnInit(): void {
    if(this.data)
    {
      this.place = this.data;
    }
    else {
      this.route.paramMap.subscribe(params => {
        const placeRef = params.get('ref');
        if (placeRef) {
          this.getPlace(placeRef);
        }
      })
    }
    if(this.place)
    {
      switch(this.place.type)
      {
        case "Vehicle":
          this.getVehicle(this.place.objectId);
          break;
        case "Clinic":
          this.getClinic(this.place.objectId);
          break;
        default:
          break;
      }
    }
  }

  getClinic(id:string):void {
    this.clinicService.getClinic(id).subscribe({
      next:(res) => {
        this.clinic = res;
      },
      error:(error) =>
        this.toastr.error("Unable to retrieve clinic details. "+(error.error ?? ""),error.status+" Error "+(error.title ?? ""))
    });
  }

  getPlace(ref:string):void {
    this.placeService.getPlaceByReference(ref).subscribe({
      next:(res:PlaceResponse) => {
        this.place=res;
        switch(this.place.type)
        {
          case "Vehicle":
            this.getVehicle(this.place.objectId);
            break;
          case "Clinic":
            this.getClinic(this.place.objectId);
            break;
          default:
            break;
        }
      },
      error:(error:any) =>
        this.toastr.error("Unable to retrieve place details. "+(error.error ?? ""),error.status+" Error "+(error.title ?? ""))
    });
  }

  getVehicle(id:string):void {
    this.vehicleService.getVehicle(id).subscribe({
      next:(res) => {
        this.vehicle = res;
      },
      error:(error) =>
        this.toastr.error("Unable to retrieve vehicle details. "+(error.error ?? ""),error.status+" Error "+(error.title ?? ""))
    });
  }

  generateQrLink(reference:string):string
  {
    return window.location.origin + `/configurations/places/${reference}`;
  }


  generatePdf(place:PlaceResponse)
  {
    const docDefinition: TDocumentDefinitions = {
      content: [
        {
          columns: [
            {
              width: '50%',
              stack: [
                { text: 'Place Reference: ' + place.reference, style: 'header' },
                { text: 'Place Type: ' + place.type, style: 'subHeader' },
                {
                  columns: [
                    {
                      width: '27%',
                      text: 'Place Name:'
                    },
                    {
                      width: '*',
                      text: ''
                    },
                    {
                      width: '70%',
                      text: place.name
                    }
                  ],
                  style: 'paragraph'
                },
                { text: 'Please scan the QR code to see details for this place', style: 'subHeader2' },
                { qr: window.location.origin + `/configurations/places/${place.reference}`, fit: 100, style: 'qrCode' }
              ],
            }
          ]
        }
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [-25, -20, 25, 8],
        },
        subHeader: {
          fontSize: 14,
          margin: [-25, 0, 25, 10],
          background: 'black',
          color: 'white',
        },
        subHeader2: {
          fontSize: 10,
          bold: true,
          margin: [-25, 10, 25, 15],
        },
        paragraph: {
          fontSize: 8,
          margin: [-25, 0, 28, 8]
        },
        qrCode: {
          alignment: 'center',
          margin: [-25, 0, 40, 5]
        }
      }
    }
    
    pdfMake.createPdf(docDefinition).download('place-' + place.reference + '.pdf');
  }


}
