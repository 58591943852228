import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { UpdateUserRoleRequest, UserInDB } from '../../../models/user.model';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { ClinicResponse } from '../../../models/clinic.model';
import { UserService } from '../../../services/user.service';
import { ClinicService } from '../../../services/clinic.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import { MatIconModule } from '@angular/material/icon';
import { UserEditDialogComponent } from '../../users/user-edit-dialog/user-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { UserNewDialogComponent } from '../../users/user-new-dialog/user-new-dialog.component';

@Component({
  selector: 'app-clinic-users',
  standalone: true,
  imports: [    
    CommonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTableModule,
    MatFormFieldModule,
    FormsModule,
    MatIconModule,
    MatButtonModule
  ],
  templateUrl: './clinic-users.component.html',
  styleUrl: './clinic-users.component.scss'
})
export class ClinicUsersComponent implements OnInit {
  @Input() clinic!: ClinicResponse;
  isLoading = false;
  //table
  displayedColumns: string[] = ['accountEnabled','givenName','surname','role'];
  dataSource = new MatTableDataSource<UserInDB>();

  //sort
  clinicUsers : UserInDB[] = [];
  selectedView: string = '1';

  //auth
  role: string = '';
  roles:{name:string,value:string}[] = [{name:'Clinic Staff',value:'clinicstaff'}, {name:'Clinic Admin',value:'clinicadmin'}];
  isAdmin = false;

  constructor(
    private ClinicService:ClinicService,
    private toastr: ToastrService,
    private loginService:AuthService,
    private userService:UserService,
    public dialog: MatDialog, 
    ){}

  ngOnInit(): void {
    this.getData()

    this.loginService.claims$.subscribe((c) => {
      this.role = c['extension_Role'];
    });
    if(this.role == 'globeadmin' || this.role == 'awladmin' || this.role == 'clinicadmin') {
      this.displayedColumns.push('actions');
      this.isAdmin = true
    }
  }

  getData()
  {
    this.isLoading = true
    
    this.ClinicService.getClinicUsers(this.clinic.id).subscribe({
      next: (res) => {
        this.dataSource.data = res.items
        console.log('users ', res.items)
        this.clinicUsers = res.items
        this.isLoading = false
      },
      error: (error) => {
        this.toastr.error(error.message,"Failed to get Clinics Users.")
        this.isLoading = false
      }
    })
  }

  filterUsers()
  {
    if (this.selectedView === '1') {
      this.dataSource.data = this.clinicUsers; // 'All' selected
    } else {
      const filteredData = this.clinicUsers.filter(user =>
        user.role === this.selectedView
      );
      this.dataSource.data = filteredData;
    }
    
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  openAddUserDialog() {
    const dialogRef = this.dialog.open(UserNewDialogComponent, {
      width: '500px',
      data: {clinic:this.clinic}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.userService.createUser(result).subscribe({
          next: (res) => {
            this.toastr.success('User created successfully, they should receive an email shortly.');
            this.getData();
          },
          error: (error) => {
            if(error.status == '403') {
              this.toastr.error(error.error?.message || 'User do not have access to perform this action');
            } else {
              this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
            }
          }
        });
      }
    })
  }
  openEditUserDialog(user: UserInDB) {
    const dialogRef = this.dialog.open(UserEditDialogComponent, {
      width: '500px',
      // data: element
      data: user.id
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        //update user
        this.userService.updateUser(user.id, result).subscribe({
          next: (res) => {
            this.toastr.success('User updated successfully.');
            this.getData();
          },
          error: (error) => {
            this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
          }
        });
        
        // update role
        const updateRequest: UpdateUserRoleRequest = {
          role: result.role
        };

        if(user.role !== result.role) {
          this.userService.patchUserRole(user.id, updateRequest).subscribe({
            next: (res) => {
              this.toastr.success('User role updated successfully.');
              this.getData();
            },
            error: (error) => {
              this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
            }
          });
        }
      }
    })
  }
}
