<form (ngSubmit)="onSubmit()">
    <h2 mat-dialog-title>Choose Status</h2>
    <mat-dialog-content>
        <div *ngIf="!filteredStatuses" class="loading-container">
            <mat-spinner
            [diameter]="50"></mat-spinner>
        </div>
        <mat-form-field *ngIf="filteredStatuses" class="field-full-width">
            <mat-label>Valid Status Transition</mat-label>
            <input type="text" matInput [formControl]="statusControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayName">
                @for (status of filteredStatuses | async; track status) {
                <mat-option *ngIf="status.name" [value]="status">{{status.name}}</mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions class="flex-end-actions">
        <button mat-raised-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary">Add</button>
    </mat-dialog-actions>
</form>