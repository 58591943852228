import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ClinicService } from '../../../services/clinic.service';
import { OrderService } from '../../../services/order.service';
import { ToastrService } from 'ngx-toastr';
import { ClinicResponse } from '../../../models/clinic.model';
import { OrderResponse } from '../../../models/orders/order.model';
import { ScheduleResponse } from '../../../models/schedule.model';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { SchedulesService } from '../../../services/schedules.service';

@Component({
  selector: 'app-schedule-add-stops',
  standalone: true,
  imports: [
    MatDialogModule,
    CommonModule,
    MatCardModule,
    MatButtonModule,
  ],
  templateUrl: './schedule-add-stops.component.html',
  styleUrl: './schedule-add-stops.component.scss'
})
export class ScheduleAddStopsComponent implements AfterViewInit {

  @ViewChild('mapRef', { static: false }) mapRef!: ElementRef<HTMLDivElement>;
  map: google.maps.Map | undefined;
  markers: { [locationId: string]: google.maps.marker.AdvancedMarkerElement } = {};
  clinics: ClinicResponse[];
  orders: OrderResponse[];
  locations!: Location[];
  schedule!: ScheduleResponse;
  selectedClinic: ClinicResponse | null = null;
  scheduleClinics: ClinicResponse[] = [];
  selectedOrder: OrderResponse | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      schedule: ScheduleResponse,
    },
    private clinicService: ClinicService,
    private orderService: OrderService,
    private scheduleService: SchedulesService,
    private toastr: ToastrService,
  ) {
    this.schedule = data.schedule;
  }

  async ngAfterViewInit(): Promise<void> {
    await this.loadMap();
    await this.fetchClinics();
    await this.fetchOrders();
    await this.loadClinicMarkers();
    await this.loadOrderMarkers();
  }

  async fetchClinics(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.clinicService.getClinics().subscribe({
        next: (response) => {
          //Todo: clinics ideally shouldn't be on a schedule on the same day already. This may get complex though.
          this.clinics = response.clinics;
          this.scheduleClinics = this.clinics.filter(clinic => this.schedule.clinics.find(c => c === clinic.id));
          resolve();
        },
        error: (error) => {
          this.toastr.error("Failed to fetch clinics");
          reject();
        }
      })
    });
  }

  async fetchOrders(): Promise<void> {
    return new Promise((resolve, reject) => {
      // TODO: Schedule PICKUP and DROPOFF orders only. 
      this.orderService.getOrders().subscribe({
        next: (response) => {
          this.orders = response.orders;
          resolve();
        },
        error: (error) => {
          this.toastr.error("Failed to fetch orders");
          reject();
        }
      })
    });
  }

  // Orders shouldn't be already on a schedule

  async loadMap(): Promise<void> {

    const mapElement = this.mapRef.nativeElement;
    if (!mapElement) {
      console.error("Could not find Map Element.");
      return;
    }

    var centre = { latitude: -34.928857245822975, longitude: 138.5999041409906 };

    this.map = new google.maps.Map(mapElement, {
      center: { lat: centre.latitude, lng: centre.longitude },
      zoom: 12,
      mapId: "a3018be7cfd38215",
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      cameraControl: false,
    });
  }

  async loadClinicMarkers(): Promise<void> {
    if (!this.clinics) {
      console.log("NO CLINICS");
    }
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    this.clinics.forEach(clinic => {
      const active = this.schedule.clinics.find(c => c === clinic.id) ? true : false;
      const defaultLoc = clinic.locations.find(x => x.id === clinic.defaultLocationId);
      if (!defaultLoc) {
        console.log("error: could not find clinic default location")
        return;
      }
      const marker = new AdvancedMarkerElement({
        map: this.map,
        position: { lat: defaultLoc.coordinates.latitude, lng: defaultLoc.coordinates.longitude },
        title: 'Clinic',
        content: this.createIcon('home', active)
      });

      if (this.markers[clinic.id]) {
        this.markers[clinic.id].remove();
      }

      this.markers[clinic.id] = marker;
      marker.addListener('click', () => {
        this.selectClinic(clinic.id);
      });
    });
  }

  async loadOrderMarkers(): Promise<void> {
    if (!this.orders) {
      console.log("NO ORDERS");
    }
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    this.orders.forEach(order => {
      if (!order.status.canBeDroppedOff && !order.status.canBePickedUp) {
        // Don't display orders which can't be picked up or dropped off
        return;
      }
      if (order.status.canBePickedUp && !order.pickupFromClinic) {
        const active = this.schedule.orders.find(o => o.id === order.id) ? true : false;
        const marker = new AdvancedMarkerElement({
          map: this.map,
          position: { lat: order.pickupLocation.coordinates.latitude, lng: order.pickupLocation.coordinates.longitude },
          title: 'Order',
          content: this.createIcon('location_pin', active)
        });
        if (this.markers[order.id]) {
          this.markers[order.id].remove();
        }
        this.markers[order.id] = marker;
        marker.addListener('click', () => {
          this.selectOrder(order.id);
        });
      }
      else if (order.status.canBeDroppedOff && !order.dropOffToClinic) {
        const active = this.schedule.orders.find(o => o.id === order.id) ? true : false;
        const marker = new AdvancedMarkerElement({
          map: this.map,
          position: { lat: order.pickupLocation.coordinates.latitude, lng: order.pickupLocation.coordinates.longitude },
          title: 'Order',
          content: this.createIcon('location_pin', active)
        });
        if (this.markers[order.id]) {
          this.markers[order.id].remove();
        }
        this.markers[order.id] = marker;
        marker.addListener('click', () => {
          this.selectOrder(order.id);
        });
      }
    });
  }

  reloadMap(): void {
    this.loadClinicMarkers();
    this.loadOrderMarkers();
  }

  createIcon(type: string = 'home', active: boolean = true): Node {
    const icon = document.createElement('div');
    icon.style.width = '32px';
    icon.style.height = '32px';
    icon.style.backgroundImage = 'url(assets/svg/google_' + type + '_icon.svg)';
    icon.style.backgroundSize = 'contain';
    if (active) {
      icon.style.backgroundColor = 'rgba(48, 68, 10, 1)';
    }
    else {
      icon.style.backgroundColor = 'rgba(48, 68, 10, 0.1)';
    }
    icon.style.borderRadius = '50%';
    icon.style.backgroundSize = '80%';
    icon.style.backgroundPosition = 'center';
    return icon;
  }

  selectClinic(clinicId: string): void {
    const foundClinic = this.clinics.find(clinic => clinic.id === clinicId);
    if (!foundClinic) {
      this.toastr.error("Couldn't find selected clinic. Try refreshing the page.", "Error");
      return;
    }
    // Reset selections and assign the selected clinic
    this.selectedOrder = null;
    this.selectedClinic = foundClinic;
  }

  isActive(id: string) {
    if (this.schedule.clinics.find(c => c === id)) {
      return true;
    }
    if (this.schedule.orders.find(o => o.id === id)) {
      return true;
    }
    return false;
  }

  selectOrder(orderId: string) {
    const foundOrder = this.orders.find(order => order.id === orderId);
    if (!foundOrder) {
      this.toastr.error("Couldn't find selected order. Try refreshing the page.", "Error");
      return;
    }
    // Reset selections and assign the selected order
    this.selectedClinic = null;
    this.selectedOrder = foundOrder;
  }

  //todo: add clinic stop
  addClinic(clinicId: string) {
    if (this.schedule.clinics.find(clinic => clinic === clinicId)) {
      this.toastr.error("Cannot add Clinic to schedule - clinic is already on schedule. Try refreshing the page.", "Error");
      return;
    }
    this.scheduleService.addScheduleClinic(this.schedule.id, {
      scheduleId: this.schedule.id,
      clinicId: clinicId
    }).subscribe({
      next: (result) => {
        this.schedule = result;
        this.reloadMap();
      },
      error: (error) => {
        this.toastr.error(error.message, "Error");
      }
    });
  }

  removeClinic(clinicId: string) {
    if (!this.isActive(clinicId)) {
      this.toastr.error("Cannot remove Clinic from schedule - clinic is not on schedule. Try refreshing the page.", "Error");
      return;
    }
    if (!this.clinics.find(c => c.id === clinicId)) {
      this.toastr.error("Invalid clinic id. Try refreshing the page.", "Error");
      return;
    }
    this.scheduleService.removeScheduleClinic(this.schedule.id, clinicId).subscribe({
      next: (result) => {
        this.schedule = result;
        this.reloadMap();
      },
      error: (error) => {
        this.toastr.error(error.message, "Error");
      }
    });
  }

  //todo: add order stop
  addOrder(orderId: string) {

    if (this.schedule.orders.find(order => order.id === orderId)) {
      this.toastr.error("Cannot add Order to schedule - order is already on schedule. Try refreshing the page.", "Error");
      return;
    }
    console.log("Adding order to schedule", orderId, this.schedule.id);
    this.scheduleService.addScheduleOrder(this.schedule.id, {
      orderId: orderId
    }).subscribe({
      next: (result) => {
        this.schedule = result;
        this.reloadMap();
      },
      error: (error) => {
        this.toastr.error(error.message, "Error");
      }
    });
  }
  removeOrder(orderId: string) {
    if (!this.isActive(orderId)) {
      this.toastr.error("Cannot remove Order from schedule - order is not on schedule. Try refreshing the page.", "Error");
      return;
    }
    if (!this.orders.find(o => o.id === orderId)) {
      this.toastr.error("Invalid order id. Try refreshing the page.", "Error");
      return;
    }
    this.scheduleService.removeScheduleOrder(this.schedule.id, orderId).subscribe({
      next: (result) => {
        this.schedule = result;
        this.reloadMap();
      },
      error: (error) => {
        this.toastr.error(error.message, "Error");
      }
    });
  }

}



interface Location {
  id: string;
  type: 'clinic' | 'order' | 'origin';
  title: string;
  lat: number;
  lng: number;
  googleId: string;
  visited: boolean; // <-- track visited status
}
