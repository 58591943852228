
<h1 class="title">Edit Schedule</h1>
<div class="container scrollable-form">
    <div class="mx-1">
        <mat-vertical-stepper [linear]="false" #stepper>
            <mat-step [stepControl]="routeFormGroup" [errorMessage]="getRouteErrorMessage()">
                <form [formGroup]="routeFormGroup">
                    <ng-template matStepLabel>Select a Route</ng-template>
                    <app-search-routes [routeId]="data.route.id" [routeControl]="routeControl" ></app-search-routes>
                    <div class="flex-end-actions">
                    <button mat-button matStepperNext color="primary">Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="vehicleFormGroup" [errorMessage]="getVehicleErrorMessage()">
                <form [formGroup]="vehicleFormGroup">
                    <ng-template matStepLabel>Select a Vehicle & Driver</ng-template>
                        <app-search-vehicle [vehicleControl]="vehicleControl" [vehicle]="data.vehicle"></app-search-vehicle>
                        <!--Role Input Need to be changed to Driver-->
                        <app-user-search [userControl]="driverControl" [user]="data.driver" roleInput="User"></app-user-search>
                    <div class="flex-end-actions">
                    <button mat-button matStepperNext color="primary">Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="orderFormGroup" [errorMessage]="getOrdersErrorMessage()">
                <form [formGroup]="orderFormGroup">
                <ng-template matStepLabel>Select Orders</ng-template>
                <div class="flex-row align-items-center" >
                    <mat-label mat-dialog-title>Orders</mat-label>
                    <button mat-raised-button color="primary" (click)="orderSearch()">Add Orders</button>
                </div>
                <div class="loading-container" *ngIf="isLoading; else ordersTable">
                    <mat-spinner 
                    [diameter]="80">
                    </mat-spinner>
                </div>
                <ng-template #ordersTable>
                    <table mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="reference">
                          <th mat-header-cell *matHeaderCellDef> Ref. </th>
                          <td mat-cell *matCellDef="let order"> {{order.reference}} </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef> Name </th>
                          <td mat-cell *matCellDef="let order"> {{order.animalName}} </td>
                        </ng-container>
                        <ng-container matColumnDef="weight">
                          <th mat-header-cell *matHeaderCellDef> Weight </th>
                          <td mat-cell *matCellDef="let order"> {{order.weight}} kg </td>
                        </ng-container>
                        <ng-container matColumnDef="animaltype">
                          <th mat-header-cell *matHeaderCellDef> Animal Type </th>
                          <td mat-cell *matCellDef="let order"> {{order.animalType.name}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </ng-template>
                <div *ngIf="orders.length <= 0" class="content mt-1 text-align-center">
                    No Orders Attached
                </div>
                    <div class="flex-end-actions">
                        <button class="mt-1" mat-button matStepperNext color="primary">Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="dateFormGroup" [errorMessage]="getDateErrorMessage()">
                <form [formGroup]="dateFormGroup">
                    <ng-template matStepLabel>Select a Date</ng-template>
                    <mat-label mat-dialog-title>Select a Date</mat-label>
                    <mat-form-field class="w-100">
                        <mat-label>Select a Date</mat-label>
                        <input matInput formControlName="date" [matDatepicker]="picker">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <div class="flex-end-actions">
                        <button mat-button matStepperNext color="primary">Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step>
            <ng-template matStepLabel>Review</ng-template>

        <mat-card>
            <mat-label mat-dialog-title>Schedule Summary</mat-label>
            <mat-card-content class="flex-container">
                <div class="information-container">
                    <span class="ref">Driver</span>
                    <span class="content" *ngIf="vehicleFormGroup.value && vehicleFormGroup.value.driver; else driverEmpty">
                        {{vehicleFormGroup.value.driver.displayName}}
                    </span>
                    <ng-template #driverEmpty>
                        <span class="content">
                            Driver not Selected
                        </span>
                    </ng-template>
                    <br>
                    <span class="ref">Vehicle</span>
                    <span class="content" *ngIf="vehicleFormGroup.value && vehicleFormGroup.value.vehicle; else vehicleEmpty">
                        {{vehicleFormGroup.value.vehicle.make}} {{vehicleFormGroup.value.vehicle.model}} 
                    </span>
                    <ng-template #vehicleEmpty>
                        <span class="content">
                            Vehicle not Selected
                        </span>
                    </ng-template>
                    <br>
                    <span class="ref">Scheduled Date</span>
                    <span class="content" *ngIf="dateFormGroup.value && dateFormGroup.value.date"> 
                        {{dateFormGroup.value.date | date:'dd-MM-yyyy'}}
                    </span><br>
                    <span class="ref">Scheduled Route</span>
                    <span class="content" *ngIf="routeFormGroup.value && routeFormGroup.value.route"> 
                        {{routeFormGroup.value.route.name}}
                    </span><br> 
                    <span class="ref"> Orders :</span>
                    @for(order of orders; track $index)
                    {
                        <span class="content"> 
                            {{order.reference}} - {{order.animalName}}
                        </span><br>
                    }
                </div>
            </mat-card-content>         
        </mat-card>
            <div class="flex-end-actions">
                <button class="confirm-btn btn-box" *ngIf="!isSaving" mat-raised-button color="primary" [disabled]="!routeFormGroup.valid || !vehicleFormGroup.valid || !orderFormGroup.valid || !dateFormGroup.valid" (click)="onSubmit()">
                    Update Schedule
                </button>
                <div *ngIf="isSaving btn-box">
                    <mat-spinner
                    [diameter]="50"></mat-spinner>
                </div> 
            </div>
            </mat-step>
        </mat-vertical-stepper>
    </div>
</div>
