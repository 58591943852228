<h2 mat-dialog-title>
    Update order status
</h2>
<mat-spinner *ngIf="!order || !order.status"></mat-spinner>
<form [formGroup]="changeStatusForm" (ngSubmit)="onSubmit()">
<mat-dialog-content *ngIf="order && order.status" class="flex">
        <p>From {{order.status.name}}</p>
        <p>to</p>
        <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select [disabled]="isSaving" [formControl]="statusIdControl" (selectionChange)="updateChosenStatus($event)">
                <mat-option>Clear</mat-option>
                <mat-option *ngFor="let status of validTransitions" value="{{status.id}}">{{status.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="chosenStatus != null" >
            <p *ngIf="chosenStatus.requirements && chosenStatus.requirements.length > 0">This transition requires the following to be completed:</p>
            <section *ngFor="let requirement of chosenStatus.requirements; let i = index">
                <!--Requirements as per what is needed-->
                <mat-card appearance="raised" class="p-1 my-1">
                <mat-card-subtitle class="pb-1">{{requirement.name}}</mat-card-subtitle>
                    <div *ngIf="requirement.photoRequired" formArrayName="photoDisplayControls">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Upload File</mat-label>
                            <input type="text" [formControlName]="i" matInput/>
                            <mat-hint>Allowed formats: .png, .jpg, .webp</mat-hint>
                        </mat-form-field>
                        <button mat-raised-button class="me-1" (click)="openFileExplorer($event,i)">
                            <mat-icon color="primary">drive_folder_upload_icon</mat-icon>    
                            Upload
                        </button>
                        <button mat-raised-button (click)="openCamera($event,requirement.id,i)">
                            <mat-icon color="primary">photo_camera</mat-icon>
                            Camera
                        </button>
                    </div>
                <div *ngIf="requirement.photoRequired" formArrayName="photoControls">
                    <input hidden type="file" (change)="onFileChange($event)" [attr.id]="'fileInput-' + i" /><!--[formControlName]="i" -->
                </div>
                </mat-card>
            </section>
        </div>
        <mat-divider></mat-divider>
        <p>The order will move from {{order.currentPlace.name}}</p>
        <p>to</p>
        <mat-form-field>
            <mat-label>Place</mat-label>
            <!--TODO: Autofill based on context-->
            <!-- This could be based in the Order Statuses, or from external components-->
            <mat-select [disabled]="isSaving || isLoading" [formControl]="toPlaceControl" (optionSelected)="onPlaceControlSelected($event)">
                <mat-option value="Current">Stay in the same location</mat-option>
                <mat-option value="Vehicle">Vehicle</mat-option>
                <mat-option value="Clinic">Clinic</mat-option>
                <mat-option value="Custom">Other</mat-option>
            </mat-select>
        </mat-form-field>
        <!-- SELECT VEHICLE -->
        <app-search-vehicle 
            *ngIf="toPlaceControl.value == 'Vehicle'"
            [vehicleControl]="vehicleControl">
        </app-search-vehicle>
        <!-- SELECT CLINIC -->
        <app-search-clinic 
            *ngIf="toPlaceControl.value == 'Clinic'"
            [clinicControl]="clinicControl">
        </app-search-clinic>
        <mat-form-field *ngIf="toPlaceControl.value == 'Custom'">
            <mat-label>Specify</mat-label>
            <input [formControl]="customPlaceControl" placeholder="e.g. Freezer 1" matInput [disabled]="isSaving"/>
        </mat-form-field>
        <button [disabled]="isSaving || isLoading" (click)="openScanner()" class="form-field-button" mat-stroked-button color="primary" type="button">
            <mat-icon fontIcon="qr_code_scanner" ></mat-icon>
            Scan QR code
        </button>
        <br>
        <mat-divider></mat-divider>
        <br>
        <mat-form-field class="field-full-width">
            <mat-label>Comment</mat-label>
            <!-- TODO: -->
            <textarea matInput [formControl]="commentControl" placeholder="Ex. Moved because of..."></textarea>
        </mat-form-field>
        <br>
    </mat-dialog-content>
    <mat-dialog-actions class="flex-end-actions">
        <button mat-raised-button color="info" mat-dialog-close>Close</button>
        <div class="save-spinner">
            <mat-spinner *ngIf="isSaving"
            [diameter]="30"
            ></mat-spinner>
        </div>
        <button *ngIf="!isSaving" [disabled]="changeStatusForm.invalid" mat-raised-button color="primary" type="submit">Update Status</button>
    </mat-dialog-actions>
</form>