<div class="container">
    <button mat-raised-button routerLink="/clinics" type="button" class="back-btn">Back</button>
    <div class="header">
        <h2>Create a new Clinic</h2>
    </div>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Name *</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="flex-container">
                    <mat-form-field class="flex-1">
                        <mat-label>Name</mat-label>
                        <input type="text" placeholder="Name" formControlName="name" matInput>
                        <mat-error *ngIf="form.get('name')?.errors?.['required']">
                            Name is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
              <mat-card-title>Location *</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <!-- The location autocomplete component emits a full LocationResponse -->
              <app-search-location *ngIf="!selectedLocation" (locationSelected)="onLocationSelected($event)"></app-search-location>
              <div *ngIf="selectedLocation">
                <p>
                  <strong>Selected Location:</strong>
                  {{ selectedLocation.address.street }}, {{ selectedLocation.address.city }}, {{ selectedLocation.address.state }}
                </p>
              </div>
            </mat-card-content>
          </mat-card>

        <mat-card>
            <mat-card-header>
                <mat-card-title>Contact *</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="contact-header">
                    <div>Only one contact is needed. You can add one new contact or select from the existing contacts.</div>
                    <div class="contact-btns">
                        <button mat-raised-button color="primary" type="button" (click)="openAddContactDialog()" class="btn">Add New</button>
                        <button mat-raised-button color="primary" type="button" (click)="openSearchContactDialog()" class="btn">Search existing contacts</button>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="flex-container" *ngIf="contact">
                    <p class="flex-20">First Name:  {{ contact.firstName }}</p>
                    <p class="flex-20">Last Name: {{ contact.lastName }}</p>
                    <p class="flex-20">Email: {{ contact.email }}</p>
                    <p class="flex-20">Phone: {{ contact.phone }}</p>
                    <p class="flex-20">Contact method: {{ contact.preferredContactMethod }}</p>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <mat-card-title>Note</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="flex-container">
                    <mat-form-field appearance="fill" class="flex-1">
                        <mat-label>Note</mat-label>
                        <textarea matInput formControlName="note"></textarea>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </mat-card>
        <div class="actions flex-end-actions">
            <button mat-raised-button color="primary" type="submit">Add</button>
        </div>
    </form>    
</div>