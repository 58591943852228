<div>
  <mat-card >
    <div *ngIf="isLoading" class="loading-container">
        <mat-spinner
        [diameter]="80"></mat-spinner>
    </div>
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" *ngIf="!isLoading" [@expand]>
        <ng-container matColumnDef="CreatedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date">CreatedAt</th>
          <td mat-cell *matCellDef="let element"> {{element.createdAt | date:"medium"}} </td>
        </ng-container>
        <ng-container matColumnDef="ToStatus">
          <th mat-header-cell *matHeaderCellDef> ToStatus </th>
          <td mat-cell *matCellDef="let element"> {{element.toStatus.name}} </td>
        </ng-container>
        <ng-container matColumnDef="FromStatus">
          <th mat-header-cell *matHeaderCellDef> FromStatus </th>
          <td mat-cell *matCellDef="let element"> {{element.fromStatus.name}} </td>
        </ng-container>
        <ng-container matColumnDef="ToPlace">
          <th mat-header-cell *matHeaderCellDef> ToPlace </th>
          <td mat-cell *matCellDef="let element"> {{element.toPlace.name}} </td>
        </ng-container>
        <ng-container matColumnDef="FromPlace">
          <th mat-header-cell *matHeaderCellDef> FromPlace </th>
          <td mat-cell *matCellDef="let element"> {{element.fromPlace.name}} </td>
        </ng-container>
        <ng-container *ngIf="filteredUsers" matColumnDef="CreatedBy">
          <th mat-header-cell *matHeaderCellDef> CreatedBy </th>
          <td mat-cell *matCellDef="let element"> {{getUserName(element.createdBy)}} </td>
        </ng-container>
        <ng-container matColumnDef="Attachments" stickyEnd >
          <th mat-header-cell *matHeaderCellDef class="attachment-header" > Attached Documents </th>
          <td mat-cell *matCellDef="let element">
            @if(element.attachmentResponses){
            <div class="attachment-column">
              <button mat-icon-button color="primary" matTooltip="Open Attachments" (click)="openAttachment(element.attachmentResponses)">
                <mat-icon>open_in_browser_icon</mat-icon>
                </button>
            </div>
            } @else 
            {
              <div class="attachment-column">
                -
              </div>
            }
          </td>
        </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
    </table>
  </mat-card>
</div>
<div *ngIf="!dataSource" class="spinner-container">
  <mat-spinner [diameter]="150" [strokeWidth]="3"></mat-spinner>
</div>