import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function fieldsFilledValidator(controlNames: string[]): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const controls = controlNames.map(name => formGroup.get(name));

    if (controls.some(control => !control)) {
      return null; // If any control is not found, return null
    }

    const filledCount = controls.filter(control => control?.value).length;

    // Validation passes if all are empty or all are filled
    if (filledCount === 0 || filledCount === controls.length) {
      return null;
    }

    // If not all are filled, return a validation error
    return { fieldsFilled: true };
  };
}
export function dateLessThanTodayValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const inputDate = new Date(control.value); // Get the control value as a date
    const today = new Date(); // Get the current date
    today.setHours(0, 0, 0, 0); // Set the time to 00:00:00 for comparison

    // If the input date is less than the current date, return an error
    return inputDate < today ? { dateLessThanToday: true } : null;
  };
}
