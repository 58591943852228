import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatLabel } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { ContactResponse, PreferredContactMethod } from '../../../models/contact.model';
import { Observable, map, startWith } from 'rxjs';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {
  MatDialogModule,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { ContactService } from '../../../services/contact.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact-search-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatLabel,
    MatDialogModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    MatButtonModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule
  ],
  templateUrl: './contact-search-dialog.component.html',
  styleUrl: './contact-search-dialog.component.scss'
})
export class ContactSearchDialogComponent implements OnInit{
  selected!: ContactResponse;
  contactControl = new FormControl<ContactResponse | null>(null);
  contactFilteredOptions!: Observable<ContactResponse[]>;
  contactMethods = Object.values(PreferredContactMethod);

  contacts!: ContactResponse[];

  constructor(public dialogRef: MatDialogRef<ContactSearchDialogComponent>, private contactService: ContactService, private toastr: ToastrService) {}

  ngOnInit() {
    this.getData();
  }
  
    setFilteredContacts() {
      this.contactFilteredOptions = this.contactControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          const searchTerm = typeof value === 'string' ? value : value?.firstName;
          return searchTerm ? this.contactFilter(searchTerm as string) : this.contacts.slice();
        }),
      );
    }

  getData() {
    this.contactService.getContacts().subscribe({
      next: (res) => {
        this.contacts = res.contacts;
        this.setFilteredContacts();
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
      }
    })
  }

  private contactFilter(searchTerm: string): ContactResponse[] {
    const filterValue = searchTerm.toLowerCase();
    return this.contacts.filter(option => 
      (option.firstName.toLowerCase()+ ' ' + option.lastName.toLocaleLowerCase()).includes(filterValue) ||
      (option.email && option.email?.toLowerCase().includes(filterValue)) ||
      (option.phone && option.phone?.toLowerCase().includes(filterValue))
    );
  }

  displayContactFn(contact: ContactResponse): string {
    return contact && typeof contact === 'object' && contact.firstName && contact.lastName
    ? `${contact.firstName} ${contact.lastName}`
    : '';
  }

  onSelectionContact(contact: ContactResponse) {
    this.selected = contact;
  }

  onSave() {
    this.dialogRef.close(this.selected);
  }
}
