import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'app-action-list',
  standalone: true,
  imports: [RouterLink, MatListModule, RouterLinkActive],
  templateUrl: './action-list.component.html',
  styleUrl: './action-list.component.scss'
})
export class ActionListComponent {
  @Input() linkPath!: string;   // Path for the routerLink
  @Input() linkLabel!: string;  // Text to display for the link

  constructor() {
  }

}
