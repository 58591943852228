import { AnimalTypeResponse } from "../animalType.model";
import { ClinicResponse } from "../clinic.model";
import { ContactResponse } from "../contact.model";
import { LocationResponse } from "../location.model";
import { CommunicationLogResponse } from "./communicationLog.model";
import { PlaceResponse } from "./place.model";

export enum CremationType {
    Private = "Private",
    Referral = "Referral",
    Communal = "Communal"
}

export class OrderStatusResponse {
    id: string;
    name: string;
    customerDisplayName: string;
    validTransitions: string[];
    requirements: StatusRequirementResponse[];
    description: string;
    isDefault: boolean;

    constructor(id: string, name: string, customerDisplayName: string, validTransitions: string[] = [], requirements: StatusRequirementResponse[] = [], description: string, isDefault: boolean) {
        this.id = id;
        this.name = name;
        this.validTransitions = validTransitions;
        this.requirements = requirements;
        this.customerDisplayName = customerDisplayName;
        this.description = description;
        this.isDefault = isDefault;
    }
}

export class ChangeOrderStatusRequest {
    statusId: string;
    toPlaceId: string;
    comment?: string;
    UploadAttachments?: AttachmentFileRequest[];

    constructor(statusId:string,toPlaceId:string,comment:string = ""){
        this.statusId = statusId;
        this.toPlaceId = toPlaceId;
        this.comment = comment;
    }
}
export class Attachment
{
    id:string;
    filePath: string;
    requirement:StatusRequirementResponse
    createdAt: Date;
    constructor(id: string, filePath: string,requirement: StatusRequirementResponse,createdAt:Date)//requirement : requirement
    {
        this.id = id;
        this.filePath = filePath;
        this.requirement = requirement;
        this.createdAt = createdAt;

    }
}
export class AttachmentWithFile extends Attachment
{
    fileUrl: string | null = null
    loaded: boolean = false
}
export class AttachmentFileRequest
{   
    requirementId: string;
    base64file: string;

    constructor(requirementId: string, base64file: string){
        this.requirementId = requirementId;
        this.base64file = base64file;
    }
}

export class CreateOrderStatus {
    name: string;
    customerDisplayName: string;
    description: string;

    constructor(name: string, customerDisplayName: string, description: string) {
        this.name = name;
        this.customerDisplayName = customerDisplayName;
        this.description = description;
    }
}

export class UpdateOrderStatus {
    name: string;
    customerDisplayName: string;
    validTransitions:string[];
    requirements:string[];
    description: string;
    isDefault: boolean;

    constructor(name: string, customerDisplayName: string, validTransitions:string[], requirements:string[], description: string, isDefault: boolean) {
        this.name = name;
        this.customerDisplayName = customerDisplayName;
        this.validTransitions = validTransitions;
        this.requirements = requirements;
        this.description = description;
        this.isDefault = isDefault;
    }
}

export class OrderResponse {
    id: string;
    reference: string;
    clinic: ClinicResponse;
    status: OrderStatusResponse;
    currentPlace: PlaceResponse;
    cremationType: string;
    weight: Number;
    pickupLocation: LocationResponse;
    dropOffLocation: LocationResponse;
    primaryContactId: string;
    contacts: ContactResponse[];
    animalName: string;
    animalType: AnimalTypeResponse;
    createdAt: string;
    lastUpdatedAt: string;
    communicationLog: CommunicationLogResponse[];
    payment: PaymentResponse[];
    specialInstructions?: string;

    constructor(
        id: string,
        reference: string,
        clinic: ClinicResponse,
        status: OrderStatusResponse,
        currentPlace: PlaceResponse,
        cremationType: string,
        pickupLocation: LocationResponse,
        dropOffLocation: LocationResponse,
        animalType: AnimalTypeResponse,
        animalName: string,
        createdAt: string,
        lastUpdatedAt: string,
        primaryContactId: string,
        contacts: ContactResponse[],
        weight: number,
        communicationLog: CommunicationLogResponse[],
        payment: PaymentResponse[],
        specialInstructions: string
    ) {
        this.id = id;
        this.reference = reference;
        this.clinic = clinic;
        this.status = status;
        this.currentPlace = currentPlace;
        this.cremationType = cremationType;
        this.pickupLocation = pickupLocation;
        this.dropOffLocation = dropOffLocation;
        this.animalName = animalName;
        this.animalType = animalType;
        this.createdAt = createdAt;
        this.lastUpdatedAt = lastUpdatedAt;
        this.primaryContactId = primaryContactId;
        this.contacts = contacts;
        this.weight = weight;
        this.communicationLog = communicationLog;
        this.payment = payment;
        this.specialInstructions = specialInstructions
    }
}

export class CreateOrderRequest {
    clinicId: string;
    cremationType: CremationType;
    weight: Number;
    pickUpLocationId: string;
    dropOffLocationId: string;
    primaryContactId: string;
    contactIds: string[];
    animalName: string;
    animalTypeId: string;
    specialInstructions?: string;
    packageIds: string[];
    productIds: string[];

    constructor(
        clinicId: string,
        cremationType: CremationType,
        weight: Number,
        pickUpLocationId: string,
        dropOffLocationId: string,
        primaryContactId: string,
        contactIds: string[],
        animalName: string,
        animalTypeId: string,
        specialInstructions: string,
        packageIds: string[],
        productIds: string[],
    ) {
        this.clinicId = clinicId;
        this.cremationType = cremationType;
        this.weight = weight;
        this.pickUpLocationId = pickUpLocationId;
        this.dropOffLocationId = dropOffLocationId;
        this.primaryContactId = primaryContactId;
        this.contactIds = contactIds;
        this.animalName = animalName;
        this.animalTypeId = animalTypeId;
        this.specialInstructions = specialInstructions;
        this.packageIds = packageIds;
        this.productIds = productIds;
    }
}

export class UpdateOrderRequest {
    clinicId: string;
    cremationType: CremationType;
    weight: Number;
    pickUpLocationId: string;
    dropOffLocationId: string;
    primaryContactId: string;
    contactIds: string[];
    animalName: string;
    animalTypeId: string;
    specialInstructions?: string;
    communicationLog: CommunicationLogResponse[];
    packageIds: string[];
    productIds: string[];

    constructor(
        clinicId: string,
        cremationType: CremationType,
        weight: Number,
        pickUpLocationId: string,
        dropOffLocationId: string,
        primaryContactId: string,
        contactIds: string[],
        animalName: string,
        animalTypeId: string,
        specialInstructions: string,
        communicationLog: CommunicationLogResponse[],
        packageIds: string[],
        productIds: string[],
    ) {
        this.clinicId = clinicId;
        this.cremationType = cremationType;
        this.weight = weight;
        this.pickUpLocationId = pickUpLocationId;
        this.dropOffLocationId = dropOffLocationId;
        this.primaryContactId = primaryContactId;
        this.contactIds = contactIds;
        this.animalName = animalName;
        this.animalTypeId = animalTypeId;
        this.specialInstructions = specialInstructions;
        this.communicationLog = communicationLog;
        this.packageIds = packageIds;
        this.productIds = productIds;
    }
}

export class StatusRequirementResponse{
    id:string;
    name:string;
    description:string;
    photoRequired:boolean;
    createdAt:string;
    lastUpdatedAt?:string;

    constructor(id:string, name:string, description:string, photoRequired:boolean, createdAt:string, lastUpdatedAt:string) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.photoRequired = photoRequired;
        this.createdAt = createdAt;
        this.lastUpdatedAt = lastUpdatedAt;
    }
}

export class CreateRequirementRequest {
    name:string;
    description:string;
    photoRequired:boolean;
    constructor(name:string, description:string, photoRequired:boolean) {
        this.name = name;
        this.description = description;
        this.photoRequired = photoRequired;
    }
}

export class EditRequirementRequest {
    name?:string;
    description?:string;
    photoRequired?:boolean;
    constructor(name?:string, description?:string, photoRequired?:boolean) {
        if (name !== undefined) {
            this.name = name;
        }
        if (description !== undefined) {
        this.description = description;
        }
        if (photoRequired !== undefined) {
        this.photoRequired = photoRequired;
        }
    }
}
//Schedule Order Response
export class ScheduleOrderResponse {
    id: string;
    reference: string;
    clinicId: string;
    statusId: string;
    currentPlaceId: string;
    cremationType: string;
    weight: Number;
    pickupLocationId: string;
    dropOffLocationId: string;
    primaryContactId: string;
    animalName: string;
    animalTypeId: string;
    createdAt: string;
    lastUpdatedAt: string;
    specialInstructions?: string;

    constructor(
        id: string,
        reference: string,
        clinicId: string,
        statusId: string,
        currentPlaceId: string,
        cremationType: string,
        pickupLocationId: string,
        dropOffLocationId: string,
        animalTypeId: string,
        animalName: string,
        createdAt: string,
        lastUpdatedAt: string,
        primaryContactId: string,
        weight: number,
        specialInstructions: string
    ) {
        this.id = id;
        this.reference = reference;
        this.clinicId = clinicId;
        this.statusId = statusId;
        this.currentPlaceId = currentPlaceId;
        this.cremationType = cremationType;
        this.pickupLocationId = pickupLocationId;
        this.dropOffLocationId = dropOffLocationId;
        this.animalName = animalName;
        this.animalTypeId = animalTypeId;
        this.createdAt = createdAt;
        this.lastUpdatedAt = lastUpdatedAt;
        this.primaryContactId = primaryContactId;
        this.weight = weight;
        this.specialInstructions = specialInstructions
    }
}
//frontend only TODO: will need to modify once backend is implemented
export class OrderStatusWithRequirementResponse {
    id: string;
    name: string;
    customerDisplayName: string;
    description: string;
    isDefault: boolean;
    nextTransitionIds: string[];
    requirements: TransitionRequirement[];

    constructor(id: string, name: string, customerDisplayName: string, description: string, isDefault: boolean, nextTransitionIds: string[], requirements: TransitionRequirement[]) {
        this.id = id;
        this.name = name;
        this.customerDisplayName = customerDisplayName;
        this.description = description;
        this.isDefault = isDefault;
        this.nextTransitionIds = nextTransitionIds;
        this.requirements = requirements;
    }
}

export class CreateOrderStatusWithRequirementResponse {
    name: string;
    customerDisplayName: string;
    description: string;
    isDefault: boolean;
    nextTransitionIds: string[];
    requirements: TransitionRequirement[];

    constructor(name: string, customerDisplayName: string, description: string, isDefault: boolean, nextTransitionIds: string[], requirements: TransitionRequirement[]) {
        this.name = name;
        this.customerDisplayName = customerDisplayName;
        this.description = description;
        this.isDefault = isDefault;
        this.nextTransitionIds = nextTransitionIds;
        this.requirements = requirements;
    }
}

export class TransitionRequirement {
    id: string;
    requirement: string;
    photoRequired: boolean;
    // photoUrl?: string;

    constructor(id: string, requirement: string, photoRequired: boolean, 
        // photoUrl?: string
    ) {
        this.id = id;
        this.requirement = requirement;
        this.photoRequired = photoRequired;
        // this.photoUrl = photoUrl;
    }
}
