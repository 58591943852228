<mat-card>
    <mat-card-title>
        <h2 mat-dialog-title>Place Details</h2>
    </mat-card-title>
    <mat-card-content>
        <mat-dialog-content *ngIf="place && place.reference">
            <div class="flex-container">
                <qrcode [qrdata]="generateQrLink(place.reference)" [elementType]="'img'" [errorCorrectionLevel]="'M'"
                    [margin]="3" [scale]="1" [width]="200"></qrcode>
                <mat-list>
                    <mat-list-item>
                        <span matListItemTitle>Name</span>
                        <span matListItemLine>{{place.name}}</span>
                    </mat-list-item>
                    <mat-list-item>
                        <span matListItemTitle>Place Reference</span>
                        <span matListItemLine>{{place.reference}}</span>
                    </mat-list-item>
                    <mat-list-item>
                        <span matListItemTitle>Type</span>
                        <span matListItemLine>{{place.type}}</span>
                    </mat-list-item>
                </mat-list>
            </div>
            <!-- VEHICLE DETAILS -->
            <mat-list *ngIf="place.type && place.type == 'Vehicle'">
                <mat-list-item *ngIf="vehicle">
                    <span matListItemTitle>
                        Vehicle Registration Number
                    </span>
                    <span matListItemLine>
                        {{vehicle.registration}}
                    </span>
                </mat-list-item>
                <mat-spinner [diameter]="40" *ngIf="!vehicle"></mat-spinner>
            </mat-list>
            <!-- CLINIC DETAILS -->
            <mat-list *ngIf="place.type && place.type == 'Clinic'">
                <mat-list-item *ngIf="clinic">
                    <span matListItemTitle>
                        Clinic Name
                    </span>
                    <span matListItemLine>
                        {{clinic.name}}
                    </span>
                </mat-list-item>
                <mat-spinner [diameter]="40" *ngIf="!clinic"></mat-spinner>
            </mat-list>
        </mat-dialog-content>
    </mat-card-content>
    <mat-dialog-actions class="flex-end-actions">
        <button mat-raised-button mat-dialog-close>Cancel</button>
        <button (click)="generatePdf(place)" mat-raised-button color="primary" type="button">Print Sticker</button>
    </mat-dialog-actions>
</mat-card>