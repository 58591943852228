import { trigger, transition, style, animate, state } from '@angular/animations';

export const expandFade = trigger('expandFade', [
    transition(':enter', [
      style({ opacity: 0, height: 0 }),
      animate('500ms ease-out', style({ opacity: 1, height: '*' }))
    ]),
    transition(':leave', [
      animate('300ms ease-in', style({ opacity: 0, height: 0 }))
    ])
  ])
export const fadeIn = trigger('fadeIn', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('500ms ease-in',
            style({ opacity: 1 }))
  ])
])
export const invisTofadeIn = trigger('invisTofadeIn', [
  state('hidden', style({
    opacity: 0
  })),
  state('visible', style({
    opacity: 1
  })),
  transition('hidden <=> visible', [
    animate('500ms ease-in')
  ])
])
export const expand = trigger('expand', [
  transition(':enter', [
    style({ opacity: 0, height: 0 ,overflow: 'hidden'}),
    animate('1500ms ease-out', style({ opacity: 1, height: '*'}))
  ])
])
export const expandPage = trigger('expandPage', [
  state('expanded', style({
    height: '*',
    opacity: 1,
    overflow: 'hidden'
  })),
  transition('collapsed => expanded', [
    animate('300ms ease-out')
  ])
])
export const expandCollapse = trigger('expandCollapse', [
  state('collapsed', style({
    height: '0px',
    opacity: 0,
    overflow: 'hidden'
  })),
  state('expanded', style({
    height: '*',
    opacity: 1,
    overflow: 'hidden'
  })),
  transition('collapsed => expanded', [
    animate('300ms ease-out')
  ]),
  transition('expanded => collapsed', [
    animate('300ms ease-in')
  ])
])