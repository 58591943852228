import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { OrderService } from '../../../services/order.service';
import { ChangeOrderStatusRequest, OrderResponse } from '../../../models/orders/order.model';
import { ScheduleOrderResponse, ScheduleResponse } from '../../../models/schedule.model';
import { CommonModule } from '@angular/common';
import { PlaceService } from '../../../services/place.service';
import { lastValueFrom } from 'rxjs';
import { GetOrCreatePlaceRequest } from '../../../models/orders/place.model';
import { VehicleResponse } from '../../../models/vechicle.model';
import { MatButtonModule } from '@angular/material/button';
import { SchedulesService } from '../../../services/schedules.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-schedule-pickup',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
  ],
  templateUrl: './schedule-pickup.component.html',
  styleUrl: './schedule-pickup.component.scss'
})
export class SchedulePickupComponent implements OnInit {
  orderReference!: string;
  order!: OrderResponse;
  scheduleOrder!: ScheduleOrderResponse;
  scheduleOrderId!: string;
  validStatuses!: string[];
  schedule!: ScheduleResponse;
  clinicId!: string;
  statusId!: string | null;

  constructor(
    private orderService: OrderService,
    private scheduleService: SchedulesService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { 
      orderReference: string, 
      scheduleOrderId: string,
      schedule: ScheduleResponse,
    }
  ) {
    this.orderReference = data.orderReference;
    this.scheduleOrderId = data.scheduleOrderId;
    this.schedule = data.schedule;
  }

  ngOnInit(): void {
    this.getOrder();
  }

  getOrder(): void {
    const orderId = this.schedule.orders.find((scheduleOrder) => scheduleOrder.id === this.scheduleOrderId)?.order!.id ?? null;
    if (!orderId) {
      console.error('Order not found');
      return;
    }
    this.orderService.getOrder(orderId).subscribe({
      next: (response) => {
        this.order = response;
        if(this.order.status.validTransitions.length == 1) {
          console.log(this.order.status.validTransitions[0]);
          this.statusId = this.order.status.validTransitions[0];
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  async onSubmit():Promise<void> {
    console.log(this.statusId);
    if(this.statusId === null) {
      console.error('Status is required');
      return;
    }
    this.scheduleService.processScheduleOrder(this.schedule.id, this.order.id, this.statusId).subscribe({
      next: (response) => {
        this.toastr.success('Order processed successfully', 'Success');
      },
      error: (error) => {
        console.error(error);
        this.toastr.error('Failed to process order', 'Error');
      }
    });
  }
}