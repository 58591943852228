import { Component, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ScheduleResponse, ScheduleOrderResponse } from '../../../models/schedule.model';
import { ClinicResponse } from '../../../models/clinic.model';
import { OrderService } from '../../../services/order.service';
import { ClinicService } from '../../../services/clinic.service';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { SchedulesService } from '../../../services/schedules.service';
import { AddressResponse } from '../../../models/location.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-table',
  standalone: true,
  imports: [
    CommonModule, 
    MatTableModule, 
    MatProgressSpinnerModule,
    MatButtonModule
  ],
  templateUrl: './order-table.component.html',
  styleUrl: './order-table.component.scss'
})
export class OrderTableComponent implements OnInit, OnChanges {
  @Input() schedule!: ScheduleResponse;
  @Input() scheduleOrders!: ScheduleOrderResponse[];
  clinics: ClinicResponse[] = [];
  isLoading = true;
  displayedColumns: string[] = ['orderNumber', 'orderType' , 'clinic', 'status', 'location','actions'];
  dataSource = new MatTableDataSource<ScheduleOrderResponse>([]);

  constructor(
    private clinicService: ClinicService,
    private scheduleService: SchedulesService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.loadInitialData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['schedule'] || changes['scheduleOrders']) {
      this.loadInitialData(); // Reload data when inputs change
    }
  }

  loadInitialData(): void {
    if (this.schedule && this.scheduleOrders) {
      this.dataSource.data = this.schedule.orders;
      this.loadClinics();
    } else {
      console.log("NO SCHEDULE FOUND");
    }
  }

  getScheduleOrders(): void {
    this.isLoading = true;
    this.scheduleService.getScheduleOrders(this.schedule.id).subscribe((orders) => {
      this.scheduleOrders = orders;
      this.dataSource.data = this.scheduleOrders; // Assign data to the data source
      this.isLoading = false;
    });
  }

  loadClinics(): void {
    this.isLoading = true;
    this.clinicService.getClinics().subscribe((clinics) => {
      this.clinics = clinics.clinics;
      this.isLoading = false;
    });
  }

  getClinicName(clinicId: string): string {
    const clinic = this.clinics.find(c => c.id === clinicId);
    return clinic ? clinic.name : 'Unknown Clinic';
  }

  viewOrder(id: string): void {
    this.router.navigate([`/orders/${id}`]);
  }

  formatAddress(address: AddressResponse | undefined): string {
    if (!address) {
      return 'Address not available';
    }
    return `${address.street}, ${address.city}`;
  }
}
