import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { count, map, Observable, startWith } from 'rxjs';
import { VehicleResponse } from '../../../models/vechicle.model';
import { ClinicResponse } from '../../../models/clinic.model';
import { CommonModule } from '@angular/common';
import { FormControl, Validators,ReactiveFormsModule,FormGroup, Form } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { VechicleService } from '../../../services/vehicle.service';
import { ToastrService } from 'ngx-toastr';
import { PagedQueryResult } from '../../../models/pagedQueryResult.interface';
import { MatDialogTitle } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-search-vehicle',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    CommonModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './search-vehicle.component.html',
  styleUrl: './search-vehicle.component.scss'
})

// This component is designed to be used as a form input.

export class SearchVehicleComponent implements OnInit {
@Input() isSaving:boolean=false; // pass in the form saving variable to disable inputs on the form
@Input() vehicle!:VehicleResponse
@Input() vehicleControl:FormControl = new FormControl(); // Pass in the form control to be manipulated by this selector.
@Output() vehicleSelected = new EventEmitter<VehicleResponse>(); 
value:string = ''
// Output

  vehicles!:VehicleResponse[]
  filteredVehicles: Observable<VehicleResponse[]>;

  constructor(
    private vehicleService:VechicleService,
    private toastr:ToastrService
  )
  {
    this.filteredVehicles = this.vehicleControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterVehicles(value))
    );
  
  }
  ngOnInit(): void {
    this.getVehicles()
    if(this.vehicle)
      {
        this.vehicleControl.setValue(this.vehicle)
        this.value = this.vehicleControl.value
      }
  }
  
  private _filterVehicles(value: string): VehicleResponse[] {
    const filterValue = value.toLowerCase();
    return this.vehicles.filter(vehicle => vehicle.name.toLowerCase().includes(filterValue));
  }
  
  getVehicles(): void {
    this.vehicleService.getAllVehicles().subscribe({
      next: (res:PagedQueryResult<VehicleResponse>) => {
        this.vehicles = res.items;
      }
    })
  }

  vehicleName(vehicle:VehicleResponse): string {
    return vehicle && typeof vehicle === 'object' && vehicle.name ? vehicle.name : "";
  }
  resetValue()
  {
    this.vehicleControl.reset()
    this.value = ''
  }
  onVehicleSelected(event:any):void
  {
    // Output
    this.value = this.vehicleControl.value;
    this.vehicleSelected.emit(this.vehicleControl.value);
  }

}
