<div class="container">
    <button mat-raised-button routerLink="/configurations/locations" type="button" class="back-btn">Back</button>
    <div class="header">
        <h2>Create a new location</h2>
    </div>
    
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Location information</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="flex-container">
                    <mat-form-field appearance="fill" class="flex-1">
                        <mat-label>Name</mat-label>
                        <input type="text" placeholder="Name" formControlName="name" matInput>
                        <mat-error *ngIf="form.get('name')?.errors?.['required']">
                            Name is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <mat-card-title>Address</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="flex-container">
                    <mat-form-field appearance="fill" class="flex-1">
                        <mat-label>Search the address...</mat-label>
                        <input type="text" placeholder="Street" matInput [matAutocomplete]="auto" [formControl]="addressSearchControl">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectionChanged($event)">
                            <mat-option *ngFor="let candidate of filteredAddresses | async" [value]="candidate">
                            {{ candidate.address }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="flex-container">
                    <mat-form-field appearance="fill" class="flex-50">
                        <mat-label>Unit </mat-label>
                        <input type="text" placeholder="Unit" formControlName="unit" matInput>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="flex-50">
                        <mat-label>Street</mat-label>
                        <input type="text" placeholder="Street" formControlName="street" matInput>
                        <mat-error *ngIf="form.get('street')?.errors?.['required']">
                            Street is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex-container">
                    <mat-form-field appearance="fill" class="flex-50">
                        <mat-label>City</mat-label>
                        <input type="text" placeholder="City" formControlName="city" matInput>
                        <mat-error *ngIf="form.get('city')?.errors?.['required']">
                            City is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="flex-50">
                        <mat-label>State</mat-label>
                        <input type="text" placeholder="State" formControlName="state" matInput>
                        <mat-error *ngIf="form.get('state')?.errors?.['required']">
                            State is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex-container">
                    <mat-form-field appearance="fill" class="flex-50">
                        <mat-label>Post Code</mat-label>
                        <input type="text" placeholder="Post Code" formControlName="postCode" matInput>
                        <mat-error *ngIf="form.get('postCode')?.errors?.['pattern'] && form.get('postCode')?.touched">
                            4 digits allowed
                        </mat-error>
                        <mat-error *ngIf="form.get('postCode')?.errors?.['required']">
                            Post Code is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="flex-50">
                        <mat-label>Country</mat-label>
                        <input type="text" placeholder="Country" formControlName="country" matInput>
                        <mat-error *ngIf="form.get('country')?.errors?.['required']">
                            Country is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex-container">
                    <mat-form-field appearance="fill" class="flex-50">
                        <mat-label>Latitude</mat-label>
                        <input type="text" placeholder="Latitude" formControlName="latitude" matInput>
                        <mat-error *ngIf="form.get('latitude')?.errors?.['pattern'] && form.get('latitude')?.touched">
                            Invalid format
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="flex-50">
                        <mat-label>Longitude</mat-label>
                        <input type="text" placeholder="Longitude" formControlName="longitude" matInput>
                        <mat-error *ngIf="form.get('longitude')?.errors?.['pattern'] && form.get('longitude')?.touched">
                            Invalid format
                        </mat-error>
                    </mat-form-field>
                </div>

                <mat-error *ngIf="form.hasError('fieldsFilled')">
                    Both cooridnate fields must be filled if either one is filled.
                </mat-error>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <mat-card-title>Note</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="flex-container">
                    <mat-form-field appearance="fill" class="flex-1">
                        <mat-label>Note</mat-label>
                        <textarea matInput formControlName="note"></textarea>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </mat-card>
        <div class="actions flex-end-actions">
            <button mat-raised-button color="primary" type="submit">Add</button>
        </div>
    </form>    
</div>