<div class="container">
    <button mat-raised-button routerLink="/orders" type="button" class="back-btn">Back</button>
    <div class="header">
        <h2>Bulk Update Order Status</h2>
    </div>
    <div class="section">
        <p>Orders can be added to the list by:</p>
        <mat-list>
            <mat-list-item>
                <span>Scanning Order QR Reference code</span>
            </mat-list-item>
            <mat-list-item>
                <span>Adding by typing in the Reference code manually</span>
            </mat-list-item>
            <mat-list-item>
                <span>Searching and selecting the orders</span>
            </mat-list-item>
        </mat-list>
        <br>
        <button mat-raised-button color="primary" (click)="toggleCamera()" class="left-btn">Scan QR codes</button>
        <button mat-raised-button color="primary" (click)="toggleManualAdd()" class="left-btn">Add by Reference</button><span></span>
        <button mat-raised-button color="primary" (click)="openOrdersDialog()">Search Orders</button><span></span>
    </div>

    <div *ngIf="manualAddingOrder">
        <mat-form-field appearance="fill" class="flex-50">
            <mat-label>Order Reference</mat-label>
            <input matInput
            [formControl]="orderReference"
            required>
        </mat-form-field>
        <div>
            <button mat-raised-button color="primary" (click)="manualAddOrder()" [disabled]="orderReference.invalid">Add to the list</button>
        </div>
        <br>
    </div>

    <mat-spinner *ngIf="isLoading" diameter="30"></mat-spinner>
    <div [hidden]="!hasDevices">
        <div *ngIf="showScanner">
            <mat-form-field appearance="fill">
                <mat-label>Select Device</mat-label>
                <mat-select [value]="deviceSelected" (valueChange)="onDeviceSelectChange($event)">
                    <mat-option value="">No Device Selected</mat-option>
                    <mat-option *ngFor="let device of availableDevices" [value]="device.deviceId">{{device.label}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    
      <div *ngIf="showScanner" class="scanner-shell">
            <div class="scanner-container">
                <div class="scanner-area">
                    <zxing-scanner [formats]="allowedFormats" [device]="deviceCurrent" (deviceChange)="onDeviceChange($event)" (scanSuccess)="onCodeResult($event)"
                        (permissionResponse)="onHasPermission($event)" (camerasFound)="onCamerasFound($event)" (scanError)="scanErrorHandler($event)"></zxing-scanner>
                    <div class="scanning-line" [class.active]="showScanner"></div>
                </div>
                <div class="button-container">
                    <button mat-raised-button (click)="closeCamera()">Close Scanner</button>
                </div>
            </div>
      </div>
    </div>
    
    <ng-container *ngIf="hasPermission === undefined && showScanner">
      <h4>Waiting for permissions.</h4>
      <blockquote>
        The QR Code scanner requires access to your device's media inputs. Please accept the permission prompt if you wish to use the QR Code scanner.
      </blockquote>
    </ng-container>
    
    <ng-container *ngIf="hasPermission === false && showScanner">
      <h4>Application does not have sufficient permission. Request has been denied by user.</h4>
    </ng-container>
    
    <ng-container *ngIf="hasDevices === undefined && showScanner">
      <h4>Couldn't check for devices.</h4>
      <blockquote>
        Something went wrong. Couldn't scan for media devices.
      </blockquote>
    </ng-container>
    
    <ng-container *ngIf="hasDevices === false && showScanner">
      <h4>No devices were found.</h4>
      <blockquote>
        No suitable media devices found.
      </blockquote>
    </ng-container>
    
    <div *ngIf="referenceResult">
        <div>
            <p>
                Order Scanned: Reference {{ referenceResult }}
            </p>   
        </div> 
        <div>
            <button mat-raised-button color="primary" (click)="addOrder(referenceResult)" type="button">Add to the list</button>
        </div>
    </div>
    <button type="button" mat-raised-button color="primary" (click)="openBulkChangeStatusDialog()">Bulk change Status</button>

    <div *ngIf="orders.length">
        <mat-card>
            <mat-card-content>
                <h4>Orders for update:</h4>
                <div *ngFor="let order of orders" class="flex-row">
                    <div>
                        <p><b>Reference</b>: {{ order.referenceNumber }}</p>
                        <p><b>Animal Name</b>: {{ order.animalName }}</p>
                        <p><b>Animal Type</b>: {{ order.animalType.name }}</p>
                        <p><b>Animal Weight</b>: {{ order.weight }}</p>
                        <p><b>Status</b>: {{ order.status.name }}</p>
                        <p><b>Clinic</b>: {{ order.clinic.name }}</p>
                    </div>
                    <button mat-icon-button aria-label="Remove button" (click)="removeOrderReference(order.referenceNumber.toString())">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>