<h2>Places</h2>
<mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="e.g. name, type, etc." #input>
</mat-form-field>
<table mat-table [dataSource]="dataSource" [@fadeIn]>
    <ng-container matColumnDef="reference">
      <th mat-header-cell *matHeaderCellDef> Ref. </th>
      <td mat-cell *matCellDef="let place"> {{place.reference}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let place"> {{place.name}} </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let place"> {{place.type}} </td>
    </ng-container>
    <ng-container matColumnDef="details">
      <th mat-header-cell *matHeaderCellDef> Details </th>
      <td mat-cell *matCellDef="let place"> {{place.type}} </td>
    </ng-container>
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let place">
            <button (click)="openViewPlaceDialog(place)" mat-icon-button color="primary" type="button">
                <mat-icon>qr_code</mat-icon>
            </button>
        </td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>