<h1 mat-dialog-title>Add stops</h1>
<div mat-dialog-content>
    <p>Search for an order</p>
    <p>Search for a clinic</p>
    <mat-card *ngIf="selectedClinic">
        <mat-card-header>
            <p>{{selectedClinic.name}}</p>
        </mat-card-header>
        <mat-card-content>
            <p>{{selectedClinic.locations[0].name}}</p>
            <button *ngIf="!isActive(selectedClinic.id)" mat-raised-button color="primary" (click)="addClinic(selectedClinic.id)">Add to schedule</button>
            <button *ngIf="isActive(selectedClinic.id)" mat-raised-button (click)="removeClinic(selectedClinic.id)">Remove from schedule</button>
        </mat-card-content>
    </mat-card>
    <mat-card *ngIf="selectedOrder">
        <mat-card-header>
            <p>{{selectedOrder.animalName}}</p>
        </mat-card-header>
        <mat-card-content>
            <p>{{selectedOrder.pickupLocation.name}}</p>
            <button *ngIf="!isActive(selectedOrder.id)" mat-raised-button color="primary" (click)="addOrder(selectedOrder.id)">Add to schedule</button>
            <button *ngIf="isActive(selectedOrder.id)" mat-raised-button (click)="removeOrder(selectedOrder.id)">Remove from schedule</button>
        </mat-card-content>
    </mat-card>
    <h2>Schedule Map</h2>
    <div #mapRef id="map"></div>
</div>