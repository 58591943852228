<h2 mat-dialog-title>Choose Orders</h2>

<!-- LOADING CONTAINER -->

<div *ngIf="isLoading" class="loading-container">
    <mat-spinner [diameter]="80"></mat-spinner>
</div>

<form [formGroup]="ordersForm" (ngSubmit)="onSubmit()">
<mat-dialog-actions *ngIf="!handset" class="flex-end-actions">
    <button mat-raised-button color="info" mat-dialog-close>Close</button>
    <!-- Confirm button -->
    <button [disabled]="ordersForm.invalid" mat-raised-button color="primary" type="submit">Choose selected Orders</button>
</mat-dialog-actions>

    <!-- ORDERS TABLE -->
    <mat-dialog-content style="padding-top: 0;" class="dialog-container" *ngIf="!isLoading">

        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>

                    <mat-checkbox color="primary" (change)="selectAll(); updateSelectedOrders()"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let order">
                    <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                        (change)="selection.toggle(order); updateSelectedOrders()" [checked]="selection.isSelected(order)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference</th>
                <td mat-cell *matCellDef="let order">{{ order.reference }}</td>
            </ng-container>

            <ng-container matColumnDef="clinic">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Clinic</th>
                <td mat-cell *matCellDef="let order">{{ order.clinic.name }}</td>
                <td mat-cell *matCellDef="let order"> {{ order.pickUpLocation.address.unit }} {{
                    order.pickUpLocation.address.street }} </td>
            </ng-container>

            <ng-container matColumnDef="animalName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Animal Name</th>
                <td mat-cell *matCellDef="let order">{{ order.animalName }}</td>
            </ng-container>

            <ng-container matColumnDef="weight">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Weight</th>
                <td mat-cell *matCellDef="let order">{{ order.weight }} {{ order.weight === 1 ? 'kg' : 'kgs'}} </td>
            </ng-container>

            <ng-container matColumnDef="cremationType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Cremation Type </th>
                <td mat-cell *matCellDef="let order">{{ order.cremationType }}</td>
            </ng-container>

            <ng-container matColumnDef="orderStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Status </th>
                <td mat-cell *matCellDef="let order">
                    {{ order.status ? order.status.name : 'null' }}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row;let i = index; columns: displayedColumns;"
            [ngClass]="{
                'active-row active-table-hover': isOrderSelected(i),
                'table-hover': !isOrderSelected(i)
            }"
            (click)="selectOrder(i)" 
            ></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter</td>
            </tr>
        </table>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="handset" class="flex-end-actions">
        <button mat-raised-button color="primary" (click)="selectAllOption($event)">@if(isAllSelected()){Deselect All}@else{Select All}</button>
        <button mat-raised-button color="info" mat-dialog-close>Close</button>
        <!-- Confirm button -->
        <button [disabled]="ordersForm.invalid" mat-raised-button color="primary" type="submit">Choose selected Orders</button>
    </mat-dialog-actions>
</form>
