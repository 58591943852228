import { TDocumentDefinitions } from "pdfmake/interfaces";
import { OrderResponse } from "../models/orders/order.model";
import { ContactResponse } from "../models/contact.model";
import pdfMake from "pdfmake/build/pdfmake";


export function generateOrderTag(order: OrderResponse) {
    const primaryContact:ContactResponse | null = order.contacts.find(c => c.id === order.primaryContactId) ?? null;

    if (!primaryContact) {
        console.error('Primary contact not found');
        return;
    }

    const docDefinition: TDocumentDefinitions = {
      content: [
        {
          columns: [
            {
              width: '50%',
              stack: [
                { text: 'Order Reference: ' + order.referenceNumber, style: 'header' },
                { text: 'Order Type: ' + order.cremationType, style: 'subHeader' },
                {
                  columns: [
                    {
                      width: '27%',
                      text: 'Clinic:'
                    },
                    {
                      width: '*',
                      text: ''
                    },
                    {
                      width: '70%',
                      text: order.pickupLocation.name
                    }
                  ],
                  style: 'paragraph'
                },
                {
                  columns: [
                    {
                      width: '27%',
                      text: 'Pick Up Location:'
                    },
                    {
                      width: '*',
                      text: ''
                    },
                    {
                      width: '70%',
                      text:
                        order.pickupLocation.address.unit + ' ' +
                        order.pickupLocation.address.street + ', ' +
                        order.pickupLocation.address.city + ', ' +
                        order.pickupLocation.address.state + ', ' +
                        order.pickupLocation.address.postCode
                    }
                  ],
                  style: 'paragraph'
                },
                {
                  columns: [
                    {
                      width: '27%',
                      text: 'Return Clinic:'
                    },
                    {
                      width: '*',
                      text: ''
                    },
                    {
                      width: '70%',
                      text: order.dropOffLocation.name
                    }
                  ],
                  style: 'paragraph'
                },
                {
                  columns: [
                    {
                      width: '27%',
                      text: 'Drop Off Location:'
                    },
                    {
                      width: '*',
                      text: ''
                    },
                    {
                      width: '70%',
                      text:
                        order.dropOffLocation.address.unit + ' ' +
                        order.dropOffLocation.address.street + ', ' +
                        order.dropOffLocation.address.city + ', ' +
                        order.dropOffLocation.address.state + ', ' +
                        order.dropOffLocation.address.postCode
                    }
                  ],
                  style: 'paragraph'
                },
                {
                  columns: [
                    {
                      width: '27%',
                      text: 'Owner Last Name:'
                    },
                    {
                      width: '*',
                      text: ''
                    },
                    {
                      width: '70%',
                      text: primaryContact?.lastName
                    }
                  ],
                  style: 'paragraph'
                },
                {
                  columns: [
                    {
                      width: '27%',
                      text: 'Animal Name:'
                    },
                    {
                      width: '*',
                      text: ''
                    },
                    {
                      width: '70%',
                      text: order.animalName
                    }
                  ],
                  style: 'paragraph'
                },
                {
                  columns: [
                    {
                      width: '27%',
                      text: 'Animal Type:'
                    },
                    {
                      width: '*',
                      text: ''
                    },
                    {
                      width: '70%',
                      text: order.animalType.name
                    }
                  ],
                  style: 'paragraph'
                },
                {
                  columns: [
                    {
                      width: '27%',
                      text: 'Animal Weight:'
                    },
                    {
                      width: '*',
                      text: ''
                    },
                    {
                      width: '70%',
                      text: order.weight + ' Kgs'
                    }
                  ],
                  style: 'paragraph'
                },

                { text: 'Please scan the QR code to see details for this order', style: 'subHeader2' },
                { qr: window.location.origin + `/orders/${order.referenceNumber}`, fit: 100, style: 'qrCode' }

              ],
            }
          ]
        }
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [-25, -20, 25, 8],
        },
        subHeader: {
          fontSize: 14,
          margin: [-25, 0, 25, 10],
          background: 'black',
          color: 'white',
        },
        subHeader2: {
          fontSize: 10,
          bold: true,
          margin: [-25, 10, 25, 15],
        },
        paragraph: {
          fontSize: 8,
          margin: [-25, 0, 28, 8]
        },
        qrCode: {
          alignment: 'center',
          margin: [-25, 0, 40, 5]
        }
      }
    }

    pdfMake.createPdf(docDefinition).download('order-' + order.referenceNumber + '.pdf');
  }