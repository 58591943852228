<div class="container">
    <mat-form-field class="mat-elevation-z8">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Goodwood" #input>
    </mat-form-field>
    <div *ngIf="isLoading" class="loading-container">
        <mat-spinner
        [diameter]="80"></mat-spinner>
    </div>   
    <div *ngIf="!isLoading" class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSourceClinics" matSort>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let row"> {{row.name}} </td>
            </ng-container>

            <ng-container matColumnDef="note">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Note </th>
                <td mat-cell *matCellDef="let row"> {{row.note}} </td>
            </ng-container>
            <ng-container matColumnDef="isSelected">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Selected </th>
                <td mat-cell *matCellDef="let row" > <mat-icon *ngIf="row.isSelected == true"color="primary">check</mat-icon> </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsClinics"></tr>
            <tr mat-row *matRowDef="let row; let i = index;columns: displayedColumnsClinics;"      
            [ngClass]="{
                'active-row active-table-hover': isClinicSelected(row),
                'table-hover': !isClinicSelected(row)
            }" 
            (click)="selectClinic(i)">
            </tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>
        
    </div>
    <!-- <mat-paginator [pageSizeOptions]="[25, 50, 100]" aria-label="Select page of clinics" showFirstLastButtons></mat-paginator> -->
</div>