import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagedQueryResult } from '../models/pagedQueryResult.interface';
import { CreateScheduleRequest, ScheduleResponse, UpdateScheduleRequest } from '../models/schedule.model';

@Injectable({
  providedIn: 'root'
})
export class SchedulesService {

  baseUrl = `${environment.apiUrl}/api/schedules`; 
  constructor(private http: HttpClient) { }

  getSchedules(): Observable<PagedQueryResult<ScheduleResponse>> {
    return this.http.get<PagedQueryResult<ScheduleResponse>>(`${this.baseUrl}`);
  }
  getSchedule(id:string): Observable<ScheduleResponse> {
    return this.http.get<ScheduleResponse>(`${this.baseUrl}/${id}`);
  }
  getTodaysSchedules(userId:string): Observable<ScheduleResponse[]> {
    return this.http.get<ScheduleResponse[]>(`${this.baseUrl}/${userId}/driver`);
  }
  createSchedule(data: CreateScheduleRequest): Observable<ScheduleResponse> {
    return this.http.post<ScheduleResponse>(`${this.baseUrl}`, data);
  }
  updateSchedule(id: string, data: UpdateScheduleRequest): Observable<ScheduleResponse> {
    return this.http.patch<ScheduleResponse>(`${this.baseUrl}/${id}`, data);
  }
  deleteSchedule(id:string): Observable<204> {
    return this.http.delete<204>(`${this.baseUrl}/${id}`);
  }
}
