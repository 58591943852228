<div class="container" >
    <div class="header">
        <h2>Clinics</h2>
    </div>

    <div class="flex-row">
        <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
        </mat-form-field>
        <button mat-raised-button color="primary" routerLink="/clinics/create" class="add-btn" *ngIf="isAdmin">Add</button>
    </div>

    <!--LOADING SPINNER-->

    <div *ngIf="isLoading"  class="loading-container">
        <mat-spinner
        [diameter]="80"></mat-spinner>
    </div>

    <!-- ACTUAL CLINICS TABLE-->

    <div class="mat-elevation-z8" [@invisTofadeIn]="isLoading ? 'hidden': 'visible'">
        <div *ngIf="!isLoading">
            <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Route Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions </th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="button-container">
                        <button mat-icon-button color="primary" class="edit-btn" matTooltip="Edit clinic"
                            [routerLink]="['/clinics', element.id]">
                            <mat-icon>edit</mat-icon>
                        </button>

                        <button mat-icon-button color="warn" matTooltip="Delete clinic"
                            (click)="openDeleteDialog(element)">
                            <mat-icon>delete</mat-icon>
                        </button>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                        <div class="details" 
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div *ngIf="isExpandLoading" class="w-100 loading-container">
                                <mat-spinner
                                [diameter]="80"></mat-spinner>
                            </div>  
                            <div *ngIf="!isExpandLoading" class="mat-elevation-z8 mb-1" style="overflow-y: hidden;">
                                <mat-card>
                                    <table mat-table [dataSource]="userDataSource">
                                        <ng-container matColumnDef="givenName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Given Name </th>
                                            <td mat-cell *matCellDef="let row"> {{row.givenName}} </td>
                                        </ng-container>
                        
                                        <ng-container matColumnDef="surname">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Surname </th>
                                            <td mat-cell *matCellDef="let row"> {{row.surname}} </td>
                                        </ng-container>
                                    
                                        <ng-container matColumnDef="displayName">
                                            <th mat-header-cell *matHeaderCellDef> Display Name </th>
                                            <td mat-cell *matCellDef="let row"> {{row.displayName}} </td>
                                        </ng-container>
                            
                                        <ng-container matColumnDef="role">
                                            <th mat-header-cell *matHeaderCellDef> Role </th>
                                            <td mat-cell *matCellDef="let row"> {{row.role ? row.role : 'User'}} </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="accountEnabled">
                                            <th mat-header-cell *matHeaderCellDef> Account Enabled </th>
                                            <td mat-cell *matCellDef="let row">
                                                <ng-container *ngIf="row.accountEnabled === null; else statusTemplate">
                                                </ng-container>
                                                <ng-template #statusTemplate>
                                                <span *ngIf="row.accountEnabled; else warnTemplate" class="text-primary">
                                                    <mat-icon aria-hidden="false" aria-label="enabled" fontIcon="check"></mat-icon>
                                                </span>
                                                <ng-template #warnTemplate>
                                                    <span class="text-warn">
                                                        <mat-icon aria-hidden="false" aria-label="disabled" fontIcon="close"></mat-icon>
                                                    </span>
                                                </ng-template>
                                                </ng-template>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="userDisplayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: userDisplayedColumns;"></tr>
                                    
                                        <!-- Row shown when there is no matching data. -->
                                        <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell text-align-center" colspan="5">No User Assigned to this Clinic.</td>
                                        </tr>
                                    </table>
                                </mat-card>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr 
                mat-row *matRowDef="let element; columns: displayedColumns;"
                class="table-row"
                [class.expanded-row]="expandedElement === element"
                (click)="expandClinic(element)">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row active-row"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>
            </table>
        </div>
        <mat-paginator [ngStyle]="{ 'display': isLoading ? 'none' : 'block' }" [pageSizeOptions]="[25, 50, 100]" aria-label="Select page of clinics" showFirstLastButtons></mat-paginator>
    </div>
</div>