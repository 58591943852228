import { Component,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { StatusRequirementResponse } from '../../../../models/orders/order.model';
import { OrderService } from '../../../../services/order.service';
import { CreateRequirementComponent } from '../create-requirement/create-requirement.component';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'app-delete-requirement',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatListModule
  ],
  templateUrl: './delete-requirement.component.html',
  styleUrl: './delete-requirement.component.scss'
})
export class DeleteRequirementComponent {

  isLoading:boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public requirement: StatusRequirementResponse,
    private orderService:OrderService,
    private dialogRef: MatDialogRef<CreateRequirementComponent>,
    private toastr:ToastrService
  ) {}

  onConfirmClick():void {
    this.isLoading = true;
    this.orderService.deleteRequirement(this.requirement.id).subscribe({
      next: () => {
        this.toastr.success("Deleted requirement","Success")
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.toastr.error(error.error,error.name);
        this.dialogRef.close(false);
      }
    });
  }
}
