<div *ngIf="!order" class="loading-container">
    <mat-spinner
    [diameter]="80"></mat-spinner>
</div>
<div *ngIf="order" [@expand] class="hide-overflow">
    <form  [formGroup]="form" (ngSubmit)="onSubmit()" class="order-edit-form">
        <mat-card>
            <mat-card-content>
                <div class="flex-container">
                    <qrcode
                    *ngIf="order && order.reference"
                    [qrdata]="generateQrLink(order.reference)"
                    [elementType]="'img'"
                    [errorCorrectionLevel]="'M'"
                    [margin]="3"
                    [scale]="1"
                    [width]="200"
                    ></qrcode>
                    <div class="information-container">
                        <span class="ref">Reference:</span><span *ngIf="order && order.reference">{{ order.reference  }}</span><br>
                        <span class="ref">Created at:</span><span *ngIf="order && order.createdAt">{{ order.createdAt+'Z' | date:'medium'}}</span><br>
                        <span class="ref">Updated at:</span><span *ngIf="order && order.lastUpdatedAt">{{ order.lastUpdatedAt+'Z' | date:'medium' }}</span><br>
                    </div>
                    <div class="information-container">
                        <span class="ref">Order Currently at:</span><span *ngIf="order && order.currentPlace">{{ order.currentPlace.name}}</span><br>
                        <span class="ref">Order Status:</span><span *ngIf="order && order.status">{{ order.status.name}}</span><br>
                    </div>
                    <div class="information-container">
                        <button *ngIf="order" class="action-button" (click)="openUpdateStatusDialog()"  [disabled]="isFormDisabled" mat-flat-button color="primary" type="button">
                            Update Order Status
                        </button>
                        <button class="action-button" (click)="generatePdf()" mat-flat-button color="primary" type="button">
                            Print Order
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-header>
                <mat-card-title>Cremation Information</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="flex-container">
                    <mat-form-field appearance="fill" class="flex-33">
                        <mat-label>Clinic</mat-label>
                        <input 
                            type="text"
                            placeholder="Pick one"
                            matInput
                            [formControl]="clinicControl"
                            [matAutocomplete]="clinicAuto">
                        <mat-autocomplete #clinicAuto="matAutocomplete" [displayWith]="displayClinicFn" (optionSelected)="onSelectionClinic($event.option.value)">
                            @for (option of clinicFilteredOptions | async; track option) {
                                <mat-option [value]="option">{{option.name}}</mat-option>
                            }
                        </mat-autocomplete>
                    </mat-form-field>
            
                    <mat-form-field appearance="fill" class="flex-33">
                        <mat-label>Cremation Type</mat-label>
                        <mat-select formControlName="cremationType">
                            <mat-option *ngFor="let type of cremationTypes" [value]="type">{{ type }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('cremationType')?.errors?.['required']">
                            Cremation Type is required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="flex-container">
                    <mat-form-field appearance="fill" class="flex-50">
                        <mat-label>Pick Up Location</mat-label>
                        <input 
                                type="text"
                                placeholder="Pick one"
                                matInput
                                [formControl]="pickUpControl"
                                [matAutocomplete]="pickUpAuto">
                        <mat-autocomplete #pickUpAuto="matAutocomplete" [displayWith]="displayLocationFn" (optionSelected)="onSelectionPickUp($event.option.value)">
                            @for (option of pickUpFilteredOptions | async; track option) {
                                <mat-option [value]="option">{{option.name}}</mat-option>
                            }
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="flex-50" >
                        <mat-label>Drop Off Location</mat-label>
                        <input 
                            type="text"
                            placeholder="Pick one"
                            matInput
                            [formControl]="dropOffControl"
                            [matAutocomplete]="dropOffAuto">
                        <mat-autocomplete #dropOffAuto="matAutocomplete" [displayWith]="displayLocationFn" (optionSelected)="onSelectionDropOff($event.option.value)" >
                            @for (option of dropOffFilteredOptions | async; track option) {
                                <mat-option [value]="option">{{option.name}}</mat-option>
                            }
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                
            </mat-card-content>
        </mat-card>
    
        <mat-card>
            <mat-card-header>
                <mat-card-title>Animal</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="flex-container">
                    <mat-form-field appearance="fill" class="flex-33">
                        <mat-label>Animal Name</mat-label>
                        <input type="text" placeholder="Animal Name" formControlName="animalName" matInput>
                        <mat-error *ngIf="form.get('animalName')?.errors?.['required']">
                            Animal Name is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="flex-33">
                        <mat-label>Weight in Kgs</mat-label>
                        <input type="text" placeholder="Weight in Kgs" formControlName="weight" (blur)="onDeselect()" matInput>
                        <mat-hint>Up to 2 decimal places</mat-hint>
                        <mat-error *ngIf="form.get('weight')?.errors?.['pattern']">
                            Invalid format
                        </mat-error >
                        <mat-error *ngIf="form.get('weight')?.errors?.['required']">
                            Weight is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="flex-33">
                        <mat-label>Animal Type</mat-label>
                        <mat-select formControlName="animalTypeId">
                            <mat-option *ngFor="let animal of animalTypes" [value]="animal.id">{{ animal.name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('animalTypeId')?.errors?.['required']">
                            Animal Type is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </mat-card>
    
        <mat-card>
            <mat-card-header>
                <mat-card-title>Contact</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="contact-header">
                    <div class="contact-btns">
                        <button mat-raised-button color="primary" type="button" (click)="openAddContactDialog()" [disabled]="isFormDisabled" class="btn">Add New</button>
                        <button mat-raised-button color="primary" type="button" (click)="openSearchContactDialog()" [disabled]="isFormDisabled" class="btn">Search existing contacts</button>
                    </div>
                </div>
                <mat-divider></mat-divider>

                <div *ngIf="form.get('contacts')?.errors">
                    <p class="error">Contact is required, you can either add a new contact or select from the existing contacts.</p>
                </div>

                <div formArrayName="contacts" *ngFor="let contactCtrl of contactsArray.controls; let i = index">
                    <div [formGroupName]="i">
                        <div class="flex-row align-items-center">
                            <mat-radio-group formControlName="isPrimary">
                                <mat-radio-button [value]="true" (change)="setPrimary(i)">Primary Contact</mat-radio-button>
                            </mat-radio-group>
                            <button mat-icon-button (click)="removeContact(i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    <div class="flex-container">
                        <p class="flex-25">First Name: {{ contactCtrl.get('contact')?.get('firstName')?.value }}</p>
                        <p class="flex-25">Last Name: {{ contactCtrl.get('contact')?.get('lastName')?.value }}</p>
                        <p class="flex-25">Email: {{ contactCtrl.get('contact')?.get('email')?.value }}</p>
                        <p class="flex-25">Phone: {{ contactCtrl.get('contact')?.get('phone')?.value }}</p>
                    </div>
                    <mat-divider></mat-divider>
                    </div>
                </div>  
            </mat-card-content>
        </mat-card>
        
        <mat-card>
            <mat-card-header>
                <mat-card-title>Communication Logs</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form [formGroup]="communicationForm" (ngSubmit)="addCommunicationLog()">
                    <div class="flex-container">
                        <mat-form-field appearance="fill" class="flex-50">
                            <!-- TODO ERROR HANDLING -->
                            <mat-label>Contact Target</mat-label>
                            <mat-select formControlName="contactId">
                                <mat-option *ngFor="let type of contacts" [value]="type">{{type.firstName}} {{type.lastName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="form.get('animalTypeId')?.errors?.['required']">
                                Requires Contacts
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="flex-50">
                            <mat-label>Type</mat-label>
                            <mat-select formControlName="communicationType">
                                <mat-option *ngFor="let type of communicationTypes; let i = index" [value]="i">{{type}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="form.get('animalTypeId')?.errors?.['required']">
                                Requires a Type
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="flex-container">
                        <mat-form-field class="flex-1">
                            <mat-label>Add Note</mat-label>
                            <textarea matInput formControlName="note"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="align-items-center flex-row">
                        <button mat-raised-button color="primary" [disabled]="communicationForm.disabled" type="submit">Add Log</button>
                        <div class="align-items-center">
                            View Logs
                        <button mat-icon-button matTreeNodeToggle (click)="toggleCard($event)" color="primary" style="cursor: pointer;">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{isExpanded ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        </div>
                    </div>
                </form>
                <div [@expandCollapse]="isExpanded ? 'expanded' : 'collapsed'" class="flex-container communication-log">
                    <app-order-communication-index class="w-100" [orderId]="order.id"></app-order-communication-index>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <mat-card-title>Other Infomation</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="flex-container">
                    <mat-form-field class="flex-1">
                        <mat-label>Special Instructions</mat-label>
                        <textarea matInput formControlName="specialInstructions"></textarea>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <mat-card-title>Packages</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <button mat-stroked-button color="primary" type="button">
                    <mat-icon>add</mat-icon>
                    Package
                </button>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <mat-card-title>Products</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <button mat-stroked-button color="primary" type="button">
                    <mat-icon>add</mat-icon>
                    Product
                </button>
            </mat-card-content>
        </mat-card>

        <div class="actions flex-end-actions">
            <button mat-raised-button color="primary" type="button" (click)="generatePdf()" class="left-btn">Print</button>
            <button *ngIf="!savingOrder"  mat-raised-button color="primary" [disabled]="isFormDisabled" type="submit">Save</button>
            <mat-spinner *ngIf="savingOrder" [diameter]="30" [strokeWidth]="3"></mat-spinner>
        </div>
    </form>
</div>    