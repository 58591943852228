import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { AnimalTypeResponse, CreateAnimalTypeResponse, UpdateAnimalTypeRequest } from '../../../models/animalType.model';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
  MatDialogModule,
} from '@angular/material/dialog';
import { OrderService } from '../../../services/order.service';
import { DeleteDialogComponent } from '../../../components/delete-dialog/delete-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../services/auth.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { fadeIn, invisTofadeIn } from '../../../../assets/animations';

@Component({
  selector: 'app-animal-type-index',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSortModule,
    MatTableModule,
    MatDialogModule,
    MatPaginatorModule,
    MatIconModule,
    MatTooltipModule,
    CommonModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './animal-type-index.component.html',
  styleUrl: './animal-type-index.component.scss',
  animations:[invisTofadeIn]
})

export class AnimalTypeIndexComponent implements OnInit, AfterViewInit{
  displayedColumnsAnimalTypes: string[] = ['id', 'name'];
  dataSourceAnimalTypes = new MatTableDataSource<AnimalTypeResponse>();
  isLoading:boolean = true;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  role: string = '';
  isAdmin: boolean = false;

  constructor(
    public dialog: MatDialog, 
    private orderService: OrderService, 
    private toastr: ToastrService, 
    private breakpointObserver: BreakpointObserver,
    private loginService: AuthService
  ) { 
      this.loginService.claims$.subscribe((c) => {
        this.role = c['extension_Role'];
      });
    }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      if (result.matches) {
        this.displayedColumnsAnimalTypes = ['name']; // MOBILE DISPLAYS
      } else {
        this.displayedColumnsAnimalTypes = ['name']; // DESKTOP DISPLAYS
      }

      if(this.role == 'globeadmin' || this.role == 'awladmin') {
        this.displayedColumnsAnimalTypes.push('actions');
        this.isAdmin = true;
      }
    });
  }
  
  ngAfterViewInit(): void {
    this.getData();
  }

  getData() {
    this.orderService.getAnimalTypes().subscribe({
      next: (res) => {
        this.dataSourceAnimalTypes.data = res.items;
        this.dataSourceAnimalTypes.paginator = this.paginator;
        this.dataSourceAnimalTypes.sort = this.sort;
        this.isLoading = false;
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
      }
    })    
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceAnimalTypes.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceAnimalTypes.paginator) {
      this.dataSourceAnimalTypes.paginator.firstPage();
    }
  }

  openCreateDialog() {
    const dialogRef = this.dialog.open(AnimalTypeCreateDialog, {
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.orderService.addAnimalType(result).subscribe({
          next: (res) => {
            this.toastr.success('Animal type created successfully.');
            this.getData();
          },
          error: (error) => {
            this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
          }
        });
      }
    })
  }

  openEditDialog(element: AnimalTypeResponse) {
    const dialogRef = this.dialog.open(AnimalTypeEditDialog, {
      width: '400px',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.orderService.updateAnimalType(element.id, result).subscribe({
          next: (res) => {
            this.toastr.success('Animal type updated successfully.');
            this.getData();
          },
          error: (error) => {
            this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
          }
        });
      }
    })
  }

  openDeleteDialog(element: AnimalTypeResponse) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: { type: "animal type", name: element.name }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.orderService.deleteAnimalType(element.id).subscribe({
          next: (res) => {
            this.toastr.success('Animal type deleted successfully.');
            this.getData();
          },
          error: (error) => {
            if(error.status == '409') {
              this.toastr.error('This animal type cannot be deleted because it is associated with other entities, such as a clinic.');
            } else {
              this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
            }
          }
        });
      }
    })
  }
}

@Component({
  selector: 'animal-type-create-dialog',
  template: 
  `
  <h2 mat-dialog-title>Add Animal Type</h2>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
      <mat-form-field appearance="fill">
        <mat-label>Animal Type</mat-label>
        <input type="text" placeholder="Animal Type" formControlName="name" matInput>
        <mat-error *ngIf="form.get('name')?.errors?.['required']">
          Name is required
        </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Description</mat-label>
          <input type="text" placeholder="Description" formControlName="description" matInput>
          <mat-error *ngIf="form.get('description')?.errors?.['required']">
          Description is required
          </mat-error>
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions class="flex-end-actions">
        <button mat-raised-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" type="submit">Add</button>
      </mat-dialog-actions>
  </form>

  `,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    CommonModule
  ],
  styles: 
  `
    .mat-mdc-dialog-title {
      font-size: large;
      padding-top: 8px;
      display: flex;
      align-items: center;
    }
    mat-form-field {
      width: 100%
    }
  `
})
export class AnimalTypeCreateDialog implements OnInit{
  form!:FormGroup;
  constructor(public dialogRef: MatDialogRef<AnimalTypeCreateDialog>, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required]
    })
  }

  onSubmit() {
    if(this.form.valid) {
      const animalType: CreateAnimalTypeResponse = {
        name: this.form.value.name,
        description: this.form.value.description,
      }
      this.dialogRef.close(animalType)
    }
  }
}

@Component({
  selector: 'animal-type-edit-dialog',
  template: 
  `
  <h2 mat-dialog-title>Edit Animal Type</h2>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
      <mat-form-field appearance="fill">
        <mat-label>Animal Type</mat-label>
        <input type="text" placeholder="Animal Type" formControlName="name" matInput>
        <mat-error *ngIf="form.get('name')?.errors?.['required']">
          Name is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Description</mat-label>
        <input type="text" placeholder="Description" formControlName="description" matInput>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions class="flex-end-actions">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" type="submit">Save</button>
    </mat-dialog-actions>
  </form>

  `,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    CommonModule
  ],
  styles: 
  `
    .mat-mdc-dialog-title {
      font-size: large;
      padding-top: 8px;
      display: flex;
      align-items: center;
    }
    
    mat-form-field {
      width: 100%
    }
  `
})
export class AnimalTypeEditDialog implements OnInit{
  form!:FormGroup;
  constructor(public dialogRef: MatDialogRef<AnimalTypeEditDialog>, @Inject(MAT_DIALOG_DATA) public data: AnimalTypeResponse, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.data.name, Validators.required],
      description: [''],
    });
  }

  onSubmit() {
    if(this.form.valid) {
      const animalType: UpdateAnimalTypeRequest = {
        name: this.form.value.name,
        description: this.form.value.description
      }
      this.dialogRef.close(animalType)
    }
  }
}