import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable, Subject, map, startWith } from 'rxjs';
import { ContactResponse, PreferredContactMethod } from '../../../models/contact.model';
import { MatButtonModule } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { RouterLink } from '@angular/router';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ContactNewDialogComponent } from '../../contacts/contact-new-dialog/contact-new-dialog.component';
import { ContactSearchDialogComponent } from '../../contacts/contact-search-dialog/contact-search-dialog.component';
import { AnimalTypeResponse } from '../../../models/animalType.model';
import { LocationResponse } from '../../../models/location.model';
import { ClinicResponse } from '../../../models/clinic.model';
import { CremationType, OrderResponse, OrderStatusResponse, OrderStatusWithRequirementResponse, TransitionRequirement, UpdateOrderRequest } from '../../../models/orders/order.model';
import { OrderService } from '../../../services/order.service';
import { CommunicationLogResponse, CreateCommunicationLogRequest } from '../../../models/orders/communicationLog.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { StatusDialogComponent } from '../status-dialog/status-dialog.component';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { MatProgressSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { QRCodeModule } from 'angularx-qrcode';
import { ChangeSingleOrderStatusComponent } from '../change-single-order-status/change-single-order-status.component';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { OrderCommunicationIndexComponent } from '../order-communication-index/order-communication-index.component';

import { ToastrService } from 'ngx-toastr';
import { LocationService } from '../../../services/location.service';
import { ClinicService } from '../../../services/clinic.service';
import { ContactService } from '../../../services/contact.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommunicationTypes } from '../../../helpers/communicationTypes';
import { CommunicationLogsService } from '../../../services/communication-logs.service';
import { AuthService, roles } from '../../../services/auth.service';
import { expand } from '../../../../assets/animations';

@Component({
  selector: 'app-order-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDivider,
    RouterLink,
    MatRadioModule,
    MatIconModule,
    MatDialogModule,
    MatTableModule,
    QRCodeModule,
    MatListModule,
    MatProgressSpinnerModule,
    ChangeSingleOrderStatusComponent,
    OrderCommunicationIndexComponent
  ],
  templateUrl: './order-edit.component.html',
  styleUrl: './order-edit.component.scss',
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  },DecimalPipe],
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({
        height: '0px', // Collapsed height
        opacity: 0,
        overflow: 'hidden'
      })),
      state('expanded', style({
        height: '*',  // Expanded to full height
        opacity: 1,
        overflow: 'hidden'
      })),
      transition('collapsed => expanded', [
        animate('300ms ease-out')
      ]),
      transition('expanded => collapsed', [
        animate('300ms ease-in')
      ])
    ]),expand
  ]
})

export class OrderEditComponent implements OnInit {
  @Input() orderRefFromTable!: string;
  @Output() orderUpdated = new EventEmitter<void>();

  isFormDisabled:boolean = false

  order!: OrderResponse;
  form!: FormGroup;
  savingOrder: boolean = false;
  addingContact: boolean = false;
  searchingContact: boolean = false;
  selectedContacts: ContactResponse[] = [];

  clinicControl = new FormControl<string | ClinicResponse>('');
  pickUpControl = new FormControl<string | LocationResponse>('');
  dropOffControl = new FormControl<string | LocationResponse>('');

  clinicFilteredOptions!: Observable<ClinicResponse[]>;
  pickUpFilteredOptions!: Observable<LocationResponse[]>;
  dropOffFilteredOptions!: Observable<LocationResponse[]>;

  communicationForm!: FormGroup;

  //mock up data
  orders: OrderResponse[] = [];
  clinics: ClinicResponse[] = [];
  locations: LocationResponse[] = [];
  contacts: ContactResponse[] = [];
  animalTypes: AnimalTypeResponse[] = [];
  statuses: OrderStatusResponse[] = [];

  cremationTypes = Object.values(CremationType);
  contactMethods = Object.values(PreferredContactMethod);

  //ComLogs
  @ViewChild(OrderCommunicationIndexComponent) communicationLog!: OrderCommunicationIndexComponent;
  isExpanded:boolean = false;
  communicationTypesKeys = Object.keys(CommunicationTypes)
  communicationTypes = Object.values(CommunicationTypes).filter(key => isNaN(Number(key)));
  
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private orderService: OrderService,
    private locationService: LocationService,
    private clinicService: ClinicService,
    private toastr: ToastrService,
    private contactService: ContactService,
    private decimalPipe: DecimalPipe,
    private communicationService: CommunicationLogsService,
    private loginService: AuthService
  ) { }

  ngOnInit(): void {

    this.getData();
    if (this.orderRefFromTable) {
      this.getOrder(this.orderRefFromTable);
    } else {
      this.route.paramMap.subscribe(params => {
        const orderReference = params.get('ref');
        if (orderReference) {
          this.getOrder(orderReference);
        }
      })
    }

  }
  initForm()
  {
    this.form = this.fb.group({
      clinicId: ['', Validators.required],
      status: ['',],
      cremationType: ['', Validators.required],
      weight: ['', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(0.01)]],
      pickUpLocationId: ['', Validators.required],
      dropOffLocationId: ['', Validators.required],
      contactId: [''],
      animalName: ['', Validators.required],
      animalTypeId: ['', Validators.required],
      specialInstructions: [''],
      contacts: this.fb.array([], Validators.required),
      primaryContactId: [''],
      communicationLog: this.fb.array([]),
      // createdBy: [''], //timestamp
    });

    //communicateForm
    this.communicationForm = new FormGroup({
      note: new FormControl('', Validators.required),
      communicationType: new FormControl('', Validators.required),
      contactId: new FormControl('', Validators.required),
    });

    this.loginService.claims$.subscribe((c) => {
      const role = c['extension_Role'];
      if(role == roles.clinicstaff)
        {
          this.toggleForm()
        }
    });
  }
  toggleForm()
  {
    this.isFormDisabled = !this.isFormDisabled
    this.form.disabled ? this.form.enable() :  this.form.disable();
    this.communicationForm.disabled ? this.communicationForm.enable() :  this.communicationForm.disable();
    this.clinicControl.disabled ? this.clinicControl.enable() :  this.clinicControl.disable();
    this.pickUpControl.disabled ? this.pickUpControl.enable() :  this.pickUpControl.disable();
    this.dropOffControl.disabled ? this.dropOffControl.enable() :  this.dropOffControl.disable();
  }

  getOrder(ref: string) {
    this.orderService.getOrderByReference(ref).subscribe({
      next: (res) => {
        this.order = res;
        this.contacts = res.contacts
        this.patchOrderData(this.order);
        this.pickUpControl.setValue(this.order.pickupLocation);
        this.dropOffControl.setValue(this.order.dropOffLocation);
        this.clinicControl.setValue(this.order.clinic);
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred');
      }
    })
    this.initForm();
  }

  //get other data
  getData() {
    this.clinicService.getClinics().subscribe({
      next: (result) => {
        this.clinics = result.clinics;
        this.setFilteredClinics();
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred when getting the clinics. Please try again.');
      }
    });

    this.locationService.getLocations().subscribe({
      next: (result) => {
        this.locations = result.locations;
        this.setFilteredLocations();
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred when getting the locations. Please try again.');
      }
    });

    this.orderService.getAnimalTypes().subscribe({
      next: (result) => {
        this.animalTypes = result.items;
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred when getting the animalTypes. Please try again.');
      }
    });

    this.orderService.getOrderStatuses().subscribe({
      next: (result) => {
        this.statuses = result.items;
      },
      error: (error) => {
        this.toastr.error(error.error?.title || 'An error occurred when getting the order statuses. Please try again.');
      }
    })
  }
  patchOrderData(order: OrderResponse) {
    this.form.patchValue({
      clinicId: order.clinic.id,
      weight: order.weight,
      cremationType: order.cremationType,
      pickUpLocationId: order.pickupLocation.id,
      dropOffLocationId: order.dropOffLocation.id,
      animalName: order.animalName,
      animalTypeId: order.animalType.id,
      specialInstructions: order.specialInstructions,
      primaryContactId: order.primaryContactId,
      status: order.status ? order.status.id : null,
    });
    this.patchContacts(order.contacts, order.primaryContactId);
  }

  patchContacts(contacts: ContactResponse[], primaryContactId: string) {
    const contactFormGroups = contacts.map(contact =>
      this.createContactFormGroup(contact, contact.id === primaryContactId)
    );
    this.contactsArray.clear();
    contactFormGroups.forEach(contactFormGroup => {
      this.contactsArray.push(contactFormGroup);
    });
  }

  createContactFormGroup(contact: ContactResponse, isPrimary: boolean): FormGroup {
    const contactFormGroup = this.fb.group({
      contact: this.fb.group({
        id: [contact.id],
        firstName: [contact.firstName, Validators.required],
        lastName: [contact.lastName, Validators.required],
        email: [contact.email, Validators.email],
        phone: [contact.phone],
        PreferredContactMethod: [contact.preferredContactMethod, Validators.required],
        note: [contact.note]
      }),
      isPrimary: [isPrimary]
    });
    return contactFormGroup;
  }

  //communication logs

  addCommunicationLog() {
    if (this.communicationForm.valid) {
      const newLog: CreateCommunicationLogRequest = {
        orderId: this.order.id,
        contactId: this.communicationForm.value.contactId.id,
        communicationType: this.communicationForm.value.communicationType,
        note: this.communicationForm.value.note

      };
      this.communicationService.createCommunicationLogForOrder(this.order.id,newLog).subscribe(
        {
          next: (res) => {
            this.communicationLog.getData();
            this.toastr.success('Communication Log Created Succesfully')
          },
          error: (error) => {
            this.toastr.error('Something went wrong creating communication log:' + error.message)
          }
        }

      );

      this.communicationForm.reset();
      Object.keys(this.communicationForm.controls).forEach(key => {
        this.communicationForm.controls[key].setErrors(null);
        this.communicationForm.controls[key].markAsPristine();
        this.communicationForm.controls[key].markAsUntouched();
      })
    }
  }

  setFilteredClinics() {
    this.clinicFilteredOptions = this.clinicControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this.clinicFilter(name as string) : this.clinics.slice();
      }),
    );
  }

  setFilteredLocations() {
    this.pickUpFilteredOptions = this.pickUpControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this.locationFilter(name as string) : this.locations.slice();
      }),
    );

    this.dropOffFilteredOptions = this.dropOffControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this.locationFilter(name as string) : this.locations.slice();
      }),
    );
  }

  private clinicFilter(name: string): ClinicResponse[] {
    const filterValue = name.toLowerCase();
    return this.clinics.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private locationFilter(name: string): LocationResponse[] {
    const filterValue = name.toLowerCase();
    return this.locations.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  displayClinicFn(clinic: ClinicResponse): string {
    return clinic && clinic.name ? clinic.name : '';
  }

  displayLocationFn(location: LocationResponse): string {
    return location && location.name ? location.name : '';
  }

  generateQrLink(reference: string) {
    return window.location.origin + `/orders/${reference}`;
  }

  onSelectionClinic(clinic: ClinicResponse) {
    this.form.patchValue({
      clinicId: clinic.id,
    });

    const defaultLocation = this.locations.find(location => location.id == clinic.defaultLocationId);
    if (defaultLocation) {
      this.pickUpControl.setValue(defaultLocation);
      this.dropOffControl.setValue(defaultLocation);
    }
  }

  onSelectionPickUp(location: LocationResponse) {
    this.form.patchValue({
      pickUpLocationId: location.id,
    });
  }

  onSelectionDropOff(location: LocationResponse) {
    this.form.patchValue({
      dropOffLocationId: location.id,
    });
  }

  get contactsArray() {
    return this.form.get('contacts') as FormArray;
  }

  addContact(contact: ContactResponse) {
    const contactFormGroup = this.fb.group({
      contact: this.fb.group({
        id: [contact.id],
        firstName: [contact.firstName, Validators.required],
        lastName: [contact.lastName, Validators.required],
        email: [contact.email, Validators.email],
        phone: [contact.phone],
        PreferredContactMethod: [contact.preferredContactMethod, Validators.required],
        note: [contact.note]
      }),
      isPrimary: [this.contactsArray.length === 0] // Automatically set the first contact as primary
    });
    this.contactsArray.push(contactFormGroup);
  }

  removeContact(index: number) {
    const wasPrimary = this.contactsArray.at(index).get('isPrimary');
    this.contactsArray.removeAt(index);

    if (wasPrimary && this.contactsArray.length > 0) {
      let anotherPrimaryExists = this.contactsArray.controls.some(c => c.value.isPrimary === true);

      if (!anotherPrimaryExists)
        this.contactsArray.at(0).get('isPrimary')?.setValue(true);
    }
  }

  setPrimary(index: number) {
    this.contactsArray.controls.forEach((control, i) => {
      control.get('isPrimary')?.setValue(i === index);
    })
  }

  onSubmit() {
    const contactIds = this.contactsArray.controls.map(c => c.value.contact.id);
    const primaryContact = this.contactsArray.controls.find(c => c.value.isPrimary === true)?.value.contact;

    if (this.form.valid && primaryContact) {
      this.savingOrder = true;

      //create a new form Data and patch the contactIds and primaryId
      this.form.get('primaryContactId')?.patchValue(primaryContact.id);
      const order: UpdateOrderRequest = {
        clinicId: this.form.value.clinicId,
        cremationType: this.form.value.cremationType,
        weight: this.form.value.weight,
        pickUpLocationId: this.form.value.pickUpLocationId,
        dropOffLocationId: this.form.value.dropOffLocationId,
        primaryContactId: this.form.value.primaryContactId,
        contactIds: contactIds,
        animalName: this.form.value.animalName,
        animalTypeId: this.form.value.animalTypeId,
        specialInstructions: this.form.value.specialInstructions,
        communicationLog: this.form.value.communicationLog,
        packageIds: this.form.value.packageIds,
        productIds: this.form.value.productIds,
      }

      this.orderService.updateOrder(this.order.id, order).subscribe({
        next: (res) => {
          this.toastr.success('Order updated successfully.');
          this.getOrder(this.order.reference);
          this.orderUpdated.emit();
          this.savingOrder = false;
        },
        error: (error) => {
          this.savingOrder = false;
          this.toastr.error(error.error?.title || 'An error occurred when updating the order. Please try again.');
        }
      });

      //TODO: add communication logs etc

    } else {
      this.toastr.error('Validation errors, make sure you fill out all required ');
    }
  }

  //dialogs
  openAddContactDialog() {
    const dialogRef = this.dialog.open(ContactNewDialogComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.contactService.createContact(result).subscribe({
          next: (res) => {
            this.toastr.success('Contact created successfully.');
            this.addContact(res);
          },
          error: (error) => {
            this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
          }
        });
      }
    })
  }

  openUpdateStatusDialog() {
    const dialogRef = this.dialog.open(ChangeSingleOrderStatusComponent, {
      width: '500px',
      data: { order: this.order }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.orderRefFromTable) {
          this.getOrder(this.orderRefFromTable);
        } else {
          this.route.paramMap.subscribe(params => {
            const orderReference = params.get('ref');
            if (orderReference) {
              this.getOrder(orderReference);
              this.orderUpdated.emit();
            }
          })
        }
      }
    });
  }


  openSearchContactDialog() {
    const dialogRef = this.dialog.open(ContactSearchDialogComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (!this.contactsArray.controls.some(c => c.get('contact')?.get('id')?.value === result.id)) {
          this.addContact(result);
        } else {
          this.toastr.error('This contact exists, cannot add it twice');
        }
      }
    })
  }

  //dialog for status requirment checking
  checkStatus(event: MatSelectChange) {
    const selectedStatusName = event.value; // Access the value property from MatSelectChange
    //TODO: check if it sits in the valid statuses range
  }

  oldOrderStatusTest: OrderStatusWithRequirementResponse = {
    name: 'New',
    customerDisplayName: 'Order Received',
    description: 'Test',
    isDefault: false,
    requirements: [
      {
        id: '1',
        requirement: 'Job 1 is done',
        photoRequired: false,
      },
      {
        id: '2',
        requirement: 'Job 2 is done',
        photoRequired: false,
      },
    ],
    id: '1',
    nextTransitionIds: ['2']
  }

  orderStatusTest: OrderStatusWithRequirementResponse = {
    name: 'Cremation',
    customerDisplayName: 'In Progress',
    description: 'Test',
    isDefault: false,
    requirements: [
      {
        id: '1',
        requirement: 'Job 1 is done',
        photoRequired: false,
      },
      {
        id: '2',
        requirement: 'Job 2 is done',
        photoRequired: true,
      },
      {
        id: '3',
        requirement: 'Job 3 is done',
        photoRequired: true,
      }
    ],
    id: '2',
    nextTransitionIds: ['3']
  }

  openStatusDialog(oldStatus: OrderStatusWithRequirementResponse, newStatus: OrderStatusWithRequirementResponse,) {
    const dialogRef = this.dialog.open(StatusDialogComponent, {
      data: { oldStatus, newStatus }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog was closed', result);
      // TODO: further process with the result, like add it to the order status
    });
  }

  //Generate PDF
  generatePdf() {
    const primaryContact = this.contactsArray.controls.find(c => c.value.isPrimary === true)?.value.contact;

    const docDefinition: TDocumentDefinitions = {
      content: [
        {
          columns: [
            {
              width: '50%',
              stack: [
                { text: 'Order Reference: ' + this.order.reference, style: 'header' },
                { text: 'Order Type: ' + this.order.cremationType, style: 'subHeader' },
                {
                  columns: [
                    {
                      width: '27%',
                      text: 'Clinic:'
                    },
                    {
                      width: '*',
                      text: ''
                    },
                    {
                      width: '70%',
                      text: this.order.pickupLocation.name
                    }
                  ],
                  style: 'paragraph'
                },
                {
                  columns: [
                    {
                      width: '27%',
                      text: 'Pick Up Location:'
                    },
                    {
                      width: '*',
                      text: ''
                    },
                    {
                      width: '70%',
                      text:
                        this.order.pickupLocation.address.unit + ' ' +
                        this.order.pickupLocation.address.street + ', ' +
                        this.order.pickupLocation.address.city + ', ' +
                        this.order.pickupLocation.address.state + ', ' +
                        this.order.pickupLocation.address.postCode
                    }
                  ],
                  style: 'paragraph'
                },
                {
                  columns: [
                    {
                      width: '27%',
                      text: 'Return Clinic:'
                    },
                    {
                      width: '*',
                      text: ''
                    },
                    {
                      width: '70%',
                      text: this.order.dropOffLocation.name
                    }
                  ],
                  style: 'paragraph'
                },
                {
                  columns: [
                    {
                      width: '27%',
                      text: 'Drop Off Location:'
                    },
                    {
                      width: '*',
                      text: ''
                    },
                    {
                      width: '70%',
                      text:
                        this.order.dropOffLocation.address.unit + ' ' +
                        this.order.dropOffLocation.address.street + ', ' +
                        this.order.dropOffLocation.address.city + ', ' +
                        this.order.dropOffLocation.address.state + ', ' +
                        this.order.dropOffLocation.address.postCode
                    }
                  ],
                  style: 'paragraph'
                },
                {
                  columns: [
                    {
                      width: '27%',
                      text: 'Owner Last Name:'
                    },
                    {
                      width: '*',
                      text: ''
                    },
                    {
                      width: '70%',
                      text: primaryContact?.lastName
                    }
                  ],
                  style: 'paragraph'
                },
                {
                  columns: [
                    {
                      width: '27%',
                      text: 'Animal Name:'
                    },
                    {
                      width: '*',
                      text: ''
                    },
                    {
                      width: '70%',
                      text: this.order.animalName
                    }
                  ],
                  style: 'paragraph'
                },
                {
                  columns: [
                    {
                      width: '27%',
                      text: 'Animal Type:'
                    },
                    {
                      width: '*',
                      text: ''
                    },
                    {
                      width: '70%',
                      text: this.order.animalType.name
                    }
                  ],
                  style: 'paragraph'
                },
                {
                  columns: [
                    {
                      width: '27%',
                      text: 'Animal Weight:'
                    },
                    {
                      width: '*',
                      text: ''
                    },
                    {
                      width: '70%',
                      text: this.order.weight + ' Kgs'
                    }
                  ],
                  style: 'paragraph'
                },

                { text: 'Please scan the QR code to see details for this order', style: 'subHeader2' },
                { qr: window.location.origin + `/orders/${this.order.reference}`, fit: 100, style: 'qrCode' }

              ],
            }
          ]
        }
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [-25, -20, 25, 8],
        },
        subHeader: {
          fontSize: 14,
          margin: [-25, 0, 25, 10],
          background: 'black',
          color: 'white',
        },
        subHeader2: {
          fontSize: 10,
          bold: true,
          margin: [-25, 10, 25, 15],
        },
        paragraph: {
          fontSize: 8,
          margin: [-25, 0, 28, 8]
        },
        qrCode: {
          alignment: 'center',
          margin: [-25, 0, 40, 5]
        }
      }
    }

    pdfMake.createPdf(docDefinition).download('order-' + this.order.reference + '.pdf');
  }
  onDeselect()
  {
    const value = this.form.value.weight
    const formatted = this.decimalPipe.transform(value, '1.2-2')
    this.form.patchValue({weight:formatted})
  }
  toggleCard(event: Event) {
    event.preventDefault();
    this.isExpanded = !this.isExpanded;
  }
}
