<div class="flex-row">
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilterStatus($event)" placeholder="Ex. Mia" #input>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="openCreateOrderStatusDialog()" class="add-btn" *ngIf="isAdmin">Add</button>
</div>

<div *ngIf="isLoading" class="loading-container">
    <mat-spinner
    [diameter]="80"></mat-spinner>
</div>


<div class="mat-elevation-z8" [@invisTofadeIn]="isLoading ? 'hidden' : 'visible'">
        <div *ngIf="!isLoading" >
            <table mat-table [dataSource]="dataSourceOrderStatuses" matSort #sortOrderStatus="matSort">
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> ID </th>
                    <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                </ng-container>
            
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                </ng-container>
                
                <ng-container matColumnDef="customerDisplayName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Display Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.customerDisplayName}} </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef> Description </th>
                    <td mat-cell *matCellDef="let row"> {{row.description}} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions </th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="button-container">
                            <button mat-icon-button color="primary" class="edit-btn" matTooltip="Edit status"
                                (click)="openEditOrderStatusDialog(element)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button color="warn" matTooltip="Delete order status"
                                (click)="openDeleteOrderStatusDialog(element)">
                                <mat-icon>delete</mat-icon>
                        </button>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsOrderStatuses"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsOrderStatuses;"></tr>
            
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>
            </table>
        </div>
    <div [ngStyle]="{ 'display': isLoading ? 'none' : 'block' }">
        <mat-paginator #paginatorOrderStatus="matPaginator" [pageSizeOptions]="[25, 50, 100]" aria-label="Select page of cremation statuses" showFirstLastButtons></mat-paginator>
        <mat-card>
            <div class="flex-row flowchart-row">
                <button type="button" mat-raised-button color="primary" (click)="openFlowChartDialog()">Status Flowchart</button>
            </div>
        </mat-card>
    </div>
</div>
