<mat-action-list>
    <a mat-list-item
       [routerLink]="linkPath"
       routerLinkActive="active-link"
       #rla="routerLinkActive"
       [routerLinkActiveOptions]="{exact: true}"
       [class.activated]="rla.isActive">
      <span>{{ linkLabel }}</span>
    </a>
</mat-action-list>
  