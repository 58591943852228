import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UpdateUserRequest, UserFromAzure, UserInDB } from '../../../models/user.model';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { ClinicService } from '../../../services/clinic.service';
import { ClinicResponse } from '../../../models/clinic.model';
import { UserService } from '../../../services/user.service';
import {MatSlideToggleModule} from '@angular/material/slide-toggle'; 

@Component({
  selector: 'app-user-edit-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    ReactiveFormsModule,
    MatButtonModule,
    MatLabel,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule
  ],
  templateUrl: './user-edit-dialog.component.html',
  styleUrl: './user-edit-dialog.component.scss'
})
export class UserEditDialogComponent {
  form!: FormGroup;
  user!: UserInDB;
  clinics: ClinicResponse[] = [];

  roles = [
    { azureRole: 'awladmin', displayName: 'AWL Admin' },
    { azureRole: 'awlstaff', displayName: 'AWL Staff' },
    { azureRole: 'clinicadmin', displayName: 'Clinic Admin' },
    { azureRole: 'clinicstaff', displayName: 'Clinic Staff' },
    { azureRole: 'enduser', displayName: 'End User' },
  ]

  constructor(
    private fb: FormBuilder, 
    public dialogRef: MatDialogRef<UserEditDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: string, 
    private clinicService: ClinicService,
    private userService: UserService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      role: ['', Validators.required],
      givenName: ['', Validators.required],
      surname: ['', Validators.required],
      displayName: ['', Validators.required],
      clinicId: ['', Validators.required], 
      accountEnabled: ['', Validators.required],
    });

    this.getData();
  }
  
  patchUser(user: UserInDB) {
    this.form.patchValue({
      givenName: user.givenName,
      surname: user.surname,
      displayName: user.displayName,
      role: user.role,
      clinicId: user.clinicId,
      accountEnabled: user.accountEnabled,
    });
  }

  getData() {
    this.userService.getUser(this.data).subscribe({
      next: (res) => {
        this.user = res;
        console.log(this.user);
        if(this.user) {
          this.patchUser(this.user);
        }
      },
      error: (err) => {
        this.toastr.error(err?.error?.title || 'An error has occurred when getting this user. Please try again');
      }
    });

    this.clinicService.getClinics().subscribe({
      next: (res) => {
        this.clinics = res.clinics;
      },
      error: (err) => {
        this.toastr.error(err?.error?.title || 'An error has occurred when getting the clinics. Please try it again.');
      }
    })
  }
  
    onSubmit() {
      if(this.form.valid) {
        const user: UpdateUserRequest = {
          firstname: this.form.value.givenName,
          lastname: this.form.value.surname,
          displayName: this.form.value.displayName,
          accountEnabled: this.form.value.accountEnabled,
          clinicId: this.form.value.clinicId,
          role: this.form.value.role
        }
        this.dialogRef.close(user);
      } else {
        this.toastr.error('Please correct the highlighted errors and ensure all required fields are filled.');
      }
    }
}
