import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, model, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { vehicle, VehicleResponse } from '../../../models/vechicle.model';
import { VechicleService } from '../../../services/vehicle.service';
import { ToastrService } from 'ngx-toastr';
import { BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { AuthService } from '../../../services/auth.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CreateVehicleComponent } from '../../../components/vehicle/create-vehicle/create-vehicle.component';
import { EditVehicleComponent } from '../../../components/vehicle/edit-vehicle/edit-vehicle.component';
import { DeleteDialogComponent } from '../../../components/delete-dialog/delete-dialog.component';
import { invisTofadeIn } from '../../../../assets/animations';
@Component({
  selector: 'app-vehicles-index',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSortModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './vehicles-index.component.html',
  styleUrl: './vehicles-index.component.scss',
  animations:[invisTofadeIn]
})
export class VehiclesIndexComponent implements OnInit , AfterViewInit {
  isAdmin: boolean = false;
  isLoading:boolean = false;
  role: string = '';

  displayedColumnsVehicles: string[] = ['registration','isAvailable','make','model','name','actions'];
  dataSourceVehicles = new MatTableDataSource<vehicle>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  constructor
  (
    private vechicleService: VechicleService,
    private toastr: ToastrService, 
    private breakpointObserver: BreakpointObserver,
    private loginService: AuthService,
    public dialog: MatDialog, 
  )
  {
    this.loginService.claims$.subscribe((c) => {
      this.role = c['extension_Role'];
    });
  }
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      if (result.matches) {
        this.displayedColumnsVehicles = ['registration','make','model'];
      } else {
        this.displayedColumnsVehicles = ['registration','isAvailable','make','model','name'];
      }

      if(this.role == 'globeadmin' || this.role == 'awladmin' || this.role == 'clinicadmin') {
        this.displayedColumnsVehicles.push('actions');
        this.isAdmin = true;
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() =>{
      this.isLoading = true;
    },0)
    this.getData();
  }

  getData() {
    this.vechicleService.getVehicles().subscribe({
      next: (res) =>{
        this.dataSourceVehicles.data = res.items;
        this.dataSourceVehicles.paginator = this.paginator;
        this.dataSourceVehicles.sort = this.sort;
        this.isLoading = false;
      },
      error:(error) => {
        this.toastr.error(error.error?.title || 'An error occurred when getting vehicles. Please try again.');
        this.isLoading = false;
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceVehicles.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceVehicles.paginator) {
      this.dataSourceVehicles.paginator.firstPage();
    }
  }
  openCreateVehicleDialog() {
    const dialogRef = this.dialog.open(CreateVehicleComponent, {
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.vechicleService.createVehicle(result).subscribe({
          next: (res) => {
            this.toastr.success('Vehicle created successfully.');
            this.getData();
          },
          error: (error) => {
            this.toastr.error(error.error?.title || 'An error occurred. Please try again.');
          }
        })
      }
    })
  }
  openEditVehicleDialog(element: VehicleResponse)
  {
    const dialogRef = this.dialog.open(EditVehicleComponent, {
      width: '600px',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log(result);
        this.vechicleService.updateVehicle(element.id, result).subscribe({
          next: (res) => {
            this.toastr.success('Vehicle updated successfully.');
            this.getData();
          },
          error: (error) => {
            this.toastr.error(error.error,error.name);//error.error?.title || 'An error occurred. Please try again.'
          }
        })
      }
    })
  }
  openDeleteVehicleDialog(element: VehicleResponse) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: { type: element.registration , name: element.make + ' ' + element.model }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.vechicleService.deleteVehicle(element.id).subscribe({
          next: (res) => {
            this.toastr.success('Vehicle deleted successfully.');
            this.getData();
          },
          error: (error) => {
            this.toastr.error(error.error,error.name);
          }
        });
      }
    });
  }
}
