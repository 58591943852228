import { Configuration, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { environment } from '../environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
export const b2cPolices = {
    names: {
        signUpSignIn: "B2C_1A_signup_signin"
    },
    authorities: {
        signUnSignIn: {
            authority: "https://awlfive.b2clogin.com/awlfive.onmicrosoft.com/B2C_1A_signup_signin",
        }
    },
    authorityDomain: "awlfive.b2clogin.com"
}

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
}

export const msalConfig: Configuration = {
    auth: {
        clientId: '9918b8d6-7487-4fa9-924a-7497d59c1071',
        authority: b2cPolices.authorities.signUnSignIn.authority,
        knownAuthorities: [b2cPolices.authorityDomain],
        redirectUri: '/',
    }, 
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE
    },
    system: {
        loggerOptions: {
            loggerCallback,
            logLevel: LogLevel.Warning,
            piiLoggingEnabled: false
        }
      }
}

export const protectedResources = {
    api: {
        endpoint: environment.apiUrl, //api url
        scopes: ["https://awlfive.onmicrosoft.com/five-api/all.read"],
    },
}

export const loginRequest = {
    scopes: ['email']
}