import { Component,OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderService } from '../../../../services/order.service';
import { PagedQueryResult } from '../../../../models/pagedQueryResult.interface';
import { StatusRequirementResponse } from '../../../../models/orders/order.model';
import { ToastrService } from 'ngx-toastr';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule,MatTableDataSource } from '@angular/material/table';
import { CreateRequirementComponent } from '../create-requirement/create-requirement.component';
import { MatDialog } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../../../../services/auth.service';
import { EditRequirementComponent } from '../edit-requirement/edit-requirement.component';
import { DeleteRequirementComponent } from '../delete-requirement/delete-requirement.component';
import { fadeIn } from '../../../../../assets/animations';

@Component({
  selector: 'app-requirements-index',
  standalone: true,
  imports: [
    MatTableModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule
  ],
  templateUrl: './requirements-index.component.html',
  styleUrl: './requirements-index.component.scss',
  animations:[fadeIn]
})
export class RequirementsIndexComponent implements OnInit {

  requirements!:StatusRequirementResponse[];
  displayedColumns: string[] = ['name', 'description', 'photoRequired', 'createdAt', 'lastUpdatedAt'];
  dataSource = new MatTableDataSource<StatusRequirementResponse>([]);
  role: string = '';
  isAdmin: boolean = false;

  constructor(
    private orderService:OrderService,
    private toastr:ToastrService,
    public dialog: MatDialog, 
    private loginService: AuthService
  ){
    this.loginService.claims$.subscribe((c) => {
      this.role = c['extension_Role'];
    });
  }

  ngOnInit(): void {
    this.getRequirements();
    if(this.role == 'globeadmin' || this.role == 'awladmin') {
      this.displayedColumns.push('actions');
      this.isAdmin = true;
    }
  }
  
  getRequirements(): void {
    this.orderService.getRequirements().subscribe({
      next: (res:PagedQueryResult<StatusRequirementResponse>) =>
        {
          this.requirements = res.items;
          //console.log(this.requirements);
          this.dataSource.data = this.requirements;
      },
      error: (err) => {
        this.toastr.error(err.error,err.name);
      }
    });
  }

  applyFilterStatus(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openCreateRequirementDialog(): void {
    const dialogRef = this.dialog.open(CreateRequirementComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getRequirements();
      }
    });
  }

  openEditRequirementDialog(element: StatusRequirementResponse): void {
    const dialogRef = this.dialog.open(EditRequirementComponent, {
      width: '600px',
      data: element
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getRequirements();
      }
    });
  }

  openDeleteRequirementDialog(element: StatusRequirementResponse): void {
    const dialogRef = this.dialog.open(DeleteRequirementComponent, {
      width: '400px',
      data: element
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getRequirements();
      }
    });
  }

}
