<h1 class="title">Create Schedule</h1>
<div class="container scrollable-form">
  <div class="mx-1">
    <form [formGroup]="scheduleForm">
        <app-search-routes [routeControl]="routeControl"></app-search-routes>
        <app-search-vehicle [vehicleControl]="vehicleControl"></app-search-vehicle>
        <app-user-search [userControl]="driverControl" roleInput="User"></app-user-search>
        <h2 class="title">Select a Date</h2>
        <mat-form-field>
          <mat-label>Date</mat-label>
          <input 
            aria-label="date-picker" 
            matInput 
            [formControl]="dateControl" 
            [matDatepicker]="picker">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        
        <div class="flex-end-actions">
          <button mat-raised-button type="button" mat-dialog-close>Cancel</button>
          <button 
            mat-raised-button 
            color="primary"
            [disabled]="scheduleForm.invalid" 
            (click)="onSubmit()">
            Create
          </button>
        </div>
    </form>
  </div>
</div>
