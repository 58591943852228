<div class="container">
    <div *ngIf="refreshing" class="loading-container">
      <mat-spinner
      [diameter]="80"></mat-spinner>
    </div>
    <div *ngIf="!refreshing">
        @if(communicationLogs.length > 0){
            @for(cl of communicationLogs; track $index)
            {   
            <div class="m-1 mat-elevation-z5">
                <mat-card class="">
                    <mat-card-header class="flex-row">
                        <mat-card-title>{{cl.user.displayName}}</mat-card-title>
                        <mat-card-subtitle>To: {{cl.contact.firstName}} {{cl.contact.lastName}}</mat-card-subtitle>
                        <div>
                            Via: {{cl.communicationType}}
                        </div>
                    </mat-card-header>
                    <mat-card-content class="ms-1">
                        {{cl.note}}
                    </mat-card-content>
                    <mat-card-footer class="my-1 me-1 text-align-end text-secondary">
                        {{formatTimestamp(cl.createdAt.toString())}}
                    </mat-card-footer>
                </mat-card>
            </div>
            }
        }@else{
            <div class="text-end">
                Communication Logs are Empty
            </div>
        }
    </div>
  </div>
  
  
