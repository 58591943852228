<div [hidden]="!hasDevices">
    <div *ngIf="showScanner">
        <mat-form-field appearance="fill">
            <mat-label>Select Device</mat-label>
            <mat-select [value]="deviceSelected" (valueChange)="onDeviceSelectChange($event)">
                <mat-option value="">No Device Selected</mat-option>
                <mat-option *ngFor="let device of availableDevices" [value]="device.deviceId">{{device.label}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

  <div *ngIf="showScanner" class="scanner-shell">
        <div class="scanner-container">
            <div class="scanner-area">
                <zxing-scanner [formats]="allowedFormats" [device]="deviceCurrent" (deviceChange)="onDeviceChange($event)" (scanSuccess)="onCodeResult($event)"
                    (permissionResponse)="onHasPermission($event)" (camerasFound)="onCamerasFound($event)" (scanError)="scanErrorHandler($event)"></zxing-scanner>
                <div class="scanning-line" [class.active]="showScanner"></div>
            </div>
            <div class="button-container">
                <button mat-raised-button (click)="closeCamera()">Close Scanner</button>
            </div>
        </div>
  </div>
</div>
