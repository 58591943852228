import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CommunicationLogResponse, CreateCommunicationLogRequest } from '../models/orders/communicationLog.model';
import { Observable } from 'rxjs';
import { PagedQueryResult } from '../models/pagedQueryResult.interface';

@Injectable({
  providedIn: 'root'
})
export class CommunicationLogsService {
  baseUrl = `${environment.apiUrl}`;
  orderBaseUrl = 'api/orders';
  logsBaseUrl = 'api/logs';
  
  constructor(private http: HttpClient) { }
  //OLD?? dont know why
  // createCommunicationLogForOrder(orderId: string, data: CreateCommunicationLogRequest): Observable<CommunicationLogResponse> {
  //   return this.http.post<CommunicationLogResponse>(`${this.baseUrl}/${this.orderBaseUrl}/${orderId}/logs`, data);
  // }
  //new
  createCommunicationLogForOrder(orderId: string, data: CreateCommunicationLogRequest): Observable<CommunicationLogResponse> {
    return this.http.post<CommunicationLogResponse>(`${this.baseUrl}/${this.logsBaseUrl}`, data);
  }
  getCommunicationLogsByOrderId(orderId :string):Observable<PagedQueryResult<CommunicationLogResponse>>
  {
    return this.http.get<PagedQueryResult<CommunicationLogResponse>>(`${this.baseUrl}/${this.orderBaseUrl}/${orderId}/logs`);
  }
}
