import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { OrderService } from '../../../services/order.service';
import { ScheduleOrderResponse } from '../../../models/schedule.model';
import { OrderResponse } from '../../../models/orders/order.model';
import { Router } from '@angular/router';
@Component({
  selector: 'app-order-schedules-index',
  standalone: true,
  imports: [
    MatButtonModule,
    MatTableModule,
  ],
  templateUrl: './order-schedules-index.component.html',
  styleUrl: './order-schedules-index.component.scss'
})
export class OrderSchedulesIndexComponent implements OnInit {
  @Input() order: OrderResponse;
  displayedColumns: string[] = ['type', 'status', 'date','actions'];
  dataSource = new MatTableDataSource<ScheduleOrderResponse>();

  constructor(
    private orderService: OrderService,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.getOrderSchedules();
  }

  getOrderSchedules(): void {
    this.orderService.getScheduleOrders(this.order.id).subscribe({
      next: (data) => {
        this.dataSource.data = data;
      },
      error: (error) => {
        console.error('Error fetching order schedules', error);
      }
    });
  }
  viewSchedule(id: string): void {
    this.router.navigate([`/schedules/${id}`]);
  }

}
