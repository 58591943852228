import {  Injectable } from '@angular/core';
import { DashboardTodaysScheduleComponent } from '../components/schedules/dashboard-todays-schedule/dashboard-todays-schedule.component';
import { SchedulesIndexComponent } from '../pages/schedules/schedules-index/schedules-index.component';
import { DashboardCrematoriumComponent } from '../components/staff-dashboards/dashboard-crematorium/dashboard-crematorium.component';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor() { }
  private layouts = [
    {
      user: 'awladmin',//will be for driver
      dashboards: [
        //{component: DashboardCrematoriumComponent,data:{role:'',params:['In Freezer','In Dry Room']}},
        {component: DashboardTodaysScheduleComponent,data:{role:'awladmin',userId:``}},
      ],
    },

  ]
  //might call the AuthService in here instead of home and just request the start of the process in home
  getUserLayout(role:string,userId:string)
  {
    const layout = this.layouts.find(layout => layout.user === role)
    layout?.dashboards.forEach(element => {
      element.data.role = role
      element.data.userId=userId
    });
    return layout?.dashboards
  }
}
